export const capitalize = (str: string) => {
  if(typeof str !== 'string') return str;

  return `${str[0].toUpperCase()}${str.slice(1).toLocaleLowerCase()}`
}

export const isJSON = (data: any) => {
  if(typeof data !== 'string') return false;

  let ret = true;
  try { JSON.parse(data); }
  catch(e) { ret = false; }
  return ret;
}