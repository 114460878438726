import React from 'react';

interface IProps {
  children: any;
  precision?: number;
}

export default function CurrencyFormat({ children, precision }: IProps) {
  return (
    <>
      {Number(children)
        .toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: precision || 2
        })}
    </>
  );
}
