import React, { useState } from 'react';
import ColumnsFilter from '../components/Columns';

export interface IColumn {
  id: string;
  name: string;
  active: boolean;
  component?: any;
  sortFunc?: any;
}

interface ILSColumn {
  id: string;
  active: boolean;
}

const version = 2;

export default function useColumns(page: string, defaultColumns: IColumn[], maxColumnsSelected?: number): [IColumn[], React.FC] {
  const lsName = `${page}_columns_${version}`;
  const saved = window.localStorage.getItem(lsName);
  const initial = [...defaultColumns];

  if (saved) {
    JSON.parse(saved).columns.forEach((c: ILSColumn) => {
      const i = initial.findIndex((col) => col.id === c.id);
      if (i !== -1) {
        initial[i] = { ...initial[i], ...c };
      }
    });
  }

  const [state, setState] = useState<IColumn[]>(initial);

  function setColumns(update: { [key: string]: boolean }) {
    const columns = [...state];
    Object.keys(update).forEach((c) => {
      const i = columns.findIndex((col) => col.id === c);
      if (i !== -1) {
        columns[i].active = update[c];
      }
    })
    const toLs: ILSColumn[] = columns.map((c) => ({ id: c.id, active: c.active }));
    window.localStorage.setItem(lsName, JSON.stringify({ columns: toLs }));
    setState(columns);
  }

  const component: React.FC = () => <ColumnsFilter columns={state} onChange={setColumns} maxColumnsSelected={maxColumnsSelected} />;

  return [state.filter((c) => c.active), component];
}
