import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 30,
    [theme.breakpoints.down(700)]: {
      paddingLeft: 0,
      overflowWrap: 'anywhere'
    },
  },
}));

interface IProps {
  data: any;
}

export default function Extra({ data }: IProps) {
  const classes = useStyles();
  switch (typeof (data)) {
    case 'object':
      return (
        <>
          {Array.isArray(data)
            ? data.join(', ')
            : (
              <div className={classes.block}>
                {Object.keys(data).map((k) => <div key={k}>{k}: <Extra data={data[k]} /></div>)}
              </div>
            )}
        </>
      );
    default:
      return <>{`${data}`}</>;
  }
}
