import React, { useEffect, useMemo } from 'react';

import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

import DetailsPopup from '../../../components/Layout/DetailsPopup';
import { Checkbox, CurrencySelect, Input, ProtectionInput, Select } from '../../../components/Form';
import useFormStyles from '../../../hooks/useFormStyles';
import { IApm, IFormApmSettings, useCreateApm, useUpdateApm } from '../../../api/apm';
import { useCurrenciesList } from '../../../api/currency';
import { usePaymentProvidersList } from '../../../api/paymentProviders';
import { composeValidators, minValue, required } from '../../../validators';
import { IKycCountry, useKycCountries } from '../../../api/kyc';
import { useBreakpoints } from '../../../hooks/useBreakpoints ';
import Descriptor from "../../../components/Descriptor";
import { mappedFormApmMetadata, prepareFormApmSettings, prepareInitialFormApmMetadata } from '../../../helpers/apmHelper';

const useStyles = makeStyles((theme) => ({
  buttonRemoveWrapper: {
    paddingTop: theme.spacing(2),
  },
  button: {
    minHeight: theme.spacing(5),
  }
}));

type IFormApmData  = Omit<IApm, 'settings'> & {settings: IFormApmSettings}

interface IProps {
  apm?: IApm;
  apmLoading: boolean;
  isCreate: boolean;
  onClose: ()=> void;
  onSave: ()=> void;
}

export default function ApmDetails({ apm, apmLoading, isCreate = false, onClose, onSave }: IProps) {
  const formClasses = useFormStyles();
  const classes = useStyles();
  const [currenciesList, loadingCurrencies, loadCurrencies] = useCurrenciesList();
  const [providersList, loadingProviders, loadProviders] = usePaymentProvidersList();
  const [{ items: countries }, loadingCountries, loadCountries] = useKycCountries();
  const isResolutionSevenHundred = useBreakpoints(700);
  const [create, creating] = useCreateApm((res: any) => {
    if (res.success) {
      onSave();
    }
  });
  
  const [update, updating] = useUpdateApm((res: any) => {
    if (res.success) {
      onSave();
    }
  });

  useEffect(() => {
    loadCurrencies();
    loadProviders({});
    loadCountries();
  }, [])

  const currencies = useMemo(() => {
    return currenciesList.items.map((i: any) => i.type === 'FIAT' && { label: i.currency, value: i.currency }).filter((v: any) => !!v);
  }, [currenciesList]);

  const providers = useMemo(() => {
    return providersList.items.map((i: any) => ({ label: i.name, value: i.id })).filter((v: any) => !!v);
  }, [providersList]);

  function sortCountries(data:any){
    return data.map((code: any) => countries.find((c: IKycCountry) => c.code === code)).filter((c: any) => c !== undefined).sort((a: any, b: any) => a.name.localeCompare(b.name)).map((c: IKycCountry) => c.code)
  }

  const formData: IFormApmData | { id: null } = (!isCreate && apm) 
    ? { ...apm,
        countries: sortCountries(apm.countries),
        settings: { ...apm.settings, metadata: apm.settings?.metadata ? prepareInitialFormApmMetadata(apm.settings.metadata): [] } 
      }
    : { id: null };

  return (
    <DetailsPopup onClose={onClose} loading={apmLoading || creating || updating} >
      {formData
        ? (
          <>
            <Typography variant="h6" color="primary">{
              formData?.id
                ? `Update APM id ${formData?.id}`
                : 'Create new APM'
            }</Typography>
            <Form
              onSubmit={(values) => formData.id
                ? update({
                  ...values, id: formData.id, externalId:values.externalId,
                  excludedMerchants: !values.excludedMerchants ? [] : String(values.excludedMerchants).split(","),
                  currencies: values.provider==='SAFECHARGE'? null : values.currencies,
                  countries: values.provider==='SAFECHARGE'? null : values.countries,
                  settings: prepareFormApmSettings(values.provider, values.settings)
                })
                : create({ ...values, 
                  excludedMerchants: !values.excludedMerchants ? [] : values.excludedMerchants.split(","),
                  settings: { ...values.settings, metadata: mappedFormApmMetadata(values.settings?.metadata) } 
                })}
              initialValues={{ ...formData, '2fa': '' }}
              mutators={{
                ...arrayMutators
              }}
              render={({ handleSubmit, values, form: { mutators: { push } } }) => (
                <form
                  autoComplete='nope'
                  onSubmit={handleSubmit}
                >
                  <div className={formClasses.row}>
                    <Input name="name" label="Name"/>
                    <Input type='search' name="externalId" autoComplete='xyz123' label="ExternalId" id='external_id'/>
                    <Select
                      name="enabled"
                      label="Enabled"
                      options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}
                      margin="normal"
                      validate={required}
                      noEmptyOption
                      fullWidth={isResolutionSevenHundred}
                    />
                  </div>

                  <div className={formClasses.row}>
                    <Select
                      name="provider"
                      label="Provider"
                      options={providers}
                      margin="normal"
                      noEmptyOption
                      validate={required}
                      fullWidth={isResolutionSevenHundred}
                    />
                    <Select
                      name="flow"
                      label="Url open flow"
                      options={[{label: 'Iframe', value: 'IFRAME'}, {label: 'Redirect', value: 'REDIRECT'}]}
                      margin="normal"
                      noEmptyOption
                      validate={required}
                      fullWidth={isResolutionSevenHundred}
                    />
                    <Descriptor group="skipKyc" title="Skip KYC" top={7} className="v-center">
                      <Checkbox
                        name="skipKyc"
                        label="Skip KYC"
                      />
                    </Descriptor>
                  </div>
                  <Typography variant="body2" color="primary">Available APMs</Typography>
                  <div className={formClasses.row}>
                    <Input type="number" name="fee.deposit" label="Deposit"/>
                    <Input type="number" name="fee.depositFixed" label="Deposit Fixed"/>
                    <Input type="number" name="fee.depositFeeMin" label="Deposit Min Fee"/>
                    <Select
                      name="fee.fixedCurrency"
                      label="Fixed Fee Currency"
                      options={currencies}
                      margin="normal"
                      validate={required}
                      fullWidth={isResolutionSevenHundred}
                    />
                  </div>
                  <div className={formClasses.row}>
                    <Input type="number" name="fee.withdrawal" label="Withdrawal" margin="normal"/>
                    <Input type="number" name="fee.withdrawalFixed" label="Withdrawal Fixed" margin="normal"/>
                  </div>
                  <Typography variant="body2" color="primary">Display settings</Typography>
                  <div className={formClasses.row}>
                    <Input type="text" name="settings.logo" label="Settings Logo" />
                    <Input type="text" name="settings.localizedName.en" label="Localized name(en)" />
                  </div>
                  <Typography variant="body2" color="primary">Other settings</Typography>
                  <Descriptor group="settings.checkOnlyApmKycLimit" title="Check only apm KYC limit" top={7}>
                    <Checkbox name="settings.checkOnlyApmKycLimit" label="Check only apm KYC limit" />
                  </Descriptor>
                  <div className={formClasses.row}>
                    <CurrencySelect
                      name="currencies"
                      label="Currencies"
                      multi
                      margin="normal"
                      fullWidth
                      supported={values.supportedCurrencies}
                    />
                  </div>
                  <div className={formClasses.row}>
                    <Select
                      name="countries"
                      label={`Countries (${values?.countries?.length || 0})`}
                      options={countries.map((c: IKycCountry) => ({value: c.code, label: `${c.name} (${c.code})`}))}
                      multi
                      loading={loadingCountries}
                      margin="none"
                      fullWidth={isResolutionSevenHundred}
                    />
                  </div>
                  <div className={formClasses.row}>
                    <Input type="text" name="excludedMerchants" margin="none" label="Excluded merchants"/>
                  </div>
                  <div>
                    <FieldArray name="settings.metadata">
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name} className={formClasses.row}>
                              <Input
                                name={`${name}.id`}
                                label="Bank ID"
                                type="search"
                                autoComplete="xyz123"
                                validate={composeValidators(
                                  required,
                                  (v: any) => minValue(v, 2)
                                )}
                              />
                              <Input
                                name={`${name}.name`}
                                label="Bank Name"
                                type="search"
                                autoComplete="xyz123"
                                validate={composeValidators(
                                  required,
                                  (v: any) => minValue(v, 2)
                                )}
                              />
                              <Input
                                name={`${name}.logo`}
                                label="Bank Logo"
                                type="search"
                                autoComplete="xyz123"
                                validate={composeValidators(
                                  required,
                                  (v: any) => minValue(v, 3)
                                )}
                              />
                              <div className={classes.buttonRemoveWrapper}>
                                <Button type="button"
                                  variant="contained"
                                  className={classes.button}
                                  color="secondary"
                                  onClick={() => fields.remove(index)}
                                >
                                  Remove Bank
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </div>

                  <div>
                    <Button 
                      type="button" 
                      variant="contained" 
                      onClick={() => push('settings.metadata')}
                    >
                      Add Bank
                    </Button>
                  </div>

                  <br />
                  <Typography variant="body2" color="primary">Required fields settings</Typography>
                  <div className={formClasses.row}>
                    <FieldArray name="settings.fields">
                      {({ fields }) => (
                          <div>
                            {fields.map((name, index) => (
                                <div key={name} className={formClasses.row}>
                                  <Input
                                      name={`${name}.id`}
                                      label="Field Name"
                                      type="search"
                                      autoComplete="xyz123"
                                      validate={composeValidators(
                                          required,
                                          (v: any) => minValue(v, 2)
                                      )}
                                  />
                                  <Descriptor group="skipKyc" title="Skip KYC" top={7} className="v-center">
                                    <Checkbox
                                        name={`${name}.checkFieldFromInternalStorage`}
                                        label="Get field data from internal storage"
                                    />
                                  </Descriptor>

                                  <div className={classes.buttonRemoveWrapper}>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        className={classes.button}
                                        color="secondary"
                                        onClick={() => fields.remove(index)}
                                    >
                                      Remove Field
                                    </Button>
                                  </div>
                                </div>
                            ))}
                          </div>
                      )}
                    </FieldArray>
                  </div>
                  <div>
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => push('settings.fields')}
                    >
                      Add Field
                    </Button>
                  </div>

                  <div className={formClasses.row}>
                    <ProtectionInput />
                  </div>
                  <div className={formClasses.actions}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={updating || creating}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              )}
            />
          </>
        ) : <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  )
}
