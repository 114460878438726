import React from 'react'

import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Select } from '../../../../components/Form';
import CreateIcon from '@material-ui/icons/Create';

import Paper from '@material-ui/core/Paper';
import useMobileStyles from '../../../../hooks/useMobileStyles';
import Tablecell from '../../../../components/TableCell/TableCell';

interface IProps {
  data: any; 
  changedFields: string[];
  onChangeField: any;
  classes: any;
  historyMarkers: any;
}

const yesNoList = [
  {label: 'Yes', value: true},
  {label: 'No', value: false}
]

const thigrPartyEnvolvedList = [
  {label: 'No', value: 'NO'},
  {label: 'Yes, licensed', value: 'YES_LICENSED'},
  {label: 'Yes, unlicensed', value: 'YES_UNLICENSED'},
]

export default function InterfaceRisks({data, changedFields, onChangeField, classes, historyMarkers}: IProps) {

  const mobileClasses = useMobileStyles();

  return (
    <>
      <Typography>Interface risks</Typography>
      <Paper classes={{root: classes.paper}} variant="outlined">
        <Table>
          <TableHead className={mobileClasses.tableHead}>
            <TableRow>
              <TableCell classes={{root: classes.firstColumn}}>Interface risks</TableCell>
              <TableCell classes={{root: classes.secondColumn}}>Value</TableCell>
              <TableCell>Risk score</TableCell>
              <TableCell>Updated By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Interface risks">A  third party is involved in the onboarding process</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select 
                    name="thirdPartyIsInvolved"
                    label=""
                    options={thigrPartyEnvolvedList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('thirdPartyIsInvolved', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('thirdPartyIsInvolved') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.thirdPartyIsInvolvedValue}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers.thirdPartyIsInvolved?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Interface risks">Customer IP is different from the country of residency</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select 
                    name="customerIpNotInResidenceCountry"
                    label=""
                    options={yesNoList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('customerIpNotInResidenceCountry', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('customerIpNotInResidenceCountry') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.customerIpNotInResidenceCountryValue}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers.customerIpNotInResidenceCountry?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Interface risks">Non face-to-face verification process</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select 
                    name="nonFaceToFaceValidationProcess"
                    label=""
                    options={yesNoList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('nonFaceToFaceValidationProcess', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('nonFaceToFaceValidationProcess') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.nonFaceToFaceValidationProcessValue}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers.nonFaceToFaceValidationProcess?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Interface risks">Online real-time interview was taken</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select 
                    name="onlineInterviewTaken"
                    label=""
                    options={yesNoList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('onlineInterviewTaken', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('onlineInterviewTaken') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.onlineInterviewTakenValue}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers.onlineInterviewTaken?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}