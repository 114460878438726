import React from 'react';

import { TableCell, TableHead,Table, TableRow, TableBody, makeStyles } from '@material-ui/core';
import Tablecell from '../../../../components/TableCell/TableCell';
import DateView from '../../../../components/DateView';
import { IWalletOperation } from '../../../../api/walletOperations';
import useMobileStyles from '../../../../hooks/useMobileStyles';

const useStyles = makeStyles((theme) => ({
   root: {},
   table: {
     marginBottom: theme.spacing(2)
   },
 }));
 
interface IOperationsList {
   operations: IWalletOperation[]
}

function OperationsList({ operations }:IOperationsList) {

   const classes = useStyles();
   const mobileClasses = useMobileStyles();

   return(
      <Table size="small" className={classes.table}>
         <TableHead className={mobileClasses.tableHead}>
            <TableRow>
            <TableCell>Wallet</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Balance</TableCell>
            <TableCell>Locked</TableCell>
            <TableCell>Issued by</TableCell>
            <TableCell>Issuer type</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Updated</TableCell>
            </TableRow>
         </TableHead>
         <TableBody>
            {operations.map((o: IWalletOperation) => (
            <TableRow key={o.id} className={mobileClasses.row}>
               <Tablecell title="Wallet">{o.currency}</Tablecell>
               <Tablecell title="Type">{o.type}</Tablecell>
               <Tablecell title="Balance">{o.balance}</Tablecell>
               <Tablecell title="Locked">{o.locked}</Tablecell>
               <Tablecell title="Issued by">{o.issuedBy}</Tablecell>
               <Tablecell title="Issuer type">{o.issuerType}</Tablecell>
               <Tablecell title="Created">
                  <DateView value={o.createdAt} />
               </Tablecell>
               <Tablecell title="Updated" isLast>
                  <DateView value={o.updatedAt} />
               </Tablecell>
            </TableRow>
            ))}
         </TableBody>
      </Table>
   );
}
export default OperationsList;