import React from 'react';
import { Form } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { IBinCodeItem, useCreateBinCodeItem } from '../../../api/binCodes';
import DetailsPopup from '../../../components/Layout/DetailsPopup';
import { Input, ProtectionInput } from '../../../components/Form';
import useFormStyles from '../../../hooks/useFormStyles';
import { required } from '../../../validators';

interface IProps {
  onSave: any;
  onClose: any;
}

export default function CreateBinCodeItemPopup({ onSave, onClose }: IProps) {
  const formClasses = useFormStyles();
  const [save, saving] = useCreateBinCodeItem((res: any) => {
    if (res.success) {
      onSave(res.data);
      onClose();
    }
  });

  return (
    <DetailsPopup onClose={onClose}>
      <Typography variant="h6" color="primary">Create BIN code item</Typography>
      <Form
        onSubmit={save}
        initialValues={{
          binCode: '',
          country: '',
          '2fa': ''
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={formClasses.row}>
              <Input
                name="binCode"
                label="Bin Code"
                type="search"
                autoComplete="xyz123"
                validate={required}
                fullWidth
              />
              <div />
            </div>
            <div className={formClasses.row}>
              <Input
                type="search"
                autoComplete="xyz123"
                name="country"
                label="Country"
                validate={required}
                fullWidth
              />
              <div />
            </div>
            <div className={formClasses.row}>
              <ProtectionInput />
              <div />
            </div>
            <div className={formClasses.actions}>
              <Button
                type="submit"
                variant="contained"
                disabled={saving}
              >
                Save
              </Button>
            </div>
          </form>
        )}
      />
    </DetailsPopup>
  )
}
