import React from 'react';

import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateView from '../../components/DateView';
import { IColumn } from '../../hooks/useColumns';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { LegalEntity } from '../../api/legalEntity';
import useMobileStyles from '../../hooks/useMobileStyles';
import Tablecell from '../../components/TableCell/TableCell';
import TableCellId from '../../components/TableCellId/TableCellId';

interface IProps {
   items: LegalEntity[];
   columns: IColumn[];
   openDetails: (id: string) => void;
}

const content: { [key: string]: (i: LegalEntity) => void } = {
   id: ({ id }) => <TableCellId key="id">{id}</TableCellId>,
   name: ({ name }) => <Tablecell key="name" title="Name">{name}</Tablecell>,
   seon: ({ seon }) => <Tablecell key="seon" title="Seon">{seon}</Tablecell>,
   domain: ({ settings }) => <Tablecell key="domain" title="Domain">{settings.domain}</Tablecell>,
   sumSubSecret: ({ sumSubSecret }) => <Tablecell key="sumSubSecret" title="SumSubSecret">{sumSubSecret}</Tablecell>,
   sumSubToken: ({ sumSubToken }) => <Tablecell key="sumSubToken" title="SumSubToken">{sumSubToken}</Tablecell>,
   createdAt: ({ createdAt }) => <Tablecell key="created" title="Created"><DateView value={createdAt} /></Tablecell>,
   updatedAt: ({ updatedAt }) => <Tablecell key="updated" title="Updated" isLast><DateView value={updatedAt} /></Tablecell>,
};

export function LegalEntityList({ items, columns, openDetails }: IProps) {
   const globalClasses = useGlobalStyles();
   const activeCols = columns.filter((c) => c.active);
   const mobileClasses = useMobileStyles();

   return (
      <Table size="small">
         <TableHead className={mobileClasses.tableHead}>
            <TableRow>
               {activeCols.map((c) => <TableCell key={c.id}>{c.name}</TableCell>)}
            </TableRow>
         </TableHead>
         <TableBody>
            {items.map((i) => (
               <TableRow hover className={clsx(globalClasses.pointer, mobileClasses.row)} key={i.id} onClick={() => openDetails(i.id)}>
                  {activeCols.map((c) => content[c.id](i))}
               </TableRow>
            ))}
         </TableBody>
      </Table>
   );
}