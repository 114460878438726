import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { Form } from 'react-final-form';
import { ProtectionInput } from '../../../../components/Form';
import CustomerRisks from './CustomerRisks';
import GeographicalRisks from './GeographicalRisks';
import InterfaceRisks from './InterfaceRisks';
import ProductRisks from './ProductRisks';

import TempReinitCustomerScore from './TempReinitCustomerScore';
import HistoryChangesDialog from './HistoryChangesDialog';

import { useAdminScoringParamsData, useAdminScoringCustomerParamsHistory, useAdminScoringParamsUpdate } from '../../../../api/kyc';

import useFormStyles from '../../../../hooks/useFormStyles';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(1)}px`,
    margin: `${theme.spacing(1)}px 0 0 0 !important`
  },
  scoring: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '15px'
    },
  },
  scoringLeftSide: {
    display: 'flex',
    alignItems: 'center',
    '&>div:first-child': {
      marginRight: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '15px'
    },
  },
  generalScore: {
    fontWeight: 600,
    padding: theme.spacing(1),
  },
  noData: {
    fontWeight: 400,
  },
  historyButton: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    cursor: 'pointer',
    paddingBottom: theme.spacing(0.6)
  },
  row: {
    flexDirection: 'column',
    '& tr:last-child': {
      '& td': {
        border: 'none'
      }
    },
    '& td': {
      padding: theme.spacing(1),
    }
  },
  firstColumn: {
    width: theme.spacing(40)
  },
  secondColumn: {
    width: theme.spacing(28)
  },
  input: {
    minHeight: 'auto',
  },
  outlined: {
    padding: theme.spacing(1)
  },
  cellInnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
     },
  },
  iconWrapper: {
    width: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
}))

const prepareHistoryMarkers = (history: any) => {
  const historyHash = {};

  function setItem(key: string, value: any) {
    //@ts-ignore
    historyHash[key] = historyHash[key] || {wasChanged: false, values: []}
    //@ts-ignore
    historyHash[key].wasChanged = historyHash[key].values.length ? !!historyHash[key].values.find((item: any) => item !== value) : false; 
    //@ts-ignore
    historyHash[key].values.push(value);
  }

  history.forEach((historyItem: any) => {
    for (let e in historyItem) {
      if(typeof historyItem[e] === "object"){
        for (let i in historyItem[e]) {
          setItem(e + '.' +i, historyItem[e][i]);
        }
      } else {
        setItem(e, historyItem[e]);
      }
    }
  })

  return historyHash;
}

export default function IndividualScore({customer}: any) {
  const formClasses = useFormStyles();
  const classes = useStyles();
  const [changedFields, setChangedFields] = useState<string[]>([]);
  const [historyChangesDialogIsOpened, setOpenHistoryChangesDialog] = useState(false);
  // const [historyMarkers, setHistoryMarkers] = useState({});
  const openHistoryChangesDialog = () => {
    setOpenHistoryChangesDialog(true);
  }

  const onCloseHistoryChangesDialog = () => {
    setOpenHistoryChangesDialog(false);
  }

  const [{data}, loadingAdminScoringParams, loadAdminScoringParams] = useAdminScoringParamsData();
  const [scoringCustomerParamsHistory, loadingAdminScoringCustomerParamsHistory, loadAdminScoringCustomerParamsHistory] = useAdminScoringCustomerParamsHistory();
  const [update, updating] = useAdminScoringParamsUpdate((res: any) => {
    if (res.success) {
      // onSave();
    }
  });

  // useEffect(() => {
  //   console.log('scoringCustomerParamsHistory.data.history', scoringCustomerParamsHistory.data.history)
  //   if (scoringCustomerParamsHistory.data.history) {
  //     setHistoryMarkers(prepareHistoryMarkers(scoringCustomerParamsHistory.data.history));
  //   }
  // }, [scoringCustomerParamsHistory]);

  const historyMarkers = scoringCustomerParamsHistory.data?.history ? prepareHistoryMarkers(scoringCustomerParamsHistory.data.history) : {};
  
  useEffect(() => {
    loadAdminScoringParams({id: customer.id});
    loadAdminScoringCustomerParamsHistory({id: customer.id});
  }, [customer])

  const onSubmit = (submitData: any) => {
    update({customerId: customer.id, scoringProperties:{...submitData}, '2fa': submitData['2fa']  });
  }

  const onChangeField = (changedField: string, value: any) => {
    if (!changedFields.includes(changedField)) {
      setChangedFields([...changedFields, changedField])
    } else if (data[changedField] === value) {
      setChangedFields(changedFields.filter((item: any) => item !== changedField))
    }
  }

  const getScoringColor = (scoring: number) => {
    let color = '#ff4444';
    if(scoring <= 1.75) color = '#1aff00';
    if(1.75 < scoring && scoring <= 2.49) color = 'yellow';
    return color
  }
  
  return (
    <div>
      <div className={classes.scoring}>
        <div className={classes.scoringLeftSide}>
          <div>
            <div>Inherit risk: 
              {data.value?.inherentRisk 
                ? <span style={{color: getScoringColor(data?.value.inherentRisk)}} className={classes.generalScore}>{data?.value.inherentRisk}</span> 
                : <span className={clsx(classes.generalScore, classes.noData)}>n/d</span>
              }
            </div>
            <div>Residual risk: 
              {data.value?.residualRisk 
                ? <span style={{color: getScoringColor(data?.value.residualRisk)}} className={classes.generalScore}>{data?.value.residualRisk}</span> 
                : <span className={clsx(classes.generalScore, classes.noData)}>n/d</span>
              }
            </div>
          </div>
          <div className={classes.historyButton} onClick={() => openHistoryChangesDialog()}>Show customer scoring history</div>
        </div>
        <TempReinitCustomerScore customerId={customer.id} load={loadAdminScoringParams} />
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={{...data, '2fa': ''}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={formClasses.root}>
            <div className={clsx(formClasses.row, classes.row)}>
              <CustomerRisks data={data} classes={classes} changedFields={changedFields} onChangeField={onChangeField} historyMarkers={historyMarkers} />
            </div>
            <div className={clsx(formClasses.row, classes.row)}>
              <GeographicalRisks data={data} classes={classes} changedFields={changedFields} onChangeField={onChangeField} historyMarkers={historyMarkers} />
            </div>
            <div className={clsx(formClasses.row, classes.row)}>
              <ProductRisks data={data} classes={classes} changedFields={changedFields} onChangeField={onChangeField} historyMarkers={historyMarkers} />
            </div>
            <div className={clsx(formClasses.row, classes.row)}>
              <InterfaceRisks data={data} classes={classes} changedFields={changedFields} onChangeField={onChangeField} historyMarkers={historyMarkers} />
            </div>
            <div className={clsx(formClasses.row, classes.row)}>
              <ProtectionInput />
            </div>
            <div className={formClasses.actions}>
              <Button
                variant="contained"
                type="submit"
                // disabled={creating || updating}
              >Save fees</Button>
            </div>
          </form>
        )}
      />
      <HistoryChangesDialog open={historyChangesDialogIsOpened} onClose={onCloseHistoryChangesDialog} data={scoringCustomerParamsHistory.data?.history} />
    </div>
  )
}