import React, {useEffect, useMemo} from 'react';
import {Select} from "../../../components/Form";
import {usePaymentProvidersList} from "../../../api/paymentProviders";
import {usePaymentGroupList} from "../../../api/paymentGroup";
import TableFilter from "../../../components/Filter";

export interface IPaymentMethodFilter {
  provider?: string;
  group?: string;
  enabled?: boolean;
}

interface IProps {
  filter: IPaymentMethodFilter;
  onChange: any;
}

export default function PaymentMethodFilter({ filter, onChange }: IProps) {
  const [providersList, loadingProviders, loadProviders] = usePaymentProvidersList();
  const [groupList, loadingGroups, loadGroups] = usePaymentGroupList();

  useEffect(() => {
    loadProviders({});
    loadGroups();
  }, [])

  const providers = useMemo(() => {
    return providersList.items.map((i: any) => ({ label: i.name, value: i.id })).filter((v: any) => !!v);
  }, [providersList]);

  const groups = useMemo(() => {
    return groupList.items.map((i: any) => ({ label: i.name, value: i.id })).filter((v: any) => !!v);
  }, [groupList]);

  return (
    <TableFilter filter={filter} onApply={onChange}>
      <Select
        name="enabled"
        label="Enabled"
        options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
      />
      <Select
        name="group"
        label="Group"
        options={groups}
      />
      <Select
        name="provider"
        label="Provider"
        options={providers}
      />
    </TableFilter>
  );
}
