import React from 'react';

import CustomerFeesForm, { IFeesFormProps } from './Fees/FeesForm';
import CustomerSettingsView from './Fees/SettingsView';

export default function FeesTab({ customer, updateSettings }: IFeesFormProps) {
  return (
    customer.authorities?.includes('ROLE_MERCHANT')
      ? <CustomerFeesForm customer={customer} updateSettings={updateSettings} />
      : <CustomerSettingsView customer={customer} />
  );
}
