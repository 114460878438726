import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import DetailsPopup from '../../../components/Layout/DetailsPopup';
import TabsPanel from '../../../components/Tabs';
import KYCOverview from './OverviewTab';
// import ScreeningTab from './Screening';
import KycInternalComments from './InternalComments';
import IndividualScore from './IndividualScore/index';
import ApplicationActivityTab from './ApplicationActivity/ApplicationActivityTab';
import { useKycDetails, useUpdateKycStatus } from '../../../api/kyc';
import { useCustomerDetails } from '../../../api/customers';

interface IProps {
  id: string;
  onClose: any;
}

export default function KYCDetails({ id, onClose }: IProps) {
  const [{ data, error }, loading, load, updateApplication] = useKycDetails();
  const [{ data: customer }, loadingCustomer, loadCustomer] = useCustomerDetails();
  const [update, updating] = useUpdateKycStatus((res: any) => {
    if (res.success) {
      load(id);
    }
  }, 'KYC application updated');
  const [activeTab, setActiveTab] = useState();

  useEffect(() => {
    load(id);
  }, [id]);

  useEffect(() => {
    if (data.customerId) {
      loadCustomer(data.customerId);
    }
  }, [data.customerId]);

  const tabs = [
    {
      title: 'Overview',
      content: <KYCOverview
        data={data}
        onSave={update}
        saving={updating}
        customer={customer}
        updateApplication={updateApplication}
        setTab={setActiveTab}
        updateDetails={() => load(id)}
      />
    },
    {
      title: 'Individual score',
      content: <IndividualScore
        customer={customer}
      />
    },
    {
      title: 'Application activity',
      content: <ApplicationActivityTab data={data.subSumCallbacks} />
    },
    // {
    //   title: 'EDD',
    //   content: <ScreeningTab application={data} updateApplication={updateApplication} />
    // },
    {
      title: 'Internal comments',
      content: <KycInternalComments type="all" data={data} updateApplication={updateApplication} />
    }
  ];

  return (
    <DetailsPopup onClose={onClose} loading={loading || loadingCustomer} width={800}>
      {Object.keys(data).length
        ? (
          <>
            <Typography variant="h6" color="primary">KYC application {data.id} ({data.customerEmail})</Typography>
            <TabsPanel tabs={tabs} activeTab={activeTab} />
          </>
        ) : <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  );
}
