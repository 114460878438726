import React, { useEffect } from 'react';

import Typography from '@material-ui/core/Typography';
import TabsPanel from '../../../components/Tabs';
import DetailsPopup from '../../../components/Layout/DetailsPopup';
import { LegalEntityConfigForm } from './LegalEntityConfig';
import {
  useLegalEntityCreate,
  useLegalEntityDelete,
  useLegalEntityOne,
  useLegalEntityUpdate
} from '../../../api/legalEntity';
import {LegalEntitySettingsForm} from "./LegalEntitySettings";

interface IProps {
  id: string;
  onClose: () => void;
  onUpdate: () => void;
}

export function LegalEntityDetails({ id, onClose, onUpdate }: IProps) {
  const [{ data }, loading, load] = useLegalEntityOne();
  const [create, creating] = useLegalEntityCreate((res: any) => {
    if (res.success) {
      onUpdate();
    }
  });

  const [update, updating] = useLegalEntityUpdate((res: any) => {
    if (res.success) {
      onUpdate();
    }
  });

  const [deleteEntity, deleting] = useLegalEntityDelete((res: any) => {
    if (res.success) {
      onUpdate();
    }
  });

  function save(item: any){
    id !== 'new' ? update(item) : create(item);
  }

  useEffect(() => {
    id !== 'new' && load(id);
  }, [id])

  const tabs = [
    {
      title: 'Overview',
      content: <LegalEntityConfigForm data={data} view={id !== 'new'} onSave={save} onDelete={deleteEntity} updating={creating || updating || deleting}/>
    },
    {
      title: 'Settings',
      content: <LegalEntitySettingsForm data={data} onSave={save} saving={creating || updating  || deleting}/>
    }
  ];

  return (
    <DetailsPopup loading={loading} onClose={onClose}>
      {id === 'new' || data
        ? (
          <>
            <Typography color='primary' variant="h6">{id === 'new' ? 'New Legal Entity' : `Update Legal Entity id ${data.id}`}</Typography>
            <TabsPanel tabs={tabs} />
          </>
        ) : <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  )
}
