import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import EmptyList from '../../components/EmptyList';
import Loader from '../../components/Loader';
import { Uploader } from '../../components/Form';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { useKycCountries, useKycCountriesImport, IKycCountry } from '../../api/kyc';
import KYCCountriesList from './KycCountriesList';
import CountryDetails from './Details';
import KYCCountriesFilter, { ICountriesFilter } from './KycCountriesFilter';
import useFilter from '../../hooks/useFilter';
import useColumns, { IColumn } from '../../hooks/useColumns';
import useMobileStyles from '../../hooks/useMobileStyles';
import FilterWrapper, { ICollapseHandle } from '../../components/FilterWrapper/FilterWrapper';

const emptyFilter: ICountriesFilter = {
  code: null,
  highRisk: null,
  supported: null,
  riskScore: null,
  scoreMin: null,
  scoreMax: null,
  EU: null,
  enableOccasionalTransactions: null,
  excludedCurrencies: null,
};

const defaultColumns: IColumn[] = [
  { id: 'code', name: 'Code', active: true, component: <TableCell align="center" key="code">Code</TableCell> },
  { id: 'name', name: 'Name', active: true },
  { id: 'supported', name: 'Supported', active: true },
  { id: 'euHRTC', name: 'In EU HRTC list', active: true },
  { id: 'eu', name: 'Is EU', active: true },
  { id: 'risk', name: 'Risk score', active: true, component: <TableCell align="center" key="rScore">Risk score</TableCell> },
  { id: 'kyc', name: 'KYC score', active: true, component: <TableCell align="center" key="kScore">KYC score</TableCell> },
  { id: 'occTx', name: 'Occasional TXs', active: true, component: <TableCell align="center" key="oct">Occasional TXs</TableCell> },
  { id: 'excludedCurrencies', name: 'Currencies excluded', active: true, component: <TableCell align="right" key="exc">Currencies excluded</TableCell> },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    display: 'flex'
  },
  import: {
    marginLeft: theme.spacing(2)
  }
}));

export default function KYCCountriesPage() {
  const classes = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  const cl = useStyles();
  const [file, setFile] = useState();
  const [details, setDetails] = useState<IKycCountry | undefined>();
  const [{ items, error }, loading, load] = useKycCountries();
  const [setFilter, filter] = useFilter('kycCountries', emptyFilter, false);
  const accordionRef = useRef<ICollapseHandle>(null);
  const [columns, ColumnsFilter] = useColumns('kycCountries', defaultColumns);
  const history = useHistory();
  const { countryId } = useParams();
  const [importCountries, importing] = useKycCountriesImport((res: any) => {
    if (res.success) {
      setFile(undefined);
      req(filter);
    }
  });

  function req(filter: any) {
    const f = { ...filter };
    if (filter.code) {
      f.code = [filter.code];
    }
    load(f);
  }

  useEffect(() => {
    accordionRef.current?.collapse();
    req(filter);
  }, [filter]);

  useEffect(() => {
    if (countryId && countryId !== 'global-settings' && items.length) {
      setDetails(items.find((i: any) => i.code === countryId));
    }
  }, [countryId, items])

  function openDetails(id?: string) {
    history.push(`/kyc-countries${id ? `/${id}` : ''}`);
  }

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <KYCCountriesFilter filter={filter as ICountriesFilter} onChange={setFilter} />
      </FilterWrapper>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justify="space-between" className={mobileClasses.gap}>
              <Grid item>
                <Form
                  onSubmit={() => importCountries(file)}
                  initialValues={{ file: null }}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={cl.form}>
                      <Uploader
                        onLoad={setFile}
                        input={{
                          accept: '.csv',
                          multiple: false,
                          name: 'file'
                        }}
                      />
                      {file && (
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          className={cl.import}
                          disabled={importing}
                        >
                          Import
                        </Button>
                      )}
                    </form>
                  )}
                />
              </Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0
            ? <KYCCountriesList items={items} openDetails={openDetails} columns={columns} />
            : <EmptyList error={error} loading={loading} />
          }
          <Loader loading={loading} />
        </Paper>
      </Grid>
      {countryId && (
        <CountryDetails
          country={details}
          countriesLoading={loading}
          onClose={() => openDetails()}
          onSave={() => req(filter)}
        />
      )}
    </Grid>
  );
}
