import React from 'react';
import { useSelector } from 'react-redux';

import TableFilter from '../../components/Filter';
import { CurrencySelect, Input, Select } from '../../components/Form';
import Descriptor from '../../components/Descriptor';

import { composeValidators, maxLength, mustBeNumber } from '../../validators';

import { PaymentProvider, PaymentSource, PaymentStatus, PaymentType } from '../../api/payment';
import { CurrencyType } from '../../api/currency';
import { IKycCountry } from '../../api/kyc';

export interface IPaymentsFilter {
  id: number | null;
  dateFrom: number | null;
  dateTo: number | null;
  status: PaymentStatus | null;
  currency: string | null;
  provider: string | null;
  source: string | null;
  orderId: string | null;
  requestedBy: string | null;
  type: string | null;
  country: string | null;
}

interface IProps {
  filter: IPaymentsFilter;
  onChange: any;
}

export default function PaymentsFilter({ filter, onChange }: IProps) {
  const countries = useSelector((store: any) => store.appData.countries.data);

  return (
    <TableFilter onApply={onChange} filter={filter}>
      <Descriptor group="filters" title="clearsDate" >
        <Input
          name="id"
          label="Payment ID"
          margin="none"
          validate={composeValidators(mustBeNumber, (v: any) => maxLength(v, 19))}
        />
      </Descriptor>
      <Descriptor group="filters" title="clearsDate" >
        <Input
          name="orderId"
          label="Order ID"
          margin="none"
          validate={composeValidators(mustBeNumber, (v: any) => maxLength(v, 19))}
        />
      </Descriptor>
      <Input
        name="requestedBy"
        label="Customer"
        margin="none"
      />
      <Select
        name="type"
        label="Type"
        options={Object.keys(PaymentType).map((s) => ({ value: s, label: s }))}
      />
      <CurrencySelect
        name="currency"
        label="Currency"
        type={CurrencyType.FIAT}
      />
      <Select
        name="status"
        label="Status"
        options={Object.keys(PaymentStatus).map((s) => ({ value: s, label: s }))}
      />
      <Select
        name="provider"
        label="Provider"
        // @ts-ignore
        options={Object.keys(PaymentProvider).map((s) => ({ value: PaymentProvider[s], label: s }))}
      />
      <Select
        name="source"
        label="Source"
        options={Object.keys(PaymentSource).map((s) => ({ value: s, label: s }))}
      />
      <Select
        name="country"
        label="Country"
        options={countries?.map((country: IKycCountry) => ({ value: country.code, label: country.name }))}
      />
    </TableFilter>
  );
}
