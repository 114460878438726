import Api, { useFetchApi, useUpdateApi } from './index';
import { IUISettings, getInitialUI } from './merchant';
import { IMerchantSettlementFees } from '../types';

export enum KycFlowType {
  DEFAULT = 'DEFAULT',
  LEGAL_ENTITY_LIMITS = 'LEGAL_ENTITY_LIMITS',
  SKIP_KYC = 'SKIP_KYC'
}

interface IApmFee{
  deposit: number;
  depositFixed: number;
  depositFixedCurrency: string;
  depositFeeMin: number;
}

interface IFeeSettings {
  rolling: number;
  deposit: number;
  depositFixed: number;
  depositMerchant: number;
  depositMerchantFixed: number;
  withdrawal: number;
  withdrawalFixed: number;
  apmFee: Map<String, IApmFee>;
}

export interface ICustomerFee {
  currency?: string;
  customerId?: string;
  fees: IFeeSettings;
}

interface IPostbackSettings {
  url: string;
  kycUrl: string;
  kycNotification: boolean;
}

export interface IMerchantNamingSettings {
  businessName?: string;
  legalName?: string;
  legalMerchantName?: string;
  merchantName?: string;
  accountType?: string;
}

interface IPaymentSettings {
  excludedGroups: string[];
  includedGroups: string[];
  includedMethods: string[];
  excludedMethods: string[];
  includedProviders: string[];
  excludedProviders: string[];
}

interface ICcwSettings {
  factory?: string;
  vault?: string;
  enabled?: boolean;
}

export interface ICustomerSettings {
  id?: number;
  createdAt?: Date;
  ui: IUISettings;
  postback: IPostbackSettings;
  paymentSettings: IPaymentSettings;
  smsesPerDay: number;
  excludedCurrencies: string[];
  successUrl?: string;
  failUrl?: string;
  manualBlockchainTx?: boolean;
  enableLegalEntityLimits?: boolean;
  failOrderWhenWalletAddressChanged?: boolean;
  maskedCardNumCallback?: boolean;
  tokenAuthorizationEnabled?: boolean;
  coinspaidAdditionalDetails?: boolean;
  dmrProxy?: boolean;
  settlementCryptoCurrency?: string;
  ultraLowMinDeposit?: boolean;
  simplifyUserFlow?: boolean;
  sendKycMeta?: boolean;
  referralBonus?: number;
  ccw?: ICcwSettings;
  kycFlow: KycFlowType;
  instantPaymentAmount?: number;
  obiPaymentScheme?: string;
  ignoreCountryOccasionalTx?: boolean;
  merchantNaming?: IMerchantNamingSettings
  settlementFees?: IMerchantSettlementFees;
  showOrderDetails?: boolean;
}

export interface ICustomer {
  id: string;
  createdAt: Date;
  email: string;
  phone: string;
  emailConfirmed: boolean;
  phoneConfirmed: boolean;
  kycApplication: any;
  settings: ICustomerSettings;
  fullVerified: boolean;
  fees: ICustomerFee[];
  level: number;
  dailySmsCounter: number;
  dailySmsLimit: number;
  excludedCurrencies?: string[];
  includedCurrencies?: string[];
  disabledLegalEntityLimitsApm?: string[];
  authorities?: string[];
  country?: string;
  state?: string;
  billingCountry?: string;
  city?: string;
  address?: string;
  zipCode?: string;
  legalEntity?: string;
  masterAccountId?: string;
  isMaster: boolean;
  kycShared?: string;
  merchantNaming?: IMerchantNamingSettings;
  ccwEnabled: boolean;
  subAccountSid?: string;
}

export enum TransactionLimitPeriod {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH'
}

export enum TransactionOBILimitPeriod {
  OBI_DAY = 'OBI_DAY',
  OBI_WEEK = 'OBI_WEEK',
  OBI_MONTH = 'OBI_MONTH'
}

export interface ITXLimit {
  period: TransactionLimitPeriod | TransactionOBILimitPeriod;
  customerId: number;
  amount?: number;
  count?: number;
  actualCount?: number;
  actualAmount?: number;
}

export interface ICustomerWallet {
  id: number;
  createdAt: number;
  updatedAt: number;
  currency: string;
  balance: number;
  locked: number;
  customerId: number;
}

export interface ICustomerActivity {
  id: number;
  createdAt: number;
  updatedAt: number;
  customerId: number;
  type: string;
  source: string;
  country: string;
  city: string;
  ip: string;
  endpoint: string;
  method: string;
  headers: string;
  userAgent: string;
}

export const defaultSettings: ICustomerSettings = {
  ui: getInitialUI(),
  postback: {
    url: '',
    kycUrl: '',
    kycNotification: false
  },
  smsesPerDay: 10,
  excludedCurrencies: [],
  successUrl: '',
  failUrl: '',
  manualBlockchainTx: false,
  paymentSettings: {
    excludedGroups: [],
    includedGroups: [],
    includedMethods: [],
    excludedMethods: [],
    includedProviders: [],
    excludedProviders: []
  },
  kycFlow: KycFlowType.DEFAULT
};

export const DEFAULT_MERCHANT_NAMING_SETTINGS = {
  businessName: '',
  legalName: '',
  legalMerchantName: '',
  merchantName: '',
  accountType: ''
};

export interface ICustomerAddress {
  id: number;
  createdAt: number;
  updatedAt: number;
  requestedById: number;
  processedById: number;
  currency: string;
  type: string;
  direction: string;
  address: string;
}

export function useCustomersList() {
  return useFetchApi(
    async (query: any) => Api.post('/customer/list', { ...query, limit: 50 }),
    { items: [] }
  );
}

export function useCustomersSubAccountsList() {
  return useFetchApi(
      async (query: any) => Api.post('/customer/subaccount/list', { ...query, limit: 50 }),
      { items: [] }
  );
}

export function useCustomerDetails() {
  return useFetchApi(
    async (id: string) => Api.post('/customer/one', { id }),
    { data: {} }
  );
}

export function useCustomerSettingsUpdate(cb: any, message: string) {
  return useUpdateApi(
    async (settings: any) => Api.post('/customer/settings', settings),
    cb,
    message
  )
}

export function useCustomerAuthoritiesUpdate(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/authority/customer/update', data),
    cb,
    'Authorities successfully updated'
  );
}

export function useCustomerSmsReset(cb: any) {
  return useUpdateApi(
    async (customerId: any) => Api.post('/customer/settings/sms/reset', { customerId }),
    cb,
    'SMS limit successfully reset'
  )
}
export function useCustomerUpdateLegalEntity(cb:any) {
  return useUpdateApi(
    async (data: any) => Api.post('/customer/legal-entity/set', data),
    cb,
    'Legal entity has been successfully updated'
  )
}

export function useCustomerLimits() {
  return useFetchApi(
    async (id: any) => Api.post('/order/customer/limits/get', { id }),
    { items: [] }
  )
}

export function useUpdateCustomerLimits(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/order/customer/limits', data),
    cb,
    'Limits updated'
  )
}

export function useCustomerWallets() {
  return useFetchApi(
    async (customerId: any) => Api.post('/customer/wallet/list', { customerId }),
    { items: [] }
  )
}

export function useCustomerActivity() {
  return useFetchApi(
    async (query: any) => Api.post('/customer/activity', { limit: 50, cursor: 0, ...query }),
    { items: [] }
  )
}

export function useCountryReset(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('customer/country/reset', data),
    cb,
    'Country was reset'
  )
}

export function useStateReset(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('customer/state/reset', data),
    cb,
    'State was reset'
  )
}

export function useDeposit(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/customer/wallet/deposit', data),
    cb,
    'Successful deposit'
  )
}

export function useWithdrawal(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/customer/wallet/withdrawal', data),
    cb,
    'Successful withdrawal'
  )
}

export function useCustomerFeesList() {
  return useFetchApi(
    async (customerId: any) => Api.post('/customer/fee/list', { cursor: 0, limit: 50, filter: { customerId } }),
    { items: [] }
  )
}

export function useCustomerFeesActual() {
  return useFetchApi(
    async (customerId: any, currency?: string) => Api.post('/customer/fee/actual', { customerId, currency }),
    { data: {} }
  )
}

export function useCustomerFeeCreate(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/customer/fee/create', data),
    cb,
    `Fee set successfully created`
  )
}

export function useCustomerFeeUpdate(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/customer/fee/update', data),
    cb,
    `Fee set successfully updated`
  )
}

export function useCustomerAddresses() {
  return useFetchApi(
    async (id: any) => Api.post('/customer/addresses', { id }),
    { items: [] }

  )
}

export function useBillingAddress(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/customer/address/update', data),
    cb
  );
}

export function useMerchantNamingList() {
  return useFetchApi(
    async (businessName: string) => Api.post('/merchant-naming/list', { businessName }),
    { data: {} }
  );
}

