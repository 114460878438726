import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { CurrencyType } from '../../api/currency';
import { useCustomerFeeUpdate } from '../../api/customers';
import { useFeeList } from '../../api/fee';
import Descriptor from '../../components/Descriptor';
import { CurrencySelect, Input, ProtectionInput } from '../../components/Form';
import Loader from '../../components/Loader';
import useFormStyles from '../../hooks/useFormStyles';
import { composeValidators, maxValue, minValue, mustBeNumber, required } from '../../validators';

export default function DefaultFees() {
  const formClasses = useFormStyles();
  const [{ items: fees }, loadingFees, loadFees] = useFeeList();
  const [updateFee, feeUpdating] = useCustomerFeeUpdate((res: any) => {
    if (res.success) {
      setInitial(res.data);
    }
  });
  const [initial, setInitial] = useState<any>({});

  useEffect(() => {
    loadFees();
  }, [])

  useEffect(() => {
    if (fees.length) {
      const exist = fees.find((f: any) => f.id === 1);
      setInitial(exist);
    }
  }, [fees])

  return (
    <Loader loading={loadingFees} width={500}>
      <Form
        onSubmit={updateFee}
        initialValues={{ ...initial, fees: { deposit: 0.65, depositFixed: 0.5, ...fees }, '2fa': '' }}
        render={({ handleSubmit, form }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // @ts-ignore
              handleSubmit(e)?.then(() => form.restart());
            }}
            className={formClasses.root}
          >
            <div className={formClasses.row}>
              <Descriptor group="customerSettings" title="Deposit" top={7}>
                <Input
                  name="fees.deposit"
                  label="Deposit"
                  validate={composeValidators(required, mustBeNumber, (v: any) => minValue(v, 0), (v: any) => maxValue(v, 1))}
                />
              </Descriptor>
              <Descriptor group="customerSettings" title="Deposit fixed" top={7}>
                <Input
                  name="fees.depositFixed"
                  label="Deposit fixed"
                  validate={composeValidators(required, mustBeNumber, (v: any) => minValue(v, 0))}
                />
              </Descriptor>
            </div>
            <div className={formClasses.row}>
              <Descriptor group="customerSettings" title="Withdrawal" top={7}>
                <Input
                  name="fees.withdrawal"
                  label="Withdrawal"
                  validate={composeValidators(required, mustBeNumber, (v: any) => minValue(v, 0), (v: any) => maxValue(v, 1))}
                />
              </Descriptor>
              <Descriptor group="customerSettings" title="Withdrawal fixed" top={7}>
                <Input
                  name="fees.withdrawalFixed"
                  label="Withdrawal fixed"
                  validate={composeValidators(required, mustBeNumber, (v: any) => minValue(v, 0))}
                />
              </Descriptor>
            </div>
            <div className={formClasses.row}>
              <Descriptor group="customerSettings" title="Merchant deposit" top={7}>
                <Input
                  name="fees.depositMerchant"
                  label="Merchant deposit"
                  validate={composeValidators((v: any) => minValue(v, 0), (v: any) => maxValue(v, 1))}
                />
              </Descriptor>
              <Descriptor group="customerSettings" title="Merchant deposit fixed" top={7}>
                <Input
                  name="fees.depositMerchantFixed"
                  label="Merchant deposit fixed"
                  validate={composeValidators((v: any) => minValue(v, 0))}
                />
              </Descriptor>
            </div>
            <div className={formClasses.row}>
              <Descriptor group="customerSettings" title="Rate Fixed Currency" top={7}>
                <CurrencySelect
                  name="fees.rateFixedCurrency"
                  label="Rate Fixed Currency"
                  validate={required}
                  fullWidth
                  type={CurrencyType.FIAT}
                />
              </Descriptor>
            </div>
            <div className={formClasses.row}>
              <ProtectionInput />
            </div>
            <div className={formClasses.actions}>
              <Button
                type="submit"
                disabled={feeUpdating}
                variant="contained"
                size="large"
              >
                Save
              </Button>
            </div>
          </form>
        )}
      />
    </Loader>
  );
}
