import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DateView from '../../../components/DateView';
import CurrencyFormat from '../../../components/UI/CurrencyFormat';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(3)
  },
  dates: {
    marginRight: theme.spacing(8),
    width: 180
  },
  date: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  table: {
    width: 300,
    '& td': {
      borderBottom: 0
    }
  }
}));

interface IData {
  include: number;
  exclude: number;
}

interface IProps {
  data: {[key: string]: IData};
  from: number;
  to: number;
  exception: any[];
}

function Totals({ data, from, to, exception }: IProps) {
  const classes = useStyles();
  const hasException = !!exception.length;
  return (
    <div className={classes.root}>
      <div className={classes.dates}>
        <Typography variant="body1" className={classes.date}>
          <span>From:</span> <strong><DateView value={from} /></strong>
        </Typography>
        <Typography variant="body1" className={classes.date}>
          <span>To:</span> <strong><DateView value={to} /></strong>
        </Typography>
      </div>
      <Table size="small" className={classes.table}>
        {hasException && (
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} align="right">
                Exclude: {exception.join(', ')}
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {Object.entries(data)
            .map(([k, v]) => (
              <TableRow key={k}>
                <TableCell>
                  <Typography component="span" variant="body2">{k}:</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography component="span" variant="body2">
                    <strong>
                      <CurrencyFormat>{v.include}</CurrencyFormat> EUR
                    </strong>
                  </Typography>
                </TableCell>
                {hasException && (
                  <TableCell align="right">
                    <Typography component="span" variant="body2">
                      <strong>
                        <CurrencyFormat>{v.exclude}</CurrencyFormat> EUR
                      </strong>
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default Totals;
