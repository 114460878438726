import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import DetailsPopup from '../../../components/Layout/DetailsPopup';
import TabsPanel from '../../../components/Tabs';
import PaymentTab from '../../Orders/Details/PaymentTab';
import { usePaymentsList } from '../../../api/payment';

interface IProps {
  id: string | number;
  onClose: any;
}

export default function PaymentDetails({ id, onClose }: IProps) {
  const [{ items }, loading, load] = usePaymentsList();

  useEffect(() => {
    load({ filter: { paymentId: id } });
  }, [id]);

  const tabs = [
    {
      title: 'Overview',
      content: <PaymentTab payments={items} loading={loading} />
    }
  ];

  return (
    <DetailsPopup onClose={onClose} loading={loading}>
      {items[0]
        ? (
          <>
            <Typography variant="h6" color="primary">Payment {items[0].id}</Typography>
            <TabsPanel tabs={tabs} />
          </>
        ) : <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  )
}
