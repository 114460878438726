import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Input } from '../Form';
import { useAuth, use2fa } from '../../api/admin';
import { required, isEmail, lengthRequired, composeValidators } from '../../validators';
import { setUserAction } from '../../store/actions/common';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [token2fa, setToken2fa] = useState();
  const [userData, setUserData] = useState();
  const dispatch = useDispatch();
  const [auth, authing] = useAuth((res: any) => {
    if (res.success) {
      if (res.data.next === 'TWO_FACTOR_CODE') {
        setUserData(res.data.user);
        setToken2fa(res.data.token);
      } else {
        dispatch(setUserAction(res.data.user));
      }
    }
  });
  const [send2fa, sending2fa] = use2fa((res: any) => {
    if (res.success) {
      dispatch(setUserAction(userData));
    } else {
      setUserData(undefined);
      setToken2fa(undefined);
    }
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Utorg PRO
        </Typography>
        {!token2fa
          ? (
            <Form
              onSubmit={(values) => {
                auth({ ...values, email: values.email.trim() });
              }}
              render={({ handleSubmit, invalid, form: { reset } }) => (
                <form className={classes.form} onSubmit={(e) => {
                  // @ts-ignore
                  const result = handleSubmit(e);
                  if (result) {
                    if (result.then) {
                      result.then(() => {
                        reset();
                      })
                    } else {
                      reset();
                    }
                  }
                }}>
                  <Input
                    required
                    label="Email Address"
                    name="email"
                    validate={composeValidators(required, isEmail)}
                  />
                  <Input
                    required
                    name="password"
                    label="Password"
                    type="password"
                    validate={required}
                  />
                  <Button
                    type="submit"
                    disabled={invalid || authing}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Sign In
                  </Button>
                </form>
              )}
            />
          ) : (
            <Form
              onSubmit={(values) => send2fa({ code: values.code, token: token2fa })}
              render={({ handleSubmit, invalid, form: { reset } }) => (
                <form className={classes.form} onSubmit={(e) => {
                  // @ts-ignore
                  handleSubmit(e).then(() => {
                    reset();
                  })
                }}>
                  <Input
                    required
                    label="2FA code"
                    name="code"
                    validate={composeValidators(required, (v: any) => lengthRequired(v, 6))}
                    autoComplete="new-password"
                    autoFocus
                  />
                  <Button
                    type="submit"
                    disabled={invalid || sending2fa}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Confirm
                  </Button>
                  <OnChange name="code">
                    {(code: any) => {
                      if (code.length === 6) {
                        handleSubmit();
                      }
                    }}
                  </OnChange>
                </form>
              )}
            />
          )}
      </div>
    </Container>
  );
}