import React from 'react';

import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import TextCopy from '../TextCopy';
import Descriptor from '../Descriptor';

interface IProps {
  label: string;
  children: any;
  copy?: string;
  descGroup?: string;
  labelWidth?: number;
  tooltipTitleType?: 'text' | 'node';
  isMobile?: boolean;
}

interface IStylesProps {
  labelWidth?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    alignItems: 'baseline'
  },
  label: ({ labelWidth }: IStylesProps) => ({
    width: labelWidth || 150,
    flex: `0 0 ${labelWidth || 150}px`,
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 100,
      flex: '0 0 100px',
    },
  }),
  content: {
    display: 'flex',
    flex: '1 1 0px',
    alignItems: 'center',
    wordWrap: 'break-word',
  },
  mobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  }
}));

export default function Row({ label, children, copy, descGroup, labelWidth, tooltipTitleType, isMobile }: IProps) {
  const classes = useStyles({ labelWidth });

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body2" color="textSecondary" component="div">
        <Descriptor group={descGroup || 'detailLabels'} title={label} type={tooltipTitleType}>{label}</Descriptor>
        {(copy && copy.length) && (
          <TextCopy text={copy} />
        )}
      </Typography>
      <div className={clsx(classes.content, isMobile && classes.mobile)}>{children}</div>
    </div>
  );
}
