import React, { useEffect } from 'react';

import { Typography, makeStyles } from '@material-ui/core';
import { useCustomersSubAccountsList } from '../../../api/customers';
import CustomersList from '../CustomersList';
import EmptyList from '../../../components/EmptyList';
import { useHistory } from 'react-router';
import useColumns, { IColumn } from '../../../hooks/useColumns';

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'subAccountSid', name: 'SID', active: true },
  { id: 'contact', name: 'Email', active: true },
  { id: 'accountType', name: 'Account type', active: true },
  { id: 'created', name: 'Created at', active: true },
];

const useStyles = makeStyles((theme) => ({
   title: {
     marginBottom: theme.spacing(1),
     display: 'flex',
     justifyContent: 'space-between',
     alignItems: 'center'
   },
   block: {
    paddingTop: theme.spacing(3),
   }
 }));

interface ISubaccountList {
  masterAccountId: string
}

function SubaccountList({ masterAccountId }:ISubaccountList) {

  const history = useHistory();

  const [{ items, error }, loading, load ] = useCustomersSubAccountsList();
  const [columns] = useColumns('customers', defaultColumns);

  useEffect(() => {
    load({
      filter: {
        masterAccountId
      },
      cursor: 0,
    });
  }, []);

  const classes = useStyles();

  function setDetails(id?: string) {
    history.push(`/customers${id ? `/customer/${id}` : ''}`);
  }

  return(
  <div className={classes.block}>
    <Typography variant="body1" color="primary" className={classes.title}>
      Sub-accounts
    </Typography>
    {items.length > 0
      ? <CustomersList items={items} onRowClick={setDetails} columns={columns} />
      : <EmptyList error={error} loading={loading} />
    }
  </div>
  );
}
export default SubaccountList;