export function base64ToFile(data: string, filename: string) {
  const mime = data.startsWith('JVBERi0') ? 'application/pdf' : 'image/jpeg';
  const bstr = atob(data);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], `${filename}.${mime.split('/')[1]}`, { type: mime });
}

export function fileToBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}