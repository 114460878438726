import Api, { useFetchApi, useUpdateApi } from './index';


export interface IBinCodeItem {
  id: number;
  createdAt: number;
  updatedAt: number;
  binCode: string;
  country: string;
  source: string;
  adminId: number;
}


export function useBinCodesList() {
  return useFetchApi(
    async (query: any) => Api.post('/bin/list', { limit: 50, ...query }),
    { items: [] }
  )
}

export function useBinCodeOneItem() {
  return useFetchApi(
    async (query: any) => Api.post('/bin/one', { ...query }),
    { data: {} }
  )
}

export function useCreateBinCodeItem(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/bin/new', data),
    cb,
    'BIN code successfully created'
  )
}

export function useUpdateBinCodeItem(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/bin/update', data),
    cb,
    'BIN code successfully updated'
  )
}

export function useDeleteBinCodeItem(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/bin/delete', data),
    cb,
    'BIN code successfully removed'
  )
}
