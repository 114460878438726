import React from 'react';

import useMobileStyles from '../../hooks/useMobileStyles';
import { TableCell } from '@material-ui/core';

interface ITableCellProps {
   children: React.ReactNode,
   position?: 'left' | 'right' | 'inherit' | 'center' | 'justify',
}

function TablecellId({ children, position }:ITableCellProps) {
   const classes = useMobileStyles();

return(
   <TableCell className={classes.id} align={position ? position : 'left'}>
      {children}
   </TableCell>
);}
export default TablecellId;