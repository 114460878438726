import Api, { useUpdateApi, useFetchApi } from './index';

export interface IAdmin {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  email: string;
  active: boolean;
  username: string;
  firstName: string;
  lastName: string;
  position: string;
  password: string;
  googleAuthEnabled: boolean;
}

export const emptyAdmin: IAdmin = {
  email: '',
  active: true,
  googleAuthEnabled: false,
  username: '',
  firstName: '',
  lastName: '',
  position: '',
  password: ''
};

export enum ActivityType {
  ADMIN_CREATED = 'ADMIN_CREATED',
  ADMIN_ACCESS_CHANGED = 'ADMIN_ACCESS_CHANGED',
  ADMIN_PASSWORD_CHANGED = 'ADMIN_PASSWORD_CHANGED',
  ADMIN_PASSWORD_CHANGE_UNSUCCESSFUL_ATTEMPT = 'ADMIN_PASSWORD_CHANGE_UNSUCCESSFUL_ATTEMPT',
  ADMIN_2FA_BOUND = 'ADMIN_2FA_BOUND',
  ADMIN_2FA_BIND_UNSUCCESSFUL_ATTEMPT = 'ADMIN_2FA_BIND_UNSUCCESSFUL_ATTEMPT',

  AUTHORITY_CREATED = 'AUTHORITY_CREATED',
  AUTHORITY_UPDATED = 'AUTHORITY_UPDATED',
  ADMIN_AUTHORITIES_UPDATED = 'ADMIN_AUTHORITIES_UPDATED',
  CUSTOMER_AUTHORITIES_UPDATED = 'CUSTOMER_AUTHORITIES_UPDATED',

  BLOCKCHAIN_TRANSACTIONS_RELEASED = 'BLOCKCHAIN_TRANSACTIONS_RELEASED',

  BOOKKEEPER_BALANCE_ADDED = 'BOOKKEEPER_BALANCE_ADDED',
  BOOKKEEPER_BALANCE_UPDATED = 'BOOKKEEPER_BALANCE_UPDATED',

  CLEARING_ORDER_RECHECKED = 'CLEARING_ORDER_RECHECKED',

  CURRENCY_CREATED = 'CURRENCY_CREATED',
  CURRENCY_UPDATED = 'CURRENCY_UPDATED',

  API_KEY_CREATED = 'API_KEY_CREATED',
  API_KEY_CHANGED = 'API_KEY_CHANGED',
  API_KEY_DELETED = 'API_KEY_DELETED',
  API_KEY_NEW_LEGAL_INFO_BOUND = 'API_KEY_NEW_LEGAL_INFO_BOUND',
  API_KEY_EXISTING_LEGAL_INFO_BOUND = 'API_KEY_EXISTING_LEGAL_INFO_BOUND',

  CUSTOMER_SETTINGS_UPDATED = 'CUSTOMER_SETTINGS_UPDATED',
  CUSTOMER_SMS_RESET = 'CUSTOMER_SMS_RESET',
  CUSTOMER_COUNTRY_SET = 'CUSTOMER_COUNTRY_SET',
  CUSTOMER_COUNTRY_RESET = 'CUSTOMER_COUNTRY_RESET',

  CUSTOMER_FEE_CREATED = 'CUSTOMER_FEE_CREATED',
  CUSTOMER_FEE_UPDATED = 'CUSTOMER_FEE_UPDATED',
  CUSTOMER_FEE_DELETED = 'CUSTOMER_FEE_DELETED',

  KYC_3RD_PARTY_SETTING_CHANGED = 'KYC_3RD_PARTY_SETTING_CHANGED',
  KYC_AUTO_APPROVAL_CHANGED = 'KYC_AUTO_APPROVAL_CHANGED',
  KYC_DOCUMENTS_ADDED = 'KYC_DOCUMENTS_ADDED',
  KYC_APPLICATION_STATUS_CHANGED = 'KYC_APPLICATION_STATUS_CHANGED',
  KYC_COUNTRIES_LIST_IMPORTED = 'KYC_COUNTRIES_LIST_IMPORTED',

  CUSTOMER_ORDER_LIMITS_CHANGED = 'CUSTOMER_ORDER_LIMITS_CHANGED',
  ORDER_POSTBACK_SENT = 'ORDER_POSTBACK_SENT',

  MANUAL_DEPOSIT = 'MANUAL_DEPOSIT',
  MANUAL_WITHDRAWAL = 'MANUAL_WITHDRAWAL',

  DISABLE_2FA_FOR_ADMIN = 'DISABLE_2FA_FOR_ADMIN',
  PAYMENT_PROVIDER_SCRIPT_UPDATED = 'PAYMENT_PROVIDER_SCRIPT_UPDATED',
  PAYMENT_PROVIDER_UPDATED = 'PAYMENT_PROVIDER_UPDATED'
}

export interface IAdminActivity {
  id: number;
  createdAt: number;
  updatedAt: number;
  adminId: number;
  adminEmail: string;
  adminUsername?: string;
  type: ActivityType;
  endpoint: string;
  method: string;
  headers: string;
  ip: string;
  country: string;
  city: string;
  userAgent: string;
  extra?: any;
}

export function useAuth(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/user/login', data),
    cb
  );
}

export function use2fa(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/user/2fa/code', data),
    cb
  );
}

export function useLogout(cb: any) {
  return useUpdateApi(
    async () => Api.post('/user/logout'),
    cb
  );
}

export function useAdminsList() {
  return useFetchApi(
    async (query: any) => Api.post('/user/list', { ...query, limit: 50 }),
    { items: [] }
  )
}

export function useSaveAdmin(cb: any) {
  return useUpdateApi(
    async (data: any) => {
      if (data.id) {
        return Api.post('/user/activate', { email: data.email, active: data.active });
      }
      return Api.post('/user/create', data);
    },
    cb
  );
}

export function usePasswordChange(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/user/password', data),
    cb,
    'Password changed'
  )
}

export function use2FAInit(cb: any) {
  return useUpdateApi(
    async () => Api.post('/user/2fa/init', {}),
    cb
  )
}

export function use2FABind(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/user/2fa/bind', data),
    cb,
    '2FA successfully enabled'
  )
}

export function use2FAUnbind(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/user/2fa/unbind', data),
    cb,
    '2FA successfully disabled'
  )
}

export function use2FADisable(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/super/2fa/disable', data),
    cb,
    '2FA successfully disabled'
  )
}

export function usePasswordReset(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/super/password/reset', data),
    cb,
    'Password successfully changed'
  )
}

export function useDisableAdmin(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/super/deactivate', data),
    cb,
    'Password successfully changed'
  )
}

export function useAdminsActivitiesList() {
  return useFetchApi(
    async (query: any) => Api.post('/user/activities', { ...query, limit: 50 }),
    { items: [] }
  )
}

export function useActivitiesList() {
  return useFetchApi(
    async () => Api.post('/user/activity/types', {}),
    { items: [] }
  )
}

export function useAuthorityUpdate(cb: any) {
  return useUpdateApi(
  async (data: any) => Api.post("/super/authority/update", data),
      cb,
      `Authority successfully updated`
);
}