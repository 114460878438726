import React from 'react';

import { TableCell, TableHead,Table, TableRow, TableBody, makeStyles } from '@material-ui/core';
import Tablecell from '../../../../components/TableCell/TableCell';
import { IMasterAccountBalance } from '../../../../api/walletOperations';
import useMobileStyles from '../../../../hooks/useMobileStyles';
import DateView from '../../../../components/DateView';

const useStyles = makeStyles((theme) => ({
   root: {},
   table: {
     marginBottom: theme.spacing(2)
   },
 }));
 
interface IMasterAccountBalancesList {
   balances: IMasterAccountBalance[]
}

function MasterAccountBalancesList({ balances }: IMasterAccountBalancesList) {

   const classes = useStyles();
   const mobileClasses = useMobileStyles();

   return(
      <Table size="small" className={classes.table}>
         <TableHead className={mobileClasses.tableHead}>
            <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>SID</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Balance</TableCell>
            <TableCell>Locked</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Updated</TableCell>
            </TableRow>
         </TableHead>
         <TableBody>
            {balances.map((item) => (
               <TableRow key={item.id} className={mobileClasses.row}>
                  <Tablecell title="ID">{item.id}</Tablecell>
                  <Tablecell title="Email">{item.email}</Tablecell>
                  <Tablecell title="SID">{item.sid}</Tablecell>
                  <Tablecell title="Currency">{item.currency}</Tablecell>
                  <Tablecell title="Balance">{item.balance}</Tablecell>
                  <Tablecell title="Locked">{item.locked}</Tablecell>
                  <Tablecell title="Created">
                     <DateView value={item.createdAt} />
                  </Tablecell>
                  <Tablecell title="Updated" isLast>
                     <DateView value={item.updatedAt} />
                  </Tablecell>
               </TableRow>
            ))}
         </TableBody>
      </Table>
   );
}
export default MasterAccountBalancesList;
