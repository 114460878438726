import React, { useEffect } from 'react';

import {Form, FormProps} from 'react-final-form';

import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import { DataRow } from '../../../components/DataView';
import DateView from '../../../components/DateView';
import { Input, Checkbox, ProtectionInput, Select } from '../../../components/Form';
import useFormStyles from '../../../hooks/useFormStyles';
import { required, composeValidators, maxValue, minValue, isLink } from '../../../validators';
import {
  CurrencyType,
  ICurrency,
  useCurrencySave,
  AddressValidator,
  useCurrencyChainsList
} from '../../../api/currency';
import {BlockchainTxProvider, BlockchainTxStatus, BlockchainTxType} from "../../../api/blockchain";
import useMobileStyles from '../../../hooks/useMobileStyles';
import { useBreakpoints } from '../../../hooks/useBreakpoints ';

interface IProps {
  currency: ICurrency;
  id: string;
  onCreate: any;
}

const validate: FormProps['validate'] = (values) => {
  const errors: Record<string, string | undefined> = {}

  if(values.explorerAddr) {
    errors.explorerAddr = isLink(values.explorerAddr, true)
  }

  if(values.explorerTx) {
    errors.explorerTx = isLink(values.explorerTx, true)
  }

  return errors
}

export default function CurrencyOverview({ currency, id, onCreate }: IProps) {
  const formClasses = useFormStyles();
  const mobileClasses = useMobileStyles();
  const isResolutionSevenHundred = useBreakpoints(700);
  const update = id !== 'new';
  const [save, saving] = useCurrencySave((res: any) => {
    if (res.success) {
      onCreate(res.data);
    }
  }, `Currency successfully ${update ? 'updated' : 'created'}`);

  const [{ items: chains }, loadingChains, loadChains] = useCurrencyChainsList();
  useEffect(() => {
    loadChains();
  }, [])

  const onSubmit = (values: Record<string, any>) => {
    const currencyToSave: Record<string, any> = {
      ...values,
      id: values.id,
      caption: values.caption ?? "",
      display: values.display ?? "",
      allowTag: Boolean(values.allowTag),
      allowInvoices: Boolean(values.allowInvoices)
    }

    if(values.type === 'CRYPTO') {
      currencyToSave.explorerTx = (values.explorerTx ?? "").trim();
      currencyToSave.explorerAddr = (values.explorerAddr ?? "").trim();
    }

    return save(currencyToSave)
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{ ...currency, id: currency.currency, '2fa': '' }}
      render={({ handleSubmit, invalid, form, values }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // @ts-ignore
            handleSubmit(e).then(() => form.restart());
          }}
          className={formClasses.root}
        >
          <div className={formClasses.row}>
            <Input
              name="id"
              label="Currency"
              type="text"
              validate={required}
              disabled={update}
            />
            <Input
              name="symbol"
              label="Symbol"
              type="text"
              validate={required}
            />
          </div>
          <div className={formClasses.row}>
            <Select
              name="type"
              label="Type"
              options={Object.keys(CurrencyType).map((c) => ({ value: c, label: c }))}
              validate={required}
              margin="normal"
              fullWidth={isResolutionSevenHundred}
            />
            {values['type'] == 'CRYPTO' && (
              <Select
                name="chain"
                label="Chain"
                options={chains.map((c: string) => ({ value: c, label: c }))}
                validate={required}
                margin="normal"
                loading={loadingChains}
                fullWidth={isResolutionSevenHundred}
              />
            )}
          </div>
          <div className={clsx(formClasses.row, isResolutionSevenHundred && mobileClasses.gap)}>
            <Input
              name="precision"
              label="Precision"
              type="number"
              validate={composeValidators((v: string) => maxValue(v, 8), (v: string) => minValue(v, 0))}
              margin="none"
            />
            <Select
              name="addressValidator"
              label="Address validator"
              options={Object.keys(AddressValidator).map((c) => ({ value: c, label: c }))}
              validate={required}
              fullWidth={isResolutionSevenHundred}
            />
            <Checkbox name="enabled" label="Currency enabled" />
          </div>
          <div className={formClasses.row}>
            <Input
              name="display"
              label="Display name"
              type="text"
            />
            <Input
              name="caption"
              label="Caption"
              type="text"
            />
          </div>
          {values.type === 'CRYPTO' && (
            <>
              <div className={formClasses.row}>
                <Input
                  name="explorerTx"
                  label="Explorer TX"
                  type="text"
                  InputProps={{
                    placeholder: values.defExplorerTx
                  }}
                  InputLabelProps={{
                    shrink: Boolean(values.defExplorerTx),
                  }}
                />
                <Input
                  name="explorerAddr"
                  label="Explorer Addr"
                  type="text"
                  InputProps={{
                    placeholder: values.defExplorerAddr
                  }}
                  InputLabelProps={{
                    shrink: Boolean(values.defExplorerAddr),
                  }}
                />
              </div>
              <div className={clsx(formClasses.row, isResolutionSevenHundred && mobileClasses.gap)}>
                <Select
                  name="provider"
                  label="Provider name"
                  options={Object.keys(BlockchainTxProvider).map((c) => ({ value: c, label: c }))}
                  validate={required}
                  fullWidth={isResolutionSevenHundred}
                />
                <Select
                  name="transaction"
                  label="Transaction type"
                  options={Object.keys(BlockchainTxType).map((c) => ({ value: c, label: c }))}
                  validate={required}
                  fullWidth={isResolutionSevenHundred}
                />
                <Select
                  name="transactionStatus"
                  label="Transaction status"
                  options={Object.keys(BlockchainTxStatus).map((c) => ({ value: c, label: c }))}
                  validate={required}
                  fullWidth={isResolutionSevenHundred}
                />
              </div>
            </>
          )}
          <div className={formClasses.row}>
            <Input
              name="nftContract"
              label="NFT Contract"
              type="text"
            />
          </div>
          <div className={formClasses.row}>
            <Input
              name="depositMin"
              label="Min deposit amount"
              type="text"
              placeholder="null"
            />
            <Input
              name="depositMax"
              label="Max deposit amount"
              type="text"
              placeholder="null"
            />
          </div>
          <div className={formClasses.row}>
            <Input
              name="withdrawalMin"
              label="Min withdrawal amount"
              type="text"
              placeholder="null"
            />
            <Input
              name="withdrawalMax"
              label="Max withdrawal amount"
              type="text"
              placeholder="null"
            />
          </div>
          {values.type === 'CRYPTO' && (
            <>
              <div className={formClasses.row}>
                <Input
                  name="udKey"
                  label="Unstoppable Domains Key"
                  type="text"
                  placeholder="null"
                />
                <Checkbox name="allowTag" label="Allow Memo/Tag" />
              </div>
              <div className={clsx(formClasses.row, mobileClasses.checkbox)}>
                <Checkbox name="allowInvoices" label="Allow invoices" />
              </div>
            </>
          )}
          <div className={formClasses.row}>
            <ProtectionInput />
          </div>
          {update && (
            <>
              <DataRow label="Created">
                <DateView value={currency.createdAt} />
              </DataRow>
              <DataRow label="Updated">
                <DateView value={currency.updatedAt} />
              </DataRow>
            </>
          )}
          <div className={formClasses.actions}>
            <Button
              type="submit"
              disabled={invalid || saving}
              variant="contained"
              size="large"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    />
  );
}
