import React, { FC } from 'react'
import { Field } from 'react-final-form'

import SearchInput, { ISearchProps } from '../Search'

interface ISearchRffProps extends ISearchProps {
  name: string
}

const SearchRff: FC<ISearchRffProps> = ({ name, ...rest }) => {
  return <Field name={name}>
    {({ input: { onChange, value } }) => {
      return (
        <SearchInput
          value={value}
          name={name}
          onChange={(e: any) => onChange(e?.target?.value)}
          {...rest}  />
      )
    }}
  </Field>
}

export default SearchRff
