import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Descriptor from '../../../components/Descriptor';
import Loader from '../../../components/Loader';
import { Input, ProtectionInput } from '../../../components/Form';
import useFormStyles from '../../../hooks/useFormStyles';
import { ICurrency, useCurrencyFeesList, useDeleteFee } from '../../../api/currency';
import { useCustomerFeeCreate, useCustomerFeeUpdate } from '../../../api/customers';
import { required, moreThan, composeValidators, mustBeNumber, maxValue, minValue } from '../../../validators';

interface IProps {
  currency: ICurrency;
}

export default function CurrencyFees({ currency }: IProps) {
  const formClasses = useFormStyles();
  const [{ items: fees }, loadingFees, loadFees] = useCurrencyFeesList();
  const [createFee, feeCreating] = useCustomerFeeCreate((res: any) => {
    if (res.success) {
      setInitial(res.data);
    }
  });
  const [updateFee, feeUpdating] = useCustomerFeeUpdate((res: any) => {
    if (res.success) {
      setInitial(res.data);
    }
  });
  const [deleteFee, feeDeleting] = useDeleteFee((res: any) => {
    if (res.success) {
      loadFees(currency.currency);
    }
  });
  const [initial, setInitial] = useState<any>({});

  useEffect(() => {
    if (currency.currency) {
      loadFees(currency.currency);
    }
  }, [currency.currency])

  useEffect(() => {
    if (fees.length) {
      const exist = fees.find((f: any) => !f.customerId && f.currency === currency.currency);
      if (exist) {
        return setInitial(exist);
      }
      setInitial({ ...fees.find((f: any) => !f.customerId && !f.currency), currency: currency.currency, id: undefined });
    }
  }, [fees])

  return (
    <Loader loading={loadingFees} width={500}>
      <Form
        onSubmit={(values) => {
          if (!!values.id) {
            return updateFee(values);
          }
          createFee(values);
        }}
        initialValues={{ ...initial, '2fa': '' }}
        render={({ handleSubmit, invalid, values, form }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // @ts-ignore
              handleSubmit(e).then(() => form.restart());
            }}
            className={formClasses.root}
          >
            <div className={formClasses.row}>
              <Descriptor group="customerSettings" title="Deposit" top={7}>
                <Input
                  name="fees.deposit"
                  label="Deposit"
                  type="search"
                  autoComplete="xyz123"
                  validate={composeValidators(required, mustBeNumber, (v: any) => minValue(v, 0), (v: any) => maxValue(v, 1))}
                />
              </Descriptor>
              <Descriptor group="customerSettings" title="Deposit fixed" top={7}>
                <Input
                  name="fees.depositFixed"
                  label="Deposit fixed"
                  type="search"
                  autoComplete="xyz123"
                  validate={composeValidators(required, mustBeNumber, (v: any) => minValue(v, 0), (v: any) => maxValue(v, 100))}
                />
              </Descriptor>
            </div>
            <div className={formClasses.row}>
              <Descriptor group="customerSettings" title="Withdrawal" top={7}>
                <Input
                  name="fees.withdrawal"
                  label="Withdrawal"
                  type="search"
                  autoComplete="xyz123"
                  validate={composeValidators(required, mustBeNumber, (v: any) => minValue(v, 0), (v: any) => maxValue(v, 1))}
                />
              </Descriptor>
              <Descriptor group="customerSettings" title="Withdrawal fixed" top={7}>
                <Input
                  name="fees.withdrawalFixed"
                  label="Withdrawal fixed"
                  type="search"
                  autoComplete="xyz123"
                  validate={composeValidators(required, mustBeNumber, (v: any) => minValue(v, 0), (v: any) => maxValue(v, 100))}
                />
              </Descriptor>
            </div>
            <div className={formClasses.row}>
              <Descriptor group="customerSettings" title="Merchant deposit" top={7}>
                <Input
                  name="fees.depositMerchant"
                  label="Merchant deposit"
                  type="search"
                  autoComplete="xyz123"
                  validate={composeValidators((v: any) => minValue(v, 0), (v: any) => maxValue(v, 1))}
                />
              </Descriptor>
              <Descriptor group="customerSettings" title="Merchant deposit fixed" top={7}>
                <Input
                  name="fees.depositMerchantFixed"
                  label="Merchant deposit fixed"
                  type="search"
                  autoComplete="xyz123"
                  validate={composeValidators((v: any) => minValue(v, 0), (v: any) => maxValue(v, 100))}
                />
              </Descriptor>
            </div>
            <div className={formClasses.row}>
              <ProtectionInput />
            </div>
            <div className={formClasses.actions}>
              <Button
                type="submit"
                disabled={feeCreating || feeUpdating || feeDeleting}
                variant="contained"
                size="large"
              >
                Save
              </Button>
              {!!initial.id && (
                <Button
                  type="button"
                  disabled={invalid || feeCreating || feeUpdating || feeDeleting}
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => deleteFee({ id: initial.id, '2fa': values['2fa'] })}
                >
                  Delete fee settings
                </Button>
              )}
            </div>
          </form>
        )}
      />
    </Loader>
  );
}
