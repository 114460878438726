import React, { FC, useState } from 'react'

import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import { CardActions } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import UploadField from "../../../components/Form/UploadField";
import { IKycState, useKycStatesImport} from '../../../api/kyc';
import useColumns, {IColumn} from "../../../hooks/useColumns";

import StatesList from './StatesList'
import StatesFilters, { IKycStatesFilter } from './StatesFilters'
import StateDetails from "./StateDetails";
import useMobileStyles from '../../../hooks/useMobileStyles';

const defaultColumns: IColumn[] = [
  { id: "name", name: "Name", active: true },
  {
    id: "code",
    name: "Code",
    active: true,
    component: (
      <TableCell align="center" key="code">
        Code
      </TableCell>
    ),
  },
  { id: "country", name: "Country", active: true },
  { id: "supported", name: "Supported", active: true },
  { id: "inHRTC", name: "In EU HRTC list", active: true },
  {
    id: "risk",
    name: "Risk score",
    active: true,
    component: (
      <TableCell align="center" key="rScore">
        Risk score
      </TableCell>
    ),
  },
  {
    id: "kyc",
    name: "KYC score",
    active: true,
    component: (
      <TableCell align="center" key="kScore">
        KYC score
      </TableCell>
    ),
  },
];

interface IStatesTabProps {
  isLoading: boolean;
  states: IKycState[];
  onLoad(...args: any[]): void;
}

const StatesTab: FC<IStatesTabProps> = ({ states, isLoading, onLoad }) => {
  const [file, setFile] = useState();
  const mobileClasses = useMobileStyles();
  const [stateDetails, setStateDetails] = useState<IKycState | null>();
  const [columns, ColumnsFilter] = useColumns("kycCountries", defaultColumns);

  const [importStates, importingStates] = useKycStatesImport((res: any) => {
    if (res.success) {
      onLoad();
    }
  });

  const onUpload = () => {
    importStates(file);
  };

  const onFiltersChange = (filter: IKycStatesFilter) => {
    onLoad(filter);
  };

  const stateDetailsOnSave = () => {
    setStateDetails(null);
    onLoad();
  };

  const stateDetailsOnCancel = () => {
    setStateDetails(null);
  };

  return (
    <Grid>
      {stateDetails && (
        <StateDetails
          onSave={stateDetailsOnSave}
          onCancel={stateDetailsOnCancel}
          state={stateDetails}
        />
      )}
      {!stateDetails && (
        <>
          <StatesFilters filter={{}} onChange={onFiltersChange} />
          <CardActions>
            <Grid container justify="space-between" className={mobileClasses.gap}>
              <Grid item>
                <UploadField
                  onLoad={setFile}
                  input={{
                    accept: ".csv",
                    multiple: false,
                    name: "file",
                  }}
                />
                {file && (
                  <Button
                    onClick={onUpload}
                    type="button"
                    variant="contained"
                    size="large"
                    disabled={importingStates}
                  >
                    Import
                  </Button>
                )}
              </Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          <StatesList
            isLoading={isLoading}
            columns={columns}
            items={states}
            onStateClick={setStateDetails}
          />
        </>
      )}
    </Grid>
  );
};

export default StatesTab
