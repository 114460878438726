import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Loader from '../Loader';

interface IStyleProps {
  width?: number | string;
}

const useStyles = makeStyles((theme) => ({
  root: ({ width }: IStyleProps) => ({
    position: 'fixed',
    height: '100vh',
    width: width || 630,
    top: 0,
    right: 0,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    background: theme.palette.background.paper,
    overflowY: 'auto',
    overflowX: 'auto',
    transition: 'width 300ms ease-out 0s',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 40px)',
    },
  }),
  close: ({ width }: IStyleProps) => ({
    position: 'absolute',
    right: width || 630,
    top: '8px',
    [theme.breakpoints.down('sm')]: {
      right: 'calc(100% - 44px)',
    },
  })
}));

export interface IDetailsProps {
  children?: any;
  onClose: any;
  loading?: boolean;
  width?: number | string;
}

export default function DetailsPopup({ children, onClose, loading, width }: IDetailsProps) {
  const classes = useStyles({ width });

  return (
    <Modal
      open
      onClose={onClose}
    >
      <>
        <IconButton onClick={onClose} className={classes.close}>
          <CloseIcon />
        </IconButton>
        <div className={classes.root}>
          <Loader loading={!!loading} width={560}>
            {children}
          </Loader>
        </div>
      </>
    </Modal>
  )
}
