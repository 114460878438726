import React from 'react';

import { Form } from 'react-final-form';

import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Input, ProtectionInput } from '../../components/Form';
import { required } from '../../validators';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import makeStyles from "@material-ui/core/styles/makeStyles";

export type TDamModalType = 'lock' | 'unlock' | 'withdraw';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    backgroundColor: "#424242",
    borderRadius: "6px",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  }
}));

interface IProps {
  onClose: any;
  send(data: any): void;
  sending: boolean;
  type: TDamModalType;
}

export default function DamModal({ onClose, send, sending, type }: IProps) {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const initValues: any = { '2fa': '' };
  if (type === 'unlock') {
    initValues.secret = '';
  }

  return (
    <div className={classes.modal}>
        <div className={clsx(globalClasses.dialog, classes.content)}>
          <Form
            onSubmit={(values) => send(values)}
            initialValues={initValues}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <DialogTitle color="primary" style={{ textTransform: 'capitalize' }}>{type} DAM wallet</DialogTitle>
                <DialogContent>
                  {type === 'unlock' && <Input name="secret" type='password' label="Secret" validate={required} />}
                  <ProtectionInput />
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={onClose}
                    size="small"
                    disabled={sending}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    size="small"
                    disabled={sending}
                  >
                    {type}
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </div>
    </div>
  );
}
