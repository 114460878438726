import Api, { useFetchApi, useUpdateApi } from './index';

export interface IWalletData {
  currency: string;
  running: boolean;
  address: string;
  lastSeenBlock: number;
  balance: number;
}

export interface IDamChain {
  chain: string;
  address: string;
}

export interface IDamChainWithURL {
  chain: string;
  address: string;
  href: string
}

export function useWalletInfo() {
  return useFetchApi(
    async () => Api.post('/wallet/info', {}),
    { data: {} }
  )
}

export function useDamStatus() {
  return useFetchApi(
    async () => Api.post('/wallet/dam/status', {}),
    { data: [] },
  );
}

export function useDamLock(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/wallet/dam/lock', data),
    cb,
  );
}

export function useDamUnlock(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/wallet/dam/unlock', data),
    cb,
  );
}

export function useDamWithdrawCCW(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/wallet/dam/withdraw', data),
    cb,
  );
}
