import React from 'react';

import clsx from 'clsx';

import useMobileStyles from '../../hooks/useMobileStyles';
import { TableCell } from '@material-ui/core';

interface ITableCellProps {
   children: React.ReactNode,
   title: string,
   isLast?: boolean,
   isNowrap?: boolean,
   position?: 'left' | 'right' | 'inherit' | 'center' | 'justify',

}

function Tablecell({ children, title, isLast, position, isNowrap }:ITableCellProps) {
   const classes = useMobileStyles();

return(
   <TableCell className={clsx(classes.cell, isLast && classes.lastChild, isNowrap && classes.nowrap)} align={position ? position : 'left'}>
      <span className={classes.mobile}>{title}: </span>
      {children}
   </TableCell>
);}
export default Tablecell;