import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateView from '../../components/DateView';
import { IPayment } from '../../api/payment';
import Descriptor from '../../components/Descriptor';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import PaymentStatusLabel from '../../components/Label/PaymentStatusLabel';
import BoolLabel from '../../components/Label/BoolLabel';
import PopupLink from '../../components/UI/PopupLink';
import { useSeonChecks } from '../../api/order';
import Ellipsis from '../../components/UI/Ellipsis';
import { IColumn } from '../../hooks/useColumns';
import {NavLink} from "react-router-dom";
import {Link} from "@material-ui/core";
import useMobileStyles from '../../hooks/useMobileStyles';
import Tablecell from '../../components/TableCell/TableCell';

interface IProps {
  item: IPayment;
  columns: IColumn[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  highlight: {
    background: 'rgba(256,256,256,0.1)',
    '&:first-of-type': {
      paddingLeft: theme.spacing(1)
    }
  },
  request: {
    wordBreak: 'break-all'
  },
  mobile: {
    display: 'none',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  id: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      textAlign: 'center',
    },
  },
  cell: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
    },
  },
}));

export default function PaymentRow({ item, columns }: IProps) {
  const [expand, setExpand] = useState(false);
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  const [{ items }, loading, load] = useSeonChecks();

  useEffect(() => {
    if (expand && !items[0]) {
      load({ orderId: item.orderId });
    }
  }, [expand])

  const operations = item.operations ? item.operations.concat(items) : items;

  const content: { [key: string]: (item: IPayment, key: number) => void } = {
    id: (i, k) => <TableCell key={k} className={clsx({ [classes.highlight]: expand }, classes.id)}>{item.id}</TableCell>,
    orderId: (i, k) => (
      <TableCell key={k} className={clsx({ [classes.highlight]: expand }, classes.cell)}>
        <span className={classes.mobile}>Order ID: </span>
        <PopupLink popup="order" id={item.orderId}>{item.orderId}</PopupLink>
      </TableCell>
    ),
    created: (i, k) => (
      <TableCell key={k} className={clsx({ [classes.highlight]: expand })}>
        <span className={classes.mobile}>Created: </span>
        <DateView value={item.createdAt} />
      </TableCell>
    ),
    type: (i, k) => <TableCell key={k} className={clsx({ [classes.highlight]: expand }, classes.cell)}>
      <span className={classes.mobile}>Type: </span>{item.type}</TableCell>,
    amount: (i, k) => <TableCell key={k} className={clsx({ [classes.highlight]: expand }, classes.cell)}>
      <span className={classes.mobile}>Amount: </span>{item.amount}</TableCell>,
    currency: (i, k) => <TableCell key={k} className={clsx({ [classes.highlight]: expand }, classes.cell)}>
      <span className={classes.mobile}>Currency: </span>{item.currency}</TableCell>,
    source: (i, k) => <TableCell key={k} className={clsx({ [classes.highlight]: expand }, classes.cell)}>
      <span className={classes.mobile}>Source: </span>{item.source}</TableCell>,
    apm: (i, k) => (
      <TableCell key={k} className={clsx({ [classes.highlight]: expand }, classes.cell)}>
         <span className={classes.mobile}>APM: </span>{item.apm?.id ? item.apm.id + '(' + item.apm.name + ')' :''}
      </TableCell>
    ),
    method: (i, k) => (
      <TableCell key={k} className={clsx({ [classes.highlight]: expand }, classes.cell)}>
         <span className={classes.mobile}>Method: </span>
         <Link component={NavLink} onClick={(e:any) => e.stopPropagation()} to={`/settings/payment-methods/${item.method}`}>{item.methodName}</Link>
      </TableCell>
    ),
    provider: (i, k) => <TableCell key={k} className={clsx({ [classes.highlight]: expand }, classes.cell)}>
      <span className={classes.mobile}>Provider: </span>{item.provider}</TableCell>,
    status: (i, k) => (
      <TableCell key={k} className={clsx({ [classes.highlight]: expand }, classes.cell)}>
         <span className={classes.mobile}>Status: </span>
        <PaymentStatusLabel status={item.status} />
      </TableCell>
    ),
    finished: (i, k) => (
      <TableCell key={k} className={clsx({ [classes.highlight]: expand }, classes.cell)}>
        <span className={classes.mobile}>Finished: </span>
        <BoolLabel value={item.finished} />
      </TableCell>
    ),
    operations: (i, k) => (
      <TableCell key={k} className={clsx({ [classes.highlight]: expand }, classes.cell)}>
        <span className={classes.mobile}>Operations: </span>
        {item.operations?.length} operation(s)
      </TableCell>
    ),
    updated: (i, k) => (
      <TableCell key={k} className={clsx({ [classes.highlight]: expand }, classes.cell)}>
        <span className={classes.mobile}>Updated: </span>
        <DateView value={item.updatedAt} />
      </TableCell>
    ),
    reqBy: (i, k) => <TableCell key={k} className={clsx({ [classes.highlight]: expand }, classes.cell)}>
       <span className={classes.mobile}>RequestedById: </span>
       {item.requestedById}</TableCell>
  };

  const getRowDescriptorTitle = (o: any) => {
    let result = '';
    if (o.type) {
      result = o.type;
    } else if (o.provider && o.provider.toUpperCase() === 'SCORECHAIN') {
      result = `${o.provider} ${o.copy ? '(last check)' : ''}`;
    } else if (o.provider) {
      result = o.provider;
    }
    return result;
  }

  return (
    <>
      <TableRow hover onClick={() => setExpand(!expand)} className={clsx(globalClasses.pointer,mobileClasses.row)}>
        {columns.map((c, index) => content[c.id](item, index))}
      </TableRow>
      {expand && (
        <TableRow>
          <TableCell colSpan={columns.length} className={classes.highlight}>
            <Table size="small">
              <TableHead className={mobileClasses.tableHead}>
                <TableRow>
                  <TableCell>Ext. ID</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Request</TableCell>
                  <TableCell>Response</TableCell>
                  <TableCell>Success</TableCell>
                  <TableCell>Created at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {operations.sort((a: any, b: any) => b.createdAt - a.createdAt).map((o: any, i: number) => (
                  <TableRow key={i} className={mobileClasses.row}>
                    <Tablecell title="Ext. ID">{o.externalId}</Tablecell>
                    <Tablecell title="Type">
                      <Descriptor group="paymentOperationTypes" title={getRowDescriptorTitle(o)}>{getRowDescriptorTitle(o)}</Descriptor>
                    </Tablecell>
                    <Tablecell title="Request">
                      <div className={classes.request}>
                        <Ellipsis text={typeof (o.request) === 'object' ? JSON.stringify(o.request).toString() : o.request} />
                      </div>
                    </Tablecell>
                    <Tablecell title="Response">
                      <div className={classes.request}>
                        <Ellipsis text={typeof (o.response) === 'object' ? JSON.stringify(o.response).toString() : o.response} />
                      </div>
                    </Tablecell>
                    <Tablecell title="Success">
                      <BoolLabel value={o.success} />
                    </Tablecell>
                    <Tablecell title="Created at">
                      <DateView value={o.createdAt} />
                    </Tablecell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
