import React, { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-final-form";

import { makeStyles } from "@material-ui/styles";

import { Select } from "../../components/Form";
import { IKycCountry } from "../../api/kyc";
import { setGlobalCountry } from "../../store/actions/appData";
import { LS_GLOBAL_COUNTRY_KEY } from '../../constants/common';
import useCountriesWithStates from '../../hooks/useCountryWithStates';

const GlobalCountrySelect: FC = () => {
  const dispatch = useDispatch();
  const countriesWithStates = useCountriesWithStates();

  const countriesOptions = [
    {
      code: "all",
      name: "All",
    },
    ...countriesWithStates,
  ].map((country: IKycCountry) => ({
    value: country.code,
    label: country.name,
  }));

  const classes = useStyles();

  const selectedCountry = window.localStorage.getItem(LS_GLOBAL_COUNTRY_KEY);

  const initialValues = {
    country: selectedCountry || "all",
  };

  const handleChange = (country: string) => {
    const selectedCountryFilter = country === "all" ? null : country;
    if (selectedCountryFilter) {
      window.localStorage.setItem(
        LS_GLOBAL_COUNTRY_KEY,
        selectedCountryFilter
      );
    }
    if (!selectedCountryFilter) {
      window.localStorage.removeItem(LS_GLOBAL_COUNTRY_KEY);
    }
    dispatch(setGlobalCountry(selectedCountryFilter));
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={() => {}}
      render={() => {
        return (
          <form className={classes.globalCountryForm}>
            <Select
              name="country"
              label="Country"
              changeSelectHandler={handleChange}
              noEmptyOption={true}
              options={countriesOptions}
            />
          </form>
        );
      }}
    />
  );
};

const useStyles = makeStyles((theme: any) => ({
  globalCountryForm: {
    minWidth: "150px",
    display: "flex",
    alignItems: "center",
    paddingBottom: "0",
    marginRight: 20,
    flexFlow: "row wrap",
    color: theme.palette.grey[800],
    [theme.breakpoints.down(400)]: {
      minWidth: '130px',
    },
    "& > *": {
      flex: "1 1 0px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[800],
    },
    "& .MuiInputBase-root": {
      color: theme.palette.grey[800],
      "&:hover": {
        borderColor: theme.palette.grey[800],
      },
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.grey[800],
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: "2px",
      paddingBottom: "2px",
    },
    "& .MuiSelect-icon": {
      color: theme.palette.grey[800],
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
      {
        borderColor: theme.palette.grey[800],
      },
  },
}));

export default GlobalCountrySelect;
