import TableFilter from "../../../components/Filter";
import {Select} from "../../../components/Form";
import React from "react";
import {InvoiceStatus} from "../../../api/oly";

export interface IOlyInvoiceFilter {
  dateFrom: Date | null;
  dateTo: Date | null;
  status: InvoiceStatus | null;
}

interface IProps{
  filter: IOlyInvoiceFilter,
  onChange: any,
}

export default function OlyInvoiceFilter({filter, onChange}: IProps){
  return (
    <TableFilter onApply={onChange} filter={filter}>
      <Select
        name="status"
        label="Status"
        options={Object.keys(InvoiceStatus).map((s) => ({ value: s, label: s }))}
      />
    </TableFilter>
  );
}