import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BoolLabel from '../../components/Label/BoolLabel';
import { IKycCountry } from '../../api/kyc';
import { IColumn } from '../../hooks/useColumns';
import Tablecell from '../../components/TableCell/TableCell';
import useMobileStyles from '../../hooks/useMobileStyles';

interface IProps {
  items: IKycCountry[];
  columns: IColumn[];
  openDetails: any;
}

const content: { [key: string]: (item: IKycCountry, key: number) => void } = {
  code: (i, k) => <Tablecell position="center" title="Code" key={k}>{i.code}</Tablecell>,
  name: (i, k) => <Tablecell key={k} title="Name">{i.name}</Tablecell>,
  supported: (i, k) => <Tablecell key={k} title="Supported"><BoolLabel value={i.supported} /></Tablecell>,
  euHRTC: (i, k) => <Tablecell key={k} title="In EU HRTC list"><BoolLabel value={i.inHRTC} /></Tablecell>,
  eu: (i, k) => <Tablecell key={k} title="Is EU"><BoolLabel value={i.EU} /></Tablecell>,
  risk: (i, k) => <Tablecell key={k} title="Risk score" position="center">{i.riskScore}</Tablecell>,
  kyc: (i, k) => <Tablecell key={k} title="KYC score" position="center">{i.kycScore}</Tablecell>,
  occTx: (i, k) => <Tablecell key={k} title="Occasional TXs" position="center"><BoolLabel value={i.enableOccasionalTransactions} /></Tablecell>,
  excludedCurrencies: (i, k) => (
    <Tablecell key={k} position="right" title="Currencies excluded" isLast>
      <BoolLabel value={!!i.excludedCurrencies?.length} />
    </Tablecell>
  )
};

export default function KYCCountriesList({ items, openDetails, columns }: IProps) {

  const mobileClasses = useMobileStyles();

  return (
    <Table size="small">
      <TableHead className={mobileClasses.tableHead}>
        <TableRow>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow key={i.code} hover onClick={() => openDetails(i.code)} className={mobileClasses.row}>
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
