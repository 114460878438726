import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Form } from 'react-final-form';
import { Checkbox, ProtectionInput } from '../../components/Form';
import Loader from '../../components/Loader';
import useFormStyles from '../../hooks/useFormStyles';
import { useThirdPartyKycData, useAutoApproveData, useKYCGlobalSet } from '../../api/kyc';

export default function GeneralTab() {
  const formClasses = useFormStyles();
  const [{ data: thirdPartyKYC }, loadingThirdPartyKyc, loadThirdPartyKyc] = useThirdPartyKycData();
  const [{ data: autoApprove }, loadingAutoApprove, loadAutoApprove] = useAutoApproveData();
  const [setKYCSettings, setting] = useKYCGlobalSet((res: any) => {
    if (res.success) {
      load();
    }
  });

  const load = () => {
    loadThirdPartyKyc();
    loadAutoApprove();
  }

  useEffect(() => {
    load();
  }, [])

  return (
    <Loader loading={loadingAutoApprove || loadingThirdPartyKyc} width={500}>
      <Form
        onSubmit={setKYCSettings}
        initialValues={{
          thirdPartyKYC,
          autoApprove,
          '2fa': ''
        }}
        render={({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit}>
            <div className={formClasses.row}>
              <Checkbox name="thirdPartyKYC" label="Enable third party KYC" />
            </div>
            <div className={formClasses.row}>
              <Checkbox name="autoApprove" label="Enable auto approve applications" />
            </div>
            <div className={formClasses.row}>
              <ProtectionInput />
            </div>
            <div className={formClasses.actions}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                disabled={invalid || setting}
              >
                Save
              </Button>
            </div>
          </form>
        )}
      />
    </Loader>
  )
}
