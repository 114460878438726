import React from 'react';

import { TableCell, TableHead,Table, TableRow, TableBody, makeStyles } from '@material-ui/core';
import Tablecell from '../../../../components/TableCell/TableCell';
import { ITotalBalanceByCurrency } from '../../../../api/walletOperations';
import useMobileStyles from '../../../../hooks/useMobileStyles';

const useStyles = makeStyles((theme) => ({
   root: {},
   table: {
     marginBottom: theme.spacing(2)
   },
 }));
 
interface ICurrencyBalancesList {
   currencyBalances: ITotalBalanceByCurrency[]
}

function CurrencyBalancesList({ currencyBalances }:ICurrencyBalancesList) {

   const classes = useStyles();
   const mobileClasses = useMobileStyles();

   return(
      <Table size="small" className={classes.table}>
         <TableHead className={mobileClasses.tableHead}>
            <TableRow>
            <TableCell>Currency</TableCell>
            <TableCell>Balance</TableCell>
            </TableRow>
         </TableHead>
         <TableBody>
            {currencyBalances.map((item) => (
            <TableRow key={item.currency} className={mobileClasses.row}>
               <Tablecell title="Currency">{item.currency}</Tablecell>
               <Tablecell title="Balance">{item.amount}</Tablecell>
            </TableRow>
            ))}
         </TableBody>
      </Table>
   );
}
export default CurrencyBalancesList;
