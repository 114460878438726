import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form } from 'react-final-form';
import { ProtectionInput } from '../../../components/Form';
import Loader from '../../../components/Loader';
import { useCreateBWItem, ACLType } from '../../../api/acl';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';

export interface ACLData {
  type: ACLType;
  value: string;
  white?: boolean;
}

interface IProps {
  onClose: any;
  value: string;
  type: ACLType;
  white?: boolean;
}

export default function ACLFastAdd({ onClose, white, type, value }: IProps) {
  const globalClasses = useGlobalStyles();
  const [addToList, addingToList] = useCreateBWItem((res: any) => {
    if (res.success) {
      onClose();
    }
  });

  return (
    <Dialog open onClose={onClose}>
      <div className={globalClasses.dialog}>
        <Loader loading={addingToList} width={416}>
          <Form
            onSubmit={(values) => addToList({
              ...values, type, value, white
            })}
            initialValues={{ '2fa': '' }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <DialogTitle color="primary">Create {white ? 'white' : 'black'}list item</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {type}: {value}
                  </DialogContentText>
                  <ProtectionInput />
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={onClose}
                    size="small"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    size="small"
                  >
                    Add to {white ? 'white' : 'black'}list
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </Loader>
      </div>
    </Dialog>
  );
}
