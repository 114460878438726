import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useHistory, useParams } from 'react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import KycLimitsList from './KycLimitsList';
import EmptyList from '../../components/EmptyList';
import Loader from '../../components/Loader';
import ColHead from '../../components/UI/ColHead';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { useKycLimits, IKYCLimitsItem  } from '../../api/kyc';
import useColumns, { IColumn } from '../../hooks/useColumns';
import Button from '@material-ui/core/Button';
import KycLimitsDetails from './Details/index';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  table: {
    width: '100%'
  },
  paper: {
    width: '100%'
  },
  addButton: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: 13
  }
}));

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true, component: <ColHead title="ID" key="id" /> },
  { id: 'amountFrom', name: 'Amount From', active: true, component: <ColHead title="Amount From" key="amountFrom" /> },
  { id: 'amountTo', name: 'Amount To', active: true, component: <ColHead title="Amount To" key="amountTo" /> },
  { id: 'createdAt', name: 'Created At', active: true, component: <ColHead title="Created At" key="createdAt" /> },
  { id: 'currentLevel', name: 'Current Level', active: true, component: <ColHead title="Current Level" key="currentLevel" /> },
  { id: 'flow', name: 'Flow', active: true, component: <ColHead title="Flow" key="flow" /> },
  { id: 'newLevel', name: 'New Level', active: true, component: <ColHead title="New Level" key="newLevel" /> },
  { id: 'legalEntity', name: 'Legal Entity', active: true, component: <ColHead title="Legal Entity" key="legalEntity" /> },
];

export default function KycLimitsPage() {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { limitId } = useParams();
  const [details, setDetails] = useState<IKYCLimitsItem  | undefined>();
  const [{ items, error }, loading, load ] = useKycLimits();
  const [columns, ColumnsFilter] = useColumns('kycList', defaultColumns);
  const history = useHistory();

  function setDetailsUrl(id?: string) {
    if (id) {
      history.push(`/settings/kyc-limits/${id}`);
    } else {
      history.push('/settings/kyc-limits');
    }
  }

  useEffect(() => {
    if (limitId && limitId !== 'new' && items.length) {
      setDetails(items.find((i: IKYCLimitsItem) => String(i.id) === limitId));
    }
  }, [limitId, items])

  useEffect(() => {
    load();
  }, [])

  const addLimit = () => {
    setDetailsUrl('new')
  }

  const onUpdate = () => {
    setDetailsUrl();
    load();
  }

  return (
    <>
      <Grid className={classes.root} container spacing={3}>
        <Grid item className={clsx(classes.table, globalClasses.table)}>
          <Paper className={clsx(classes.paper, globalClasses.paper)}>
            <CardActions>
              <Grid container justify="space-between" style={{ gap: '20px' }}>
                <Grid item>
                  <Button variant="contained" className={classes.addButton} onClick={addLimit}>Create Limit</Button>
                </Grid>
                <Grid item>
                  <ColumnsFilter />
                </Grid>
              </Grid>
            </CardActions>
            {items.length > 0
              ? <KycLimitsList items={items} onRowClick={setDetailsUrl} columns={columns} />
              : <EmptyList error={error} loading={loading} />}
            <Loader loading={loading} />
          </Paper>
        </Grid>
      </Grid>
      {limitId && (
        <KycLimitsDetails
          limit={details}
          isCreate={limitId === 'new'}
          limitLoading={loading}
          onClose={() => setDetailsUrl()}
          onSave={() => onUpdate()}
          onDelete={() => onUpdate()}
        />
      )}
    </>
  )
}
