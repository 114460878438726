import React, { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DateView from '../../../../components/DateView';
import { DataRow } from '../../../../components/DataView';
import ExtraData from '../../../../components/UI/ExtraData';
import { ISumSubCallback } from '../../../../api/kyc';
import useGlobalStyles from '../../../../components/Layout/useGlobalStyles';
import useMobileStyles from '../../../../hooks/useMobileStyles';
import TableCellId from '../../../../components/TableCellId/TableCellId';
import Tablecell from '../../../../components/TableCell/TableCell';

interface IProps {
  item: ISumSubCallback;
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  highlight: {
    background: 'rgba(256,256,256,0.1)',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
    },
  },
  headers: {
    wordBreak: 'break-all'
  },
  sumSubResult: {
    '&.red': {
      color: theme.palette.error.main
    },
    '&.green': {
      color: theme.palette.success.main
    }
  }
}));

export default function ActivityRow({ item }: IProps) {
  const [expand, setExpand] = useState(false);
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  return (
    <>
      <TableRow hover onClick={() => setExpand(!expand)} className={clsx(globalClasses.pointer, mobileClasses.row, expand && classes.highlight)}>
        <TableCellId>{item.id}</TableCellId>
        <Tablecell title="Type">{item.data.type}</Tablecell>
        <Tablecell title="Review answer">
          <span className={clsx(classes.sumSubResult, item.data.reviewResult?.reviewAnswer.toLowerCase())}>
            {item.data.reviewResult?.reviewAnswer}
          </span>
        </Tablecell>
        <Tablecell title="Review status">{item.data.reviewStatus}</Tablecell>
        <Tablecell title="Created at" isLast>
          <DateView value={item.createdAt} />
        </Tablecell>
      </TableRow>
      {expand && (
        <TableRow>
          <TableCell colSpan={3} className={classes.highlight}>
            <DataRow label="Request" labelWidth={80}>
              <ExtraData data={item.data} />
            </DataRow>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
