import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Loader from '../../../components/Loader';
import LegalInfoForm from './LegalForm';
import { useApiKeys, IApiKey, useBindLegalInfo } from '../../../api/apiKeys';
import {Waypoint} from "react-waypoint";

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
}));

interface IProps {
  keyData: IApiKey;
  keys?: IApiKey[];
  onSave?: any;
}

export default function LegalInfo({ keyData, keys, onSave }: IProps) {
  const [{ items, full, error, cursor }, loading, load] = useApiKeys();
  const classes = useStyles();
  const [bindedInfo, setBindedInfo] = useState();
  const [add, setAdd] = useState(false);
  const [bind, binding] = useBindLegalInfo((res: any) => {
    if (res.success) {
      if (onSave) {
        onSave({ ...keyData, legal: bindedInfo.legal });
      }
      setBindedInfo(undefined);
    }
  });


  useEffect(() => {
    if (keyData && keyData.customerId && (!keys || !keys.length)) {
      load({ cursor: 0, filter: { queryData: keyData.customerId}});
    }
  }, [keyData, keys]);

  const apiKeys = keys || items;
  const othersKeysInfo = apiKeys.filter((k: IApiKey) => !!k.legal);

  return (
    <Loader loading={loading || binding} width={500}>
      {keyData?.legal
        ? (
          <LegalInfoForm
            keyId={keyData.id}
            onSave={onSave}
            legal={keyData.legal}
          />
        ) : (
          <>
            {!add && othersKeysInfo.length > 0 && (
              <>
                <Typography variant="body1" color="primary">Legal info assigned to other merchant API keys</Typography>
                {bindedInfo && (
                  <LegalInfoForm
                    keyId={bindedInfo.id}
                    legal={bindedInfo.legal}
                    bind={(code: string) => bind({ id: bindedInfo.legal.id, apiKeyIds: [keyData.id], '2fa': code })}
                    cancel={() => setBindedInfo(undefined)}
                  />
                )}
                <Table size="small" className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Domain</TableCell>
                      <TableCell>SID</TableCell>
                      <TableCell>Merchant name</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Reg.no</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {othersKeysInfo.map((k: IApiKey) => (
                      <TableRow key={k.id}>
                        <TableCell>{k.domain}</TableCell>
                        <TableCell>{k.sid}</TableCell>
                        <TableCell>{k.legal?.name}</TableCell>
                        <TableCell>{k.legal?.address}</TableCell>
                        <TableCell>{k.legal?.regNum}</TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => setBindedInfo(k)}
                          >
                            Bind info to current key
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <Loader loading={loading} />
                    {!loading && !error && !full && <Waypoint onEnter={() => load({ cursor, filter: { queryData: keyData.customerId} })} />}
                  </TableBody>
                </Table>
              </>
            )}
            {(add || !othersKeysInfo.length)
              ? <LegalInfoForm keyId={keyData.id} onSave={onSave} cancel={() => setAdd(false)} />
              : (
                <Button
                  onClick={() => {
                    setAdd(true);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Add new legal info
                </Button>
              )
            }
          </>
        )
      }
    </Loader>
  )
}
