import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IColumn } from '../../../hooks/useColumns';
import PaymentGroupRow from "./PaymentGroupRow";
import {IPaymentGroup} from "../../../api/paymentGroup";

interface IProps {
  items: IPaymentGroup[];
  columns: IColumn[];
  openDetails: any;
}

export default function PaymentGroupList({ items, columns, openDetails }: IProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) =>
          <PaymentGroupRow item={i} key={i.id} columns={columns} openDetails={openDetails}/>)}
      </TableBody>
    </Table>
  );
}