import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import SortIcon from '@material-ui/icons/Sort';
import { TSortDirection } from '../../views/Currencies';
import Descriptor from '../Descriptor';
import FilterListIcon from '@material-ui/icons/FilterList';

interface IProps {
  title: string;
  descGroup?: string;
  onSort?: any;
  isSorted?: boolean;
  sortDirection?: TSortDirection;
  isSortNumber?: boolean;
}

export default function ColHead({ title, descGroup, onSort, isSorted, sortDirection, isSortNumber }: IProps) {
  return (
    <TableCell>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Descriptor group={descGroup || 'columns'} title={title}>{title}</Descriptor>
        </Grid>
        {onSort && (
          <Grid item>
            <IconButton
              onClick={onSort}
              size="small"
              color={isSorted ? 'primary' : 'inherit'}
              style={isSorted && sortDirection === 'asc' ? { transform: 'rotateX(180deg)' } : undefined}
            >
              {isSorted ? <SortIcon /> : (isSortNumber ? <FilterListIcon/> : <SortByAlphaIcon />)}
            </IconButton>
          </Grid>
        )}
      </Grid>
    </TableCell>
  );
}
