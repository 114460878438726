import makeStyles from "@material-ui/core/styles/makeStyles";

const useAuthorityStyles = makeStyles((theme) => ({
  root: {},
  select: {
    width: "180px",
  },
  selectItem: {
    minHeight: "32.5px",
  },
  button: {
    marginRight: "16px",
  },
  itemWrapper: {
    display: "flex",
  },
  form: {
    marginTop: theme.spacing(2),
  },
  asc: {
    alignSelf: "center",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  item: {
    display: "flex",
    alignItems: "center",
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
  },
  label: {
    marginRight: theme.spacing(2),
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  row: {
    marginBottom: theme.spacing(0),
  },
}));

export default useAuthorityStyles;
