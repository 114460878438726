import Api, { useFetchApi, useUpdateApi } from './index';

class GatewayConnectionConfig {
  key = '';
  secret = '';
  host = '';
  username = '';
  password = '';
}

export type GatewayConfigExtendFields = { [key: string]: string };

export class GatewayConfig {
  id = '';
  enabled = false;
  fee = 0;
  pairs: string[] = [];
  mappings: string[] = [];
  connection: GatewayConnectionConfig = new GatewayConnectionConfig();
  other: GatewayConfigExtendFields = {};
  createdAt?: number;
  updatedAt?: number;
}

export function useGatewaysConfigsList() {
  return useFetchApi(
    async (data: any) => Api.post('/gateway/list', data),
    {
      items: []
    },
  )
}

export function useGatewayConfigOne() {
  return useFetchApi(
    async (id: string) => Api.post('/gateway/one', { id }),
    { data: new GatewayConfig() },
  );
}

export function useGatewayConfigSave(type: 'create' | 'update', cb: (data: any) => void) {
  return useUpdateApi(
    async (data: any) => Api.post('/gateway/' + type, data),
    cb,
    `Gateway successfully ${type}d`
  );
}
