import React, { ForwardRefRenderFunction, forwardRef, useImperativeHandle, useState } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { useBreakpoints } from '../../hooks/useBreakpoints ';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiAccordionDetails-root': {
         padding: '8px 0px',
       }
    },
    heading: {
      width: '100%',
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 500,
    },
  }),
);

export interface ICollapseHandle {
   collapse: () => void
 }

interface IFilterWrapperProps {
   children: React.ReactNode
}
const FilterWrapperBase: ForwardRefRenderFunction<ICollapseHandle, IFilterWrapperProps> = ({ children }, forwardedRef)=> {
   const classes = useStyles();
   const isXs = useBreakpoints('xs');
   const [expanded, setExpanded] = useState(false);

   useImperativeHandle(forwardedRef, () => {
      return {
         collapse() {
            setExpanded(false);
        },
      };
    }, []);

return(
   <>
      {!isXs && <>{children}</>}
      {isXs && 
         <Accordion className={classes.root} TransitionProps={{ timeout: 100 }} expanded={expanded} onChange={()=> setExpanded(!expanded)}>
            <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
            >
               <Typography className={classes.heading}>Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
               {children}
            </AccordionDetails>
         </Accordion>}
   </>
  );
};
const FilterWrapper = forwardRef(FilterWrapperBase);

export default FilterWrapper;