import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router';

import { Waypoint } from 'react-waypoint';

import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import EmptyList from '../../../components/EmptyList';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import CardActions from '@material-ui/core/CardActions';
import useColumns, { IColumn } from '../../../hooks/useColumns';
import Loader from '../../../components/Loader';
import useFilter from '../../../hooks/useFilter';
import ApiRequestsFilter, { IApiRequestsFilter } from './ApiRequestsFilter';
import { IApiRequests, useApiRequestsList } from '../../../api/apiRequests';
import ApiRequestsList from './ApiRequestsList';
import IApiRequestsDetails from './Details/ApiRequestDetails.tsx';
import SearchSubstr from '../../../components/SearchSubstr/SearchSubstr';
import { offset, thisMonthStart, todayEnd } from '../../../helpers/datesHelper';


const useStyles = makeStyles(() => ({
  root: {},
  table:{ 
   width: '100%',
   minWidth: 'fit-content' 
  },
  search: {
    padding: '8px 12px 0px'
  }
}));

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'requestedById', name: 'Requested By ID', active: true },
  { id: 'url', name: 'URL', active: true },
  { id: 'created', name: 'Created at', active: true },
  { id: 'updated', name: 'Updated at', active: true },
];

export default function ApiRequestsPage(){

  const classes = useGlobalStyles();
  const classesPage = useStyles();
  const [{ items, full, error }, loading, load] = useApiRequestsList();
  const history = useHistory();
  const [search, setSearch] = useState('');
  const { apiRequestId } = useParams<any>();
  const [columns, ColumnsFilter] = useColumns('apiRequests', defaultColumns);
  const emptyFilter = () => ({
    requestedById: undefined,
    dateTo: todayEnd().valueOf() - offset,
    dateFrom: thisMonthStart().valueOf() - offset,
    pickerViewDateTo: todayEnd().valueOf(),
    pickerViewDateFrom: thisMonthStart().valueOf(),
  });
  const [setFilter, filter] = useFilter('apiRequests', emptyFilter(), false);

  useEffect(() => {
    load({
      filter,
    });
  }, [filter]);

  function setDetails(id?: string) {
    history.push(`/other/api-requests${id ? `/${id}` : ''}`);
  }

  const searchFilter = items.filter((item: any) =>
    (item?.response || item?.request) && (JSON.stringify(item.response).toLowerCase().includes(search.toLowerCase()) ||
    JSON.stringify(item.request).toLowerCase().includes(search.toLowerCase())
    )
  ); 

  return(
    <Grid container spacing={3}>
      <Grid container>
        <Grid item xs={10}><ApiRequestsFilter filter={filter as IApiRequestsFilter} onChange={setFilter} /></Grid>
        <Grid item className={classesPage.search} xs={2}>
          <SearchSubstr search={search} setSearch={setSearch} label="Search" id="api_requests_search"/>
        </Grid>
      </Grid>
      <Grid item className={clsx(classes.table,classesPage.table)}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justify="flex-end">
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {searchFilter.length > 0
            ? <ApiRequestsList items={searchFilter} onRowClick={setDetails} columns={columns} />
            : <EmptyList error={error} loading={loading} />
          }
          <Loader loading={loading} />
        {!loading && !error && !full && searchFilter.length > 0 && <Waypoint onEnter={() => load({ cursor: items.length, filter })} />}
        </Paper>
      </Grid>
      {apiRequestId && items.length > 0 &&
      <IApiRequestsDetails
        id={apiRequestId}
        data={items.find((i: IApiRequests) => i.id.toString() === apiRequestId)}
        onClose={()=>setDetails()}
        loading={false} 
      />}
    </Grid>
  );
}