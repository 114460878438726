import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { usePaymentProviderDetails } from '../../../../api/paymentProviders';
import DetailsPopup from '../../../../components/Layout/DetailsPopup';
import PaymentProviderOverview from './PaymentProviderOverview';
import TabsPanel from '../../../../components/Tabs';
import PaymentProviderIvyBanks from './PaymentProviderIvyBanks/PaymentProviderIvyBanks';

interface IProps {
  id: string;
  onClose: any;
}

export default function PaymentProviderDetails({ id, onClose }: IProps) {
  const [{ data }, loading, load] = usePaymentProviderDetails();
  const onUpdate = () => load(id);

  useEffect(() => {
    load(id);
  }, [id])

  const tabs = [
    {
      title: 'Overview',
      content: <PaymentProviderOverview data={data} onUpdate={onUpdate} />
    }
  ];

  if (id === 'IVY') {
    tabs.push({
      title: 'Banks',
      content: <PaymentProviderIvyBanks />
    });
  }

  return (
    <DetailsPopup width={900} loading={loading} onClose={onClose}>
      {data
        ? (
          <>
            <Typography variant="h6">{data.name}</Typography>
            <TabsPanel tabs={tabs} activeTab={0} />
          </>
        ) : <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  )
}
