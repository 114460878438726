import React from 'react';
import Label from './index';
import Descriptor from '../Descriptor';
import { getBlockchainTxStatusType } from '../../helpers/blockchainTxHelper';
import { BlockchainTxStatus } from '../../api/blockchain';

interface IProps {
  status: BlockchainTxStatus;
}

export default function OrderStatusLabel({ status }: IProps) {
  return (
    <Descriptor group="blockchainTxStatus" title={status}>
      <Label label={status} type={getBlockchainTxStatusType(status)} size="small" />
    </Descriptor>
  );
}
