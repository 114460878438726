import React from 'react';
import Typography from '@material-ui/core/Typography';
import DetailsPopup from '../../../components/Layout/DetailsPopup';
import ApiKeyForm from './ApiKeyForm';
import LegalInfo from '../Legal/Info';
import UISettings from './UISettings';
import TabsPanel from '../../../components/Tabs';

interface IProps {
  keyData: any;
  id: any;
  onClose: any;
  loading: boolean;
  onActionComplete: any;
}

export default function ApiKeyDetails({ id, keyData, loading, onClose, onActionComplete }: IProps) {
  const view = id !== 'new';

  function onSuccessAction(data: any, col?: string, type?: string) {
    onActionComplete(data, col, type);
    onClose();
  }

  const tabs = [
    {
      title: 'Key info',
      content: <ApiKeyForm keyData={keyData} isNew={!view} onSuccess={onSuccessAction} />
    }
  ];

  if (view && keyData) {
    tabs.push(
      {
        title: 'Legal info',
        content: <LegalInfo keyData={keyData} onSave={onActionComplete} />
      },
      {
        title: 'UI',
        content: <UISettings onSave={onSuccessAction} keyData={keyData} />
      }
    );
  }

  return (
    <DetailsPopup loading={loading} onClose={onClose}>
      <Typography variant="h6">{id === 'new' ? 'Create API key' : `API key for ${keyData?.domain}`}</Typography>
      {keyData ? <TabsPanel tabs={tabs} /> : <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  );
}
