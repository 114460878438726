import React, { useState } from 'react';

import clsx from 'clsx';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DateView from '../../components/DateView';
import { DataRow } from '../../components/DataView';
import { IAdminActivity } from '../../api/admin';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMobileStyles from '../../hooks/useMobileStyles';
import Tablecell from '../../components/TableCell/TableCell';

interface IProps {
  item: IAdminActivity;
  type?: 'popup' | 'page';
  lastChild?: IAdminActivity;
}

interface IExtraProps {
  data: any;
  oldData?: any;
}

const useExtraStyles = makeStyles((theme) => ({
  root: {},
  text: {
    color: 'inherit',
  },
  keyPadding: {
    paddingLeft: 30,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      wordWrap: 'break-word',
      paddingLeft: 0,
    },
  },
  block: {
    maxWidth:'300px',
    wordWrap: 'break-word',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '160px',
    },
  }
}));

function Extra({ data, oldData}: IExtraProps) {
  const classes = useGlobalStyles();
  const classesExtra = useExtraStyles();
  function highlightСhanges(key:string, oldData:any, newData:any){
    if((newData[key] && !oldData)) {
      return classes.errorText
    } 
    if(newData && oldData && newData[key] !== oldData[key] && (typeof (newData[key])!=='object' || typeof (oldData[key])!=='object')){
      return classes.errorText
    }
    return classesExtra.text
  }
  
  switch (typeof (data)) {
    case 'object':
      if (data === null) {
        return <>N/A</>;
      }
      return (
        <>
        {Array.isArray(data)
          ? data.join(', ')
          : (
            <div className={classesExtra.keyPadding}>
              {Object.keys(data).map((k) => 
              <div className={clsx(classesExtra.block, highlightСhanges(k, oldData, data))} 
             key={k}>{k}:
               <Extra oldData={oldData && oldData[k] ? oldData[k]: null} data={data[k]} /></div>)}
            </div>
          )}
        </>
      );
    default:
      return <>{`${data}`}</>;
  }
}

export default function AdminsList({ item, type, lastChild }: IProps) {
  const classes = useGlobalStyles();
  const [expand, setExpand] = useState(false);
  const mobileClasses = useMobileStyles();

  return (
    <>
      <TableRow className={clsx(classes.pointer, mobileClasses.row, lastChild && mobileClasses.lastChild)} hover onClick={() => setExpand(!expand)}>
        {type !== 'popup' && (
          <>
            <Tablecell title="Admin ID">{item.adminId}</Tablecell>
            <Tablecell title="Admin Email">{item.adminEmail}</Tablecell>
            <Tablecell title="Admin username">{item.adminUsername}</Tablecell>
          </>
        )}
        <Tablecell title="IP address">{item.ip}</Tablecell>
        <Tablecell title="Type">{item.type}</Tablecell>
        {type !== 'popup' && <Tablecell title="Endpoint">{item.endpoint}</Tablecell>}
        <Tablecell title="Country">{item.country}</Tablecell>
        <Tablecell title="Created at">
          <DateView value={item.createdAt} />
        </Tablecell>
      </TableRow>
      {expand && (
        <TableRow>
          <TableCell colSpan={7}>
            {type === 'popup' && <DataRow label="Endpoint" labelWidth={80}>{item.endpoint}</DataRow>}
            <DataRow label="City" labelWidth={80}>{item.city}</DataRow>
            <DataRow label="User agent" labelWidth={80}>{item.userAgent}</DataRow>
            <DataRow label="Headers" labelWidth={80}>
              <div className={classes.breakWord}>{item.headers}</div>
            </DataRow>
            <DataRow label="Extra" labelWidth={80} isMobile>
              {Object.keys(item.extra).map((k) => (
                <div key={k}>
                  <strong>{k}</strong>: <Extra oldData={k.startsWith('new') ? item.extra[k.replace('new', 'old')] : item.extra[k]} data={item.extra[k]} />
                </div>
              ))}
            </DataRow>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}