import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { Form } from 'react-final-form';

import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import Descriptor from '../../../components/Descriptor';
import {Input, Checkbox, ProtectionInput, Select} from '../../../components/Form';
import Loader from '../../../components/Loader';
import useFormStyles from '../../../hooks/useFormStyles';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import { required, isDomain, composeValidators, isURL } from '../../../validators';
import { useCreateApiKey, useUpdateApiKey, useDeleteApiKey, useSettingsReset, useConvertApiKey } from '../../../api/apiKeys';
import {IKycCountry, useKycCountries} from "../../../api/kyc";
import {useBreakpoints} from "../../../hooks/useBreakpoints ";
import Modal from './Modal';
import { TRAFFIC_TYPE_OPTIONS } from '../../../constants/apiKey';

interface IProps {
  keyData: any;
  isNew: boolean;
  onSuccess: any;
  customerId?: any;
  subAccount?: string | undefined;
  loadCustomer?: (id: string)=> void
}

const useStyles = makeStyles((theme) => ({
  root: {},
  generateKey: {
    marginTop: 16,
    maxWidth: 150,
    height: 40,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  customerIdRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    '& a': {
      color: '#e0e0e0'
    }
  },
  buttons: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: '20px'
    },
  },
}));

function makeKey(length: number) {
  let key = '';
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
  const charsLength = chars.length;
  for ( var i = 0; i < length; i++ ) {
    key += chars.charAt(Math.floor(Math.random() * charsLength));
  }
  return key;
}

export default function ApiKeyForm({ keyData, isNew, onSuccess, customerId, subAccount, loadCustomer }: IProps) {
  const location = useLocation();
  const formClasses = useFormStyles();
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const [resetForm, setResetForm] = useState(false);
  const [{ items: countries }, loadingCountries, loadCountries] = useKycCountries();
  const isResolutionSevenHundred = useBreakpoints(700);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const isCustomerTab = location.pathname.includes('customer');

  const saveFunc = isNew ? useCreateApiKey : useUpdateApiKey;
  const [save, saving] = saveFunc((res: any) => {
    if (res.success) {
      onSuccess(res.data);
    }
  });
  const [del, deleting] = useDeleteApiKey((res: any) => {
    if (res.success) {
      onSuccess(keyData, 'id', 'delete');
    }
  });
  const [reset, resetting] = useSettingsReset((res: any) => {
    if (res.success) {
      setResetForm(false);
      onSuccess(res.data);
    }
  })
  const [convert, converting] = useConvertApiKey((res: any) => {
    if (res.success) {
      loadCustomer && loadCustomer(customerId);
    }
    setIsOpenDialog(false);
  });

  useEffect(() => {
    loadCountries();
  }, [])

  const initial = { ...keyData, trusted: keyData?.trusted ?? 'both', '2fa': '' };
  if (customerId) {
    initial.customerId = customerId;
  }

  return (
    <Loader loading={saving || deleting || converting} width={550}>
      {resetForm && (
        <Dialog open onClose={() => setResetForm(false)}>
          <div className={globalClasses.dialog}>
            <Form
              onSubmit={reset}
              initialValues={{
                id: keyData.id,
                ui: false,
                tokenAuthorizationEnabled: false,
                dmrProxy: false,
                sendKycMeta: false,
                '2fa': ''
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Input name="id" type="hidden" />
                  <DialogTitle color="primary">What do you want to override?</DialogTitle>
                  <DialogContent>
                    <div className={formClasses.row}>
                      <Checkbox name="ui" label="UI settings" />
                    </div>
                    <div className={formClasses.row}>
                      <Checkbox name="tokenAuthorizationEnabled" label="Token authorization" />
                    </div>
                    <div className={formClasses.row}>
                      <Checkbox name="dmrProxy" label="Use DMR proxy" />
                    </div>
                    <div className={formClasses.row}>
                      <Checkbox name="sendKycMeta" label="Send KYC meta info in postback" />
                    </div>
                    <ProtectionInput />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      onClick={() => setResetForm(false)}
                      size="small"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      size="small"
                      disabled={resetting}
                    >
                      Reset
                    </Button>
                  </DialogActions>
                </form>
              )}
            />
          </div>
        </Dialog>
      )}
      <Form
        onSubmit={(values) => { 
          save({
            ...values,
            trusted: values.trusted === 'both' ? null : values.trusted,
            id: !isNew ? keyData.id : undefined,
          });
        }}
        initialValues={initial}
        mutators={{
          generateKey: (args, state, { changeValue }) => {
            changeValue(state, 'key', () => makeKey(25));
          },
          generateSID: (args, state, { changeValue }) => {
            changeValue(state, 'sid', () => makeKey(10));
          },
        }}
        render={({ handleSubmit, invalid, values, form }) => (
          <form onSubmit={handleSubmit} className={formClasses.root}>
            <div className={formClasses.row}>
              <div className={classes.customerIdRow}>
                <Input
                  name="customerId"
                  label="Customer ID"
                  type="search"
                  autoComplete="xyz123"
                  validate={required}
                  disabled={!isNew || !!customerId}
                />
                <a href={`/customers/customer/${values.customerId}`} target="_blank">
                  <OpenInNewIcon />
                </a>
              </div>
            </div>
            <div className={formClasses.row}>
              <Input
                name="domain"
                label="Domain"
                validate={composeValidators(required, isDomain)}
              />
            </div>
            <div className={formClasses.row}>
              <Input
                name="key"
                label="Key"
                validate={required}
              />
              <Button
                variant="contained"
                onClick={form.mutators.generateKey}
                className={classes.generateKey}
              >
                Generate key
              </Button>
            </div>
            <div className={formClasses.row}>
              <Input
                name="sid"
                label="SID"
                type="search"
                autoComplete="xyz123"
                validate={required}
              />
              <Button
                variant="contained"
                onClick={form.mutators.generateSID}
                className={classes.generateKey}
              >
                Generate SID
              </Button>
            </div>
            <div className={formClasses.row}>
              <Checkbox
                name="checkSignature"
                label="Check signature"
              />
              <Checkbox
                name="publicApi"
                label="Public API"
              />
            </div>
            <div className={formClasses.row}>
              <Descriptor group="customerSettings" title="Authorise customer with token" top={7}>
                <Checkbox
                  name="tokenAuthorizationEnabled"
                  label={`Authorise customer with token ${typeof (keyData.tokenAuthorizationEnabled) !== 'boolean' ? '(currently not set)' : ''}`}
                />
              </Descriptor>
              <Checkbox
                name="enabledInvoices"
                label="Enabled Invoices"
              />
            </div>
            <div className={formClasses.row}>
              <Input name="dmrProxyUrl" label="DMR proxy URL" validate={isURL} />
              <Descriptor group="customerSettings" title="Use DMR proxy" top={7} className="v-center">
                <Checkbox name="dmrProxy" label={`Use DMR proxy ${typeof (keyData.dmrProxy) !== 'boolean' ? '(currently not set)' : ''}`} />
              </Descriptor>
            </div>
            <div className={formClasses.row}>
              <Select 
                fullWidth 
                name="trusted" 
                margin="normal"
                noEmptyOption
                label="Traffic type" 
                options={TRAFFIC_TYPE_OPTIONS} 
              /> 
              <Descriptor group="customerSettings" title="Send KYC meta" top={7} className="v-center">
                <Checkbox name="sendKycMeta" label={`Send KYC meta info in postback ${typeof (keyData.sendKycMeta) !== 'boolean' ? '(currently not set)' : ''}`} />
              </Descriptor>
            </div>
            <div className={formClasses.row}>
               <Select
                  name="excludedCountries"
                  label={`Excluded Countries (${values?.countries?.length || 0})`}
                  options={countries.map((c: IKycCountry) => ({ value: c.code, label: `${c.name} (${c.code})` }))}
                  multi
                  loading={loadingCountries}
                  margin="normal"
                  fullWidth={isResolutionSevenHundred}
                />
            </div>
            <div className={formClasses.row}>
              <ProtectionInput />
              <div />
            </div>
            <div className={clsx(formClasses.actions, classes.buttons)}>
              <Button
                type="submit"
                disabled={invalid}
                variant="contained"
                size="large"
              >
                Save
              </Button>
              {!isNew && (
                <>
                  <Button
                    disabled={!values['2fa'] || !values['2fa'].length}
                    onClick={() => del(values)}
                    variant="contained"
                    size="large"
                    color="secondary"
                  >
                    Delete
                  </Button>
                 {!subAccount && isCustomerTab &&
                    <Button
                      variant="contained"
                      onClick={() => setIsOpenDialog(true)}
                    >
                      Convert to Account
                    </Button>}
                  <Button
                    onClick={() => setResetForm(true)}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Reset settings
                  </Button>
                </>
              )}
            </div>
          </form>
        )}
      />
      {isOpenDialog && <Modal keyData={keyData} setIsOpenDialog={setIsOpenDialog} convert={convert} />}
    </Loader>
  );
}
