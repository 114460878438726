import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Big from 'big.js';
import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { CurrencyType } from '../../../../api/currency';
import { ISettlement, SettlementStatus, useSettlementAdditionalFeeUpdate } from '../../../../api/settlements';
import { Input, ProtectionInput } from '../../../../components/Form';
import PopupLink from '../../../../components/UI/PopupLink';
import useFormStyles from '../../../../hooks/useFormStyles';
import { mustBeNumber } from '../../../../validators';
import { IMappedSettlementOperation } from '../SettlementInfo';

export interface ISettlementFees {
  settlement: ISettlement;
  operations: IMappedSettlementOperation[];
  type: CurrencyType;
  onAdditionalFeeUpdate(): void;
}

const calcFee = (amount: number, feePercent?: number) => feePercent !== undefined
  ? Big(amount).mul(feePercent).round(2, Big.roundUp).toString()
  : '-';

export const SettlementFees: FC<ISettlementFees> = ({
  settlement: { settlementFees, id, status },
  operations,
  type,
  onAdditionalFeeUpdate,
}) => {
  const [setAdditionalFee, savingAdditionalFee] = useSettlementAdditionalFeeUpdate((res: any) => {
    res.success && onAdditionalFeeUpdate();
  });
  const formClasses = useFormStyles();
  return (
    <>
      <Typography variant="body1" component="div" color="primary">Settlement Fees</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Customer ID</TableCell>
            <TableCell align="center">Currency</TableCell>
            <TableCell align="center">Requested Amount</TableCell>
            <TableCell align="center">Fiat Settlement fee</TableCell>
            <TableCell align="center">Crypto Settlement fee</TableCell>
            <TableCell align="center">FX Settlement fee</TableCell>
            <TableCell align="center">Additional Settlement Fee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {operations.map((o, index) =>
            (
              <TableRow key={index}>
                <TableCell>
                  <PopupLink popup="customer" id={o.customerId} baseUrl={'/customers'}>
                    {o.customerId}
                  </PopupLink>
                </TableCell>
                <TableCell align="center">{o.currency}</TableCell>
                <TableCell align="center">{o.amount}</TableCell>
                <TableCell align="center">
                  {type === CurrencyType.FIAT ? calcFee(o.amount, settlementFees[o.customerId]?.fiatSettlementFee) : '-'}
                </TableCell>
                <TableCell align="center">
                  {type === CurrencyType.CRYPTO ? calcFee(o.amount, settlementFees[o.customerId]?.cryptoSettlementFee) : '-'}
                </TableCell>
                <TableCell align="center">
                  {o.currency !== 'EUR' ? calcFee(o.amount, settlementFees[o.customerId]?.fxSettlementFee) : '-'}
                </TableCell>
                <TableCell align="center">
                  {calcFee(o.amount, settlementFees[o.customerId]?.additionalSettlementFee)}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
      {status === SettlementStatus.REQUESTED && (
        <Form
          initialValues={{
            additionalSettlementFee: settlementFees[Object.keys(settlementFees)[0]].additionalSettlementFee,
            '2fa': '',
          }}
          onSubmit={(values) => setAdditionalFee({ ...values, settlementId: id })}
          render={({ valid, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={formClasses.row}>
                <Input name="additionalSettlementFee" label="Additional Fee" validate={mustBeNumber} />
                <ProtectionInput />
                <Button
                  // size="small"
                  variant="contained"
                  type="submit"
                  className="v-center"
                  style={{ flex: '0 0 100px' }}
                  disabled={!valid || savingAdditionalFee}
                >
                  Save
                </Button>
              </div>
            </form>
          )}
        />
      )}
    </>
  )
}
