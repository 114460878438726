import React from 'react';
import Label from './index';
import Descriptor from '../Descriptor';
import { PaymentStatus } from '../../api/payment';

interface IProps {
  status: PaymentStatus;
}

function getType(status: PaymentStatus) {
  switch (status) {
    case PaymentStatus.SUCCESS:
      return 'success';
    case PaymentStatus.ACCEPTED:
    case PaymentStatus.BLOCKED:
      return 'warning';
    case PaymentStatus.ERROR:
    case PaymentStatus.ERROR_UNBLOCK:
      return 'danger';
    default:
      return;
  }
}

export default function OrderStatusLabel({ status }: IProps) {
  return (
    <Descriptor group="paymentStatus" title={status}>
      <Label label={status} type={getType(status)} size="small" />
    </Descriptor>
  );
}
