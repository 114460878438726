import {OrderPostbackStatus, OrderStatus} from '../api/order';
import {ClearingStatus} from '../api/clearing';

export function getOrderStatusType(status: OrderStatus | ClearingStatus) {
  switch (status) {
    case OrderStatus.SUCCESS:
    case ClearingStatus.SUCCESS:
      return 'success';
    case OrderStatus.EXECUTED:
    case ClearingStatus.EXECUTED:
    case OrderStatus.ACCEPTED:
    case OrderStatus.VERIFICATION:
      return 'warning';
    case OrderStatus.PROCESSING:
      return 'primary';
    case OrderStatus.ERROR:
    case OrderStatus.EXPIRED:
    case OrderStatus.SUSPICIOUS:
    case ClearingStatus.ERROR:
      return 'danger';
    default:
      return;
  }
}

export function getPostbackStatusType(status: OrderPostbackStatus) {
  switch (status) {
    case OrderPostbackStatus.SUCCESS:
      return 'success';
    case OrderPostbackStatus.FAILED:
      return 'danger';
    default:
      return;
  }
}
