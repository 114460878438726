import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  preview: {
    width: '30px',
    height: '38px',
    position: 'absolute',
    right: 1,
    top: 17,
    borderRadius: '0 3px 3px 0'
  }
}));

interface IProps {
  children: any;
  color?: string;
}

export default function PaletteItem({ children, color }: IProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
      <div className={classes.preview} style={{ backgroundColor: color && color.length > 0 ? `#${color}` : 'transparent' }} />
    </div>
  );
}
