import React from 'react';
import Label from './index';
import Descriptor from '../Descriptor';
import { getRollingStatusType } from '../../helpers/rollingHelper';
import { RollingStatus } from '../../api/rolling';

interface IProps {
  status: RollingStatus;
}

export default function RollingStatusLabel({ status }: IProps) {
  return (
    <Descriptor group="rollingReserveStatus" title={status}>
      <Label label={status} type={getRollingStatusType(status)} size="small" />
    </Descriptor>
  );
}
