import React from 'react';
import Typography from '@material-ui/core/Typography';
import DetailsPopup from '../../components/Layout/DetailsPopup';
import TabsPanel from '../../components/Tabs';
import GeneralTab from './General';
import DefaultFees from './Fees';

interface IProps {
  onClose: any;
}

export default function GlobalSettings({ onClose }: IProps) {
  const tabs = [
    {
      title: 'General',
      content: <GeneralTab />
    },
    {
      title: 'Default fees',
      content: <DefaultFees />
    },
  ];

  return (
    <DetailsPopup onClose={onClose}>
      <Typography variant="h6" color="primary">Global settings</Typography>
      <TabsPanel tabs={tabs} />
    </DetailsPopup>
  )
}
