import React from 'react';

import clsx from 'clsx';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import { IColumn } from '../../../hooks/useColumns';
import { TSortDirection, TSortField } from './TracingPage';
import ColHead from '../../../components/UI/ColHead';
import Tablecell from '../../../components/TableCell/TableCell';
import useMobileStyles from '../../../hooks/useMobileStyles';
import { useBreakpoints } from '../../../hooks/useBreakpoints ';

export interface ITracing {
  name: string,
  description: string,
  baseUnit: string,
  count: string | number,
  total: string | number,
  max: string | number,
  avarage: string | number
}

interface IProps {
  items: ITracing[];
  columns: IColumn[];
  sortBy?: TSortField;
  sortDirection: TSortDirection;
}

const content: { [key: string]: (item: ITracing, key: number) => void } = {
  name: (i, key) => <Tablecell key={key} title="Name">{i.name}</Tablecell>,
  description: (i, key) =>
    <Tablecell key={key} title="Description">
      {i.description}
    </Tablecell>,
  base: (i, key) =>
    <Tablecell key={key} title="Base unit">
     {i.baseUnit}
    </Tablecell>,
  count: (i, key) => <Tablecell key={key} title="Count">{i.count}</Tablecell>,
  total: (i, key) => <Tablecell key={key} title="Total Time">{i.total}</Tablecell>,
  max: (i, key) => <Tablecell key={key} title="Max">{i.max}</Tablecell>,
  avarage: (i, key) => <Tablecell key={key} title="Avarage time">{i.avarage}</Tablecell>,
};

export default function TracingList({ items, columns, sortBy, sortDirection }: IProps){

  const mobileClasses = useMobileStyles();
  const isSm = useBreakpoints('sm');

  const isNumber = (id: string) => {
    return ['count','total','max','avarage'].includes(id);
  }; 

  return(
    <Table size="small">
      <TableHead>
        <TableRow className={clsx(mobileClasses.colHead, isSm && mobileClasses.lastChild)}>
          {!isSm && columns.map((c) => (
            <ColHead
              key={c.id}
              isSortNumber={isNumber(c.id)}
              title={c.name}
              onSort={c.sortFunc ? () => c.sortFunc(c.id, sortDirection, sortBy) : undefined}
              isSorted={sortBy === c.id}
              sortDirection={sortDirection}
            />
          ))}
          {isSm && columns.filter(column => column.sortFunc).map((c) => (
            <ColHead
              key={c.id}
              isSortNumber={isNumber(c.id)}
              title={c.name}
              onSort={c.sortFunc ? () => c.sortFunc(c.id, sortDirection, sortBy) : undefined}
              isSorted={sortBy === c.id}
              sortDirection={sortDirection}
            />
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow key={i.description} hover className={clsx(mobileClasses.row, mobileClasses.lastChild)}>
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}