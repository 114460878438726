import React from 'react';

import clsx from 'clsx';

import { Form } from 'react-final-form';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Input, Select, ProtectionInput, Checkbox, CurrencySelect } from '../../../components/Form';
import useFormStyles from '../../../hooks/useFormStyles';
import { useKycCountryUpdate, IKycCountry } from '../../../api/kyc';
import { useBreakpoints } from '../../../hooks/useBreakpoints ';
import useMobileStyles from '../../../hooks/useMobileStyles';

interface IProps {
  country?: IKycCountry;
  onSave: any;
}

const options = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export default function CountryDetails({ country, onSave }: IProps) {
  const formClasses = useFormStyles();
  const mobileClasses = useMobileStyles();
  const isResolutionSevenHundred = useBreakpoints(700);
  const [update, updating] = useKycCountryUpdate((res: any) => {
    if (res.success) {
      onSave();
    }
  })


  if(!country || !country.name) {
    return <Typography align="center">No data to display</Typography>
  }

  return (
    <>
      <Typography variant="h6" color="primary">{country.name}</Typography>
      <Form
        onSubmit={(values) => update({ ...values, id: country.code })}
        initialValues={{ ...country, '2fa': '' }}
        render={({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
          >
            <div className={formClasses.row}>
              <Input disabled name="code" label="Code" />
              <Input disabled name="name" label="Name" />
            </div>
            <div className={clsx(formClasses.row, isResolutionSevenHundred && mobileClasses.gap)}>
              <Select name="supported" label="Supported" options={options} fullWidth={isResolutionSevenHundred}/>
              <Select name="inHRTC" label="In EU HRTC list" options={options} fullWidth={isResolutionSevenHundred}/>
              <Select name="EU" label="EU country" options={options} fullWidth={isResolutionSevenHundred}/>
            </div>
            <div className={formClasses.row}>
              <Input name="riskScore" label="Risk score" type="number" inputProps={{ lang: 'en' }} />
              <Input name="kycScore" label="Kyc score" type="number" inputProps={{ lang: 'en' }} />
            </div>
            <div className={formClasses.row}>
              <Checkbox name="useState" label="Use State" />
              <Checkbox name="enableOccasionalTransactions" label="Occasional transactions" />
            </div>
            <div className={formClasses.row}>
              <CurrencySelect name="excludedCurrencies" label="Excluded currencies" multi />
            </div>
            <div className={formClasses.row}>
              <ProtectionInput />
            </div>
            <div className={formClasses.actions}>
              <Button
                type="submit"
                variant="contained"
                disabled={updating}
              >
                Save
              </Button>
            </div>
          </form>
        )}
      />
    </>
  )
}
