import React from 'react';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import { DateTimeField, Select, BoolSelect } from '../../../components/Form';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import {
  startDate,
  predefinedDates
} from '../../../helpers/datesHelper';

export interface IFilter {
  date1: number;
  date2: number;
  withSubAccounts?: boolean;
}

interface IProps {
  filter: IFilter,
  onChange: any
}

export default function BookkeeperReportFilter({ filter, onChange }: IProps) {
  const classes = useGlobalStyles();
  let dates = '';
  const datesOptions: any[] = [];
  Object.entries(predefinedDates()).forEach(([id, data]) => {
    datesOptions.push({ value: id, label: data.title });
    if (data.dateFrom === filter.date1 && data.dateTo === filter.date2) {
      dates = id;
    }
  })
  return (
    <Form
      onSubmit={onChange}
      initialValues={{ ...filter, dates }}
      render={({ values, handleSubmit }) => (
        <form className={classes.filters} onSubmit={handleSubmit}>
          <DateTimeField name="date1" label="Date from" min={startDate} max={values.date2 || undefined} />
          <DateTimeField name="date2" label="Date to" min={values.date1 || undefined} />
          <Select
            name="dates"
            label="Period"
            options={datesOptions}
          />
          <BoolSelect
            name="withSubAccounts"
            label="With sub-accounts"
          />
          <Button type="submit" variant="contained" size="large" className="apply">Apply</Button>
        </form>
      )}
    />
  );
}
