import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DateView from "../../../components/DateView";
import BoolLabel from "../../../components/Label/BoolLabel";
import { IColumn } from "../../../hooks/useColumns";
import useGlobalStyles from "../../../components/Layout/useGlobalStyles";
import { GatewayConfig } from "../../../api/gatewayConfig";
import { Chip, makeStyles } from "@material-ui/core";
import Tablecell from "../../../components/TableCell/TableCell";
import clsx from 'clsx';
import useMobileStyles from "../../../hooks/useMobileStyles";

const useStyles = makeStyles((theme) => ({
  root: {},
  chip: {
    flex: '0 0 30%',
    margin: '2px',
    [theme.breakpoints.down('sm')]: {
      flexBasis: 'auto',
    },
  },
  mobile: {
    display: 'none',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  cell: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
  },
}));

interface IProps {
  items: GatewayConfig[];
  columns: IColumn[];
  openDetails: (id: string) => void;
}

export function GatewaysConfigsList({ items, columns, openDetails }: IProps) {
  const globalClasses = useGlobalStyles();
  const activeCols = columns.filter((c) => c.active);
  const mobileClasses = useMobileStyles();
  const classes = useStyles();

  const content: { [key: string]: (i: GatewayConfig) => void } = {
    id: ({ id }) => <TableCell key="id" className={mobileClasses.id}>{id}</TableCell>,
    enabled: ({ enabled }) => (
      <Tablecell key="enabled" title="Enabled">
        <BoolLabel value={enabled} />
      </Tablecell>
    ),
    fee: ({ fee }) => <Tablecell key="fee" title="Fee">{fee}</Tablecell>,
    pairs: ({ pairs }) => (
      <TableCell className={classes.cell} key="pairs" title="Pairs">
        <span className={classes.mobile}>Pairs: </span>
        {pairs &&
          pairs.map((item: string) => (
            <Chip
              label={item}
              key={item}
              className={classes.chip}
            />
          ))}
      </TableCell>
    ),
    mappings: ({ mappings }) => (
      <TableCell className={classes.cell} key="mappings" title="Mappings">
        <span className={classes.mobile}>Mappings: </span>
        {mappings &&
          mappings.map((item: string) => (
            <Chip
              label={item}
              key={item}
              className={classes.chip}
            />
          ))}
      </TableCell>
    ),
    createdAt: ({ createdAt }) => (
      <Tablecell key="created" title="Created">
        <DateView value={createdAt} />
      </Tablecell>
    ),
    updatedAt: ({ updatedAt }) => (
      <Tablecell key="updated" title="Updated">
        <DateView value={updatedAt} />
      </Tablecell>
    ),
  };

  return (
    <Table size="small">
      <TableHead className={mobileClasses.tableHead}>
        <TableRow>
          {activeCols.map((c) => (
            <TableCell key={c.id}>{c.name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow
            hover
            className={clsx(globalClasses.pointer, mobileClasses.row)}
            key={i.id}
            onClick={() => openDetails(i.id)}
          >
            {activeCols.map((c) => content[c.id](i))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
