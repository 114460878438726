import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../Layout';

interface IRouteProps {
  component: any;
  title: string;
  exact?: boolean;
  path: string;
}

export default function RouteWithLayout(props: IRouteProps) {
  const { component: Component, title, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout title={title}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
}
