import React from 'react';
import Label from './index';
import Descriptor from '../Descriptor';
import { OrderType } from '../../api/order';
import { TLabelType } from './index';

interface IProps {
  type?: OrderType;
}

interface IData {
  type: TLabelType;
  label: string;
}

export default function OrderTypeLabel({ type }: IProps) {
  if (!type) return null;

  let data: IData = {
    label: type,
    type: 'success'
  };
  if (type === OrderType.WALLET_TO_FIAT || type === OrderType.WALLET_TO_CRYPTO) {
    data.type = 'warning';
  }
  return (
    <Descriptor group="orderStatus" title={data.label}>
      <Label {...data} size="small" />
    </Descriptor>
  );
}
