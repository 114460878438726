import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import useGlobalStyles from '../Layout/useGlobalStyles';

interface IProps {
  chars: number;
  text?: string;
}

export default function ShortString({ chars, text}: IProps) {
  const classes = useGlobalStyles();
  if (!text) return null;

  if (text.length <= chars) return <>{text}</>;

  return (
    <Tooltip title={text} placement="top-end">
      <span className={classes.tooltiped}>{text.substring(0, chars)}...</span>
    </Tooltip>
  );
}
