import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import JSZip from 'jszip';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { DataRow } from '../../../components/DataView';
import DateView from '../../../components/DateView';
import { Checkbox, Input, ProtectionInput, Select } from '../../../components/Form';
import {
  DocumentType,
  IKYCDocument,
  IKYCItem,
  KysStatus,
  useDocumentsRecognize,
  useKycCountries,
  IKycCountry, useKycRecheckApplicant
} from '../../../api/kyc';
import useFormStyles from '../../../hooks/useFormStyles';
import { required } from '../../../validators';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DocumentView from './DocumentView';
import DocumentsCarousel from './Documents';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import KycInternalComments from './InternalComments';
import { ACLType } from '../../../api/acl';
import ACLFastAdd, { ACLData } from '../../ACL/BlakWhite/ACLFastAdd';
import { ICustomer } from '../../../api/customers';
import { getSumSubUrl } from '../index';
import { base64ToFile } from '../../../helpers/fileHelper';
import DocumentUploader from './DocumentUploader';
import {useLegalEntityOne} from '../../../api/legalEntity';
import {values} from "lodash";
import { useBreakpoints } from '../../../hooks/useBreakpoints ';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  docTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  image: {
    width: 400,
    margin: `0 auto ${theme.spacing(2)}px`,
  },
  docView: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    position: 'relative',
    '& .head': {
      position: 'fixed',
      width: '100%',
      height: '40px',
      left: 0,
      top: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(2),
      background: theme.palette.background.paper,
      opacity: 0.3,
      zIndex: 10,
      '&:hover': {
        opacity: 0.7
      }
    }
  },
  fullImage: {
    width: '100%'
  },
  comments: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  recheck: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down(700)]: {
      marginLeft: '0px',
    },
  },
  statusError: {
    marginTop: -theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.error.main
  },
  correctionComment: {
    '& .MuiFormLabel-root': {
      color: '#f44336'
    }
  },
  sumSubResult: {
    '&.red': {
      color: theme.palette.error.main
    },
    '&.green': {
      color: theme.palette.success.main
    }
  },
  setSumSubComment: {
    position: 'absolute',
    right: 8,
    top: -5,
    cursor: 'pointer'
  }
}));

interface IProps {
  data: IKYCItem;
  onSave: any;
  saving: boolean;
  customer: ICustomer;
  updateApplication: any;
  setTab: any;
  updateDetails: any;
}

interface IDocuments {
  [key: string]: IKYCDocument[];
}

export default function KYCOverview({ data, onSave, saving, customer, updateApplication, setTab, updateDetails }: IProps) {
  const formClasses = useFormStyles();
  const classes = useStyles();
  const [docView, setDocView] = useState('');
  const [idDoc, setIdDoc] = useState();
  const [residenceDoc, setResidenceDoc] = useState();
  const [downloading, setDownloading] = useState(false);
  const requestedInitial: string[] = [];
  const [requestedAdditional, setRequestedAdditional] = useState(requestedInitial);
  const [acl, setAcl] = useState<ACLData | undefined>();
  const [recognize, savingRecognize] = useDocumentsRecognize((res: any) => { })
  const [{ items: countries }, loading, load] = useKycCountries();
  const [recheck, rechecking] = useKycRecheckApplicant();
  const [{ data:entity }, loadingEntity, loadEntity] = useLegalEntityOne();
  const isResolutionSevenHundred = useBreakpoints(700);

  useEffect(() => {
    loadEntity(data.legalEntity)
    load();
  }, [])

  const initial: any = { reason: '', ...data, '2fa': '', country: customer.country };

  if (data.corrections) {
    data.corrections.forEach((c) => {
      if (!c.resolved) {
        initial[c.type] = true;
        initial[`comment-${c.type}`] = c.comment;
      }
    })
  }

  if (data.applicantData && data.applicantData.info) {
    const { firstNameEn, lastNameEn, dob } = data.applicantData.info;
    //@ts-ignore
    const { townEn, postCode, streetEn } = data.applicantData.info.addresses && data.applicantData.info.addresses.length ? data.applicantData.info.addresses[0] : {};
    initial.city = townEn;
    initial.zipCode = postCode;
    initial.address = streetEn;
    initial.firstName = firstNameEn;
    initial.lastName = lastNameEn;
    initial.birthDate = dob;
  }

  const documents: IDocuments = {};
  data.documents?.sort((a: any, b: any) => b.createdAt - a.createdAt).forEach((d) => {
    if (documents[d.type]) {
      documents[d.type].push(d);
    } else {
      documents[d.type] = [d];
    }
  })

  const noDocsCorrections = [...requestedAdditional];
  Object.keys(DocumentType).forEach((t) => {
    if (initial[t] && !documents[t] && !noDocsCorrections.includes(t)) {
      noDocsCorrections.push(t);
    }
  });
  const additionalDocs: string[] = [];
  if (Object.keys(documents).length !== Object.keys(DocumentType).length) {
    Object.keys(DocumentType).forEach((t) => {
      if (!documents[t] && !noDocsCorrections.includes(t)) {
        additionalDocs.push(t);
      }
    });
  }

  function downloadDocs() {
    if (downloading) return;
    setDownloading(true);
    const files: File[] = [];

    data.documents?.forEach((d) => {
      files.push(base64ToFile(d.data, `${d.type}-${d.createdAt}`));
    })

    const zip = new JSZip();

    files.forEach((f) => {
      const filename = f.name;
      zip.file(filename, f);
    })

    zip.generateAsync({ type: 'blob' }).then((blob) => {
      const zipblob = new Blob([blob])

      const a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(zipblob);
      const d = new Date();
      a.download = `utp-customer-${data.customerId}-documents-${d.toISOString()}.zip`;
      a.click();
      setDownloading(false);
    })
  }

  const countryOptions = countries.map((c: IKycCountry) => ({ value: c.code, label: c.name }));
  const lastCallback = data.subSumCallbacks && data.subSumCallbacks[0];

  return (
    <>
      {acl && <ACLFastAdd onClose={() => setAcl(undefined)} value={acl.value} type={acl.type} white={acl.white} />}
      <DataRow label="Legal Entity">
        {entity?.name}
      </DataRow>
      <DataRow label="Email">
        <div>
          <Link href={`/customers/customer/${customer.id}#orders`}><span>{data.customerEmail}</span></Link>&nbsp;&nbsp;
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setAcl({ type: ACLType.EMAIL, value: customer.email })}
            size="small"
          >
            Blacklist
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            size="small"
            onClick={() => setAcl({ type: ACLType.EMAIL, value: customer.email, white: true })}
          >
            Whitelist
          </Button>
          {data.flow !== 'SUMSUB' && data.documents && data.documents.length > 0 && (
            <div style={{ float: 'right' }}>
              <Button size="small" onClick={downloadDocs} disabled={downloading}>Download documents</Button>
            </div>
          )}
        </div>
      </DataRow>
      {lastCallback && (
        <>
          <DataRow label="Open on SumSub">
            <Link href={getSumSubUrl(lastCallback.data.applicantId)} target="_blank">
              {lastCallback.data.applicantId}
            </Link>
          </DataRow>
          {lastCallback.data.reviewResult && (
            <>
              <DataRow label="Review answer">
                <span className={clsx(classes.sumSubResult, lastCallback.data.reviewResult.reviewAnswer.toLowerCase())}>
                  {lastCallback.data.reviewResult.reviewAnswer}
                </span>
              </DataRow>
              {lastCallback.data.reviewResult.rejectLabels && (
                <DataRow label="Reject labels">{lastCallback.data.reviewResult.rejectLabels.join(', ')}</DataRow>
              )}
              {lastCallback.data.reviewResult.moderationComment && (
                <DataRow label="Moderation comment" copy={lastCallback.data.reviewResult.moderationComment}>
                  {lastCallback.data.reviewResult.moderationComment}
                </DataRow>
              )}
              {lastCallback.data.reviewResult.clientComment && (
                <DataRow label="Client comment" copy={lastCallback.data.reviewResult.clientComment}>
                  {lastCallback.data.reviewResult.clientComment}
                </DataRow>
              )}
            </>
          )}
          <DataRow label="Callback type">{lastCallback.data.type}</DataRow>
          <DataRow label="Updated at">
            <DateView value={lastCallback.updatedAt} />
          </DataRow>
        </>
      )}
      <Divider />
      <Form
        onSubmit={(values) => {
          const corrections: any = {};
          Object.keys(DocumentType).forEach((t) => {
            if (!!values[t]) {
              corrections[t] = values[`comment-${t}`];
            }
          });

          // recognize documents
          if (idDoc || residenceDoc) {
            const docsData: any = {};
            if (idDoc) {
              docsData[idDoc] = {
                firstName: values.firstName,
                lastName: values.lastName,
                birthDate: values.birthDate
              };
            }
            if (residenceDoc) {
              docsData[residenceDoc] = {
                country: values.country,
                residence: values.residence,
                city: values.city,
                zipCode: values.zipCode,
                address: values.address
              };
            }
            if (Object.keys(docsData).length) {
              recognize({ documents: docsData, '2fa': values['2fa'] });
            }
          }
          onSave(data.id, { status: values.status, reason: values.reason, corrections, '2fa': values['2fa'] })
        }}
        mutators={{
          setStatus: (args, state, { changeValue }) => {
            changeValue(state, 'status', () => args[0]);
          },
          setCountry: (args, state, { changeValue }) => {
            const [country] = args;
            changeValue(state, 'country', () => country ? country.value : '');
            changeValue(state, 'residence', () => country ? country.name : '');
          },
          setComment: (args, state, { changeValue }) => changeValue(state, 'reason', () => args[0])
        }}
        initialValues={initial}
        render={({ handleSubmit, values, invalid, form }) => {
          let correctionsRequested = false;
          Object.keys(DocumentType).forEach((t) => {
            if (!!values[t]) {
              correctionsRequested = true;
            }
          });
          return (
            <form onSubmit={handleSubmit} style={{ marginTop: 16 }}>
              {Object.keys(documents).map((d) => (
                <div className={classes.row} key={d}>
                  <div className={classes.docTitle}>
                    <Typography variant="body1" color="primary" component="div">
                      {d.split('_').join(' ')}
                      <Typography component="span" color={values[d] ? 'error' : 'secondary'}>
                        &nbsp;{values[d] && 'Unresolved'}
                      </Typography>
                    </Typography>
                  </div>
                  <DocumentsCarousel
                    documents={documents[d]}
                    setFullScreen={setDocView}
                    type={d as DocumentType}
                    countryOptions={countryOptions}
                    setCountry={form.mutators.setCountry}
                    setIdDoc={setIdDoc}
                    setResidenceDoc={setResidenceDoc}
                    values={values}
                  />
                  <div>
                    <Checkbox name={d} label="Need corrections" />
                  </div>
                  {values[d] && (
                    <Input
                      name={`comment-${d}`}
                      multiline
                      validate={required}
                      label={`Comment for ${d.split('_').join(' ')}`}
                      className={classes.correctionComment}
                    />
                  )}
                  <OnChange name={d}>
                    {(val: any) => {
                      if (val) {
                        form.mutators.setStatus(KysStatus.CORRECTION_REQUESTED);
                      }
                    }}
                  </OnChange>
                </div>
              ))}
              {noDocsCorrections.map((d: string) => (
                d &&
                <div className={classes.row} key={d}>
                  <div className={classes.docTitle}>
                    <Typography variant="body2">
                      {d.split('_').join(' ')}
                    </Typography>
                  </div>
                  <div>
                    <Checkbox name={d} label="Need corrections" />
                  </div>
                  {values[d] && (
                    <Input
                      name={`comment-${d}`}
                      multiline
                      validate={required}
                      label={`Comment for ${d.split('_').join(' ')}`}
                      className={classes.correctionComment}
                    />
                  )}
                  <OnChange name={d}>
                    {(val: any) => {
                      if (val) {
                        form.mutators.setStatus(KysStatus.CORRECTION_REQUESTED);
                      }
                    }}
                  </OnChange>
                </div>
              ))}
              {additionalDocs.length > 0 && (
                <div className={formClasses.row}>
                  <Select
                    name="addDoc"
                    label="Request another document"
                    options={additionalDocs.map((t) => ({ value: t, label: t }))}
                    fullWidth={isResolutionSevenHundred}
                  />
                  <div />
                  <OnChange name="addDoc">
                    {(value: any) => setRequestedAdditional([...requestedAdditional, value])}
                  </OnChange>
                </div>
              )}
              <div className={formClasses.row}>
                <Select
                  name="status"
                  label="Status"
                  options={
                    Object.keys(KysStatus)
                      //@ts-ignore
                      .filter(s => ![KysStatus.NONE, KysStatus.PARTIAL, KysStatus.TRUSTED, KysStatus.NEW].includes(s))
                      .map(s => ({ value: s, label: s }))}
                  validate={required}
                  fullWidth={isResolutionSevenHundred}
                />
                <div />
              </div>
              {values.status === KysStatus.CORRECTION_REQUESTED && !correctionsRequested && (
                <Typography variant="body2" color="textSecondary" className={classes.statusError}>
                  Please, select document which requires corrections
                </Typography>
              )}
              <DataRow label="Last update">
                <DateView value={data.updatedAt} />
              </DataRow>
              {values.status === KysStatus.REJECTED && (
                <div className={formClasses.row} style={{ position: 'relative' }}>
                  {lastCallback?.data.reviewResult?.moderationComment && (
                    <Link className={classes.setSumSubComment} onClick={() => form.mutators.setComment(lastCallback.data.reviewResult?.moderationComment)}>
                      Add moderation comment
                    </Link>
                  )}
                  <Input
                    name="reason"
                    validate={required}
                    label="Reason"
                    multiline
                  />
                </div>
              )}
              <div className={formClasses.row}>
                <ProtectionInput disabled={values.status === KysStatus.CORRECTION_REQUESTED && !correctionsRequested} />
              </div>
              <div className={formClasses.actions}>
                <Button
                  type="submit"
                  disabled={invalid || saving || savingRecognize}
                  size="large"
                  variant="contained"
                >
                  Save
                </Button>
              </div>
            </form>
          )
        }}
      />
      <Divider className={classes.comments} />
      <DocumentUploader
        updateDetails={updateDetails}
        kycId={data.id}
        additionalDocs={Object.keys(DocumentType)}
      />
      <Divider className={classes.comments} />
      <Form
        onSubmit={(values) => recheck({ ...values, id: data.id })}
        initialValues={{ '2fa': '' }}
        render={({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={formClasses.row}>
              <ProtectionInput margin={isResolutionSevenHundred ? 'normal' : 'none'} />
              <Button
                type="submit"
                disabled={rechecking || values['2fa']?.length < 6}
                variant="contained"
                size="large"
                className={classes.recheck}
              >
                Recheck applicant
              </Button>
            </div>
          </form>
        )}
      />
      <Divider className={classes.comments} />
      <KycInternalComments type="last" data={data} updateApplication={updateApplication} changeTab={setTab} />
      <Modal open={docView.length > 0} onClose={() => setDocView('')}>
        <div className={classes.docView}>
          <div className="head">
            <IconButton onClick={() => setDocView('')} size="small">
              <CloseIcon />
            </IconButton>
          </div>
          <DocumentView
            document={docView}
            imageClass={classes.fullImage}
            width={window.innerWidth}
          />
        </div>
      </Modal>
    </>
  );
}
