import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import Button from '@material-ui/core/Button';
import { CurrencySelect, Input, Autocomplete, BoolSelect } from '../../../components/Form';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import { useKycCountries, IKycCountry } from '../../../api/kyc';

export interface IPaymentProvidersFilter {
  id?: string;
  enabled?: boolean;
  depositSupported?: boolean;
  withdrawalSupported?: boolean;
  countries?: string[];
  currencies?: string[];
}

interface IProps {
  filter: IPaymentProvidersFilter,
  onChange: any,
  onSubmit: any,
  reload: any
}

export default function PaymentProvidersFilter({ filter, onChange, onSubmit, reload }: IProps) {
  const classes = useGlobalStyles();
  const [{ items: countries }, loadingCountries, loadCountries] = useKycCountries();

  useEffect(() => {
    loadCountries();
  }, [])

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={filter}
      render={({ handleSubmit }) => (
        <form className={classes.filters} onSubmit={handleSubmit}>
          <Input
            name="id"
            label="Provider ID"
            margin="none"
          />
          <CurrencySelect
            name="currencies"
            label="Currencies"
            multi
          />
          <Autocomplete
            name="country"
            label="Country"
            options={countries.map((a: IKycCountry) => ({ value: a.code, label: a.name }))}
            loading={loadingCountries}
          />
          <BoolSelect
            name="enabled"
            label="Enabled"
          />
          <BoolSelect
            name="depositSupported"
            label="Deposit supported"
          />
          <BoolSelect
            name="withdrawalSupported"
            label="Withdrawal supported"
          />
          <Button type="submit" variant="contained" size="large" className="apply">Apply</Button>
          <Button onClick={reload} variant="contained" size="large" className="apply">Reload</Button>
          <OnChange name="currencies">
            {(currencies: any) => onChange({ currencies })}
          </OnChange>
          <OnChange name="id">
            {(id: any) => onChange({ id })}
          </OnChange>
          <OnChange name="enabled">
            {(enabled: any) => onChange({ enabled })}
          </OnChange>
          <OnChange name="depositSupported">
            {(depositSupported: any) => onChange({ depositSupported })}
          </OnChange>
          <OnChange name="withdrawalSupported">
            {(withdrawalSupported: any) => onChange({ withdrawalSupported })}
          </OnChange>
        </form>
      )}
    />
  );
}
