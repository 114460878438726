import React, { FC } from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420,
    [theme.breakpoints.down('xs')]: {
      border: '1px solid #FFF'
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  }
}));

export interface ISearchProps {
  value?: string;
  className?: any;
  onChange?: any;
  onBlur?: any;
  placeholder?: string;
  name?: string;
}

const SearchInput: FC<ISearchProps> = ({
  className,
  name,
  value,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Paper
      className={clsx(classes.root, className)}
    >
      <SearchIcon className={classes.icon} />
      <Input
        {...rest}
        value={value}
        name={name}
        className={classes.input}
        disableUnderline
      />
    </Paper>
  );
};

export default SearchInput
