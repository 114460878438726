import React from 'react';

import Typography from '@material-ui/core/Typography';
import DetailsPopup from '../../../../components/Layout/DetailsPopup';
import TabsPanel from '../../../../components/Tabs';
import IApiRequestsOverview from './ApiRequestOverview';
import { IApiRequests } from '../../../../api/apiRequests';

interface IProps {
  id: string;
  data : IApiRequests | undefined;
  onClose: (id?: string | undefined)=> void;
  loading: boolean;
}

export default function IApiRequestsDetails({ data, onClose, loading, id }: IProps) {

  const tabs = [
    {
      title: 'Overview',
      content: <IApiRequestsOverview data={data}/>
    }
  ];

  return (
    <DetailsPopup loading={loading} onClose={onClose}>
      <>
        <Typography variant="h6">{'Api request ' + id}</Typography>
        <TabsPanel tabs={tabs} />
      </>
    </DetailsPopup>
  );
}
