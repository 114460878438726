import React, { useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import ActivitiesList from '../ActivitiesList';
import EmptyList from '../../../components/EmptyList';
import Loader from '../../../components/Loader';
import AdminActivityFilter, {IAdminActivityFilter} from '../ActivitiesFilter';
import { useAdminsActivitiesList } from '../../../api/admin';
import { emptyFilter } from '../ActivitiesPage';
import useFilter from '../../../hooks/useFilter';

interface IProps {
  id: any;
}

export default function AdminActivityTab({ id }: IProps) {
  const [{ items, full, error }, loading, load] = useAdminsActivitiesList();
  const [setFilter, filter] = useFilter('adminPopupActivity', emptyFilter());

  useEffect(() => {
    load({ filter: { ...filter, adminId: id } });
  }, [id, filter])

  return (
    <>
      <AdminActivityFilter
        filter={filter as IAdminActivityFilter}
        onChange={setFilter}
        type="popup"
      />
      {items.length > 0
        ? <ActivitiesList items={items} type="popup" />
        : <EmptyList error={error} loading={loading} width={450} />
      }
      <Loader loading={loading} />
      {!loading && !error && !full && items.length > 1
      && <Waypoint onEnter={() => load({ cursor: items.length, filter: { ...filter, adminId: id } })} />}
    </>
  );
}
