export const required = (value: any) =>
  value !== undefined && value !== null && value.toString().length > 0
    ? undefined
    : "Required field";

export const composeValidators =
  (...validators: any[]) =>
  (value: any) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

const emailRegexp =
  /^(([^<>()\[\]\\.,;:!&%#'\s@"]+(\.[^<>()\[\]\\.,;:!&%#'\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmail = (value: string) =>
  emailRegexp.test(value.trim().toLowerCase()) ? undefined : "Invalid email";

export const isHEX = (value: string) =>
  value
    ? /^([0-9A-F]{3}){1,2}$/i.test(value.toUpperCase())
      ? undefined
      : "Invalid color HEX"
    : undefined;

export const lengthRequired = (value: any, l: number) =>
  value && value.length !== l ? `Must be ${l} characters length` : undefined;

export const onlyAlphabetical = (value: string) =>
  value && !/^[a-zA-Z]+$/.test(value)
    ? "Only alphabetical characters allowed"
    : undefined;

export const isDomain = (value: any) => {
  if (!value) return;
  var pattern = new RegExp(
    "^(https?:\\/\\/)((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|)$",
    "i"
  );
  return !pattern.test(value)
    ? "Must be valid URL (https://utorg.pro etc.)"
    : undefined;
};

export const isURL = (value: any) => {
  if (!value) return;
  var pattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+!@#$%^&*()_;:-]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !pattern.test(value)
    ? "Must be valid URL (https://utorg.pro etc.)"
    : undefined;
};

export const maxValue = (value: any, max: number) =>
  parseFloat(value) > max ? `Maximum is ${max}` : undefined;
export const minValue = (value: any, min: number) =>
  parseFloat(value) < min ? `Minimum is ${min}` : undefined;

export const moreThan = (value: any, min: number) =>
  parseFloat(value) <= min ? `Must be greater than ${min}` : undefined;

export const mustBeNumber = (value: any) => {
  let pattern = new RegExp("^(\\b[0-9]*[.]?[0-9]+\\b)$");
  return !!value && !pattern.test(value) ? "Must be a number" : undefined;
};

export const maxLength = (value: any, max: number) =>
  !!value && value.length > max ? `${max} symbols maximum` : undefined;

export const length = (min: number, max: number) => (value: any) =>
  !!value && (value.length < min || value.length > max)
    ? `From ${min} to ${max} symbols`
    : undefined;

export const minLength = (value: any, min: number) =>
  !!value && value.length < min ? `${min} symbols minimum` : undefined;

// export const isLink = (str: string) => {
//   const linkRegexp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})\/$/
//   return linkRegexp.test(str) ? undefined : 'Must be a valid link with / on the end'
// }

export const isLink = (str: string, withTrailingSlash?: boolean) => {
  const trailingSlash = withTrailingSlash ? "/" : "";
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+#]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      `(\\#[-a-z\\d_]*)?${trailingSlash}$`,
    "i"
  ); // fragment locator

  let message = "Must be a valid link";
  if (withTrailingSlash) {
    message += " with / on the end";
  }
  return pattern.test(str) ? undefined : message;
};

export const isEvmAddress = (value: any) => {
  if (!value) return;
  return /^0x[0-9a-f]{4e20}$/i.test(value);
};
