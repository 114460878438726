import React, { useEffect } from 'react';

import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Loader from '../../../../components/Loader';
import useFormStyles from '../../../../hooks/useFormStyles';
import { ICustomer } from '../../../../api/customers';
import { useCurrenciesList } from '../../../../api/currency';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  mobileRow: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
  },
  rowColumn: {
  [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }
}));

interface IProps {
  customer: ICustomer;
}

export default function CustomerSettingsView({ customer }: IProps) {
  const classes = useFormStyles();
  const mobileClasses = useStyles();
  const [{ items: currencies }, loadingCurrencies, loadCurrencies] = useCurrenciesList();
  const { fees } = customer;

  useEffect(() => {
    loadCurrencies();
  }, [])

  const data: any = {};

  if (fees) {
    currencies.forEach((c: any) => {
      const actual = fees.find((f: any) => f.currency === c.currency);
      if (!!actual) {
        data[c.currency] = actual;
      } else {
        data[c.currency] = { ...fees.find((f: any) => !f.currency), currency: c.currency };
      }
    })
  }

  return (
    <Loader loading={loadingCurrencies} width={500}>
      <div className={clsx(classes.row, mobileClasses.rowColumn)}>
        <Typography variant="body1" color="primary" align="center">Fees</Typography>
      </div>
      {Object.keys(data).sort((a, b) => a.localeCompare(b)).map((f) => (
        <div className={clsx(classes.row, mobileClasses.rowColumn)} key={f}>
          <Typography variant="body1" color="primary" component="div" style={{ flex: '0 0 35px' }}>
            {f}
          </Typography>
          <div className={mobileClasses.mobileRow}>
            <Typography variant="body2" color="textSecondary">Deposit</Typography>
            <Typography variant="body1">{data[f].fee?.deposit}</Typography>
          </div>
          <div className={mobileClasses.mobileRow}>
            <Typography variant="body2" color="textSecondary">Deposit fixed</Typography>
            <Typography variant="body1">{data[f].fee?.depositFixed}</Typography>
          </div>
          <div className={mobileClasses.mobileRow}>
            <Typography variant="body2" color="textSecondary">Withdrawal</Typography>
            <Typography variant="body1">{data[f].fee?.withdrawal}</Typography>
          </div>
          <div className={mobileClasses.mobileRow}>
            <Typography variant="body2" color="textSecondary">Withdrawal fixed</Typography>
            <Typography variant="body1">{data[f].fee?.withdrawalFixed}</Typography>
          </div>
          <div className={mobileClasses.mobileRow}>
            <Typography variant="body2" color="textSecondary">Deposit merchant</Typography>
            <Typography variant="body1">{data[f].fee?.depositMerchant}</Typography>
          </div>
          <div className={mobileClasses.mobileRow}>
            <Typography variant="body2" color="textSecondary">Deposit merchant fixed</Typography>
            <Typography variant="body1">{data[f].fee?.depositMerchantFixed}</Typography>
          </div>
        </div>
      ))}
    </Loader>
  );
}
