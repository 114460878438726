import update from 'immutability-helper';
import { LS_GLOBAL_COUNTRY_KEY } from '../../constants/common';

const initial = {
  currencies: {
    loading: false,
    data: [],
    map: {},
  },
  countries: {
    loading: false,
    data: [],
    globalCountry: window.localStorage.getItem(LS_GLOBAL_COUNTRY_KEY) || null,
  },
};

export default function appData(state = initial, action: any) {
  const { data } = action;
  switch (action.type) {
    case 'UPDATE_CURRENCIES':
      return update(state, {currencies: { $merge: data }});
    case 'UPDATE_COUNTRIES':
      return update(state, {countries: { $merge: data }});
    case 'SET_GLOBAL_COUNTRY':
      return update(state, {countries: { $merge: {globalCountry: data} }});
    default:
      return state;
  }
}
