import React from 'react';

import { Form } from 'react-final-form';

import { Button, DialogActions, DialogContent, DialogContentText, makeStyles } from '@material-ui/core';

import { ProtectionInput } from '../../../components/Form';

const useStyles = makeStyles((theme) => ({
   root: {},
   modal: {
     display: 'flex',
     justifyContent: 'center',
     alignItems: 'center',
     position: 'fixed',
     top: 0,
     left: 0,
     right: 0,
     bottom: 0,
     width: '100%',
     height: '100%',
     zIndex: 10,
     backgroundColor: 'rgba(0, 0, 0, 0.5)',
   },
   sendConfirmation: {
     padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
     backgroundColor: '#424242',
     borderRadius: '6px',
     maxWidth: 600
   },
 }));

interface IModal {
   convert: any
   setIsOpenDialog: (open: boolean)=> void
   keyData: any
}

function Modal({ convert, keyData, setIsOpenDialog }:IModal) {

const classes = useStyles();

return(
   <div className={classes.modal} aria-labelledby="alert-modal-title" aria-describedby="alert-modal-description">
      <div className={classes.sendConfirmation}>
         <Form
            onSubmit={(values) => {
               convert({ ...values, id: keyData.id });
            }}
            initialValues={{ '2fa': '' }}
            render={({ handleSubmit, values, invalid }) => (
               <form onSubmit={handleSubmit}>
                  <DialogContent>
                     <DialogContentText id="alert-modal-description">
                        Are you sure that you want to convert a key: {keyData.key}
                     <ProtectionInput />
                     </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={() => setIsOpenDialog(false)} size="small">
                        Cancel
                     </Button>
                     <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        color="secondary"
                        disabled={invalid}
                     >
                        Confirm
                     </Button>
                  </DialogActions>
               </form>
            )}
         />
      </div>
   </div>
 );
}
export default Modal;