import React, { useState, useEffect } from 'react';

import { Form } from 'react-final-form';

import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DetailsPopup, { IDetailsProps } from '../../../components/Layout/DetailsPopup';
import { DataRow } from '../../../components/DataView';
import { Input, ProtectionInput } from '../../../components/Form';
import ClearingStatusLabel from '../../../components/Label/ClearingStatusLabel';
import DateView from '../../../components/DateView';
import { required } from '../../../validators';
import { useClearingRecheck, useClearingList, useClearingReinit, IClearingItem, ClearingStatus} from '../../../api/clearing';
import ShortString from '../../../components/UI/ShortString';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& .input': {
      flex: '1 1 0px',
      marginRight: theme.spacing(2)
    },
    '& .save': {
      flex: '0 0 100px',
      width: '100px',
      [theme.breakpoints.down(700)]: {
        flex: '0 0 100%',
        width: '100%',
      },
    },
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      gap: '20px'
    },
  },
  form: {
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px'
    },
  },
  button: {
    margin: '0 0 0 8px',
    [theme.breakpoints.down(700)]: {
      margin: '0px',
    },
  }
}));

interface IProps extends IDetailsProps {
  id: string;
}

export default function ClearingDetails({ id, onClose }: IProps) {
  const classes = useStyles();
  const [recheck, setRecheck] = useState(false);
  const [reinit, setReinit] = useState(false);
  const [{ items }, loading, load] = useClearingList();
  const [update, updating] = useClearingRecheck(
    () => {},
    'Order successfully rechecked'
  );
  const [reinitOrder, reiniting] = useClearingReinit((res: any) => {
    if (res.success) {
      load({ filter: { clearingOrderId: [parseInt(id)] }, limit: 1, cursor: 0 });
    }
  })

  useEffect(() => {
    load({ filter: { clearingOrderId: [parseInt(id)] }, limit: 1, cursor: 0 });
  }, []);

  const item: IClearingItem = items[0];

  return (
    <DetailsPopup onClose={onClose} loading={loading || updating}>
      {item && item.id ? (
        <>
          <Typography variant="h6" color="primary">Clearing order {item.id}</Typography>
          <div className={classes.root}>
            <DataRow label="Status">
              <ClearingStatusLabel status={item.status} />
            </DataRow>
            {item.status === ClearingStatus.ERROR && (
              <DataRow label="Error">
                <div className={classes.form}>
                  <ShortString chars={40} text={item.error} />
                  &nbsp;&nbsp;
                  {reinit
                    ? (
                      <Form
                        onSubmit={(values) => reinitOrder({ ...values, id: item.id })}
                        initialValues={{ '2fa': '' }}
                        render={({ handleSubmit, invalid, values }) => (
                          <form onSubmit={handleSubmit}>
                            <div className={classes.row}>
                              <ProtectionInput margin="none" />
                              <Button
                                type="submit"
                                disabled={invalid || reiniting}
                                variant="contained"
                                className={clsx(classes.button, 'save')}
                                size="large"
                              >
                                Reinit
                              </Button>
                            </div>
                          </form>
                        )}
                      />
                    )
                    : <Button onClick={() => setReinit(true)} variant="contained" size="large">Reinit</Button>
                  }
                </div>
              </DataRow>
            )}
            <DataRow label="Order ID">{item.orderId}</DataRow>
            <DataRow label="Pair">{item.pair}</DataRow>
            <DataRow label="Amount">{item.amount}</DataRow>
            <DataRow label="Price">{item.price}</DataRow>
            <DataRow label="Side">{item.side}</DataRow>
            <DataRow label="Exchange">{item.provider}</DataRow>
            <DataRow label="External ID">
              {item.externalId
               ? item.externalId
               : (
                 <>
                   {item.provider && !['kuna', 'coins_paid'].includes(item.provider.toLowerCase()) && (
                     <>
                       {recheck
                        ? (
                          <Form
                            onSubmit={(values) => update({ ...values, id: item.id })}
                            initialValues={{ externalId: '', '2fa': '' }}
                            render={({ handleSubmit, invalid, values }) => (
                              <form onSubmit={handleSubmit}>
                                <div className={classes.row}>
                                  <Input name="externalId" type="search" autoComplete="xyz123" validate={required} label="External ID" margin="none" />
                                </div>
                                <div className={classes.row}>
                                  <ProtectionInput />
                                </div>
                                <div className={classes.row}>
                                  <Button
                                    type="submit"
                                    disabled={invalid || updating || reiniting}
                                    variant="contained"
                                    className="save"
                                    size="large"
                                  >
                                    Save
                                  </Button>
                                </div>
                              </form>
                            )}
                          />
                         )
                         : <Button onClick={() => setRecheck(true)} variant="contained" size="medium">Recheck</Button>
                       }
                     </>
                   )}
                 </>
                )
              }
            </DataRow>
            <DataRow label="Created">
              <DateView value={item.createdAt} />
            </DataRow>
            <DataRow label="Updated">
              <DateView value={item.updatedAt} />
            </DataRow>
          </div>
        </>
      ) : <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  );
}
