import React from 'react';

import { Field } from 'react-final-form';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

const filterOptionsList = createFilterOptions<string>();
interface IAutocompleteFieldWithCreateOption {
  name: string
  label: string
  disabled?: boolean
  canCreateOption?: boolean
  optionsList: string[] | undefined
  loadOptions?: (merchantName: string) => void
}

const AutocompleteFieldWithCreateOption = ({
  name,
  label,
  optionsList,
  loadOptions,
  disabled = false,
  canCreateOption = true,
}: IAutocompleteFieldWithCreateOption) => {

  return (
    <Field name={name}>
      {({ input }) => (
        <Autocomplete
          value={input.value}
          onChange={(event, inputValue) => {
            const newInputValue = typeof inputValue === 'string' ? inputValue.trim() : inputValue;
            if (typeof newInputValue === 'string' && newInputValue.startsWith('Create ')) {
              const parsedInputValue = newInputValue.slice(7);
              input.onChange(parsedInputValue);
              loadOptions?.(parsedInputValue ?? '');
            } else {
              input.onChange(newInputValue);
              loadOptions?.(newInputValue ?? '');
            }
          }}
          disabled={disabled}
          filterOptions={(options, params) => {
            const filteredOptions = filterOptionsList(options, params);
            if (params.inputValue !== '' && canCreateOption) {
              filteredOptions.push(`Create ${params.inputValue}`);
            }
            return filteredOptions;
          }}
          freeSolo
          clearOnBlur
          selectOnFocus
          fullWidth
          handleHomeEndKeys
          options={optionsList || []}
          getOptionLabel={(option) => {
            if (!option) {
              return '';
            }

            if (typeof option === 'string') {
              return option;
            }

            if (Array.isArray(option)) {
              if (option.length) {
                return option[0];
              } else {
                return '';
              }
            }

            return '';
          }}
          renderOption={(option) => option}
          renderInput={(params) => (
            <TextField {...params} fullWidth label={label} variant="outlined" />
          )}
        />
      )}
    </Field>
  );
};

export default AutocompleteFieldWithCreateOption;