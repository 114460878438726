import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { DataRow } from '../../../components/DataView';
import PopupLink from '../../../components/UI/PopupLink';
import Loader from '../../../components/Loader';
import EmptyList from '../../../components/EmptyList';
import DateView from '../../../components/DateView';
import TextCopy from '../../../components/TextCopy';
import ShortString from '../../../components/UI/ShortString';
import { IOrderItem, useOrderInitDebug } from '../../../api/order';
import { getMaskedRequestCardData } from '../../../helpers/maskHelper';

const useStyles = makeStyles((theme) => ({
  root: {},
  list: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      wordWrap: 'break-word',
    },
  }
}))

interface IProps {
  order: IOrderItem;
}

export default function InitDebugTab({ order }: IProps) {
  const [{ data, error }, loading, load] = useOrderInitDebug();
  const match = useRouteMatch();
  const classes = useStyles();
  const baseUrl = `/${match.url.split('/')[1]}`;

  useEffect(() => {
    load(order.id);
  }, [order.id])

   let maskedRequest: any;

  if(data) {
    maskedRequest = getMaskedRequestCardData(data.request)
  } 

  return (
    <Loader loading={loading} width={560}>
      {data && Object.keys(data).length && 
      <>
        <DataRow label="Requested by ID">
          {data.requestedById && (
            <PopupLink popup="customer" id={data.requestedById} baseUrl={baseUrl}>
              {data.requestedById}
            </PopupLink>
          )}
          </DataRow>
        <DataRow label="URL">{data.url}</DataRow>
        <DataRow label="Created at">
          <DateView value={data.createdAt} />
        </DataRow>
        <DataRow label="Updeated at">
          <DateView value={data.updatedAt} />
        </DataRow>
        <Typography variant="body1" component="div" color="primary">
          Request
          <TextCopy text={JSON.stringify(data.request).toString()} />
        </Typography>
          {Object.keys(maskedRequest).map((k) => {
          return (
            <Typography variant="body2" key={k} className={classes.list}>
              <strong>{k}:</strong> { JSON.stringify(maskedRequest[k]) }
            </Typography>
          )
        })}
        <Typography variant="body1" component="div" color="primary">
          Response
          {data.response.data && <TextCopy text={JSON.stringify(data.response.data).toString()} />}
        </Typography>
        {data.response.data && Object.keys(data.response.data).map((k) => (
          <Typography variant="body2" key={k} className={classes.list}>
            <strong>{k}:</strong> {'' + JSON.stringify(data.response.data[k])}
          </Typography>
        ))}
        <Typography variant="body1" component="div" color="primary">
          Headers
          {data.headers && <TextCopy text={JSON.stringify(data.headers).toString()} />}
        </Typography>
        {Object.keys(data.headers).map((k) => (
          <Typography variant="body2" key={k} className={classes.list}>
            <strong>{k}:</strong> <ShortString chars={80} text={data.headers[k]} />
          </Typography>
        ))}
      </>}
      {(!data || !Object.keys(data).length || error) && 
      <EmptyList loading={loading} error={error} text="No data to display" width={500} />}
    </Loader>
  )
}
