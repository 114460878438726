import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ActivityRow from './ActivityRow';
import { IAdminActivity } from '../../api/admin';
import useMobileStyles from '../../hooks/useMobileStyles';

interface IProps {
  items: IAdminActivity[];
  type?: 'popup' | 'page';
}

export default function AdminsList({ items, type }: IProps) {
  const mobileClasses = useMobileStyles();

  return (
    <Table size="small">
      <TableHead className={mobileClasses.tableHead}>
        <TableRow>
          {type !== 'popup' && (
            <>
              <TableCell>Admin ID</TableCell>
              <TableCell>Admin Email</TableCell>
              <TableCell>Admin username</TableCell>
            </>
          )}
          <TableCell>IP address</TableCell>
          <TableCell>Type</TableCell>
          {type !== 'popup' && <TableCell>Endpoint</TableCell>}
          <TableCell>Country</TableCell>
          <TableCell>Created at</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => <ActivityRow item={i} key={i.id} type={type} lastChild={items[items.length-1]}/>)}
      </TableBody>
    </Table>
  );
}