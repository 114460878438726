import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { Waypoint } from 'react-waypoint';
import { Form } from 'react-final-form'

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import BinCodesList from './BinCodesList';
import EmptyList from '../../../components/EmptyList';
import Loader from '../../../components/Loader';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import { useBinCodesList } from '../../../api/binCodes';
import SearchField from '../../../components/Form/SearchField';
import CreateBinCodeItemPopup from './BinCodeCreate';
import useColumns, { IColumn } from '../../../hooks/useColumns';
import { makeStyles } from '@material-ui/core/styles';
import FilterWrapper, { ICollapseHandle } from '../../../components/FilterWrapper/FilterWrapper';

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'createdAt', name: 'Created at', active: true},
  { id: 'updatedAt', name: 'Updated at', active: true},
  { id: 'binCode', name: 'Bin Code', active: true},
  { id: 'country', name: 'Country', active: true},
  { id: 'source', name: 'Source', active: true},
  { id: 'adminId', name: 'Admin Id', active: true},
  { id: 'action', name: 'Action', active: true}
];

const useStyles = makeStyles((theme) => ({
  filter: {
    '& .MuiPaper-root': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5)
    }
  }
}))

const initialValue = {
  binCode: ''
}

export default function BinCodesPage() {
  const history = useHistory();
  const classes = useStyles();
  const gClasses = useGlobalStyles();
  const [{ items, full, error, cursor }, loading, load, update ] = useBinCodesList();
  const [binCode, setBinCode] = useState('');
  const [columns] = useColumns('binCodes', defaultColumns);
  const accordionRef = useRef<ICollapseHandle>(null);

  function setDetailsUrl(id?: string) {
    if (id) {
      history.push(`/acl/bin-codes/code/${id}`);
    } else {
      history.push('/acl/bin-codes');
    }
  }

  function filter(filter: any) {
    accordionRef.current?.collapse();
    load({ filter, limit: 100});
  }

  const filteredItems = items.sort((a: any, b: any) => b.createdAt - a.createdAt);

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <Form
          initialValues={initialValue}
          onSubmit={filter}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} className={clsx(gClasses.filters, classes.filter)}>
                <SearchField
                  name="binCode"
                  placeholder="Search by BIN code..."
                />
                <Button type="submit" variant="contained" size="large" className="apply">Apply</Button>
                <Button
                  type="button"
                  onClick={() => history.push(`/acl/bin-codes/new`)}
                  variant="contained"
                  size="large"
                  className="import"
                >
                  Add new
                </Button>
              </form>
            )
          }}
        />
      </FilterWrapper>

      <Grid item className={gClasses.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={gClasses.paper}>
          {items.length > 0
            ? <BinCodesList items={filteredItems} columns={columns} onDelete={update} onRowClick={setDetailsUrl} />
            : <EmptyList error={error} loading={loading} />
          }
          <Loader loading={loading} />
          {!loading && !error && !full && <Waypoint onEnter={() => load({ cursor: cursor || 0, filter })} />}
        </Paper>
      </Grid>
      {window.location.pathname.includes('/new') && (
        <CreateBinCodeItemPopup
          onSave={update}
          onClose={() => history.push(`/acl/bin-codes/`)}
        />
      )}
    </Grid>
  );
}