import React from 'react';
import TableFilter from '../../../components/Filter';
import { CurrencySelect, Input, Select, BoolSelect } from '../../../components/Form';
import { AccountType, AccountName } from '../../../api/bookkeeper';

export interface IFilter {
  dateFrom: Date;
  dateTo: Date;
  types?: string[];
  accounts?: string[];
  subAccounts?: string[];
  currencies?: string[];
  manual?: boolean;
}

interface IProps {
  filter: IFilter,
  onChange: any
}

export default function BookkeeperBalanceFilter({ filter, onChange }: IProps) {
  return (
    <TableFilter onApply={onChange} filter={filter}>
      <Select
        name="types"
        label="Types"
        options={Object.values(AccountType).map((e: string) => ({ value: e, label: e }))}
        multi
      />
      <Select
        name="accounts"
        label="Accounts"
        options={Object.values(AccountName).map((n: string) => ({ value: n, label: n }))}
        multi
      />
      <CurrencySelect
        name="currencies"
        label="Currencies"
        multi
      />
      <Input
        name="subAccounts"
        label="Sub accounts"
        helperText="Comma-separated (e.g. '1,12,453')"
        margin="none"
      />
      <BoolSelect
        name="manual"
        label="Manual"
      />
    </TableFilter>
  );
}
