import React, { useState } from 'react';

import Box from "@material-ui/core/Box"
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button'

import { useFaqUpdate, useHowToUpdate, useRecommendsUpdate, useMetaUpdate, useSBSUpdate, usePagesUpdate, useGlobalFaqUpdate, useBlogsUpdate, useCurrenciesUpdate, useMerchantCurrenciesUpdate } from '../../hooks/useUpdateApi';

const useStyles = makeStyles({
   root: {},
   button: {
      height: 40,
      width: 300,
      cursor: 'pointer'
   },
   row: {
      marginBottom: 20,
   }
});


function UpdateWebflow() {

   const classes = useStyles();

   const [currenciesUpdating, setCurrenciesUpdating] = useState(false);
   const [updateCurrencies, ucs] = useCurrenciesUpdate((res: any) => {
      if (res.success) {
         setCurrenciesUpdating(true);
         setTimeout(() => setCurrenciesUpdating(false), 20000);
      }
   });

   const [merchantCurrenciesUpdating, setMerchantCurrenciesUpdating] = useState(false);
   const [updateMerchantCurrencies, umcs] = useMerchantCurrenciesUpdate((res: any) => {
      if (res.sucess) {
         setMerchantCurrenciesUpdating(true)
         setTimeout(() => setMerchantCurrenciesUpdating(false), 20000);
      }
   })

   const [howtosUpdating, setHowtosUpdating] = useState(false);
   const [updateHowTos, uhts] = useHowToUpdate((res: any) => {
      if (res.success) {
         setHowtosUpdating(true);
         setTimeout(() => setHowtosUpdating(false), 60000);
      }
   });
   const [sbsUpdating, setSBSUpdating] = useState(false);
   const [updateSBS, usbs] = useSBSUpdate((res: any) => {
      if (res.success) {
         setSBSUpdating(true);
         setTimeout(() => setSBSUpdating(false), 60000);
      }
   });
   const [faqUpdating, setFaqUpdating] = useState(false);
   const [updateFaq, ufs] = useFaqUpdate((res: any) => {
      if (res.success) {
         setFaqUpdating(true);
         setTimeout(() => setFaqUpdating(false), 60000);
      }
   });
   const [globalFaqUpdating, setGlobalFaqUpdating] = useState(false);
   const [updateGlobalFaq, ugfs] = useGlobalFaqUpdate((res: any) => {
      if (res.success) {
         setGlobalFaqUpdating(true);
         setTimeout(() => setGlobalFaqUpdating(false), 60000);
      }
   });
   const [metaUpdating, setMetaUpdating] = useState(false);
   const [updateMeta, ums] = useMetaUpdate((res: any) => {
      if (res.success) {
         setMetaUpdating(true);
         setTimeout(() => setMetaUpdating(false), 30000);
      }
   });
   const [pagesUpdating, setPagesUpdating] = useState(false);
   const [updatePages, uP] = usePagesUpdate((res: any) => {
      if (res.success) {
         setPagesUpdating(true);
         setTimeout(() => setPagesUpdating(false), 60000);
      }
   });
   const [blogsUpdating, setBlogsUpdating] = useState(false);
   const [updateBlogs, ubs] = useBlogsUpdate((res: any) => {
      if (res.success) {
         setBlogsUpdating(true);
         setTimeout(() => setBlogsUpdating(false), 60000);
      }
   });
   const [updateRecommends] = useBlogsUpdate((res: any) => {
      if (res.success) {
         alert('Recommends data update started');
      }
   });
   return (
      <div>
         <Box className={classes.row}>
            <Button variant='contained' onClick={updateCurrencies} className={classes.button} disabled={currenciesUpdating || ucs}>
               {currenciesUpdating ? 'Updating currencies...' : 'Update currencies data'}
            </Button>
         </Box>
         <Box className={classes.row}>
            <Button variant='contained' onClick={updateMerchantCurrencies} className={classes.button} disabled={merchantCurrenciesUpdating || umcs}>
               {merchantCurrenciesUpdating ? 'Updating merchant currencies...' : 'Update merchant currencies data'}
            </Button>
         </Box>
         <Box className={classes.row}>
            <Button variant='contained' onClick={updateMeta} className={classes.button} disabled={metaUpdating || ums}>
               {metaUpdating ? 'Updating metadata...' : 'Update Meta data'}
            </Button>
         </Box>
         <Box className={classes.row}>
            <Button variant='contained' onClick={updateHowTos} className={classes.button} disabled={howtosUpdating || uhts}>
               {howtosUpdating ? 'Updating How to buy...' : 'Update How To Buy data'}
            </Button>
         </Box>
         <Box className={classes.row}>
            <Button variant='contained' onClick={updateSBS} className={classes.button} disabled={sbsUpdating || usbs}>
               {sbsUpdating ? 'Updating Step-by-step...' : 'Update Step-by-step data'}
            </Button>
         </Box>
         <Box className={classes.row}>
            <Button variant='contained' onClick={updateFaq} className={classes.button} disabled={faqUpdating || ufs}>
               {faqUpdating ? 'Updating FAQ...' : 'Update FAQ data'}
            </Button>
         </Box>
         <Box className={classes.row}>
            <Button variant='contained' onClick={updateGlobalFaq} className={classes.button} disabled={globalFaqUpdating || ugfs}>
               {globalFaqUpdating ? 'Updating Global FAQ...' : 'Update Global FAQ data'}
            </Button>
         </Box>
         <Box className={classes.row}>
            <Button variant='contained' onClick={updateBlogs} className={classes.button} disabled={blogsUpdating || ubs}>
               {blogsUpdating ? 'Updating blogs...' : 'Update Blogs data'}
            </Button>
         </Box>
         <Box className={classes.row}>
            <Button variant='contained' onClick={updatePages} className={classes.button} disabled={pagesUpdating || uP}>
               {pagesUpdating ? 'Updating pages...' : 'Update Static Pages data'}
            </Button>
         </Box>
      </div >
   )
}
export default UpdateWebflow;