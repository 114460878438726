import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import ChainsList from './ChainsList';
import Loader from '../../components/Loader';
import DamModal, { TDamModalType } from './DamModal';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { useDamStatus, useDamLock, useDamUnlock, useDamWithdrawCCW } from '../../api/wallets';
import EmptyList from '../../components/EmptyList';

export default function WalletsPage() {
  const classes = useGlobalStyles();
  const [showModal, setShowModal] = useState<TDamModalType | null>(null);
  const [{ data }, loading, load ] = useDamStatus();
  const [lock, locking] = useDamLock((res: any) => {
    if (res.success) {
      setShowModal(null);
      load();
    }
  });
  const [unlock, unlocking] = useDamUnlock((res: any) => {
    if (res.success) {
      setShowModal(null);
      load();
    }
  })
  const [withdraw, withdrawing] = useDamWithdrawCCW((res: any) => {
    if (res.success) {
      setShowModal(null);
      load();
    }
  })

  useEffect(() => {
    load();
  }, []);

  const modal: TDamModalType = data?.locked ? 'unlock' : 'lock';

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        {showModal !== null && (
          <DamModal
            onClose={() => setShowModal(null)}
            send={showModal == 'withdraw' ? withdraw : data.locked ? unlock : lock}
            sending={unlocking || locking || withdrawing}
            type={showModal}
          />
        )}
        {data && 
          <Paper className={classes.paper}>
            {Object.keys(data).length > 0 && (
              <>
                <CardActions>
                  <Grid container justify="space-between" alignItems="stretch">
                    <Grid item>
                      {data.locked && (
                        <Typography align="center" variant="body1">
                          You need to unlock DAM wallet to see chains statuses
                        </Typography>
                      )}
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() => setShowModal(modal)}
                      >
                        {modal}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() => setShowModal('withdraw')}
                      >
                        Withdraw ccw
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
                <Loader loading={loading || locking || unlocking}>
                  {!data.locked && <ChainsList items={data.chains} />}
                </Loader>
              </>
            )}
          </Paper>
        }
        {!data  && <EmptyList error={'Currently wallets are not awailable'} loading={loading}></EmptyList>}
      </Grid>
    </Grid>
  );
}
