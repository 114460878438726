import Api, { useFetchApi, useUpdateApi } from './index';

export interface IPaymentProvider {
  id: string;
  name: string;
  enabled: boolean;
  depositSupported: boolean;
  withdrawalSupported: boolean;
  priority: number;
  currencies: string[];
  countries: string[];
  supportedCurrencies: string[];
  supportedCountries: string[];
  processingExpirationTime: number;
  createdAt: number;
  updatedAt: number;
}

export function usePaymentProvidersList() {
  return useFetchApi(
    async (q: any) => Api.post('/payment/provider/list', q),
    { items: [] }
  )
}

export function usePaymentProvidersCacheClear(cb: any) {
  return useUpdateApi(
    async () => Api.post('/payment/provider/reload'),
    cb
  )
}

export function usePaymentProviderDetails() {
  return useFetchApi(
    async (id: any) => Api.post('/payment/provider/get', { id }),
    { data: {} }
  )
}

export function useUpdatePaymentProvider(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/payment/provider/update', data),
    cb,
    'Payment provider successfully updated'
  )
}
