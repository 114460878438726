import MenuItem from '@material-ui/core/MenuItem';
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Loader from '../../../components/Loader';
import EmptyList from '../../../components/EmptyList';
import BookkeeperReportFilter from './Filter';
import Totals from './Total';
import BookkeepingReportList from './List';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import useFilter from '../../../hooks/useFilter';
import { useBookkeeperReport } from '../../../api/bookkeeper';
import { useCurrenciesList } from '../../../api/currency';
import { lastWeekStart, todayEnd } from '../../../helpers/datesHelper';
import { dataAdapter, defaultTypeOptions } from './helper';

const emptyFilter = () => ({
  date1: lastWeekStart().valueOf(),
  date2: todayEnd().valueOf(),
  withSubAccounts: undefined
});

export default function BookkeepingReport() {
  const [{ data, error }, loading, load] = useBookkeeperReport();
  const [{ items: currencies }, loadingCurrencies, loadCurrencies] = useCurrenciesList();
  const classes = useGlobalStyles();
  const [setFilter, filter] = useFilter('bookkeeper_report', emptyFilter(), false);
  const [prepared, setPrepared] = useState([]);
  const [exception] = useState(defaultTypeOptions);
  const [currException, setCurrException] = useState([]);

  useEffect(() => {
    loadCurrencies();
  }, [])

  useEffect(() => {
    load(filter);
  }, [filter])

  useEffect(() => {
    if (data?.elements && data.elements.length && currencies.length) {
      // @ts-ignore
      setPrepared(dataAdapter(data.elements, {
        exceptionLiq: exception,
        exceptionCur: currException,
        currencies: currencies.map((c: any) => c.currency)
      }));
    }
  }, [data, currencies, currException]);

  return (
    <Grid container spacing={3}>
      <BookkeeperReportFilter filter={filter} onChange={setFilter} />
      <div className={classes.filters}>
        <TextField
          variant="outlined"
          label="Exclude currencies"
          select
          SelectProps={{ multiple: true }}
          onChange={(e: any) => setCurrException(e.target.value)}
          value={currException}
        >
          <MenuItem>&nbsp;</MenuItem>
          {currencies.map((c: any) => (
            <MenuItem value={c.currency} key={c.currency}>{c.currency}</MenuItem>
          ))}
        </TextField>
      </div>
      <Grid item className={classes.table}>
        <Paper className={classes.paper}>
          <Loader loading={loading || loadingCurrencies}>
            {data?.elements && data.elements.length
              ? (
                <>
                  <Totals
                    data={prepared[1]}
                    from={data.elements[0]?.date}
                    to={data.elements[1]?.date}
                    exception={currException}
                  />
                  <BookkeepingReportList data={prepared[0]} currencies={currencies} />
                </>
              ) : <EmptyList loading={loading} error={error} />}
          </Loader>
        </Paper>
      </Grid>
    </Grid>
  );
}
