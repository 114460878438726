import React, { useState, useEffect } from 'react';

import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Select } from '../../../../components/Form';
import CreateIcon from '@material-ui/icons/Create';

import Paper from '@material-ui/core/Paper';

import { useKycCountries } from '../../../../api/kyc';
import useMobileStyles from '../../../../hooks/useMobileStyles';
import Tablecell from '../../../../components/TableCell/TableCell';

interface IProps {
  data: any; 
  changedFields: string[];
  onChangeField: any;
  classes: any;
  historyMarkers: any;
}

const yesNoList = [
  {label: 'Yes', value: true},
  {label: 'No', value: false}
]

export default function GeographicalRisks({data, changedFields, onChangeField, classes, historyMarkers}: IProps) {
  const [{ items, error }, loading, load] = useKycCountries();
  const [countriesList, setCountriesLise] = useState([]);
  const mobileClasses = useMobileStyles();

  useEffect(() => {
    const remapedCountriesList = items.map((item: any) => ({label: item.name, value: item.code}));
    setCountriesLise(remapedCountriesList);
  }, [items])

  useEffect(() => {
    load()
  }, [])

  return (
    <>
      <Typography>Geographical risks</Typography>
      <Paper classes={{root: classes.paper}} variant="outlined">
        <Table>
          <TableHead className={mobileClasses.tableHead}>
            <TableRow>
              <TableCell classes={{root: classes.firstColumn}}>Geographical risks</TableCell>
              <TableCell classes={{root: classes.secondColumn}}>Value</TableCell>
              <TableCell>Risk score</TableCell>
              <TableCell>Updated By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Geographical risks">Country of residense risk level</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select 
                    name="countryOfResidence"
                    label=""
                    options={countriesList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('countryOfResidence', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('countryOfResidence') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.countryOfResidenceRisk?.level}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers.countryOfResidence?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Geographical risks">Country of residence is in EU High-Risk Third Countries list</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select 
                    name="countryOfResidenceRisk.highRisk"
                    label=""
                    options={yesNoList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('countryOfResidenceRisk.highRisk', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('countryOfResidenceRisk.highRisk') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.countryOfResidenceRisk?.highRiskValue}</Tablecell>
              <Tablecell title="Updated By" isLast><>{/*historyMarkers.countryOfResidenceRisk?.wasChanged ? 'Admin' : 'System'*/}</></Tablecell>
            </TableRow>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Geographical risks">ID document country risk</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select 
                    name="idDocumentCountry"
                    label=""
                    options={countriesList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('idDocumentCountry', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('idDocumentCountry') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.idDocumentCountryRisk?.level || '-'}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers.idDocumentCountry?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Geographical risks">Bank country risk level</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select 
                    name="countryOfBank"
                    label=""
                    options={countriesList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('countryOfBank', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('countryOfBank') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.countryOfBankRisk?.level || '-'}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers.countryOfBank?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Geographical risks">Bank registration country is in EU High-Risk Third Countries list</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select 
                    name="countryOfBankRisk.highRisk"
                    label=""
                    options={yesNoList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('countryOfBankRisk.highRisk', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('countryOfBankRisk.highRisk') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.countryOfBankRisk?.highRiskValue}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers.countryOfBankRiskValue?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>  
    </>
  )
}