import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Waypoint } from 'react-waypoint';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import ApmList from './ApmList';
import EmptyList from '../../components/EmptyList';
import Loader from '../../components/Loader';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { useApmList, IApm } from '../../api/apm';
import useColumns, { IColumn } from '../../hooks/useColumns';
import Button from '@material-ui/core/Button';
import ApmDetails from './Details';

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'name', name: 'Name', active: true },
  { id: 'externalId', name: 'External Id', active: true },
  { id: 'provider', name: 'Provider', active: true },
  { id: 'flow', name: 'Url open flow', active: true },
  { id: 'enabled', name: 'Enabled', active: true },
  { id: 'deposit', name: 'Deposit', active: true },
  { id: 'depositFixed', name: 'Deposit Fixed', active: true },
  { id: 'fixedCurrency', name: 'Fixed Fee Currency', active: true },
  { id: 'depositFeeMin', name: 'Deposit Fee Min', active: true },
];

export default function APMPage() {
  const classes = useGlobalStyles();
  const { apmId } = useParams<any>();
  const [{ items, cursor, full, error }, loading, load ] = useApmList();
  const [details, setDetails] = useState<IApm | undefined>();
  const history = useHistory();
  const [isCreate, setIsCreate] = useState(false);
  const [columns, ColumnsFilter] = useColumns('apm', defaultColumns);

  function setDetailsUrl(id?: string) {
    if (id) {
      history.push(`/settings/apm/${id}`);
    } else {
      history.push('/settings/apm');
      setIsCreate(false);
    }
  }

  useEffect(() => {
    if (apmId && apmId !== '' && items.length) {
      setDetails(items.find((i: any) => i.id === Number(apmId)));
    }
    isCreate && setIsCreate(false);
  }, [apmId, items]);

  useEffect(() => {
    load({
      cursor: 0
    });
  }, []);

  const onUpdate = () => {
    setDetailsUrl();
    load({
      cursor: 0
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
        <CardActions>
            <Grid container justify="space-between">
              <Grid item>
                <Button type="button" variant="contained" size="large" onClick={() => setIsCreate(true)}>Create</Button>
              </Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0
            ? <ApmList items={items.sort((a: IApm, b: IApm) => a.name.localeCompare(b.name))
              .sort((a: any, b: any) => b.enabled - a.enabled)} onRowClick={setDetailsUrl} columns={columns} />
            : <EmptyList error={error} loading={loading} />
          }
          <Loader loading={loading} />
          {!loading && !error && !full && items.length > 1
          && <Waypoint onEnter={() => load({ cursor })} />}
        </Paper>
      </Grid>
      {(isCreate || apmId) &&
        <ApmDetails
          apm={details}
          isCreate={isCreate}
          apmLoading={loading}
          onClose={() => setDetailsUrl()}
          onSave={() => onUpdate()}
        />
      }
    </Grid>

  );
}
