import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import DateView from '../../components/DateView';
import PopupLink from '../../components/UI/PopupLink';
import { IWalletOperation } from '../../api/walletOperations';
import { IColumn } from '../../hooks/useColumns';
import TableCellId from '../../components/TableCellId/TableCellId';
import Tablecell from '../../components/TableCell/TableCell';
import useMobileStyles from '../../hooks/useMobileStyles';

interface IProps {
  operations: IWalletOperation[];
  columns: IColumn[];
}

const content: { [key: string]: (item: IWalletOperation, key: number) => void } = {
  id: (o, k) => <TableCellId key={k}>{o.id}</TableCellId>,
  walletId: (o, k) => <Tablecell key={k} title="Wallet ID">{o.walletId}</Tablecell>,
  currency: (o, k) => <Tablecell key={k} title="Currency">{o.currency}</Tablecell>,
  customer: (o, k) => <Tablecell key={k} title="Customer ID"><PopupLink popup="customer" id={o.customerId}>{o.customerId}</PopupLink></Tablecell>,
  type: (o, k) => <Tablecell key={k} title="Type">{o.type}</Tablecell>,
  balance: (o, k) => <Tablecell key={k} title="Balance">{o.balance}</Tablecell>,
  locked: (o, k) => <Tablecell key={k} title="Locked">{o.locked}</Tablecell>,
  issuedBy: (o, k) => <Tablecell key={k} title="Issued by">{o.issuedBy}</Tablecell>,
  issuerType: (o, k) => <Tablecell key={k} title="Issuer type">{o.issuerType}</Tablecell>,
  created: (o, k) => <Tablecell key={k} title="Created"><DateView value={o.createdAt} /></Tablecell>,
  updated: (o, k) => <Tablecell key={k} title="Updated" isLast><DateView value={o.updatedAt} /></Tablecell>
};

export default function WalletOperationsList({ operations, columns }: IProps) {

  const mobileClasses = useMobileStyles();

  return (
    <Table size="small">
      <TableHead className={mobileClasses.tableHead}>
        <TableRow>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {operations.map((o) => (
          <TableRow key={o.id} className={mobileClasses.row}>
            {columns.map((c, index) => content[c.id](o, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}