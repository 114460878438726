import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EmptyList from '../../../../components/EmptyList';
import Loader from '../../../../components/Loader';
import ApiKeysList from '../../../ApiKeys/ApiKeysList';
import Button from '@material-ui/core/Button';
import ApiKeyForm from '../../../ApiKeys/Details/ApiKeyForm';
import { useApiKeys, emptyApiKey } from '../../../../api/apiKeys';
import Typography from '@material-ui/core/Typography';
import TabsPanel from '../../../../components/Tabs';
import LegalInfo from '../../../ApiKeys/Legal/Info';
import UISettings from '../../../ApiKeys/Details/UISettings';
import {Waypoint} from "react-waypoint";

interface IProps {
  customerId: any;
  subAccount: string | undefined
  loadCustomer?: (id: string)=> void
}

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  form: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}));

export default function CustomerApiKeys({ customerId, subAccount, loadCustomer }: IProps) {
  const classes = useStyles();
  const [{ items, full, error, cursor  }, loading, load, updateList] = useApiKeys();
  const [keyData, setKeyData] = useState();

  useEffect(() => {
    load({filter: {queryData: customerId}});
  }, [customerId]);

  function showForm(id?: string) {
    let key = undefined;
    if (id) {
      key = id === 'new' ? emptyApiKey : items.find((i: any) => i.id.toString() === id.toString());
    }
    setKeyData(key)
  }

  function onSuccessAction(data: any, col?: string, type?: string) {
    updateList(data, col, type);
    setKeyData(undefined);
  }

  let keyTabs: any = [];
  if (keyData) {
    keyTabs = [
      {
        title: 'Key info',
        content: <ApiKeyForm 
          keyData={keyData} 
          isNew={!keyData.id} 
          onSuccess={onSuccessAction} 
          customerId={customerId} 
          subAccount={subAccount} 
          loadCustomer={loadCustomer} />
      }
    ];
    if (keyData.id) {
      keyTabs.push(
        {
          title: 'Legal info',
          content: <LegalInfo keyData={keyData} keys={items} onSave={onSuccessAction} />
        },
        {
          title: 'UI',
          content: <UISettings onSave={onSuccessAction} keyData={keyData} />
        }
      );
    }
  }

  return (
    <>
      <Typography variant="body1" color="primary" className={classes.title}>
        API keys
          <Button
            variant="contained"
            size="small"
            onClick={() => showForm(keyData ? undefined : 'new')}
          >
            {keyData ? 'Close form' : 'Add API key'}
          </Button>
      </Typography>
      {keyData && (
        <div className={classes.form}>
          <TabsPanel tabs={keyTabs} />
        </div>
      )}
      {items.length > 0
          ? <ApiKeysList items={items} type="popup" onRowClick={showForm} />
          : <EmptyList loading={loading} error={error} width={500} />
      }
      <Loader loading={loading} width={500}/>
      {!loading && !error && !full && items.length > 1 && (
          <Waypoint onEnter={() => load({ cursor, filter: {queryData: customerId}})} />
      )}
    </>
  );
}
