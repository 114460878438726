import React, { useEffect, useRef } from 'react';
import { Waypoint } from 'react-waypoint';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import RollingReserveList from './RollingReserveList';
import EmptyList from '../../components/EmptyList';
import Loader from '../../components/Loader';
import ColHead from '../../components/UI/ColHead';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { useRollingReserveList } from '../../api/rolling';
import useFilter from '../../hooks/useFilter';
import RollingFilter from './RollingFilter';
import useColumns, { IColumn } from '../../hooks/useColumns';
import {clearDatesIfIdsInFilter} from "../../helpers/filtersHelper";
import FilterWrapper, { ICollapseHandle } from '../../components/FilterWrapper/FilterWrapper';

const emptyFilter = () => ({
  orderId: null,
  customer: undefined
});

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'created', name: 'Created', active: true },
  { id: 'amount', name: 'Amount', active: true },
  { id: 'currency', name: 'Currency', active: true },
  { id: 'orderId', name: 'Order ID', active: true },
  { id: 'customer', name: 'Customer', active: true, component: <ColHead title="Customer" descGroup="rollingColumns" key="customer" /> },
  { id: 'status', name: 'Status', active: true, component: <ColHead title="Status" descGroup="rollingColumns" key="status" /> },
  { id: 'updated', name: 'Updated', active: true },
];

export default function RollingPage() {
  const classes = useGlobalStyles();
  const [{ items, error, full }, loading, load] = useRollingReserveList();
  const [setFilter, filter] = useFilter('rolling', emptyFilter());
  const accordionRef = useRef<ICollapseHandle>(null);
  const [columns, ColumnsFilter] = useColumns('rolling', defaultColumns);

  useEffect(() => {
    const reqFilters = clearDatesIfIdsInFilter(filter, ['orderId'])
    accordionRef.current?.collapse();
    load({ filter: reqFilters });
  }, [filter])

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <RollingFilter filter={filter} onChange={setFilter} />
      </FilterWrapper>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justify="flex-end">
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0 ? <RollingReserveList items={items} columns={columns} /> : <EmptyList error={error} loading={loading} />}
          <Loader loading={loading} />
          {!loading && !error && !full && <Waypoint onEnter={() => load({ cursor: items.length, filter })} />}
        </Paper>
      </Grid>
    </Grid>
  );
}
