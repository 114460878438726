import React, {useEffect, useState} from 'react';

import { makeStyles, Typography } from '@material-ui/core';

import { ICustomerSettings, useMerchantNamingList } from '../../../../api/customers';
import useFormStyles from '../../../../hooks/useFormStyles';
import { AutocompleteFieldWithCreateOption } from '../../../../components/Form';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.down(700)]: {
      marginBottom: theme.spacing(2),
    }
  },
}));

interface ICustomerBusinessForm {
  customerSettings?: ICustomerSettings
}

function CustomerBusinessForm ({ customerSettings }: ICustomerBusinessForm ) {

  const formClasses = useFormStyles();
  const classes = useStyles();
  const [customerAccountTypes, setCustomerAccountTypes] = useState([]);

  const [
    {
      data: items,
    },
    loadingMerchantNaming,
    loadMerchantNaming,
  ] = useMerchantNamingList();

  const [
    {
      data: merchantNamingList,
    },
    loadingMerchantNamingList,
    loadMerchantNamingList,
  ] = useMerchantNamingList();

  useEffect(() => {
    if (customerSettings?.merchantNaming?.businessName) {
      loadMerchantNaming(customerSettings?.merchantNaming?.businessName);
    } else {
      loadMerchantNaming('');
    }
  }, [customerSettings?.merchantNaming?.businessName]);

  useEffect(() => {
    loadMerchantNamingList('');
  }, []);

  useEffect(() => {
    if (merchantNamingList?.accountTypes?.length > 0){
      setCustomerAccountTypes(merchantNamingList?.accountTypes);
    }
  }, [merchantNamingList?.accountTypes?.length]);

  return (
      <>
        <div className={formClasses.row}>
          <Typography variant="body1" color="primary" align="center">Business information</Typography>
        </div>
        <div className={formClasses.row}>
          <div className={classes.wrapper}>
            <AutocompleteFieldWithCreateOption
                name="merchantNaming.businessName"
                disabled={loadingMerchantNaming}
                loadOptions={loadMerchantNaming}
                optionsList={items?.businessNames}
                label="Business name"
            />
          </div>
          <div className={classes.wrapper}>
            <AutocompleteFieldWithCreateOption
                name="merchantNaming.legalName"
                disabled={loadingMerchantNaming}
                optionsList={items?.legalNames}
                label="Legal name"
            />
          </div>
        </div>
        <div className={formClasses.row}>
          <div className={classes.wrapper}>
            <AutocompleteFieldWithCreateOption
                name="merchantNaming.legalMerchantName"
                disabled={loadingMerchantNaming}
                optionsList={items?.legalMerchantNames}
                label="Legal merchant name"
            />
          </div>
          <div className={classes.wrapper}>
            <AutocompleteFieldWithCreateOption
                name="merchantNaming.merchantName"
                disabled={loadingMerchantNaming}
                optionsList={items.merchantNames}
                label="Merchant name"
            />
          </div>
        </div>
        <div className={formClasses.row}>
          <div className={classes.wrapper}>
            <AutocompleteFieldWithCreateOption
                name="merchantNaming.accountType"
                disabled={loadingMerchantNaming}
                optionsList={customerAccountTypes}
                label="Account type"
            />
          </div>
        </div>
      </>
  );
}

export default CustomerBusinessForm;
