import React from 'react';
import { Router, BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import theme from './theme';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { pdfjs } from 'react-pdf';
import Routes from './Routes';
import SignIn from './components/Login';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const history = createBrowserHistory();

const App: React.FC = () => {
  const notistackRef: any = React.createRef();
  const onClickDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  const { isAuth } = useSelector((state: any) => state.common);

  const content = isAuth ? <Routes /> : <SignIn />;

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          hideIconVariant={false}
          ref={notistackRef}
          action={(key) => (
            <IconButton onClick={onClickDismiss(key)}>
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
          )}
        >
          <Router history={history}>
            {content}
          </Router>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
