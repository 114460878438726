import React from 'react';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import { IColumn } from '../../../hooks/useColumns';
import DateView from '../../../components/DateView';
import { IApiRequests } from '../../../api/apiRequests';

interface IProps {
  items: IApiRequests[];
  columns: IColumn[];
  onRowClick: (id?: string | undefined)=> void;
}

const content: { [key: string]: (i: IApiRequests, key: number) => void } = {
  id: (i, key) => <TableCell key={key}>{i.id}</TableCell>,
  requestedById: (i, key) =>
    <TableCell key={key}>
      {i.requestedById}
    </TableCell>,
  url: (i, key) =>
    <TableCell key={key}>
     {i.url}
    </TableCell>,
   created: (i, key) => <TableCell key={key}><DateView value={i.createdAt} /></TableCell>,
   updated: (i, key) => <TableCell key={key}><DateView value={i.updatedAt} /></TableCell>,
};

export default function ApiRequestsList({ items, onRowClick, columns }: IProps){
  const classes = useGlobalStyles();

  return(
    <Table size="small">
      <TableHead>
        <TableRow>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow key={i.id} className={classes.pointer} onClick={() => onRowClick(i.id.toString())} hover>
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}