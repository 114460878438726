import React from "react";

import { Form } from "react-final-form";

import clsx from "clsx";

import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { Input, ProtectionInput } from "../../../components/Form";
import useFormStyles from "../../../hooks/useFormStyles";
import {
  LegalEntity,
  useLegalEntityCreate,
  useLegalEntityUpdate,
} from "../../../api/legalEntity";
import {
  composeValidators,
  onlyAlphabetical,
  maxLength,
  minLength,
  required,
} from "../../../validators";

interface IProps {
  data: LegalEntity;
  view: boolean;
  onSave: (data: any) => void;
  onDelete: (data: any) => void;
  updating: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    marginTop: theme.spacing(2),
  },
  asc: {
    alignSelf: "center",
  },
}));

export function LegalEntityConfigForm({
  data,
  view,
  onSave,
  onDelete,
  updating,
}: IProps) {
  const classes = useStyles();
  const formClasses = useFormStyles();

  if (!data && Object.keys(data).length) {
    return <Typography align="center">No data to display</Typography>;
  }

  return (
    <Form
      onSubmit={onSave}
      initialValues={{
        ...data,
        "2fa": "",
      }}
      render={({ handleSubmit, values }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(formClasses.root, classes.form)}
        >
          <div className={formClasses.row}>
            <Input
              disabled={view}
              name="id"
              label="ID"
              type="search"
              autoComplete="xyz123"
              validate={composeValidators(
                required,
                (v: string) => onlyAlphabetical(v),
                (v: string) => maxLength(v, 20),
                (v: string) => minLength(v, 2)
              )}
            />
          </div>
          <div className={formClasses.row}>
            <Input
              name="name"
              label="Name"
              validate={composeValidators(
                required,
                (v: any) => maxLength(v, 20),
                (v: any) => minLength(v, 2)
              )}
            />
          </div>
          <div className={formClasses.row}>
            <Input
              name="sumSubToken"
              label="SumSubToken"
              type="search"
              autoComplete="xyz123"
              validate={composeValidators(
                required,
                (v: any) => maxLength(v, 20),
                (v: any) => minLength(v, 2)
              )}
            />
          </div>
          <div className={formClasses.row}>
            <Input
              name="sumSubSecret"
              label="SumSubSecret"
              type="search"
              autoComplete="xyz123"
              validate={composeValidators(
                required,
                (v: any) => maxLength(v, 20),
                (v: any) => minLength(v, 2)
              )}
            />
          </div>
          <div className={formClasses.row}>
            <Input
              name="seon"
              label="Seon"
              type="search"
              autoComplete="xyz123"
              validate={composeValidators(
                (v: any) => maxLength(v, 20),
                (v: any) => minLength(v, 2)
              )}
            />
          </div>
          <div className={formClasses.row}>
            <ProtectionInput />
            <div />
          </div>
          <div className={formClasses.actions}>
            <Button type="submit" disabled={updating} variant="contained">
              Save
            </Button>
            {data?.id && (
              <Button
                variant="contained"
                disabled={updating || values["2fa"]?.length < 6}
                type="button"
                color="secondary"
                onClick={() => {
                  onDelete({ id: values.id, "2fa": values["2fa"] });
                }}
              >
                Delete
              </Button>
            )}
          </div>
        </form>
      )}
    />
  );
}
