import React from "react";

import Typography from "@material-ui/core/Typography";
import DetailsPopup from "../../../components/Layout/DetailsPopup";
import TabsPanel from "../../../components/Tabs";
import AdminOverviewTab from "./Overview";
import AdminActivityTab from "./Activity";
import BoolLabel from "../../../components/Label/BoolLabel";
import { IAdmin } from "../../../api/admin";
import AuthorityAdminTab from "./AuthorityAdminTab";

interface IProps {
  id: string;
  admin: IAdmin;
  onClose: any;
  loading: boolean;
  onCreate: any;
}

export default function AdminDetails({
  admin,
  onClose,
  loading,
  id,
  onCreate,
}: IProps) {
  const view = id !== "new";

  let tabs = [
    {
      title: "Overview",
      content: (
        <AdminOverviewTab admin={admin} onCreate={onCreate} view={view} />
      ),
    },
  ];

  if (view) {
    tabs = [
      ...tabs,
      {
        title: "Activity",
        content: <AdminActivityTab id={id} />,
      },
      {
        title: "Authority",
        content: <AuthorityAdminTab admin={admin} id={Number(id)} />,
      },
    ];
  }

  return (
    <DetailsPopup loading={loading} onClose={onClose}>
      {admin && (
        <>
          <Typography variant="h6" color="primary">
            {id === "new" ? (
              "Create admin"
            ) : (
              <>
                {admin.username || admin.email}&nbsp;&nbsp;
                <BoolLabel
                  value={admin.active}
                  size="small"
                  labels={["Active", "Inactive"]}
                />
              </>
            )}
          </Typography>
          <TabsPanel tabs={tabs} />
        </>
      )}
      {!admin && <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  );
}
