import { useSelector } from "react-redux";
import { IKycCountry } from "../api/kyc";

const useCountryWithStates = () => {
    const countries = useSelector((store: any) => store.appData.countries.data);

    return countries.filter((country: IKycCountry) => country.useState);
} 

export default useCountryWithStates
