import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IDamChain, IDamChainWithURL } from '../../api/wallets';
import Link from '@material-ui/core/Link';
import { chainLinks } from '../../helpers/walletsHelper';
import { makeStyles } from '@material-ui/core';
import theme from '../../theme';

const useStyles = makeStyles(() => ({
  address: {
    [theme.breakpoints.down(700)]: {
      overflowWrap: 'anywhere',
    }
  }
}));

interface IProps {
  items: IDamChain[];
}

export default function ChainsList({ items }: IProps) {

let arr: IDamChainWithURL[] = []

const classes = useStyles();

if(items.length > 0) {
  items.map((i) => {
    chainLinks.map((item) =>{
      if(i.chain === item.chain) {
        const chainItem = {
          chain: item.chain,
          address: i.address,
          href: item.href
        }
        arr.push(chainItem)
      }
    })
  })
}

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Chain</TableCell>
          <TableCell>Address</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {arr.map((i:IDamChainWithURL) => (
          <TableRow key={i.chain}>
            <TableCell>{i.chain}</TableCell>
            <TableCell className={classes.address}>{i.href ? <Link target="_blank" href={`${i.href}${i.address}`}>{i.address}</Link> : i.address}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}