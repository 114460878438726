import React, { useEffect } from "react";

import { useHistory, useParams } from 'react-router';

import { useLegalEntityList } from "../../api/legalEntity";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import useGlobalStyles from './../../components/Layout/useGlobalStyles';
import CardActions from '@material-ui/core/CardActions';
import EmptyList from "../../components/EmptyList";
import Loader from "../../components/Loader";
import useColumns, { IColumn } from "../../hooks/useColumns";
import { LegalEntityList } from "./LegalEntityList";
import { LegalEntityDetails } from "./Details";
import useMobileStyles from "../../hooks/useMobileStyles";

const defaultColumns: IColumn[] = [
   { id: 'id', active: true, name: 'ID' },
   { id: 'name', active: true, name: 'Name' },
   { id: 'seon', active: true, name: 'Seon' },
   { id: 'domain', active: true, name: 'Domain' },
   { id: 'sumSubSecret', active: true, name: 'SumSubSecret' },
   { id: 'sumSubToken', active: true, name: 'SumSubToken' },
   { id: 'createdAt', active: true, name: 'Created' },
   { id: 'updatedAt', active: true, name: 'Updated' },
];

function LegalEntityPage() {
   const classes = useGlobalStyles();
   const mobileClasses = useMobileStyles();
   const [{ items, error }, loading, load] = useLegalEntityList();
   const [columns, ColumnsFilter] = useColumns('legal-entity', defaultColumns);
   const history = useHistory();
   const { entityId } = useParams<any>();

   function setDetails(id: string) {
      history.push(`/legal-entity/${id}`);
   }

   useEffect(() => {
      load()
   }, [])

   if (items.length === 0) {
      return <EmptyList error={error} loading={loading} />
   }

   let sortItems = items.sort((a: { createdAt: number; },b: { createdAt: number; })=>b.createdAt-a.createdAt)

   return (
      <Grid container spacing={3}>
         <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
            <Paper className={classes.paper}>
               <CardActions>
                  <Grid container justify="space-between" className={mobileClasses.gap}>
                     <Grid item>
                        <Button variant="contained" size="large" onClick={() => setDetails('new')}>Create</Button>
                     </Grid>
                     <Grid item>
                        <ColumnsFilter />
                     </Grid>
                  </Grid>
               </CardActions>
               <Loader loading={loading}>
                  <LegalEntityList items={sortItems} columns={columns} openDetails={setDetails} />
               </Loader>
            </Paper>
         </Grid>
         {entityId && (
            <LegalEntityDetails id={entityId} onClose={() => history.push('/legal-entity')} onUpdate={load} />
         )}
      </Grid>
   )
}
export default LegalEntityPage;