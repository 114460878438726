import React, { useState, useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { Form } from 'react-final-form';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ColHead from '../../../../components/UI/ColHead';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useFormStyles from '../../../../hooks/useFormStyles';
import {Input, ProtectionInput} from '../../../../components/Form';
import {composeValidators, mustBeNumber, maxValue, minValue, required} from '../../../../validators';
import {useDeleteFee} from '../../../../api/currency';
import ApmFeeForm from "./ApmFeeForm";
import useMobileStyles from '../../../../hooks/useMobileStyles';
import Tablecell from '../../../../components/TableCell/TableCell';

const useStyles = makeStyles((theme) => ({
  customerFeeFormTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      gap: '10px'
    },
  },
  tableRow: {
    "&:last-child td": {
      borderBottom: 0,
    },
    "& td:last-child": {
      paddingRight: 0,
    },
  },
  actions: {
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between'
  },
  form: {
    padding: theme.spacing(1)
  },
  protection: {
    marginRight: '0 !important',
  }
}))

export default function CustomerFeesForm({ customer, fees, restHandlers }: any) {
  const formClasses = useFormStyles();
  const classes = useStyles();
  const [formIsShowing, setFormIsShowing] = useState(false);
  const [validApm, setValidApm] = useState(true);
  const mobileClasses = useMobileStyles();
  const { create, creating, update, updating, load } = restHandlers;
  const [deleteFee, feeDeleting] = useDeleteFee(()=>{
    load(customer.id)
  });

  const customerIsPresent = useMemo(() => fees.find((item: any) => item.customerId && !item.currency), [fees]);
  const apmFeeIsPresent = useMemo(() => customerIsPresent?.fees?.apmFee, [customerIsPresent]);

  const onClickDeleteFee = (value: any) => {
    if(value?.length == 6)
      deleteFee({id: customerIsPresent.id, '2fa': value});
  }

  return <>
    <div className={formClasses.row}>
      <Typography variant="body1" color="primary">Fees exceptions for customer</Typography>
    </div>

    { !customerIsPresent && !formIsShowing &&
      <div className={classes.customerFeeFormTitle}>
        No exceptions for customer
        <div>
          <Button
            variant="contained"
            type="submit"
            onClick={() => setFormIsShowing(true)}
          >Add exceptions</Button>
        </div>
      </div>
    }

    { (formIsShowing || customerIsPresent) &&
      <Form
        onSubmit={(values) => {
          if(!customerIsPresent && values.fees){
            create({customerId: customer.id, ...values});
          } else if (!isEqual(values.fees, customerIsPresent.fees) || !isEqual(values.fees.apmFee, apmFeeIsPresent)) {
            if (!!values.id) {
              values.fees = values.fees || {}
              return update(values);
            }
          }
        }}
        initialValues={
          {
            ...customerIsPresent,
            '2fa': ''
          }
        }
        mutators={{setApm: ([val], state, { changeValue }) => {
          let values = state.formState.values as any;
          if(values?.fees)
            values.fees.apmFee = val;
          else
            values.fees = {apmFee: val};
          changeValue(state, 'fees', (value: any) => values.fees);
        }}}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} className={clsx(formClasses.root, classes.form)}>
            <Table size="small">
              <TableHead className={mobileClasses.tableHead}>
                <TableRow>
                  <ColHead title='Deposit' />
                  <ColHead title='Dep. Fixed' />
                  <ColHead title='Withdrawal' />
                  <ColHead title='Withdrawal Fixed' />
                  <ColHead title='Merch. Deposit' />
                  <ColHead title='Merch. Deposit Fixed' />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={clsx(formClasses.row, mobileClasses.row, classes.tableRow, 'closest')}>
                  <Tablecell title="Deposit">
                    <Input
                      name={`fees.deposit`}
                      label="Deposit"
                      validate={composeValidators(required, mustBeNumber, (v: any) => maxValue(v, 1), (v: any) => minValue(v, 0))}
                    />
                  </Tablecell>
                  <Tablecell title="Dep. Fixed">
                    <Input
                      name={`fees.depositFixed`}
                      label="Deposit fixed"
                      validate={composeValidators(required, mustBeNumber, (v: any) => minValue(v, 0))}
                    />
                  </Tablecell>
                  <Tablecell title="Withdrawal">
                    <Input
                      name={`fees.withdrawal`}
                      label="Withdrawal"
                      validate={composeValidators(required, mustBeNumber, (v: any) => maxValue(v, 1), (v: any) => minValue(v, 0))}
                    />
                  </Tablecell>
                  <Tablecell title="Withdrawal Fixed">
                    <Input
                      name={`fees.withdrawalFixed`}
                      label="Withdrawal fixed"
                      validate={composeValidators(required, mustBeNumber, (v: any) => minValue(v, 0))}
                    />
                  </Tablecell>
                  <Tablecell title="Merch. Deposit">
                    <Input
                      name={`fees.depositMerchant`}
                      label="Merch. deposit"
                      validate={composeValidators(required, mustBeNumber, (v: any) => maxValue(v, 1), (v: any) => minValue(v, 0))}
                    />
                  </Tablecell>
                  <Tablecell title="Merch. Deposit Fixed">
                    <Input
                      name={`fees.depositMerchantFixed`}
                      label="Merch. deposit fix"
                      validate={composeValidators(required, mustBeNumber, (v: any) => minValue(v, 0))}
                    />
                  </Tablecell>
                </TableRow>
              </TableBody>
            </Table>
            <ApmFeeForm
              fee={customerIsPresent?.fees || {}}
              update={(value: any) => form.mutators.setApm(value)}
              isValid={setValidApm}
            />
            <div className={formClasses.row}>
              <ProtectionInput className={classes.protection}/>
            </div>
            <div className={classes.actions}>
              <Button
                variant="contained"
                type="submit"
                disabled={creating || updating || !validApm}

              >Save fees</Button>
              {customerIsPresent && <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={creating || updating}
                onClick={() => {onClickDeleteFee(values['2fa'])}}
              >Delete Fee</Button>}
            </div>
          </form>
        )}
      />
    }
  </>
}
