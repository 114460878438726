import React from 'react';
import { useSelector } from 'react-redux';

import TableFilter from '../../components/Filter';
import {Input, Select} from '../../components/Form';
import {IKycCountry, KysExtReviewAnswer, KysStatus} from '../../api/kyc';
import {composeValidators, maxLength, mustBeNumber} from "../../validators";

export interface IKycFilter {
  id: string | null;
  customerId: string | null;
  customerEmail: string | null,
  country: IKycCountry | null,
  status: KysStatus | null;
  externalReviewAnswer: KysExtReviewAnswer | null,
  dateFrom: Date | null;
  dateTo: Date | null;
  updateDateFrom: Date | null;
  updateDateTo: Date | null;
}

interface IProps {
  filter: IKycFilter;
  onChange: any;
}

export default function KYCFilter({ filter, onChange }: IProps) {
  const countries = useSelector((store: any) => store.appData.countries.data);

  return (
    <TableFilter onApply={onChange} filter={filter} useUpdateDateFilter={true}>
      <Input
        name="id"
        placeholder="Search by id..."
        validate={composeValidators(mustBeNumber, (v: any) => maxLength(v, 19))}
        margin='none'
      />
      <Input
        name="customerId"
        placeholder="Search by customer id..."
        validate={composeValidators(mustBeNumber, (v: any) => maxLength(v, 19))}
        margin='none'
      />
      <Input
        name="customerEmail"
        placeholder="Search by customer email..."
        margin='none'
      />
      <Select
        name="country"
        label="Country"
        options={countries?.map((country: IKycCountry) => ({ value: country.code, label: country.name }))}
        multi
      />
      <Select
        name="status"
        label="Status"
        options={Object.keys(KysStatus).map((s) => ({ value: s, label: s }))}
        multi
      />
      <Select
        name="externalReviewAnswer"
        label="Ext. review answer"
        options={Object.keys(KysExtReviewAnswer).map((s) => ({ value: s, label: s }))}
        multi
      />
    </TableFilter>
  );
}
