import Api, { useFetchApi, useUpdateApi } from './index';

export enum ClearingStatus {
  ERROR = 'ERROR',
  DEFERRED = 'DEFERRED',
  MANUAL = 'MANUAL',
  SUCCESS = 'SUCCESS',
  EXECUTED = 'EXECUTED',
}

export enum LiquidityProvider {
  BITSTAMP = 'BITSTAMP',
  DAM = 'DAM',
  KRAKEN = 'KRAKEN',
  CEXIO = 'CEXIO',
  COINS_PAID = 'COINS_PAID',
  OKEX = 'OKEX',
  KUCOIN = 'KUCOIN',
  BINANCE = 'BINANCE',
}

export interface IClearingItem {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  pair: string;
  side: string;
  provider: LiquidityProvider;
  amount: number;
  price: number;
  orderId: number;
  externalId: string;
  error: string;
  status: ClearingStatus;
}

export function useClearingList() {
  return useFetchApi(
    async (query: any) => Api.post('/clearing/list', { filter: {}, limit: 50, ...query }),
    { items: [] }
  )
}

export function useClearingCSV() {
  return useFetchApi(
    async (query: any) => Api.post('/clearing/list', { filter: {}, ...query }),
    { data: '' },
    true,
    'clearing'
  )
}

export function useClearingRecheck(cb: any, message: string) {
  return useUpdateApi(
    async (query: any) => Api.post('/clearing/recheck', query),
    cb,
    message
  )
}

export function useClearingReinit(cb: any) {
  return useUpdateApi(
    async (query: any) => Api.post('/clearing/reinit', query),
    cb,
    'Successfully reinit'
  );
}

export function useClearingByOrder() {
  return useFetchApi(
    async (orderId: any) => Api.post('/clearing/list', { filter: { orderId }, limit: 5 }),
    { items: [] }
  )
}
