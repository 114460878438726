import {BlockchainTxStatus} from '../api/blockchain';

export function getBlockchainTxStatusType(type: BlockchainTxStatus) {
  switch (type) {
    case BlockchainTxStatus.SUCCESS:
      return 'success';
    case BlockchainTxStatus.EXECUTED:
      return 'warning';
    case BlockchainTxStatus.ERROR:
      return 'danger';
    case BlockchainTxStatus.MANUAL:
      return 'warning';
    default:
      return;
  }
}
