import React, { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import MinimizeIcon from '@material-ui/icons/IndeterminateCheckBox';
import MaximizeIcon from '@material-ui/icons/AddBox';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { Cell } from './helper';
import { ICurrency } from '../../../api/currency';

interface IProps {
  data: any[];
  currencies: ICurrency[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& tr:not(.divider-cell):hover': {
      '& td': {
        background: 'rgba(256,256,256,0.2)'
      }
    },
    '& tr.divider-cell': {
      '& td': {
        paddingTop: 65,
        borderRight: 0
      }
    },
    '& .bold-cell': {
      '& td': {
        fontWeight: 'bold'
      }
    },
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: theme.spacing(1)
    },
    '& .level': {
      paddingLeft: 0
    },
    '& th': {
      position: 'sticky',
      zIndex: 10,
      top: 64,
      background: '#555',
      '&:first-child': {
        borderRight: `1px solid ${theme.palette.divider}`,
      }
    }
  },
  firstCell: {
    minWidth: 200,
    borderRight: `1px solid ${theme.palette.divider}`,
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .final': {
        paddingLeft: theme.spacing(4)
      }
    }
  },
  hideRow: {
    position: 'relative',
    '&.noBorder': {

    },
    '&.secondLevel': {
      '& td:first-child': {
        paddingLeft: theme.spacing(4)
      }
    }
  }
}));

export default function BookkeepingReportList({ data, currencies }: IProps) {
  const classes = useStyles();
  const [openedArr, setOpen] = useState<any[]>([]);
  const [openedPrevArr, setOpenPrev] = useState<any[]>([]);
  const [checkAll, toggleCheckAll] = useState(false);
  const [checkPrevAll, togglePrevCheckAll] = useState(false);

  function toggleBlock(id: any, renderPrev = false) {
    if (renderPrev) {
      setOpenPrev((prev: any[]) => (prev.includes(id) ? prev.filter(i => i !== id) : [...prev, id]));
    } else {
      setOpen((prev: any[]) => (prev.includes(id) ? prev.filter(i => i !== id) : [...prev, id]));
    }
  }

  function toggleAll(isPrev = false) {
    if (isPrev) {
      togglePrevCheckAll(p => !p);
      setOpenPrev([]);
    } else {
      toggleCheckAll(p => !p);
      setOpen([]);
    }
  }

  function columns() {
    const fiat = currencies.filter(a => a.type === 'FIAT');
    const crypto = currencies.filter(a => a.type === 'CRYPTO');
    const sort = [...fiat, ...crypto];
    return [
      {
        title: '',
        id: 'title',
        render: (text: any, col: any) => (
          <TableCell className={classes.firstCell} key={`${col.id}-fc`}>
            <div>
              <div>
                {col.hasHideBlock && (
                  <IconButton onClick={() => toggleBlock(col.id)} size="small">
                    {(checkAll || openedArr.includes(col.id)) ? <MinimizeIcon /> : <MaximizeIcon />}
                  </IconButton>
                )}
                {col.isBold ? <strong className={col.isFinal ? 'final' : ''}>{text}</strong> : text}
              </div>
              {col.hasPrevBlock && (
                <IconButton onClick={() => toggleBlock(col.id, true)} size="small">
                  {(checkPrevAll || openedPrevArr.includes(col.id)) ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
              )}
            </div>
          </TableCell>
        )
      },
      ...sort.map((c) => ({
        title: c.currency,
        id: c.currency,
        render: (text: any, col: any) => (
          <Cell {...col} key={c.currency}>{text}</Cell>
        )
      }))
    ];
  }

  function renderChild(row: any) {
    if (!row) return null;
    return row.map((i: any) =>
      ([
        <TableRow className={clsx(classes.hideRow, { noBorder: i.noBorder, secondLevel: i.secondLevel})} key={i.title}>
          {columns()
            .map((c: any) => (
              c.render ? c.render(i[c.id], i, data[0]) : i[c.id]
            ))}
        </TableRow>,
        (checkPrevAll || openedPrevArr.includes(i.id)) ? renderChild(i.prevBlock) : null
      ]));
  }

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.firstCell}>
            <div>
              <IconButton onClick={() => toggleAll()} size="small">
                {checkAll ? <MinimizeIcon /> : <MaximizeIcon />}
              </IconButton>
              <IconButton onClick={() => toggleAll(true)} size="small">
                {checkPrevAll ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </IconButton>
            </div>
          </TableCell>
          {columns().map((c: any, key) => key === 0 ? null : <TableCell key={key}>{c.title}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((i) => ([
          <TableRow key={i.title} className={clsx({ 'divider-cell': i.isDivider, 'bold-cell': i.isBold })}>
            {columns().map((c: any) => (
              c.render ? c.render(i[c.id], i, data) : i[c.id]
            ))}
          </TableRow>,
          ((checkPrevAll || openedPrevArr.includes(i.id)) ? renderChild(i.prevBlock) : null),
          ((checkAll || openedArr.includes(i.id)) ? renderChild(i.childBlock) : null)
        ]))}
      </TableBody>
    </Table>
  );
}
