import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DocumentView from './DocumentView';
import { IKYCDocument, DocumentType } from '../../../api/kyc';
import DateView from '../../../components/DateView';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import {Form} from 'react-final-form';
import { DateField, Input, ProtectionInput, Autocomplete } from '../../../components/Form';
import Button from '@material-ui/core/Button';
import { ICustomer } from '../../../api/customers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .form': {
      width: 300,
      marginTop: 8,
      [theme.breakpoints.down(700)]: {
        width: '100%',
       },
    },
    [theme.breakpoints.down(800)]: {
     flexDirection: 'column',
    },
  },
  carousel: {
    margin: `${theme.spacing(2)}px auto 0`,
    width: 400,
    height: 200,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    '&.viewMode': {
      height: 370
    },
    [theme.breakpoints.down(800)]: {
      justifyContent: 'flex-start',
     },
     [theme.breakpoints.down(450)]: {
      width: '100%',
     },
  },
  swipe: {
    width: 20,
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    opacity: 0.3,
    '&:hover': {
      opacity: 1
    }
  },
  view: {
    width: 360,
    position: 'relative',
    [theme.breakpoints.down(450)]: {
      width: '100%',
     },
  },
  item: {
    width: 360,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    display: 'none',
    '&.active': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      overflowY: 'auto'
    },
    [theme.breakpoints.down(450)]: {
      width: '100%',
     },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down(450)]: {
      display: 'block',
     },
  },
  image: {
    maxWidth: 360,
    maxHeight: 180,
    '&.viewMode': {
      width: 360,
      height: 'auto',
      maxHeight: 350,
      [theme.breakpoints.down(450)]: {
        width: '100%',
       },
    },
    [theme.breakpoints.down(450)]: {
      maxWidth: '100%',
     },
  }
}));

interface IProps {
  type: DocumentType;
  documents: IKYCDocument[];
  setFullScreen: any;
  countryOptions: any;
  setCountry: any;
  setIdDoc: any;
  setResidenceDoc: any;
  values: any;
}

export default function DocumentsCarousel({
  documents,
  setFullScreen,
  type,
  countryOptions,
  setCountry,
  setIdDoc,
  setResidenceDoc,
  values
}: IProps) {
  const [active, setActive] = useState(0);
  const [viewMode, setViewMode] = useState(false);
  const classes = useStyles();
  const maxIndex = documents.length - 1;
  const useSwipe = documents.length > 1;
  const isResidence = type === DocumentType.RESIDENCE;
  const isIdCard = [DocumentType.ID_CARD, DocumentType.PASSPORT, DocumentType.DRIVING_LICENSE].includes(type);

  function setViewItem(backward = false) {
    if (backward) {
      setActive(active > 0 ? active - 1 : maxIndex);
    } else {
      setActive(active === maxIndex ? 0 : active + 1);
    }
  }

  useEffect(() => {
    if (isIdCard) {
      setIdDoc(documents[active].id);
    }
    if (isResidence) {
      setResidenceDoc(documents[active].id);
    }
  }, [active])

  return (
    <div className={classes.root}>
      {(isResidence || isIdCard) && (
        <div className="form">
          {isResidence
            ? (
              <>
                {
                  countryOptions.length
                  ? <Autocomplete
                      name="country"
                      label="Country"
                      options={countryOptions}
                      // onChange={(ev, v) => setCountry(v)}
                      // value={values.country}
                    />
                  : null
                }
                <Input name="country" type="hidden" />
                <Input name="residence" type="hidden" />
                <Input name="city" label="City" />
                <Input name="zipCode" label="ZIP code" />
                <Input name="address" label="Address" />
              </>
            ) : (
              <>
                <Input name="firstName" label="First name" />
                <Input name="lastName" label="Last name" />
                <DateField name="birthDate" label="Birth date" margin />
              </>
            )}
        </div>
      )}
      <div className={clsx(classes.carousel, { viewMode })}>
        {useSwipe && (
          <div className={classes.swipe} onClick={() => setViewItem(true)}>
            <ArrowBackIosIcon />
          </div>
        )}
        <div className={classes.view}>
          {documents.map((d, i) => (
            <div className={clsx(classes.item, { active: i === active })} key={d.id}>
              <TransformWrapper onWheelStart={() => !viewMode && setViewMode(true)}>
                {({ zoomIn, zoomOut, resetTransform }: any) => (
                  <>
                    <Typography variant="subtitle2" component="div" className={classes.title}>
                      <div>Uploaded at <DateView value={d.createdAt} /></div>
                      <div>
                        <IconButton
                          onClick={(e: any) => {
                            if (!viewMode) {
                              setViewMode(true);
                            }
                            zoomIn(e);
                          }}
                          size="small"
                        >
                          <ZoomInIcon />
                        </IconButton>
                        <IconButton onClick={zoomOut} size="small">
                          <ZoomOutIcon />
                        </IconButton>
                        <IconButton
                          onClick={(e: any) => {
                            if (viewMode) {
                              setViewMode(false);
                            }
                            resetTransform(e);
                          }}
                          size="small"
                          title="Reset zoom"
                        >
                          <SettingsOverscanIcon />
                        </IconButton>
                        <IconButton onClick={() => setFullScreen(d.data)} size="small" title="Full screen view">
                          <OpenWithIcon />
                        </IconButton>
                      </div>
                    </Typography>
                    <TransformComponent>
                      <DocumentView
                        document={d.data}
                        type={d.type}
                        width={360}
                        imageClass={clsx(classes.image, { viewMode })}
                        height={viewMode ? 350 : 180}
                      />
                    </TransformComponent>
                  </>
                )}
              </TransformWrapper>
            </div>
          ))}
        </div>
        {useSwipe && (
          <div className={classes.swipe} onClick={() => setViewItem()}>
            <ArrowForwardIosIcon />
          </div>
        )}
      </div>
    </div>
  );
}
