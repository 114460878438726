import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BlockchainTxRow from './BlockchainTxRow';
import { IBlockchainTx } from '../../api/blockchain';
import { IColumn } from '../../hooks/useColumns';
import { Checkbox } from '@material-ui/core';
import useMobileStyles from '../../hooks/useMobileStyles';
import { useBreakpoints } from '../../hooks/useBreakpoints ';

interface IProps {
  items: IBlockchainTx[];
  columns: IColumn[];
  rowCheck: boolean;
  selected: number[];
  selectAll: any;
  selectOne: any;
  multiSelect: boolean;
  setDetails: (id: string) => void
}

export default function BlockchainTxList({ setDetails, items, rowCheck, selected, selectAll, selectOne, columns, multiSelect }: IProps) {

  const mobileClasses = useMobileStyles();
  const isSm = useBreakpoints('sm');

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {rowCheck && (
            multiSelect
              ? (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selected.length === items.length}
                    color="primary"
                    indeterminate={
                      selected.length > 0
                      && selected.length < items.length
                    }
                    onChange={selectAll}
                  />
                </TableCell>
              )
              : (
                <TableCell padding="checkbox" className={mobileClasses.tableHead}>
                  <div style={{ width: 20, height: 20 }}></div>
                </TableCell>
              )
          )}
          {!isSm && columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <BlockchainTxRow
            lastChild={items[items.length-1]}
            setDetails={setDetails}
            item={i}
            rowCheck={rowCheck}
            multiSelect={multiSelect}
            isSelected={selected.includes(i.id)}
            select={selectOne}
            key={i.id}
            columns={columns}
          />
        ))}
      </TableBody>
    </Table>
  );
}