import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import { ProtectionInput, Select, UploadDocumentsField } from '../../../components/Form';
import useFormStyles from '../../../hooks/useFormStyles';
import { useKycDocumentAdd } from '../../../api/kyc';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { CircularProgress, IconButton, Typography } from '@material-ui/core';
import { useBreakpoints } from '../../../hooks/useBreakpoints ';

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    display: 'block'
  },
  import: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  dropZone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '100%',
    height: 100,
    border: '2px dashed rgba(255, 255, 255, 0.12)',
  },
  documents: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  document: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: 0
    }
  },
  docInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  preview: {
    width: '30%',
    height: '30%',
    marginRight: theme.spacing(2),
    border: '1px solid ' + theme.palette.divider,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      maxWidth: '100%',
      width: 'auto',
      height: 'auto',
      borderRadius: 4
    }
  },
  loader: {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
    color: '#fff',
    flex: '0 0 37px',
    marginRight: 0
  },
  remove: {
    padding: 6,
    '&.abort': {
      color: theme.palette.error.main
    }
  }
}));

interface IProps {
  additionalDocs: any;
  kycId: String;
  updateDetails: any;
}

interface IUploadedFile {
  name: string;
  type: string;
  size: string;
  data: string | ArrayBuffer | null;
}

let restartForm: any;

export default function DocumentUploader({additionalDocs, kycId, updateDetails}: IProps) {
  const form = useRef(null);
  const classes = useStyles();
  const formClasses = useFormStyles();
  const [file, setFile] = useState<IUploadedFile | null>(null);
  const [initialValues, setInitialValues] = useState<any>({ addDoc: '', file: null, '2fa': '' });
  const isResolutionSevenHundred = useBreakpoints(700);

  const [addDocument, adding] = useKycDocumentAdd((res: any) => {
    if (res.success) {
      setInitialValues({ addDoc: '', file: null, '2fa': '' });
      restartForm();
      updateDetails();
    }
  })

  return (
    <Form
      onSubmit={(values, form) => {
        if (file && file.data) {
          const documents = {};
          //@ts-ignore
          documents[values.addDoc] = file.data.split(',')[1];
          addDocument({
            id: kycId,
            documents,
            '2fa': values['2fa']
          });
        }
      }}
      initialValues={initialValues}
      render={({ handleSubmit, form, values }) => {
        // @ts-ignore
        restartForm = form.restart;
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
          <div className={formClasses.row}>
            <Select
              name="addDoc"
              label="Upload document"
              options={additionalDocs.map((t: any) => ({ value: t, label: t }))}
              fullWidth={isResolutionSevenHundred}
            />
            <div />
          </div>
          {values.addDoc && 
            <>
              {file && (
                <div className={classes.documents}>
                    <div className={classes.document} >
                      <div className={classes.docInfo}>
                        <div className={classes.preview}>
                          {!file.data
                            ? <div className={clsx(classes.loader, classes.preview)}><CircularProgress color="inherit" size={20} /></div>
                            : (file.type && !file.type.includes('pdf')) && <img src={file.data.toString()} alt={file.name} />
                          }
                        </div>
                        <div>
                          <Typography variant="body2" component="div" color={file.data ? 'textPrimary' : 'textSecondary'}>
                            {file.name}
                          </Typography>
                          <Typography variant="body2" component="div" color="textSecondary">
                            {file.size}
                          </Typography>
                        </div>
                      </div>
                      <IconButton
                        onClick={() => setFile(null)}
                        className={clsx(classes.remove, { abort: !file.data })}
                      >
                        {file.data ? <DeleteForeverIcon /> : <CancelRoundedIcon color="inherit" />}
                      </IconButton>
                    </div>
                </div>
              )}
              {!file && (
                <div className={formClasses.row}>
                  <UploadDocumentsField
                    onLoad={(r: any) => {
                      setFile(r)
                    }}
                    className={classes.dropZone}
                    input={{
                      multiple: false,
                      name: 'file'
                    }}
                  />
                </div>
              )}
              <div className={formClasses.row}>
                <ProtectionInput />
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  className={classes.import}
                  disabled={!file}
                >
                  Submit document
                </Button>
              </div>
            </>
            }
          </form>
        )}}
    />  
  )
}