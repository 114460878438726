import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: ({ width }: any) => width || 'calc(100vw - 320px)',
    padding: theme.spacing(2),
    height: ({ height }: any) => height || '100%'
  }
}));

interface ILoaderProps {
  loading: boolean;
  children?: any;
  width?: number;
  height?: number;
}

export default function Loader({ loading, children, width, height }: ILoaderProps) {
  const classes = useStyles({ width, height });
  return loading
    ? (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    )
    : (children || null);
}
