import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import blue from '@material-ui/core/colors/blue';

import overrides from './overrides';

// @ts-ignore
const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: blue[200],
      dark: blue[500],
      light: blue['A200'],
    }
  },
  overrides,
  typography: {
    fontSize: 12
  }
});

export default theme;
