interface IPaletteColor {
  contrastText: string;
  dark: string;
  main: string;
  light: string;
}

interface ITextColor {
  primary: string;
  secondary: string;
  link: string;
}

export interface IPalette {
  primary: IPaletteColor;
  secondary: IPaletteColor;
  text: ITextColor;
  icon: string;
  border?: string;
}

export interface IMenuLink {
  url?: string | null;
  text?: ILocales;
}

export interface IFooterMenu {
  terms?: IMenuLink;
  privacy?: IMenuLink;
}

interface IGlobalSettings {
  logo: string | null;
  palette: IPalette;
  footer: {
    show: boolean;
    copyright?: string | null;
    menu?: IFooterMenu;
    text?: ILocales;
  };
  locale?: {
    langChangeDisable: boolean;
    defaultLang: string;
  }
}

interface ILocales {
  en: string;
  ru: string;
}

interface IFormField {
  show: boolean;
  disable?: boolean;
  title: ILocales;
}

interface IFormFields {
  [key: string]: IFormField;
}

interface IFormStep {
  title: ILocales;
  infoText?: ILocales;
  fields?: IFormFields;
  stepCurrencyTitle?: ILocales;
  stepEmailTitle?: ILocales;
}

interface IButtonColor {
  base: string;
  hover: string;
}

interface IBorderRadius {
  button: string;
  input: string;
}

export interface IUISettings {
  global: IGlobalSettings;
  form: {
    title: ILocales;
    buttonName: ILocales;
    buttonColor?: IButtonColor;
    borderRadius?: IBorderRadius;
    authorization: IFormStep;
    personalInfo: IFormStep;
    verification: IFormStep;
    card: IFormStep;
  }
}

export function getInitialUI() {
  const s: IUISettings = {
    global: {
      logo: "",
      palette: {
        primary: {
          contrastText: "",
          dark: "",
          main: "",
          light: ""
        },
        secondary: {
          contrastText: "",
          dark: "",
          main: "",
          light: ""
        },
        text: {
          primary: "",
          secondary: "",
          link: ""
        },
        icon: "",
        border: ""
      },
      footer: {
        show: true,
        copyright: "",
        menu: {
          terms: {
            url: "",
            text: {
              en: "",
              ru: "",
            },
          },
          privacy: {
            url: "",
            text: {
              en: "",
              ru: "",
            },
          }
        },
        text: {
          en: "",
          ru: "",
        },
      },
      locale: {
        langChangeDisable: false,
        defaultLang: 'en-US'
      }
    },
    form: {
      title: {
        en: "",
        ru: "",
      },
      buttonName: {
        en: "",
        ru: "",
      },
      buttonColor: {
        base: "",
        hover: "",
      },
      borderRadius: {
        button: "",
        input: ""
      },
      authorization: {
        title: {
          en: "",
          ru: "",
        },
        stepCurrencyTitle: {
          en: "",
          ru: ""
        },
        stepEmailTitle: {
          en: "",
          ru: ""
        },
        fields: {
          currency: {
            show: true,
            title: {
              en: "",
              ru: "",
            }
          },
          paymentCurrency: {
            show: true,
            title: {
              en: "",
              ru: "",
            }
          },
          walletAddress: {
            show: true,
            title: {
              en: "",
              ru: "",
            }
          },
          email: {
            show: true,
            disable: false,
            title: {
              en: "",
              ru: "",
            }
          }
        }
      },
      verification: {
        title: {
          en: "",
          ru: "",
        },
        infoText: {
          en: "",
          ru: "",
        }
      },
      personalInfo: {
        title: {
          en: "",
          ru: "",
        }
      },
      card: {
        title: {
          en: "",
          ru: "",
        }
      }
    }
  };

  return s;
}
