import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import TableFilter from '../../components/Filter';
import { AutocompleteFieldWithCreateOption, Input, Select } from '../../components/Form';
import { IKycCountry } from '../../api/kyc';
import { IAuthority, useAuthorityList } from '../../api/authority';
import { LegalEntityOne, useLegalEntityList } from '../../api/legalEntity';
import { useMerchantNamingList } from '../../api/customers';

export interface ICustomerFilter {
  email: string | null;
  customerId: string | null;
  phone: string | null;
  role?: string;
  address: string | null;
  level: string | null;
  legalEntity: string | null;
  businessNames: string[],
  legalNames: string[],
  legalMerchantNames: string[],
  merchantNames: string[],
  accountTypes: string[],
  ccwEnabled: boolean | null,
}

interface IProps {
  filter: ICustomerFilter,
  onChange: any
}

export default function CustomersFilter({ filter, onChange }: IProps) {
  const countries = useSelector((store: any) => store.appData.countries.data);
  const [{ items: authorities }, loadingAuthorities, loadAuthorities ] = useAuthorityList();
  const [{ items: entities, error }, loadingEntity, loadEntity] = useLegalEntityList()
  const [customerAccountTypes, setCustomerAccountTypes] = useState([]);
  const [isLoadedMerchantNaming, setIsLoadedMerchantNaming] = useState(false);

  const [
    { 
      data: items,
    }, 
    loadingMerchantNaming, 
    loadMerchantNaming,
  ] = useMerchantNamingList();

  useEffect(() => {
   loadMerchantNaming('');
   loadAuthorities('CUSTOMER');
   loadEntity();
   setIsLoadedMerchantNaming(true);
  }, []);

  useEffect(() => {
    if (isLoadedMerchantNaming && items?.accountTypes?.length) {
      setCustomerAccountTypes(items?.accountTypes);
      setIsLoadedMerchantNaming(false);
    }
  }, [items?.accountTypes?.length, isLoadedMerchantNaming]);

  return (
    <TableFilter onApply={onChange} filter={filter}>
      <Input
         name="customerId"
         label="Search by id..."
         margin="none"
      />
      <Input
         name="email"
         label="Search by email"
         margin="none"
      />
      <Input
         name="phone"
         label="Search by phone"
         margin="none"
      />
      <Input
         name="level"
         label="Search by level"
         margin="none"
      />
       <Select
        name="ccwEnabled"
        label="CCW"
        options={[{ value: true, label: 'Enable'}, {value: false, label: 'Disable' }]}
      />
      <Select
        name="role"
        label="Role"
        options={authorities.map((authority: IAuthority) => ({ value: authority.name, label: authority.description }))}
      />
      <Select
        name="legalEntity"
        label="Legal entity"
        options={entities.map((entity: LegalEntityOne) => ({ value: entity.id, label: entity.name }))}
      />
      <Select
        name="country"
        label="Country"
        options={countries?.map((country: IKycCountry) => ({ value: country.code, label: country.name }))}
      />
      <AutocompleteFieldWithCreateOption 
        name="businessNames"
        canCreateOption={false}
        disabled={loadingMerchantNaming}
        loadOptions={loadMerchantNaming}
        optionsList={items?.businessNames}
        label="Business name"
      />
      <AutocompleteFieldWithCreateOption 
        name="legalNames"
        canCreateOption={false}
        disabled={loadingMerchantNaming}
        optionsList={items?.legalNames}
        label="Legal name"
      />
      <AutocompleteFieldWithCreateOption 
        name="legalMerchantNames"
        canCreateOption={false}
        disabled={loadingMerchantNaming}
        optionsList={items?.legalMerchantNames}
        label="Legal merchant name"
      />
      <AutocompleteFieldWithCreateOption 
        name="merchantNames"
        canCreateOption={false}
        disabled={loadingMerchantNaming}
        optionsList={items?.merchantNames}
        label="Merchant name"
      />
      <AutocompleteFieldWithCreateOption 
        name="accountTypes"
        canCreateOption={false}
        disabled={loadingMerchantNaming}
        optionsList={customerAccountTypes}
        label="Account type"
      />
    </TableFilter>
  );
}
