import { Button, makeStyles, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { CurrencyType, ICurrency } from '../../../api/currency';
import { useCustomerDetails } from '../../../api/customers';
import { ISettlement, SettlementStatus } from '../../../api/settlements';

import { DataRow } from '../../../components/DataView';
import DateView from '../../../components/DateView';
import Label from '../../../components/Label';
import PopupLink from '../../../components/UI/PopupLink';
import { getSettlementStatusType } from '../../../helpers/settlementHelper';
import { ISettlementFees, MerchantsFees, SettlementFees } from './components';

export interface IMappedSettlementOperation {
  customerId: string
  amount: number
  currency: string
}

interface ISettlementInfo extends Pick<ISettlementFees, 'onAdditionalFeeUpdate'> {
  settlement: ISettlement
  currencies: ICurrency[]
  setOpenDialog: (open: boolean)=> void
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2.5),
  },
}));

function SettlementInfo({ settlement, setOpenDialog, currencies, ...feesProps }: ISettlementInfo) {
  const [settlementType, setSettlementType] = useState<CurrencyType | undefined>(CurrencyType.FIAT);
  const classes = useStyles();

  const [{ data: customer }, , loadCustomer] = useCustomerDetails();

  const mappedSettlementOperations: IMappedSettlementOperation[] = [];
  let customerId = '';

  if (Object.keys(settlement.operations).length > 0) {
    customerId = Object.keys(settlement.operations)[0];
    Object.entries(settlement.operations).forEach(([customerId, requestInfo]) =>{
       Object.entries(requestInfo as Record<string, number>).forEach(([currency, amount]) => {
        mappedSettlementOperations.push({ customerId, currency, amount });
      });
    });
  }

  useEffect(() => {
    if (customerId) {
      loadCustomer(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (settlement.status === SettlementStatus.SETTLED) {
      const currency = currencies.find((c) => c.currency === settlement.settlementCurrency)
      setSettlementType(currency?.type)
    }
  }, [settlement.status])

  return(
    <>
      <Typography 
        color="primary"
        variant="h6"
        className={classes.title}
      >
        Settlement ID {settlement.id}
      </Typography>
      <DataRow label="Status"><Label label={settlement.status} type={getSettlementStatusType(settlement.status)} size="small" /></DataRow>
      <DataRow label="Merchant ID">
        <PopupLink popup="customer" id={settlement.customerId} baseUrl={'/customers'}>
          {settlement.customerId}
        </PopupLink>
      </DataRow>
      <DataRow label="Legal entity">
        {customer?.legalEntity ?? ''}
      </DataRow>
      <DataRow label="Requested amount">{settlement.requestedEurAmount} EUR</DataRow>
      <DataRow label="Settled amount">{settlement?.settlementAmount ? `${settlement.settlementAmount} ${settlement.settlementCurrency}` : '-'}</DataRow>
      <DataRow label="Created"><DateView value={settlement.createdAt} /></DataRow>
      <DataRow label="Updated"><DateView value={settlement.updatedAt} /></DataRow>
      {settlement.status === SettlementStatus.REQUESTED && (
        <>
          <DataRow label="Сhange status">
            <Button
              variant="contained"
              size="small"
              onClick={() => setOpenDialog(true)}
            >
              Сhange
            </Button>
          </DataRow>
          {settlement.settlementFees && (
            <TextField
              select
              value={settlementType}
              variant="outlined"
              label="Settlement Type"
              style={{ width: 300 }}
              margin="normal"
              // @ts-ignore
              onChange={(e) => setSettlementType(e.target.value)}
            >
              <MenuItem>&nbsp;</MenuItem>
              <MenuItem value={CurrencyType.CRYPTO}>Crypto</MenuItem>
              <MenuItem value={CurrencyType.FIAT}>Fiat</MenuItem>
            </TextField>
          )}
        </>
      )}

      <Typography variant="body1" component="div" color="primary">Requested amounts</Typography>
      {mappedSettlementOperations.length > 0 &&
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Customer ID</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell>Currency</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mappedSettlementOperations.map((row, index) =>
            (
              <TableRow key={index}>
                <TableCell>
                  <PopupLink popup="customer" id={row.customerId} baseUrl={'/customers'}>
                    {row.customerId}
                  </PopupLink>
                </TableCell>
                <TableCell align="right">{row.amount}</TableCell>
                <TableCell>{row.currency}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
      }
      <br />
      {settlementType && settlement.settlementFees && (
        <SettlementFees settlement={settlement} operations={mappedSettlementOperations} type={settlementType} {...feesProps} />
      )}
      <br />
      {settlement.settlementFees && <MerchantsFees fees={settlement.settlementFees} />}
    </>
  );
}
export default SettlementInfo;