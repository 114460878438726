import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Waypoint } from 'react-waypoint';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import AdminsList from './AdminsList';
import EmptyList from '../../components/EmptyList';
import Loader from '../../components/Loader';
import AdminDetails from './Details';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { useAdminsList, emptyAdmin } from '../../api/admin';
import useColumns, { IColumn } from '../../hooks/useColumns';
import useMobileStyles from '../../hooks/useMobileStyles';

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'email', name: 'Email', active: true },
  { id: 'userName', name: 'User name', active: true },
  { id: 'name', name: 'Name', active: true },
  { id: 'position', name: 'Position', active: true },
  { id: 'active', name: 'Active', active: true },
  { id: 'gAuth', name: '2fA enabled', active: true },
  { id: 'created', name: 'Created at', active: true },
  { id: 'updated', name: 'Updated at', active: true },
];

export default function AdminsPage() {
  const classes = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  const [{ items, full, error }, loading, load, updateItem] = useAdminsList();
  const [columns, ColumnsFilter] = useColumns('admins', defaultColumns);
  const history = useHistory();
  const [admin, setAdmin] = useState();
  const { adminId } = useParams();

  function setDetails(id?: string) {
    history.push(`/admins/list${id ? `/${id}` : ''}`);
  }

  useEffect(() => {
    load({});
  }, []);

  useEffect(() => {
    if (adminId && adminId !== 'global-settings' && items.length) {
      const adm = adminId === 'new' ? emptyAdmin : items.find((i: any) => i.id.toString() === adminId);
      setAdmin(adm);
    }
  }, [adminId, items]);

  function save(data: any) {
    updateItem(data);
    if (adminId === 'new') {
      setDetails(data.id);
    } else {
      setAdmin(data);
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justify="space-between" className={mobileClasses.gap}>
              <Grid item>
                <Button variant="contained" size="large" onClick={() => setDetails('new')}>Create Admin</Button>
              </Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0
            ? <AdminsList items={items} onRowClick={setDetails} columns={columns} />
            : <EmptyList error={error} loading={loading} />
          }
          <Loader loading={loading} />
          {!loading && !error && !full && items.length > 1
          && <Waypoint onEnter={() => load({ cursor: items.length })} />}
        </Paper>
      </Grid>
      {adminId && adminId !== 'global-settings' && <AdminDetails id={adminId} admin={admin} onClose={() => setDetails()} loading={loading} onCreate={save} />}
    </Grid>
  );
}
