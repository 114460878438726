import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import DetailsPopup from '../../../../components/Layout/DetailsPopup';
import { useBinCodeOneItem, useUpdateBinCodeItem } from '../../../../api/binCodes';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { Form } from 'react-final-form';
import { Input, ProtectionInput } from '../../../../components/Form';
import useFormStyles from '../../../../hooks/useFormStyles';
import { required } from '../../../../validators';

interface IProps {
  id: string | number;
  onClose: any;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    marginTop: theme.spacing(2)
  },
  supported: {
    marginTop: theme.spacing(1)
  }
}));

export default function PaymentDetails({ id, onClose }: IProps) {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const [{ data }, loading, load] = useBinCodeOneItem();

  useEffect(() => {
    load({ id });
  }, [id]);

  const [update, updating] = useUpdateBinCodeItem((res: any) => {
    if (res.success) {
      load({ id });
    }
  })

  return (
    <DetailsPopup onClose={onClose} loading={loading}>
      {data
        ? (
          <>
            <Typography variant="h6" color="primary">BIN code item {data.id}</Typography>
            <Form
              onSubmit={update}
              initialValues={{ binCode: data.binCode, country: data.country, '2fa': '' }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} className={clsx(formClasses.root, classes.form)}>
                  <div className={formClasses.row}>
                    <Input
                      name="country"
                      label="Country"
                      validate={required}
                    />
                  </div>
                  <div className={formClasses.row}>
                    <ProtectionInput />
                    <div />
                  </div>
                  <div className={formClasses.actions}>
                    <Button
                      type="submit"
                      disabled={updating}
                      variant="contained"
                    >Save</Button>
                  </div>
                </form>
              )}
            />
          </>
        ) : <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  )
}
