import React from 'react';

import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core';
import { ProtectionInput } from '../../../../components/Form';
import Button from '@material-ui/core/Button';


import useFormStyles from '../../../../hooks/useFormStyles';
import {useResendKycPostback} from "../../../../api/kyc";

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    width: '100%',
    height: '40px',
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(2),

    '&>div': {
      margin: 0,
      marginRight: theme.spacing(0.5)
    },
    '& input': {
      padding: `${theme.spacing(0.49)}px ${theme.spacing(1)}px`,
      height: '32px'
    },
    '& label.MuiInputLabel-outlined': {
      transform: 'translate(15px, 15px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -5px) scale(0.75) !important'
    },
    '& .MuiFormHelperText-contained': {
      top: theme.spacing(5)
    },
    '& button':{
      lineHeight: '16px'
    }
  },
  button: { 
    marginLeft: theme.spacing(0.5)
  }

}))

export default function ResendPostbackKycForm({customerId}: any) {
  const classes = useStyles();

  const [reinit, reining] = useResendKycPostback((res: any) => {
  });

  return (
    <Form
      onSubmit={(data: any) => reinit({...data, id: customerId})}
      initialValues={{'2fa': ''}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.row}>
            <ProtectionInput />
            <Button
              variant="contained"
              type="submit"
              disabled={reining}
              fullWidth
              classes={{root: classes.button}}
            >
              Resend failed notifications
            </Button>
          </div>
        </form>
      )}
    />
  )
}