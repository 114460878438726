import {
  applyMiddleware, combineReducers, createStore
} from 'redux';
import thunk from 'redux-thunk';
import common from './reducers/common';
import appData from './reducers/appData';

export default function configureStore() {
  return createStore(
    combineReducers({
      common,
      appData,
    }),
    applyMiddleware(thunk),
  );
}