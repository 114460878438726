import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import MinimizeIcon from '@material-ui/icons/IndeterminateCheckBox';
import MaximizeIcon from '@material-ui/icons/AddBox';
import CurrencyFormat from '../../../components/UI/CurrencyFormat';

export const defaultTypeOptions = ['CRYPTO_WALLET', 'MERCHANT'];
export const typeOptions = [
  'PORTFOLIO',
  'USER',
  'OTHER'
];

function getBalances(data: any) {
  return data.balances.reduce((acc: any, item: any) => {
    const account = item.subAccount ? `${item.account} - ${item.subAccount}` : item.account;
    if (acc[item.accountType]) {
      if (acc[item.accountType].total[item.currency]) {
        acc[item.accountType].total[item.currency] = acc[item.accountType].total[item.currency] + item.balance;
      } else {
        acc[item.accountType].total[item.currency] = item.balance;
      }
      if (acc[item.accountType].child[account]) {
        acc[item.accountType].child[account][item.currency] = item.balance;
      } else {
        acc[item.accountType].child[account] = { [item.currency]: item.balance };
      }
    } else {
      acc[item.accountType] = {
        total: {
          [item.currency]: item.balance
        },
        child: {
          [account]: {
            [item.currency]: item.balance
          }
        }
      };
    }
    return acc;
  }, {});
}

export function getPrecision(currencies: any[]) {
  return currencies.reduce((acc, item) => {
    acc[item.code] = item.precision;
    return acc;
  }, {});
}

function toTitle(t: string) {
  return (t[0] + t.substring(1)
    .toLowerCase()
    .replace('_', ' '));
}

function getTotalBalance(balances: any) {
  return Object.keys(balances)
    .reduce((acc: any, item) => {
      const arr = balances[item];
      const temp: any = {};
      Object.keys(arr.total)
        .forEach(i => {
          temp[i] = acc[i] ? acc[i] + arr.total[i] : arr.total[i];
        });
      return { ...acc, ...temp };
    }, {});
}


function getLiabilitiesBalances(data: any[], exception: any[] = []) {
  return data.reduce((acc, item) => {
    const account = item.subAccount;
    const isException = exception.includes(account);

    if (isException) {
      if (acc.exception[account]) {
        acc.exception[account][item.currency] = item.balance;
      } else {
        acc.exception[account] = { [item.currency]: item.balance };
      }
      return acc;
    }

    if (acc[item.accountType]) {
      if (acc[item.accountType].total[item.currency]) {
        acc[item.accountType].total[item.currency] = acc[item.accountType].total[item.currency] + item.balance;
      } else {
        acc[item.accountType].total[item.currency] = item.balance;
      }
      if (acc[item.accountType].child[account]) {
        acc[item.accountType].child[account][item.currency] = item.balance;
      } else {
        acc[item.accountType].child[account] = { [item.currency]: item.balance };
      }
    } else {
      acc[item.accountType] = {
        total: {
          [item.currency]: item.balance
        },
        child: {
          [account]: {
            [item.currency]: item.balance
          }
        }
      };
    }

    // if (acc.total[item.currency]) {
    //   acc.total[item.currency] = acc.total[item.currency] + item.balance;
    // } else {
    //   acc.total[item.currency] = item.balance;
    // }
    // if (acc.child[account]) {
    //   acc.child[account][item.currency] = item.balance;
    // } else {
    //   acc.child[account] = { [item.currency]: item.balance };
    // }
    return acc;
  }, {
    exception: {}
  });
}

function getTotalLiabilities(liabilities: any) {
  return Object.keys(liabilities)
    .reduce((acc: any, item) => {
      if (item === 'exception') return {};
      const arr = liabilities[item];
      const temp: any = {};
      Object.keys(arr.total)
        .forEach(i => {
          temp[i] = acc[i] ? acc[i] + arr.total[i] : arr.total[i];
        });
      return { ...acc, ...temp };
    }, {});
}

function getTotalsTable(res: any[], ratesToUsd: any[], exceptionCur: any[]) {
  const cells = ['ASSETS', 'Current balance', 'Change absolute', 'LIABILITIES'];
  return res.reduce((acc, item) => {
    if (cells.includes(item.title)) {
      const inUsd = ratesToUsd.reduce((acc, i) => {
        if (exceptionCur.includes(i.currency)) {
          return [acc[0], acc[1] + (item[i.currency] || 0) * i.rate];
        } else {
          return [acc[0] + (item[i.currency] || 0) * i.rate, acc[1]];
        }
      }, [0, 0]);
      acc[item.title] = {
        include: inUsd[0],
        exclude: inUsd[1]
      };
    }
    return acc;
  }, {});
}

function getPrev(prev: number, curr: number) {
  if (isNaN(prev) || isNaN(curr)) return ['-', '-'];
  const percent = prev === 0 ? 0 : ((curr / prev) - 1) * 100;
  return [curr - prev, percent];
}

interface IAdapterProps {
  exceptionLiq: any[];
  exceptionCur?: any[];
  currencies: string[];
}

export function dataAdapter(response: any, { exceptionLiq, exceptionCur, currencies }: IAdapterProps): any[] {
  const exception = exceptionLiq.map(i => (i.value || i));
  const exceptionCurr = exceptionCur ? exceptionCur.map(i => (i.value || i)) : [];
  const data = response[1];
  const previousData = response[0];
  const balances = getBalances(data);

  const prevBalances = getBalances(previousData);
  // total balance
  const totalBalances = getTotalBalance(balances);
  const prevTotalBalances = getTotalBalance(prevBalances);
  let res = [];

  const generateChilds = (data: any, prevData: any, allChilds: boolean) => {
    Object.keys(data)
      .forEach((i) => {
        if (i === 'exception') return;
        let hasPrev = false;
        let prevBlock = [];

        if (prevData[i]) {
          hasPrev = true;
          const totalDiff = Object.entries(data[i].total)
            .reduce((acc: any[], t) => {
              const [k, v] = t;
              acc[0][k] = getPrev(prevData[i] ? prevData[i].total[k] : 0, v as number)[0];
              acc[1][k] = getPrev(prevData[i] ? prevData[i].total[k] : 0, v as number)[1];
              return acc;
            }, [{}, {}]);
          prevBlock.push(
            {
              title: 'Previous',
              noBorder: true,
              ...prevData[i].total
            },
            {
              title: 'Change absolute',
              noBorder: true,
              ...totalDiff[0]
            },
            {
              title: 'Change, %',
              hasPercent: true,
              noBorder: true,
              ...totalDiff[1]
            }
          );
        }

        const el = {
          id: i,
          title: toTitle(i),
          isBold: true,
          hasHideBlock: allChilds,
          isFinal: !allChilds,
          hasPrevBlock: hasPrev,
          toUpper: true,
          ...data[i].total,
          prevBlock
        };

        if (allChilds) {
          el.childBlock = Object.keys(data[i].child)
            .map((item) => {
              let hasPrevBlock = false;
              let prevBlock = [];
              if (prevBalances[i] && prevBalances[i].child[item]) {
                hasPrevBlock = true;

                const totalDiff = Object.entries(data[i].child[item])
                  .reduce((acc: any[], [k, v]) => {
                    acc[0][k] = getPrev(prevData[i].child[item][k], v as number)[0];
                    acc[1][k] = getPrev(prevData[i].child[item][k], v as number)[1];
                    return acc;
                  }, [{}, {}]);
                prevBlock.push(
                  {
                    title: 'Previous',
                    noBorder: true,
                    secondLevel: true,
                    ...prevData[i].child[item]
                  },
                  {
                    title: 'Change absolute',
                    noBorder: true,
                    secondLevel: true,
                    ...totalDiff[0]
                  },
                  {
                    title: 'Change, %',
                    hasPercent: true,
                    noBorder: true,
                    secondLevel: true,
                    ...totalDiff[1]
                  }
                );
              }
              return ({
                id: item,
                title: toTitle(item),
                isBold: true,
                secondLevel: true,
                ...data[i].child[item],
                hasPrevBlock,
                prevBlock
              });
            });
        }

        res.push(el);
      });
  };

  const dataRatesToUsd = [...data.rates];

  const dataPrevRatesToUsd = [...previousData.rates];


  const totalBalancesDiff = Object.entries(totalBalances)
    .reduce((acc: any[], [k, v]) => {
      acc[0][k] = getPrev(prevTotalBalances[k], v as number)[0];
      acc[1][k] = getPrev(prevTotalBalances[k], v as number)[1];
      return acc;
    }, [{}, {}]);
  res.push({
    id: 'ASSETS',
    title: 'ASSETS',
    isBold: true,
    hasPrevBlock: true,
    ...totalBalances,
    prevBlock: [
      {
        title: 'Previous',
        noBorder: true,
        ...prevTotalBalances
      },
      {
        title: 'Change absolute',
        noBorder: true,
        ...totalBalancesDiff[0]
      },
      {
        title: 'Change, %',
        hasPercent: true,
        noBorder: true,
        ...totalBalancesDiff[1]
      }
    ]
  });
  generateChilds(balances, prevBalances, true);

  // add Liabilities
  const getLiabilities = getLiabilitiesBalances(data.liabilities, exception);
  const getPrevLiabilities = getLiabilitiesBalances(previousData.liabilities, exception);

  const liabilitiesException = getLiabilities.exception;

  const totalLiabilities = getTotalLiabilities(getLiabilities);
  const prevTotalLiabilities = getTotalLiabilities(getPrevLiabilities);

  const prevLiabilitiesException = getPrevLiabilities.exception;

  const totalLiabilitiesDiff = Object.entries(totalLiabilities)
    .reduce((acc: any[], [k, v]) => {
      acc[0][k] = getPrev(prevTotalLiabilities[k], v as number)[0];
      acc[1][k] = getPrev(prevTotalLiabilities[k], v as number)[1];
      return acc;
    }, [{}, {}]);
  res.push({
    title: 'LIABILITIES',
    id: 'LIABILITIES',
    isBold: true,
    ...totalLiabilities,
    hasPrevBlock: true,
    prevBlock: [
      {
        title: 'Previous',
        noBorder: true,
        ...prevTotalLiabilities,
      },
      {
        title: 'Change absolute',
        noBorder: true,
        ...totalLiabilitiesDiff[0]
      },
      {
        title: 'Change, %',
        noBorder: true,
        hasPercent: true,
        ...totalLiabilitiesDiff[1]
      }
    ]
  });
  generateChilds(getLiabilities, getPrevLiabilities, false);

  res.push({
    title: 'SUMMARY',
    isDivider: true
  });

  // Current balance
  const currentBalance = currencies.reduce((acc: any, item) => {
    acc[item] = (totalBalances[item] || 0) - (totalLiabilities[item] || 0);
    return acc;
  }, {});
  res.push({
    title: 'Current balance',
    isBold: true,
    ...currentBalance
  });

  // current balance of the previous period
  const previousPeriodCurrentBalance = currencies.reduce((acc: any, item) => {
    acc[item] = (prevTotalBalances[item] || 0) - (prevTotalLiabilities[item] || 0);
    return acc;
  }, {});
  const changeBalance = currencies.reduce((acc: any[], item) => {
    acc[0][item] = previousPeriodCurrentBalance[item];
    acc[1][item] = currentBalance[item] - previousPeriodCurrentBalance[item];
    acc[2][item] = !previousPeriodCurrentBalance[item] ? '-' : ((currentBalance[item] / previousPeriodCurrentBalance[item]) - 1) * 100;
    return acc;
  }, [{}, {}, {}]);
  res.push({
    title: 'Previous balance',
    ...changeBalance[0]
  }, {
    title: 'Change absolute',

    ...changeBalance[1]
  }, {
    title: 'Change, %',
    hasPercent: true,
    ...changeBalance[2]
  });

  // Exchange rate to the dollar, 2 lines
  if (dataRatesToUsd.length) {
    const ratesToUsd = dataRatesToUsd.reduce((acc, rates) => {
      return ([{
        ...acc[0],
        [rates.currency]: rates.rate * currentBalance[rates.currency]
      }, {
        ...acc[1],
        [rates.currency]: rates.rate
      }]);
    }, []);
    const previousRatesToUsd = dataPrevRatesToUsd.reduce((acc, rates) => {
      return ([{
        ...acc[0],
        [rates.currency]: rates.rate
      }, {
        ...acc[1],
        [rates.currency]: (!rates.rate || !ratesToUsd[1][rates.currency]) ? '-' : ((ratesToUsd[1][rates.currency] / rates.rate) - 1) * 100
      }]);
    }, []);

    res.push({
      title: 'Balance, EUR',
      ...ratesToUsd[0]
    }, {
      title: 'Previous Exchange rate to the EUR',
      ...previousRatesToUsd[0]
    }, {
      title: 'Exchange rate to the EUR',
      ...ratesToUsd[1]
    }, {
      title: 'Course changes, %',
      hasPercent: true,
      ...previousRatesToUsd[1]
    });
  }

  if (Object.keys(liabilitiesException).length) {
    res.push({
      title: 'EXCLUDED LIABILITIES',
      isDivider: true
    });
  }

  Object.entries(liabilitiesException)
    .forEach(([k, v]) => {

      const prevEx = prevLiabilitiesException[k];
      const ex = liabilitiesException[k];

      const changeLiabilities = currencies.reduce((acc: any[], item) => {
        acc[0][item] = (ex[item] || 0) - ((prevEx && prevEx[item]) || 0);
        acc[1][item] = (prevEx && prevEx[item] && ex[item]) ? ((ex[item] / prevEx[item]) - 1) * 100 : '-';
        return acc;
      }, [{}, {}]);
      res.push({
        title: k,
        id: k,
        hasPrevBlock: true,
        // @ts-ignore
        ...v,
        isBold: true,
        prevBlock: [{
          title: 'Previous',
          noBorder: true,
          ...prevLiabilitiesException[k]
        }, {
          title: 'Change absolute',
          noBorder: true,
          ...changeLiabilities[0]
        }, {
          title: 'Change, %',
          noBorder: true,
          hasPercent: true,
          ...changeLiabilities[1]
        }]
      });
    });
  return [res, getTotalsTable(res, dataRatesToUsd, exceptionCurr)];
}

interface ICellProps {
  isBold?: boolean;
  isDivider?: boolean;
  hasPercent?: boolean
}

function getCell(val: any = 0, { isBold, isDivider, hasPercent }: ICellProps) {
  let res = typeof (val) === 'string' ? val : <>{val}{hasPercent && ' %'}</>;
  if (isBold) res = <strong>{res}</strong>;
  return (
    <TableCell>{isDivider ? '' : res}</TableCell>
  );
}

export const content = (currencies: any[], openedRows: any) => {
  const curr = currencies.reduce((acc, item) => {
    acc[item.code] = (t: any) => getCell(t[item.code], t);
    return acc;
  }, {});
  return {
    ...curr,
    title: (t: any) => {
      const isLevel = t.title === 'LIABILITIES' || t.toUpper;
      const text = t.hasHideBlock && !isLevel ? t.title[0] + t.title.substring(1)
        .toLowerCase()
        .replace('_', ' ') : t.title;
      return (
        <TableCell style={{ minWidth: '200px' }}>
          {t.hasHideBlock && (
            <IconButton className={isLevel ? 'level' : ''} style={{ color: '#ADB3B8' }}>
              {openedRows.includes(t.title) ? <MaximizeIcon /> : <MinimizeIcon />}
            </IconButton>
          )}
          {t.isBold ? <strong>{text}</strong> : text}
        </TableCell>
      );
    }
  };
};


export function Cell(props: any) {
  const { children = 0, isDivider, hasPercent, precision } = props;
  let res = typeof children === 'string' ? children
    : <>{children === 0 ? 0
      : (
        <CurrencyFormat precision={hasPercent ? 2 : precision || 8}>{children}</CurrencyFormat>
      )}{hasPercent && ' %'}</>;
  return <TableCell style={{ width: '15%' }}>{isDivider ? '' : res}</TableCell>;
}
