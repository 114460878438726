import React from 'react';
import TableFilter from '../../../components/Filter';

export interface IBlackWhiteFilter {
  dateFrom: number | null;
  dateTo: number | null;
}

interface IProps {
  filter: IBlackWhiteFilter,
  onChange: any,
}

export default function BlackWhiteFilter({ filter, onChange }: IProps) {
  return <TableFilter onApply={onChange} filter={filter} />;
}
