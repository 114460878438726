import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { Checkbox } from 'final-form-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {SwitchBaseProps} from "@material-ui/core/internal/SwitchBase";

interface ICBoxProps {
  label?: any;
  className?: string;
  wrapperClassName?: string
}

const CBox: FC<ICBoxProps> = ({ label, className, wrapperClassName, ...rest }) => (
  <div className={wrapperClassName}>
    <FormControlLabel
      className={className}
      control={<Checkbox {...rest} />}
      label={label}
      style={{ textTransform: 'capitalize' }}
    />
  </div>
);

interface ICheckboxProps extends Partial<SwitchBaseProps>{
  label?: any;
  name: string;
  validate?: any;
  className?: any;
  disabled?: boolean;
}

const CheckboxField = (props: ICheckboxProps & ICBoxProps) => (
  <Field
    {...props}
    // @ts-ignore
    component={CBox}
    type="checkbox"
    validate={props.validate}
  />
);

export default CheckboxField;