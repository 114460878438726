import React from 'react';
import Typography from '@material-ui/core/Typography';
import DetailsPopup from '../../../components/Layout/DetailsPopup';
import TabsPanel from '../../../components/Tabs';
import CurrencyOverview from './Overview';
import CurrencyFees from './Fees';
import { ICurrency } from '../../../api/currency';

interface IProps {
  currency: ICurrency;
  id: string;
  onClose: any;
  loading: boolean;
  onCreate: any;
}

export default function CurrencyDetails({ currency, onClose, loading, id, onCreate }: IProps) {
  const tabs = [
    {
      title: 'Overview',
      content: <CurrencyOverview currency={currency} id={id} onCreate={onCreate} />
    },
    {
      title: 'Fees',
      content: <CurrencyFees currency={currency} />
    }
  ];

  return (
    <DetailsPopup loading={loading} onClose={onClose}>
      {currency
        ? (
          <>
            <Typography variant="h6">{currency.currency || 'Create currency'}</Typography>
            <TabsPanel tabs={tabs} />
          </>
        ) : <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  );
}
