import {RollingStatus} from '../api/rolling';

export function getRollingStatusType(status: RollingStatus) {
  switch (status) {
    case RollingStatus.LOCKED:
      return 'warning';
    case RollingStatus.UNLOCKED:
      return 'primary';
    case RollingStatus.PAYOUT:
      return 'success';
    default:
      return;
  }
}
