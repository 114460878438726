import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Form } from 'react-final-form';
import { Input, ProtectionInput } from '../../../../components/Form';
import Loader from '../../../../components/Loader';
import { useDeposit, useWithdrawal } from '../../../../api/customers';
import useFormStyles from '../../../../hooks/useFormStyles';
import { required, maxValue, mustBeNumber, composeValidators } from '../../../../validators';

interface IProps {
  customerId: any;
  type: 'deposit' | 'withdrawal';
  currency: string;
  max?: number;
  onSuccess: any;
  cancel: any;
}

const useStyles = makeStyles((theme)=> ({
  root: {
    width: 600,
    [theme.breakpoints.down(700)]: {
      width: '100%',
    },
  }
}));

export default function FinanceForm({ customerId, type, currency, max, onSuccess, cancel }: IProps) {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const action = type === 'deposit' ? useDeposit : useWithdrawal;
  const [send, sending] = action((res: any) => {
    if (res.success) {
      onSuccess(res.data);
    }
  })

  return (
    <Dialog open>
      <Loader loading={sending} width={550}>
        <Form
          onSubmit={(values) => send(values)}
          initialValues={{
            id: customerId,
            currency,
            amount: '',
            comment: '',
            '2fa': ''
          }}
          render={({ handleSubmit }) => (
            <form className={classes.root} onSubmit={handleSubmit}>
              <DialogTitle>Customer {customerId} {type}</DialogTitle>
              <DialogContent>
                <div className={formClasses.row}>
                  <Input
                    name="amount"
                    label="Amount"
                    validate={composeValidators(required, mustBeNumber, type === 'withdrawal' ? (v: any) => maxValue(v, max || 0) : () => {})}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{currency}</InputAdornment>
                    }}
                  />
                </div>
                <div className={formClasses.row}>
                  <Input
                    name="comment"
                    type="text"
                    label="Comment"
                    validate={required}
                    fullWidth
                    multiline
                  />
                </div>
                <div className={formClasses.row}>
                  <ProtectionInput />
                </div>
                <Input name="id" type="hidden" />
                <Input name="currency" type="hidden" />
              </DialogContent>
              <DialogActions>
                <Button
                  type="submit"
                  variant="contained"
                >
                  Make {type}
                </Button>
                <Button
                  onClick={cancel}
                  variant="text"
                >
                  Cancel
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Loader>
    </Dialog>
  );
}
