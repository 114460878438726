import React from 'react';

import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateView from '../../components/DateView';
import BoolLabel from '../../components/Label/BoolLabel';
import ColHead from '../../components/UI/ColHead';
import { ICurrency } from '../../api/currency';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { IColumn } from '../../hooks/useColumns';
import { TSortDirection, TSortField } from './index';
import useMobileStyles from '../../hooks/useMobileStyles';
import Tablecell from '../../components/TableCell/TableCell';
import { useBreakpoints } from '../../hooks/useBreakpoints ';

interface IProps {
  items: ICurrency[];
  columns: IColumn[];
  sortBy?: TSortField;
  sortDirection: TSortDirection;
  onRowClick: any;
}

const content: { [key: string]: (item: ICurrency, key: number) => void } = {
  currency: (i, k) => <Tablecell key={k} title="Currency">{i.currency}</Tablecell>,
  type: (i, k) => <Tablecell key={k} title="Type">{i.type}</Tablecell>,
  enabled: (i, k) => <Tablecell key={k} title="Enabled"><BoolLabel value={i.enabled} /></Tablecell>,
  symbol: (i, k) => <Tablecell key={k} title="Symbol">{i.symbol}</Tablecell>,
  chain: (i, k) => <Tablecell key={k} title="Chain">{i.chain}</Tablecell>,
  depositMin: (i, k) => <Tablecell key={k} title="Deposit Min">{i.depositMin}</Tablecell>,
  depositMax: (i, k) => <Tablecell key={k} title="Deposit Max">{i.depositMax}</Tablecell>,
  withdrawalMin: (i, k) => <Tablecell key={k} title="Withdrawal Min">{i.withdrawalMin}</Tablecell>,
  withdrawalMax: (i, k) => <Tablecell key={k} title="Withdrawal Max">{i.withdrawalMax}</Tablecell>,
  precision: (i, k) => <Tablecell key={k} title="Precision">{i.precision}</Tablecell>,
  createdAt: (i, k) => <Tablecell key={k} title="Created at"><DateView value={i.createdAt} /></Tablecell>,
  updatedAt: (i, k) => <Tablecell key={k} title="Updated at"><DateView value={i.updatedAt} /></Tablecell>,
  provider: (i, k) => <Tablecell key={k} title="Provider name">{i.provider}</Tablecell>,
  transaction: (i, k) => <Tablecell key={k} title="Transaction type">{i.transaction}</Tablecell>,
  transactionStatus: (i, k) => <Tablecell key={k} title="Transaction status">{i.transactionStatus}</Tablecell>,
  udKey: (i, k) => <Tablecell key={k} title="Unstoppable domain key">{i.udKey}</Tablecell>
}

export default function CurrenciesList({ items, onRowClick, columns, sortBy, sortDirection }: IProps) {
  const classes = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  const isSm = useBreakpoints('sm');
  return (
    <Table size="small">
      <TableHead>
        <TableRow className={clsx(mobileClasses.colHead, isSm && mobileClasses.lastChild)}>
          {!isSm && columns.map((c) => (
            <ColHead
              key={c.id}
              title={c.name}
              onSort={c.sortFunc ? () => c.sortFunc(c.id, sortDirection, sortBy) : undefined}
              isSorted={sortBy === c.id}
              sortDirection={sortDirection}
            />
          ))}
          {isSm &&
          columns.filter(column => column.sortFunc).map((c) => (
            <ColHead
              key={c.id}
              title={c.name}
              onSort={c.sortFunc ? () => c.sortFunc(c.id, sortDirection, sortBy) : undefined}
              isSorted={sortBy === c.id}
              sortDirection={sortDirection}
            />
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow key={i.currency} className={clsx(classes.pointer, mobileClasses.row, mobileClasses.lastChild)} 
            onClick={() => onRowClick(i.currency)} hover>
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
