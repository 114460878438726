import Typography from "@material-ui/core/Typography";
import DetailsPopup from "../../../../components/Layout/DetailsPopup";
import React from "react";
import {IOlyInvoice} from "../../../../api/oly";
import TabsPanel from "../../../../components/Tabs";
import {OlyInvoiceSendForm} from "./OlyInvoiceForm";

interface IProps {
  id: string;
  data : IOlyInvoice;
  onClose: any;
  loading: boolean;
  onSend: any;
}

export default function OlyInvoiceDetails({ data, onClose, loading, id, onSend }: IProps){
  const tabs = [
    {
      title: 'Overview',
      content: <OlyInvoiceSendForm data={data} onSend={onSend} />
    }
  ];

  return (
    <DetailsPopup loading={loading} onClose={onClose}>
      {id === 'new' || data
        ? (
          <>
            <Typography variant="h6">{id === 'new' ? 'Send new invoice' : ('Oly invoice ' + data.id)}</Typography>
            <TabsPanel tabs={tabs} />
          </>
        ) : <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  )
}