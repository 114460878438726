import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { Input } from './index';
import { lengthRequired, required, composeValidators } from '../../validators';

interface IProps {
  className?: any;
  margin?: 'none' | 'dense' | 'normal';
  disabled?: boolean;
}

export default function ProtectionField({ className, margin, disabled }: IProps) {
  const { user } = useSelector((state: any) => state.common);
  if (user.googleAuthEnabled) {
    return (
      <Input
        name="2fa"
        label="2FA code"
        validate={composeValidators(required, (v: any) => lengthRequired(v, 6))}
        className={className}
        margin={margin}
        disabled={disabled}
      />
    );
  }
  return (
    <Link component={NavLink} to="/profile/settings">
      Enable 2FA protection
    </Link>
  );
}
