import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { DataRow } from '../../../components/DataView';
import BoolLabel from '../../../components/Label/BoolLabel';
import Label from '../../../components/Label';
import Loader from '../../../components/Loader';
import DateView from '../../../components/DateView';
import EmptyList from '../../../components/EmptyList';
import Descriptor from '../../../components/Descriptor';
import { IPayment, IPaymentOperation } from '../../../api/payment';
import PaymentStatusLabel from '../../../components/Label/PaymentStatusLabel';
import ACLFastAdd, { ACLData } from '../../ACL/BlakWhite/ACLFastAdd';
import { ACLType } from '../../../api/acl';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import { useSeonChecks } from '../../../api/order';
import Ellipsis from '../../../components/UI/Ellipsis';
import AntifraudDialog from './AntifraudDialog';
import {NavLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import useMobileStyles from '../../../hooks/useMobileStyles';
import Tablecell from '../../../components/TableCell/TableCell';

const useStyles = makeStyles((theme) => ({
  root: {},
  payment: {
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderBottom: `2px dashed ${theme.palette.divider}`,
    '&:last-child': {
      paddingBottom: 0,
      marginBottom: 0,
      borderBottom: 'none',
    }
  },
  request: {
    wordBreak: 'break-all'
  },
  gap: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      gap: '10px',
      alignItems: 'flex-start',
    },
  },
}));

interface IProps {
  payments?: IPayment[];
  loading: boolean;
  error?: any;
  orderId?: number;
}

export default function OrderPayment({ error, payments, loading, orderId }: IProps) {
  const [antiFraudDialogIsOpenedWithData, setOpenAntiFraudDialogWith] = useState(null);
  const globalClasses = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  const classes = useStyles();
  const [acl, setAcl] = useState<ACLData | undefined>();

  const [{ items: seon }, loadingSeon, loadSeon] = useSeonChecks();
  
  useEffect(() => {
    if (!seon[0]) {
      loadSeon({ orderId });
    }
  }, [orderId])

  if (!payments || !payments.length) return <EmptyList loading={loading} error={error} text="No data to display" width={500} />;

  function getCard(operations: IPaymentOperation[]) {
    const withCard = operations.find((o) => o.transactionInfo?.cardNumber);
    return withCard?.transactionInfo?.cardNumber;
  }

  function getError(operations: IPaymentOperation[]) {
    const failedOperation = operations.find((o) => !o.success);
    return failedOperation?.error || '';
  }

  const openAntiFraudDialogWithData = (data: any) => {
    setOpenAntiFraudDialogWith(data);
  }

  const onCloseAntiFraudDialog = () => {
    setOpenAntiFraudDialogWith(null);
  }

  const getRowDescriptorTitle = (o: any) => {
    let result = '';
    if (o.type) {
      result = o.type;
    } else if (o.provider && o.provider.toUpperCase() === 'SCORECHAIN') {
      result = `${o.provider} ${o.copy ? '(last check)' : ''}`;
    } else if (o.provider) {
      result = o.provider;
    }
    return result;
  }

  return (
    <Loader loading={loading}>
      <AntifraudDialog 
        open={!!antiFraudDialogIsOpenedWithData} 
        onClose={onCloseAntiFraudDialog}
        //@ts-ignore
        typeName={antiFraudDialogIsOpenedWithData ? antiFraudDialogIsOpenedWithData.typeName : ''} 
        //@ts-ignore
        data={antiFraudDialogIsOpenedWithData ? antiFraudDialogIsOpenedWithData.data : {}}
      />
      {acl && <ACLFastAdd onClose={() => setAcl(undefined)} value={acl.value} type={acl.type} white={acl.white} />}
      {payments.map((payment) => (
        <div key={payment.id} className={classes.payment}>
          <DataRow label="Payment ID">{payment.id}</DataRow>
          <DataRow label="Status">
            <PaymentStatusLabel status={payment.status} />
          </DataRow>
          <DataRow label="Amount">{payment.amount} {payment.currency}</DataRow>
          <DataRow label="Provider">{payment.provider}</DataRow>
          <DataRow label="Type">{payment.type}</DataRow>
          <DataRow label="Source">{payment.source}</DataRow>
          <DataRow label="Payment Method">
            {payment?.apm?.id}
          </DataRow>
          <DataRow label="Finished">
            <Label label={payment.finished ? 'YES' : 'NO'} type={payment.finished ? 'success' : 'danger'} size='small' />
          </DataRow>
          {payment.status === 'ERROR' && payment.error && (
            <DataRow label="Error code" copy={payment.error}>
              <Tooltip title={payment.error} placement="top-end">
                <span className={globalClasses.tooltiped}>{payment.error?.substring(0, 40)}...</span>
              </Tooltip>
            </DataRow>
          )}
          {getError(payment.operations).length > 0 && (
            <DataRow label="Error msg" copy={getError(payment.operations)}>
              <Tooltip title={getError(payment.operations)} placement="top-end">
                <span className={globalClasses.tooltiped}>{getError(payment.operations)?.substring(0, 40)}...</span>
              </Tooltip>
            </DataRow>
          )}
          {getCard(payment.operations) && (
            <DataRow label="Card">
              <div className={classes.gap}>
                {getCard(payment.operations)}
                <Button
                  variant="contained"
                  color="secondary"
                  // @ts-ignore
                  onClick={() => setAcl({ type: ACLType.CARD_PAN, value: getCard(payment.operations) })}
                  size="small"
                >
                  Blacklist
                </Button>
                <Button
                  variant="contained"
                  // @ts-ignore
                  onClick={() => setAcl({ type: ACLType.CARD_PAN, value: getCard(payment.operations), white: true })}
                  size="small"
                >
                  Whitelist
                </Button>
              </div>
            </DataRow>
          )}
          <DataRow label="Created at"><DateView value={payment.createdAt} /></DataRow>
          <DataRow label="Updated at"><DateView value={payment.updatedAt} /></DataRow>
          <Typography variant="subtitle1">Operations</Typography>
          <Table size="small">
            <TableHead className={mobileClasses.tableHead}>
              <TableRow>
                <TableCell>Ext. ID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Request</TableCell>
                <TableCell>Response</TableCell>
                <TableCell>Success</TableCell>
                <TableCell>Created at</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payment.operations?.concat(seon).sort((a, b) => b.createdAt - a.createdAt).map((o, i) => (
                <TableRow key={i} className={mobileClasses.row}>
                  <Tablecell title="Ext. ID">{o.externalId}</Tablecell>
                  <Tablecell title="Type">
                    <Descriptor group="paymentOperationTypes" 
                      title={getRowDescriptorTitle(o)}>
                        {getRowDescriptorTitle(o)}
                    </Descriptor>
                  </Tablecell>
                  <Tablecell title="Request">
                    <div className={classes.request}>
                      <Ellipsis onClick={() => openAntiFraudDialogWithData({typeName:'request', data: o.request})} text={typeof (o.request) === 'object' ? JSON.stringify(o.request).toString() : o.request} />
                    </div>
                  </Tablecell>
                  <Tablecell title="Response">
                    <div className={classes.request}>
                      <Ellipsis onClick={() => openAntiFraudDialogWithData({typeName:'response', data: o.response})} text={typeof (o.response) === 'object' ? JSON.stringify(o.response).toString() : o.response} />
                    </div>
                  </Tablecell>
                  <Tablecell title="Success">
                    <BoolLabel value={o.success} />
                  </Tablecell>
                  <Tablecell title="Created at" isLast>
                    <DateView value={o.createdAt} />
                  </Tablecell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ))}
    </Loader>
  );
}