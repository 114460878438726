import React from 'react';
import Label from './index';
import Descriptor from '../Descriptor';
import { getOrderStatusType } from '../../helpers/orderHelper';
import { ClearingStatus } from '../../api/clearing';

interface IProps {
  status: ClearingStatus;
}

export default function OrderStatusLabel({ status }: IProps) {
  return (
    <Descriptor group="clearingStatus" title={status}>
      <Label label={status} type={getOrderStatusType(status)} size="small" />
    </Descriptor>
  );
}
