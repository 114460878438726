import React from 'react';

import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DateView from '../../components/DateView';
import { IKYCLimitsItem } from '../../api/kyc';
import { IColumn } from '../../hooks/useColumns';
import Tablecell from '../../components/TableCell/TableCell';
import useMobileStyles from '../../hooks/useMobileStyles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  row: {
    cursor: 'pointer'
  },
  nowrap: {
    whiteSpace: 'nowrap'
  }
}));

interface IKycLimitsListProps {
  items: IKYCLimitsItem[];
  columns: IColumn[];
  onRowClick: any;
}

const sortColumn = (items: IKYCLimitsItem[], columnName: string = 'id') => {
  const sorted = items.sort((a, b) => {
    //@ts-ignore
    if (a[columnName] < b[columnName])
      return -1
      //@ts-ignore
    if (a[columnName] > b[columnName])
      return 1
    return 0 // 
  })
  return sorted;
}

export default function KycLimitsList({ items, onRowClick, columns }: IKycLimitsListProps) {
  const classes = useStyles();
  const mobileClasses = useMobileStyles();

  const content: { [key: string]: (item: IKYCLimitsItem, key: number) => void } = {
    id: (i, k) => <TableCell className={mobileClasses.id} key={k}>{i.id}</TableCell>,
    amountFrom: (i, k) => <Tablecell key={k} title="Amount From">{(!!i.amountFrom || i.amountFrom === 0) ? i.amountFrom : '-'}</Tablecell>,
    amountTo: (i, k) => <Tablecell key={k} title="Amount To">{(!!i.amountTo || i.amountTo === 0 ) ? i.amountTo : '-' }</Tablecell>,
    createdAt: (i, k) => <Tablecell key={k} title="Created At">{i.createdAt ? <DateView value={i.createdAt} /> : '-'}</Tablecell>,
    currentLevel: (i, k) => <Tablecell key={k} title="Current Level">{(!!i.currentLevel || i.newLevel === 0 ) ? i.currentLevel : '-'}</Tablecell>,
    flow: (i, k) => <Tablecell key={k} title="Flow">{i.flow}</Tablecell>,
    newLevel: (i, k) => <Tablecell key={k} title="New Level">{(!!i.newLevel || i.newLevel === 0) ? i.newLevel : '-'}</Tablecell>,
    legalEntity: (i, k) => <Tablecell key={k} title="Legal Entity">{i.legalEntity}</Tablecell>,
  }

  return (
    <Table className={classes.root}>
      <TableHead className={mobileClasses.tableHead}>
        <TableRow>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortColumn(items, 'amountFrom').map((i) => (
          <TableRow key={i.id} className={clsx(classes.row, mobileClasses.row)} onClick={() => onRowClick(i.id)} hover>
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}