import React from 'react';

import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateView from '../../components/DateView';
import BoolLabel from '../../components/Label/BoolLabel';
import { IAdmin } from '../../api/admin';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { IColumn } from '../../hooks/useColumns';
import TableCellId from '../../components/TableCellId/TableCellId';
import Tablecell from '../../components/TableCell/TableCell';
import useMobileStyles from '../../hooks/useMobileStyles';

interface IProps {
  items: IAdmin[];
  columns: IColumn[];
  onRowClick: any;
}

const content: { [key: string]: (i: IAdmin, key: number) => void } = {
  id: (i, key) => <TableCellId key={key}>{i.id}</TableCellId>,
  email: (i, key) => <Tablecell key={key} title="Email">{i.email}</Tablecell>,
  userName: (i, key) => <Tablecell key={key} title="User name">{i.username}</Tablecell>,
  name: (i, key) => <Tablecell key={key} title="Name">{i.firstName} {i.lastName}</Tablecell>,
  position: (i, key) => <Tablecell key={key} title="Position">{i.position}</Tablecell>,
  active: (i, key) => <Tablecell key={key} title="Active"><BoolLabel value={i.active} /></Tablecell>,
  gAuth: (i, key) => <Tablecell key={key} title="2fA enabled"><BoolLabel value={i.googleAuthEnabled} /></Tablecell>,
  created: (i, key) => <Tablecell key={key} title="Created at"><DateView value={i.createdAt} /></Tablecell>,
  updated: (i, key) => <Tablecell key={key} title="Updated at" isLast><DateView value={i.updatedAt} /></Tablecell>,
};

export default function AdminsList({ items, onRowClick, columns }: IProps) {
  const classes = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  
  return (
    <Table size="small">
      <TableHead className={mobileClasses.tableHead}>
        <TableRow>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow key={i.id} className={clsx(classes.pointer, mobileClasses.row)} onClick={() => onRowClick(i.id)} hover>
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}