import React from 'react';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import { Input, BoolSelect, CurrencySelect } from '../../components/Form';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';

export interface ICountriesFilter {
  code: string | null;
  supported: boolean | null;
  highRisk: boolean | null;
  riskScore: string | null;
  scoreMin: string | null;
  scoreMax: string | null;
  EU: boolean | null;
  enableOccasionalTransactions: boolean | null;
  excludedCurrencies: string[] | null;
}

interface IProps {
  filter: ICountriesFilter;
  onChange: any;
}

export default function KYCCountriesFilter({ filter, onChange }: IProps) {
  const classes = useGlobalStyles();
  return (
    <Form
      onSubmit={onChange}
      initialValues={filter}
      render={({ handleSubmit }) => (
        <form className={classes.filters} onSubmit={handleSubmit}>
          <Input
            name="code"
            label="Code"
            margin="none"
          />
          <Input
            name="riskScore"
            label="Risk score"
            margin="none"
          />
          <Input
            name="scoreMin"
            label="Min KYC score"
            margin="none"
          />
          <Input
            name="scoreMax"
            label="Max KYC score"
            margin="none"
          />
          <BoolSelect
            name="EU"
            label="Europe"
          />
          <BoolSelect
            name="supported"
            label="Supported"
          />
          <BoolSelect
            name="highRisk"
            label="High risk"
          />
          <BoolSelect
            name="enableOccasionalTransactions"
            label="Occasional transactions"
          />
          <CurrencySelect name="excludedCurrencies" label="Excluded currencies" multi />
          <Button type="submit" variant="contained" size="large" className="apply">Apply</Button>
        </form>
      )}
    />
  );
}
