import React from 'react';

import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BoolLabel from '../../components/Label/BoolLabel';
import { IApm } from '../../api/apm';
import { IColumn } from '../../hooks/useColumns';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import useMobileStyles from '../../hooks/useMobileStyles';
import TableCellId from '../../components/TableCellId/TableCellId';
import Tablecell from '../../components/TableCell/TableCell';

const useStyles = makeStyles((theme) => ({
  root: {},
  store: {
    width: '15px',
    height: '24px',
    marginRight: theme.spacing(1),
    '& svg': {
      width: '15px'
    }
  },
}));

interface IApmListProps {
  items: IApm[];
  columns: IColumn[];
  onRowClick: any;
}

export default function ApmList({ items, onRowClick, columns }: IApmListProps) {
  const classes = useStyles();
  const mobileClasses = useMobileStyles();

  const content: { [key: string]: (item: IApm, key: number) => void } = {
    id: (i, k) => (<TableCellId key={k}>{i.id}</TableCellId>),
    name: (i, k) => (<Tablecell key={k} title="Name">{i.name}</Tablecell>),
    externalId: (i, k) => (<Tablecell title="External Id" key={k}>{i.externalId}</Tablecell>),
    provider: (i, k) => <Tablecell title="Provider" key={k}>
      {i.provider === 'SAFECHARGE'
      ? <Link component={NavLink} onClick={(e:any) => e.stopPropagation()} to='/payment-providers/provider/SAFECHARGE/#apm'>{i.provider}</Link>
      : i.provider
    }
    </Tablecell>,
    flow: (i, k) => <Tablecell title="Url open flow" key={k}>{i.flow}</Tablecell>,
    enabled: (i, k) => <Tablecell title="Enabled" key={k}>
      <BoolLabel value={i.enabled} size='small' /></Tablecell>,
    deposit: (i, k) => <Tablecell title="Deposit" key={k}>{i.fee.deposit}</Tablecell>,
    depositFixed: (i, k) => <Tablecell title="Deposit Fixed" key={k}>
     {i.fee.depositFixed}</Tablecell>,
    fixedCurrency: (i, k) => <Tablecell title="Fixed Fee Currency" key={k}>
      {i.fee.fixedCurrency}</Tablecell>,
    depositFeeMin: (i, k) => <Tablecell title="Deposit Fee Min" key={k}>
      {i.fee.depositFeeMin}</Tablecell>
  }

  return (
    <Table size="small">
      <TableHead className={mobileClasses.tableHead}>
        <TableRow>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow key={i.id} className={clsx(mobileClasses.row, mobileClasses.lastChild)} onClick={() => onRowClick(i.id)} hover>
            {columns.map((c, index) => content[c.id] && content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}