import Api, { useFetchApi, useUpdateApi } from './index';

export enum AccountType {
  MERCHANT = 'MERCHANT',
  BANK = 'BANK',
  REFEX = 'REFEX',
  CRYPTO_WALLET = 'CRYPTO_WALLET',
  CASH = 'CASH',
  INTERNAL = 'INTERNAL',
  EXCHANGE = 'EXCHANGE'
}

export enum AccountName {
  HOT = 'HOT',
  FOURBILL = 'FOURBILL',
  PAYTURE = 'PAYTURE',
  CONNECTUM = 'CONNECTUM'
}

export interface IBookkeeperBalanceItem {
  id?: number | string;
  createdAt?: number;
  type?: AccountType;
  account?: AccountName;
  subAccount?: string | number;
  currency?: string;
  balance: number;
  manual: boolean;
  extra?: any;
  timestamp?: number;
}

export function useBookkeeperBalanceList() {
  return useFetchApi(
    async (query: any) => Api.post('/bookkeeper/balances/list', { filter: {}, limit: 50, ...query }),
    { items: [] }
  )
}

export function useBookkeeperBalanceAdd(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/bookkeeper/balance/add', data),
    cb,
    'Balance entry successfully added'
  )
}

export function useBookkeeperBalanceUpdate(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/bookkeeper/balance/update', data),
    cb,
    'Balance entry successfully updated'
  )
}

export function useBookkeeperReport() {
  return useFetchApi(
    async (query: any) => Api.post('bookkeeper/report', query),
    { data: {} }
  )
}
