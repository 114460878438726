import { SettlementStatus } from '../api/settlements';

export function getSettlementStatusType(status: SettlementStatus) {
   switch (status) {
     case SettlementStatus.SETTLED:
       return 'success';
     case SettlementStatus.REQUESTED:
       return 'warning';
     case SettlementStatus.REJECTED:
       return 'danger';
     default:
       return;
   }
 }