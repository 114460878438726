import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  }
}));

interface IUploadFieldProps {
  onLoad: any;
  input: any;
  className?: any;
  buttonTitle?: string;
  dropZoneTitle?: string;
}

export default function UploadDocumentsField({ onLoad, input, className, buttonTitle, dropZoneTitle }: IUploadFieldProps) {
  const onDrop = useCallback((files: File[]) => {
    files.forEach((f) => {
      const reader = new FileReader();
      reader.onload = () => {
        onLoad({
          ...f,
          name: f.name,
          type: f.type,
          data: reader.result
        })
      };
      reader.readAsDataURL(f);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...getRootProps()}>
      <input {...getInputProps()} {...input} />
      {dropZoneTitle && <p>{dropZoneTitle}</p> }
      <Button variant="text" color="primary">{buttonTitle || 'Select file to import'}</Button>
    </div>
  );
}
