import React, { useEffect, useRef, useState } from 'react';

import { useHistory, useParams } from 'react-router';

import { Waypoint } from 'react-waypoint';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Paper, Button, CardActions } from '@material-ui/core';

import EmptyList from '../../components/EmptyList';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import useColumns, { IColumn } from '../../hooks/useColumns';
import Loader from '../../components/Loader';
import useFilter from '../../hooks/useFilter';
import { useSettlementsList } from '../../api/settlements';
import SettlementsList from './SettlementsList';
import { offset, todayEnd, todayStart } from '../../helpers/datesHelper';
import SettlementsFilter from './SettlementsFilter';
import FilterWrapper, { ICollapseHandle } from '../../components/FilterWrapper/FilterWrapper';
import SettlementDetails from './Details/SettlementDetails';


const useStyles = makeStyles((theme) => ({
  root: {},
  table:{ 
   width: '100%',
   minWidth: 'fit-content' 
  },
  addButton: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: 13
  },
  card: {
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(850)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '15px',
    },
  }, }));

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'Request ID', active: true },
  { id: 'status', name: 'Status', active: true },
  { id: 'customerId', name: 'Merchant ID', active: true },
  { id: 'reqAmount', name: 'Req. Amount', active: true },
  { id: 'settlementAmount', name: 'Settlement amount', active: true },
  { id: 'created', name: 'Created at', active: true },
  { id: 'updated', name: 'Updated at', active: true },
];

const emptyFilter = () => ({
  dateTo: todayEnd().valueOf() - offset,
  dateFrom: todayStart().valueOf() - offset,
  pickerViewDateTo: todayEnd().valueOf(),
  pickerViewDateFrom: todayStart().valueOf(),
  customerId: null,
  id: null,
  status: null,
});

export default function SettlementsPage(){
  const { settlementId } = useParams<any>();
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const history = useHistory();
  const [{ items, cursor, full, error }, loading, load] = useSettlementsList();
  const [columns, ColumnsFilter] = useColumns('settlements', defaultColumns);
  const accordionRef = useRef<ICollapseHandle>(null);
  const [isIgnoreDateFilter, setIsIgnoreDateFilter] = useState(false);

  function checkIgnoringFilterDate(filter: Record<string, any>) {
    if (isIgnoreDateFilter){
     return { ...filter, dateFrom: null, dateTo: null };
    } 

    return filter;
  }

  const [setFilter, filter] = useFilter('settlements', emptyFilter(), false);

  useEffect(() => {
    accordionRef.current?.collapse();
    load({
      filter: checkIgnoringFilterDate(filter),
      cursor: 0,
    });
  }, [filter]);

  function setDetails(id?: string) {
    history.push(`/settlements${id ? `/${id}` : ''}`);
  }

  return(
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <SettlementsFilter 
          setIsIgnoreDateFilter={setIsIgnoreDateFilter}
          isIgnoreDateFilter={isIgnoreDateFilter}
          filter={filter} 
          onChange={setFilter} 
        />
      </FilterWrapper>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={globalClasses.paper}>
          <CardActions className={classes.card}>
            <Button variant="contained" className={classes.addButton} onClick={() => setDetails('new')}>Create Settlement</Button>
            <ColumnsFilter />
          </CardActions>
          {items.length > 0
            ? <SettlementsList items={items} onRowClick={setDetails} columns={columns} />
            : <EmptyList error={error} loading={loading} />}
          <Loader loading={loading} />
          {!loading && !error && !full && items.length > 0 && 
            <Waypoint onEnter={() => load({ cursor: items.length, filter: checkIgnoringFilterDate(filter) })} 
          />}
        </Paper>
      </Grid>
     {settlementId && <SettlementDetails id={settlementId} updateList={load} onClose={()=> history.push('/settlements')} />}
    </Grid>
  );
}