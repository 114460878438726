import React from 'react';
import TableFilter from '../../components/Filter';
import { Select, Input } from '../../components/Form';
import { ClearingStatus, LiquidityProvider } from '../../api/clearing';
import { composeValidators, maxLength, mustBeNumber } from '../../validators';
import Descriptor from '../../components/Descriptor';

export interface IClearingFilter {
  dateFrom: Date | null;
  dateTo: Date | null;
  status: ClearingStatus | null;
  pair: string | null;
  orderId: string | null;
  externalId: string | null;
  provider: LiquidityProvider | null;
}

interface IProps {
  filter: IClearingFilter,
  onChange: any,
  onExport: any
}

export default function ClearingFilter({ filter, onChange, onExport }: IProps) {
  return (
    <TableFilter onApply={onChange} filter={filter} onExport={onExport}>
      <Select
        name="status"
        label="Status"
        options={Object.keys(ClearingStatus).map((s) => ({ value: s, label: s }))}
      />
      <Descriptor group="filters" title="clearsDate" >
        <Input
          name="orderId"
          label="Order ID"
          margin="none"
          validate={composeValidators(mustBeNumber, (v: any) => maxLength(v, 19))}
        />
      </Descriptor>
      <Descriptor group="filters" title="clearsDate" >
        <Input name="externalId" label="External ID" margin="none" />
      </Descriptor>
      <Select
        name="provider"
        label="Exchange"
        options={Object.keys(LiquidityProvider).map((s) => ({ value: s, label: s }))}
      />
    </TableFilter>
  );
}
