import Api, { useFetchApi, useUpdateApi } from './index';
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {updateCurrencies} from "../store/actions/appData";
import {currencyMapper} from "../helpers/currencyHelper";
import {BlockchainTxProvider, BlockchainTxStatus, BlockchainTxType} from "./blockchain";

export enum CurrencyType {
  FIAT = 'FIAT',
  CRYPTO = 'CRYPTO'
}

export enum AddressValidator {
  BTC = 'BTC',
  ETH = 'ETH',
  LTC = 'LTC',
  BCH = 'BCH',
  TRX = 'TRX',
  XRP = 'XRP',
  LUHN = 'LUHN',
  NEAR = 'NEAR',
  DOGE = 'DOGE',
  SOLANA = 'SOLANA',
  TERRA = 'TERRA',
  BNB = 'BNB',
  TEZOS = 'TEZOS',
  COSMOS = 'COSMOS',
  APT = 'APT',
  STELLAR = 'STELLAR',
  TON = 'TON'
}

export interface ICurrency {
  id: string;
  symbol: string;
  chain?: string;
  createdAt?: number;
  updatedAt?: number;
  currency: string;
  type: CurrencyType;
  enabled: boolean;
  withdrawalMax: number;
  withdrawalMin: number;
  depositMin: number;
  depositMax: number;
  addressValidator?: AddressValidator;
  precision?: number;
  display: string;
  caption?: string;
  explorerTx?: string;
  explorerAddr?: string;
  defExplorerAddr?: string;
  defExplorerTx?: string;
  allowTag?: boolean;
  allowInvoices?: boolean;
  udKey?: string;
  provider?: BlockchainTxProvider;
  transaction?: BlockchainTxType;
  transactionStatus?: BlockchainTxStatus;
}

export function useCurrencyChainsList() {
  return useFetchApi(
    async (query: any) => Api.post('/currency/chains', { ...query }),
    { items: [] }
  )
}

export function useCurrenciesList() {
  const dispatch = useDispatch();

  const fetchCurrenciesApi = useFetchApi(
    async (query: any) => Api.post('/currency/list', { ...query }),
    { items: [] }
  )

  const [{ items }, loading] = fetchCurrenciesApi

  useEffect(() => {
    dispatch(updateCurrencies({
      data: items,
      loading,
      map: currencyMapper(items)
    }))
  }, [items, loading])

  return fetchCurrenciesApi
}

export function useCurrencySave(cb: any, successMessage: string) {
  return useUpdateApi(
    async (data: any) => Api.post(`/currency/${data.createdAt ? 'update' : 'create'}`, data),
    cb,
    successMessage
  )
}

export function useCurrencyFeesList() {
  return useFetchApi(
    async (currency: string) => Api.post('/customer/fee/list', { cursor: 0, filter: { currency } }),
    { items: [] }
  )
}

export function useDeleteFee(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/customer/fee/delete', data),
    cb,
    'Fee set successfully deleted'
  )
}
