import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DataRow } from '../../../components/DataView';
import AntifrodDialog from './AntifraudDialog';
import { capitalize } from '../../../helpers/stringHelper';
import Link from '@material-ui/core/Link';

interface IAntifrodProps {
  data: any;
}

const defaultStyle = {cursor: 'pointer'};

interface IScoreRow {
  score?: string
  severity?: string
  label: string
}

function ScoreRow({ score, severity, label }:IScoreRow){
  return (
    <>
      <span>{label}: </span>
      <span style={{ color: getColor(severity) }}>{score}</span> /
      &nbsp;
    </>
  );
}

const getScore = (data: any, openAntiFraudDialogWithData: any) => {
  switch(data.provider) {
    case 'SCORECHAIN': {
      return (
        <span style={{ ...defaultStyle }} onClick={() => openAntiFraudDialogWithData(data)}>
          <ScoreRow label="IN" severity={data.response?.incoming?.severity} score={data.response?.incoming?.score}/>
          <ScoreRow label="OUT" severity={data.response?.outgoing?.severity} score={data.response?.outgoing?.score}/>
          <span>lowestScore: {data.response?.lowestScore}</span>
        </span>
      ); 
    }
    case 'SEON': {
      const responseData = data.response ? data.response.data : null;
      let color = 'red';
      if (responseData && responseData.state === 'APPROVE') {
        color = 'green';
      }
      return (
        responseData
          ? <>
              <span style={{...defaultStyle, color}} onClick={() => openAntiFraudDialogWithData(data)}>
                {responseData?.fraud_score !== undefined ? responseData?.fraud_score : 'No data'}
              </span>
              <Link style={{marginLeft: 8}} href={`https://admin.seon.io/transactions/${responseData.seon_id}/`} target="_blank">link to Seon</Link>
            </>
          : <span>null</span>
      )
    }
    default: return <span>No data</span>; 
  } 
}

const getLabel = (data: any) => {
  let label = data.provider;
  if (data.provider === 'SCORECHAIN') {
    label += data.request.type ? ` (${data.request.type.toUpperCase()})` : '';
  } 
  return label;
}

const getColor = (severity?: string) => {
  if (severity ==='MEDIUM_RISK') {
    return 'orange';
  } 
  if (severity === 'HIGH_RISK') {
    return 'red';
  }
  return 'green';
};

const Antifrod = (props: IAntifrodProps) => {
  const {data} = props;
  const [antiFraudDialogIsOpenedWithData, setOpenAntiFraudDialogWith] = useState(null);
  const openAntiFraudDialogWithData = (data: any) => {
    setOpenAntiFraudDialogWith(data);
  }

  const onCloseScorechainDialog = () => {
    setOpenAntiFraudDialogWith(null);
  }

  return <>
    <AntifrodDialog open={!!antiFraudDialogIsOpenedWithData} onClose={onCloseScorechainDialog} data={antiFraudDialogIsOpenedWithData}/>
    {data.map((data: any, k: number) => {
      return (
        <DataRow key={k} label={capitalize(getLabel(data)) } descGroup='antifrodLabels' tooltipTitleType='node' >
          {getScore(data, openAntiFraudDialogWithData)}
        </DataRow>
      )
    })}
  </>
}

export default Antifrod;