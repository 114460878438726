import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

interface IStylesProps {
  width?: number;
}

const useStyles = makeStyles((theme) => ({
  root: ({ width }: IStylesProps) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    minWidth: width ? `${width}px` : 'calc(100vw - 320px)',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  })
}));

interface IEmptyListProps {
  error?: any;
  loading: boolean;
  text?: string;
  width?: number;
}

export default function EmptyList({ error, loading, text, width }: IEmptyListProps) {
  const classes = useStyles({ width });

  if (loading) return null;

  return (
    <div className={classes.root}>
      {error
        ? (
          <Typography variant="body1" color="secondary">
            {typeof (error) === 'string' ? error : (error.message || error.type)}
          </Typography>
        ) : (
          <Typography variant="body1" color="textPrimary">
            {text || 'There are no elements here...'}
          </Typography>
        )}
    </div>
  );
}
