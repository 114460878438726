import React from 'react';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import { Input, ProtectionInput } from '../../../components/Form';
import Loader from '../../../components/Loader';
import useFormStyles from '../../../hooks/useFormStyles';
import { required } from '../../../validators';
import { useCreateLegalInfo, ILegalInfo } from '../../../api/apiKeys';

interface IProps {
  keyId: string;
  legal?: ILegalInfo;
  onSave?: any;
  bind?: any;
  cancel?: any;
}

export default function LegalInfoForm({ keyId, legal, onSave, bind, cancel }: IProps) {
  const formClasses = useFormStyles();
  const [save, saving] = useCreateLegalInfo((res: any) => {
    if (res.success && typeof (onSave) === 'function') {
      onSave(res.data);
    }
  })
  const isBinding = typeof (bind) === 'function';

  return (
    <Loader loading={saving} width={450}>
      <Form
        onSubmit={(values) => {
          if (isBinding) {
            return bind(values['2fa'])
          }
          save({ id: keyId, ...values })
        }}
        initialValues={{ name: legal?.name, address: legal?.address, regNum: legal?.regNum, '2fa': '' }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={formClasses.root}>
            <div className={formClasses.row}>
              <Input
                name="name"
                label="Merchant name"
                validate={required}
                disabled={isBinding}
              />
            </div>
            <div className={formClasses.row}>
              <Input
                name="address"
                label="Address"
                type="search"
                autoComplete="xyz123"
                validate={required}
                disabled={isBinding}
              />
            </div>
            <div className={formClasses.row}>
              <Input
                name="regNum"
                label="Reg.no"
                type="search"
                autoComplete="xyz123"
                validate={required}
                disabled={isBinding}
              />
            </div>
            <div className={formClasses.row}>
              <ProtectionInput />
            </div>
            <div className={formClasses.actions}>
              <Button
                type="submit"
                variant="contained"
                size="large"
              >
                {isBinding ? 'Bind' : 'Save'}
              </Button>
              {cancel && (
                <Button
                  onClick={cancel}
                  size="large"
                >
                  Cancel
                </Button>
              )}
            </div>
          </form>
        )}
      />
    </Loader>
  )
}
