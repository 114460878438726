import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Waypoint } from 'react-waypoint';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Loader from '../../../components/Loader';
import EmptyList from '../../../components/EmptyList';
import BookkeeperBalanceFilter from './Filter';
import BalanceList from './List';
import BookkeepingBalanceDetails from './Details';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import useFilter from '../../../hooks/useFilter';
import { useBookkeeperBalanceList, IBookkeeperBalanceItem } from '../../../api/bookkeeper';
import useColumns, { IColumn } from '../../../hooks/useColumns';

const emptyFilter = () => ({
  types: undefined,
  accounts: undefined,
  subAccounts: undefined,
  currencies: undefined,
  manual: undefined
});

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'type', name: 'Type', active: true },
  { id: 'account', name: 'Account', active: true },
  { id: 'subAccount', name: 'Sub account', active: true },
  { id: 'balance', name: 'Balance', active: true },
  { id: 'currency', name: 'Currency', active: true },
  { id: 'manual', name: 'Is manual', active: true },
  { id: 'created', name: 'Created at', active: true }
];

export default function BookkeepingBalance() {
  const [{ items, error, full }, loading, load, updateItem] = useBookkeeperBalanceList();
  const classes = useGlobalStyles();
  const [setFilter, filter] = useFilter('bookkeeper_balance', emptyFilter());
  const history = useHistory();
  const [detailItem, setDetailItem] = useState<any | undefined>();
  const { itemId } = useParams();
  const [columns, ColumnsFilter] = useColumns('balanceReport', defaultColumns);

  const setDetails = (id?: any) => history.push(`/bookkeeping/balance${id ? `/${id}` : ''}`);

  useEffect(() => {
    if (items.length) {
      if (itemId && itemId !== 'global-settings') {
        let item: IBookkeeperBalanceItem = {
          type: undefined,
          account: undefined,
          subAccount: '',
          currency: '',
          balance: 0,
          manual: true,
          timestamp: Date.now()
        };
        if (itemId !== 'new') {
          item = items.find((i: IBookkeeperBalanceItem) => i.id === parseInt(itemId));
        }
        return setDetailItem(item);
      }
      setDetailItem(undefined);
    }
  }, [itemId, items])

  useEffect(() => {
    load({ filter });
  }, [filter])

  return (
    <Grid container spacing={3}>
      <BookkeeperBalanceFilter filter={filter} onChange={setFilter} />
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justify="space-between">
              <Grid item>
                <Button variant="contained" size="large" onClick={() => setDetails('new')}>Add new</Button>
              </Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0
            ? <BalanceList items={items} onRowClick={setDetails} columns={columns} />
            : <EmptyList loading={loading} error={error} />
          }
          {!loading && !error && !full && <Waypoint onEnter={() => load({ cursor: items.length, filter })} />}
          <Loader loading={loading} />
        </Paper>
      </Grid>
      {detailItem
      && <BookkeepingBalanceDetails item={detailItem} onClose={() => setDetails()} loading={loading} onSave={updateItem} />}
    </Grid>
  );
}
