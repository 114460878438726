import omitBy from 'lodash/omitBy';
import Api, { useFetchApi, useUpdateApi } from './index';
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {updateCountries} from "../store/actions/appData";

export enum KysStatus {
  NONE = 'NONE',
  PARTIAL = 'PARTIAL',
  NEW = 'NEW',
  STARTED = 'STARTED',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  REVIEWED = 'REVIEWED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CORRECTION_REQUESTED = 'CORRECTION_REQUESTED',
  TRUSTED = 'TRUSTED'
}

export enum KysExtReviewAnswer {
  GREEN = 'GREEN',
  RED = 'RED'
}

export enum DocumentType {
  ID_CARD_SELFIE = 'ID_CARD_SELFIE',
  ID_CARD = 'ID_CARD',
  RESIDENCE = 'RESIDENCE',
  PASSPORT = 'PASSPORT',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  ID_CARD_BACKSIDE = 'ID_CARD_BACKSIDE',
  DRIVING_LICENSE_BACKSIDE = 'DRIVING_LICENSE_BACKSIDE',
  PROOF_OF_FUND = 'PROOF_OF_FUND'
}

export interface IKYCDocument {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  type: DocumentType;
  data: string;
  document: any;
}

export interface IKYCCorrections {
  type: DocumentType;
  comment: string;
  resolved: boolean;
}

export interface INamescanPerson {
  referenceType: string;
  name: string;
  matchRate: number;
  datesOfBirth: any[] | null;
}

export interface INamescanResponse {
  date: Date;
  scanId: string;
  persons: INamescanPerson[] | null;
}

export interface IKycInternalComment {
  timestamp: number;
  comment: string;
  adminId: number;
}

export interface ISumSubCallback {
  id: number;
  createdAt: number;
  updatedAt: number;
  data: {
    applicantId: string;
    type: string;
    reviewResult?: {
      reviewAnswer: string;
      moderationComment?: string;
      clientComment?: string;
      rejectLabels?: string[];
    },
    reviewStatus?: string;
  }
}

export interface IKYCItem {
  applicantData: any;
  id: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
  residence: string;
  address: string;
  zipCode: string;
  country: string;
  city: string;
  status: KysStatus;
  createdAt: Date;
  updatedAt: Date;
  documents?: IKYCDocument[];
  customerEmail?: string;
  customerId?: string;
  corrections?: IKYCCorrections[];
  namescanResponse?: INamescanResponse;
  comments: IKycInternalComment[];
  subSumCallbacks?: ISumSubCallback[];
  flow?: string;
  externalApplicationId?: string;
  externalReviewAnswer?: string;
  legalEntity?:string
}

export interface IKYCLimitsItem {
  id: number,
  newLevel: number,
  amountFrom: number,
  amountTo: number,
  createdAt: number
  currentLevel: number,
  flow: string,
  legalEntity: string
}

export function useKycList() {
  return useFetchApi(
    async (query: any) => {
      const q: any = { filter: {} };
      Object.keys(query).forEach((k) => {
        if (k === 'filter') {
          Object.keys(query.filter).forEach((f) => {
            if(f === 'dateTo')
              q.filter['createDateTo'] = query.filter[f];
            else if(f === 'dateFrom')
              q.filter['createDateFrom'] = query.filter[f];
            else
              q.filter[f] = query.filter[f];
          })
        } else {
          q[k] = query[k];
        }
      })
      return Api.post('/kyc/list', { filter: {}, limit: 50, ...q })
    },
    { items: [] }
  );
}

export function useKycDetails() {
  return useFetchApi(
    async (id: string) => Api.post('/kyc/details', { id }),
    { data: {} }
  );
}

export function useUpdateKycStatus(cb: any, message: string) {
  return useUpdateApi(
    async (id: string, data: object) => Api.post('/kyc/status', { id, ...data }),
    cb,
    message,
    true
  );
}

export function useKycRecheckApplicant() {
  return useUpdateApi(
    async (data: object) => {
      return Api.post('/kyc/sumsub/recheck', data)
    },
    () => {},
    'KYC application sent to recheck'
  );
}

export function useKycComment(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/kyc/comment', data),
    cb
  );
}

export interface IKycCountry {
  code: string;
  name: string;
  supported: boolean;
  inHRTC: boolean;
  riskScore: number;
  kycScore: number;
  useState: boolean;
  EU: boolean;
  enableOccasionalTransactions: boolean;
  excludedCurrencies?: string[];
}

export interface IKycState {
  code: string
  country: string
  id: number
  inHRTC: boolean
  kycScore: number
  name: string
  riskScore: number
  supported: boolean
}

export function useKycCountries() {
  return useFetchApi(
    async (query: any) => {
      const filter = omitBy(query, (item: any) => item === null);
      if (Object.keys(filter).length) {
        return Api.post('/kyc/country/find', { filter })
      }
      return Api.post('/kyc/country/list', {});
    },
    { items: [] }
  );
}

export function useCountriesList() {
  const dispatch = useDispatch();

  const fetchCountriesApi = useFetchApi(
    async (query: any) => Api.post('/kyc/country/list', {}),
    { items: [] }
  )

  const [{ items }, loading] = fetchCountriesApi;

  useEffect(() => {
    dispatch(updateCountries({
      data: items,
      loading,
    }))
  }, [items, loading])

  return fetchCountriesApi
}

export function useKycCountriesImport(cb: any) {
  return useUpdateApi(
    async (file: any) => {
      const fd = new FormData();
      fd.append('file', file);
      return Api.post('/kyc/country/import', fd, { headers: { 'content-type': 'multipart/form-data' } })
    },
    cb,
    'Countries successfully imported'
  )
}

export function useKycCountryUpdate(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/kyc/country/update', data),
    cb,
    'Country successfully updated'
  )
}


export function useKycStates() {
  return useFetchApi(
    async (query: any) => {
      const filter = omitBy(query, (item: any) => item === null);
      return Api.post('/kyc/state/find', { filter })
    },
    { items: [] }
  );
}

export function useKycStatesUpdate(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/kyc/state/update', data),
    cb,
    'State successfully updated'
  )
}

export function useKycStatesImport(cb: any) {
  return useUpdateApi(
    async (file: any) => {
      const fd = new FormData();
      fd.append('file', file);
      return Api.post('/kyc/state/import', fd, { headers: { 'content-type': 'multipart/form-data' } })
    },
    cb,
    'States successfully imported'
  )
}


export function useDocumentsRecognize(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/kyc/documents/recognize', data),
    cb,
    undefined,
    true
  )
}

export function useNamescan(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/kyc/namescan', data),
    cb
  )
}

export function useThirdPartyKycData() {
  return useFetchApi(
    async () => Api.post('/kyc/3rdPartyKYC', {}),
    { data: false }
  )
}

export function useAutoApproveData() {
  return useFetchApi(
    async () => Api.post('/kyc/autoApprove', {}),
    { data: false }
  )
}

export function useKYCGlobalSet(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/kyc/set', data),
    cb,
    'KYC settings updated'
  )
}

export function useAdminScoringParamsData() {
  return useFetchApi(
    async (data: any) => Api.post('/scoring/one', data),
    { data: false }
  )
}

export function useAdminScoringCustomerParamsHistory() {
  return useFetchApi(
    async (data: any) => Api.post('/scoring/one/extended', data),
    { data: false }
  )
}

export function useAdminScoringReinitParamsData(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/scoring/reinit/one', data),
    cb,
    'Customer score is reinited'
  )
}

export function useAdminScoringParamsUpdate(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/scoring/update', data),
    cb,
    'Customer score is updated'
  )
}

export function useKycLimits() {
  return useFetchApi(
    async (data: any) => Api.post('kyc/limits/list', data),
    { items: [] }
  )
}

export function useKycLimitsCreate(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('kyc/limits/create', data),
    cb,
    'Kyc limit is created'
  )
}

export function useKycLimitsUpdate(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('kyc/limits/update', data),
    cb,
    'Kyc limit is updated'
  )
}
export function useKycLimitsDelete(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('kyc/limits/delete', data),
    cb,
    'Kyc limit is deleted'
  )
}

export function useKycDocumentAdd(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('kyc/documents/add', data),
    cb,
    'Kyc Document is Added'
  )
}

export function useResendKycPostback(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post(`/kyc/postback/retry`, data),
    cb,
    'Postback KYC resent successfully'
  )
}
