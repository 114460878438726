import React, { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextCopy from '../TextCopy';

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: ({ height }: any) => height || 50,
    '&.expand': {
      maxHeight: 'inherit'
    }
  }
});

interface IProps {
  text: string;
  height?: number;
  onClick?: any;
}

export default function Ellipsis({ text, height, onClick }: IProps) {
  const classes = useStyles( { height });
  const [expand, setExpand] = useState(false);

  return (
    <div>
      <div className={clsx(classes.root, { expand })}>
        {text}
      </div>
      {(text && text.length > 0) && (
        <Typography variant="body2" align="right" style={{ whiteSpace: 'nowrap' }}>
          <TextCopy text={text} />
          <Link
            onClick={(e: any) => {
              e.stopPropagation();
              onClick ? onClick() : setExpand(!expand);
            }}
            style={{ whiteSpace: 'nowrap' }}
          >Show {expand ? 'less' : 'more'}</Link>
        </Typography>
      )}
    </div>
  );
}
