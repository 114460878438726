import {KysStatus} from '../api/kyc';

export function getKYCStatusLabelType(status: KysStatus) {
  switch (status) {
    case KysStatus.APPROVED:
      return 'success';
    case KysStatus.NEW:
    case KysStatus.PARTIAL:
    case KysStatus.WAITING_FOR_APPROVAL:
    case KysStatus.CORRECTION_REQUESTED:
    case KysStatus.REVIEWED:
      return 'warning';
    case KysStatus.REJECTED:
      return 'danger';
    default:
      return;
  }
}