import React from 'react';

import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Select } from '../../../../components/Form';
import CreateIcon from '@material-ui/icons/Create';

import Paper from '@material-ui/core/Paper';
import useMobileStyles from '../../../../hooks/useMobileStyles';
import Tablecell from '../../../../components/TableCell/TableCell';

interface IProps {
  data: any; 
  changedFields: string[];
  onChangeField: any;
  classes: any;
  historyMarkers: any;
}

const yesNoList = [
  {label: 'Yes', value: true},
  {label: 'No', value: false}
]

const transactionAmountCorrelationList = [
  {label: 'No document requested', value: 'NO_DOCUMENT_REQUESTED'},
  {label: 'Yes', value: 'YES'},
  {label: 'No', value: 'NO'}
]

const sanctionList = [
  {label: 'Found', value: true},
  {label: 'Not Found', value: false},
]

const empoymentStatusList = [
  {label: 'Employed', value: 'EMPLOYED'},
  {label: 'Self-Employed', value: 'SELF_EMPLOYED'},
  {label: 'Unemployed', value: 'UNEMPLOYED'},
]

const sourceOfFundsList = [
  {label: 'Salary', value: 'SALARY'},
  {label: 'Self-employed income', value: 'SELF_EMPLOYED_INCOME'},
  {label: 'Savings', value: 'SAVINGS'},
  {label: 'Tradeing income', value: 'TRADING_INCOME'},
  {label: 'Rent income', value: 'RENT_INCOME'},
  {label: 'Return investment income', value: 'RETURN_ON_INVESTMENT'},
  {label: 'Loan', value: 'LOAN'},
  {label: 'Gambling', value: 'GAMBLING'},
  {label: 'Gift', value: 'GIFT'},
  {label: 'Inheritance', value: 'INHERITANCE'},
  {label: 'Other', value: 'OTHER'},
  {label: 'Fully checked and confirm', value: 'FULLY_CHECKED_AND_CONFIRMED'},
]

const monthlyIncomeList = [
  {label: 'From 5000', value: 'FROM_5000_EUR'},
  {label: 'From 1000 to 5000', value: 'FROM_1000_TO_5000_EUR'},
  {label: 'Below 1000', value: 'BELOW_1000_EUR'},
]

export default function CustomerRisks({data, changedFields, onChangeField, classes, historyMarkers}: IProps) {

  const mobileClasses = useMobileStyles();

  return (
    <>
      <Typography>Customer risks</Typography>
      <Paper classes={{root: classes.paper}} variant="outlined">
        <Table>
          <TableHead className={mobileClasses.tableHead}>
            <TableRow>
              <TableCell classes={{root: classes.firstColumn}}>Customer risks</TableCell>
              <TableCell classes={{root: classes.secondColumn}}>Value</TableCell>
              <TableCell>Risk score</TableCell>
              <TableCell>Updated By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Customer risks">Adverse media found</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select
                    name="adverseMediaFound"
                    label=""
                    options={yesNoList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('adverseMediaFound', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('adverseMediaFound') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.adverseMediaFoundValue}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers.adverseMediaFound?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Customer risks">Sanction list</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select
                    name="sanctionsListsFound"
                    label=""
                    options={sanctionList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('sanctionsListsFound', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('sanctionsListsFound') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.sanctionsListsFoundValue}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers.sanctionsListsFound?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Customer risks">Foregin PEP</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select
                    name="foreignPep"
                    label=""
                    options={yesNoList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('foreignPep', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('foreignPep') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.foreignPepValue}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers.foreignPep?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Customer risks">Employment status</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select
                    name="employmentStatus"
                    label=""
                    options={empoymentStatusList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('employmentStatus', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('employmentStatus') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.employmentStatusValue}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers.employmentStatus?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Customer risks">The SoF and Wealth risk</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select
                    name="sourceOfFunds"
                    label=""
                    options={sourceOfFundsList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('sourceOfFunds', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('sourceOfFunds') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.sourceOfFundsValue}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers.sourceOfFunds?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Customer risks">Monthly income</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select
                    name="monthlyIncome"
                    label=""
                    options={monthlyIncomeList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('monthlyIncome', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('monthlyIncome') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.monthlyIncomeValue}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers.monthlyIncomeValue?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow >
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Customer risks">SoF/SoW documents</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select
                    name="sofSow.comfirmationDocumentsProvided"
                    label=""
                    options={yesNoList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('sofSow.comfirmationDocumentsProvided', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('sofSow.comfirmationDocumentsProvided') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.sofSow?.comfirmationDocumentsProvidedValue}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers['sofSow.comfirmationDocumentsProvided']?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Customer risks">SoF/SoW correlation</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select
                    name="sofSow.transactionAmountCorrelation"
                    label=""
                    options={transactionAmountCorrelationList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('sofSow.transactionAmountCorrelation', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('sofSow.transactionAmountCorrelation') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.sofSow?.transactionAmountCorrelationValue}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers['sofSow.transactionAmountCorrelation']?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}