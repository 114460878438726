import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';

import toLower from 'lodash/toLower'

import { AddressValidator, CurrencyType, ICurrency, useCurrenciesList } from '../../api/currency';
import EmptyList from '../../components/EmptyList';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import Loader from '../../components/Loader';
import CurrenciesList from './CurrenciesList';
import CurrencyDetails from './Details';
import CurrenciesFilter from './CurrenciesFilter';
import useColumns, { IColumn } from '../../hooks/useColumns';
import useFilter from '../../hooks/useFilter';
import useMobileStyles from '../../hooks/useMobileStyles';
import FilterWrapper, { ICollapseHandle } from '../../components/FilterWrapper/FilterWrapper';

const emptyCurrency: ICurrency = {
  id: '',
  currency: '',
  symbol: '',
  type: CurrencyType.FIAT,
  enabled: true,
  depositMin: 0,
  depositMax: 0,
  withdrawalMin: 0,
  withdrawalMax: 0,
  addressValidator: AddressValidator.LUHN,
  display: '',
};

export type TSortField = 'currency' | 'createdAt' | 'updatedAt';
export type TSortDirection = 'asc' | 'desc';

export default function CurrenciesPage() {
  const [{ items, error }, loading, load, updateItem] = useCurrenciesList();
  const accordionRef = useRef<ICollapseHandle>(null);
  const globalClasses = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  const history = useHistory();
  const [currencyDetails, setCurrency] = useState();
  const { currency } = useParams<any>();
  const [setFilter, filter] = useFilter('currencies', {
    type: undefined,
    enabled: undefined,
    input: undefined,
  }, false);
  const [sortBy, setSortBy] = useState<TSortField | undefined>();
  const [sortDirection, setSortDirection] = useState<TSortDirection>('asc');

  const sort = (field: TSortField, currentDirection: TSortDirection, currentSort?: TSortField) => {
    if (currentSort === field) {
      if (currentDirection === 'asc') {
        setSortDirection('desc');
      } else {
        setSortDirection('asc');
      }
    } else {
      setSortBy(field);
      setSortDirection('asc');
    }
  }
  const defaultColumns: IColumn[] = [
    {
      id: 'currency',
      name: 'Currency',
      active: true,
      sortFunc: sort
    },
    { id: 'type', name: 'Type', active: true },
    { id: 'enabled', name: 'Enabled', active: true },
    { id: 'symbol', name: 'Symbol', active: true },
    { id: 'chain', name: 'Chain', active: true },
    { id: 'depositMin', name: 'Deposit Min', active: true },
    { id: 'depositMax', name: 'Deposit Max', active: true },
    { id: 'withdrawalMin', name: 'Withdrawal Min', active: true },
    { id: 'withdrawalMax', name: 'Withdrawal Max', active: true },
    { id: 'precision', name: 'Precision', active: true },
    {
      id: 'createdAt',
      name: 'Created at',
      active: true,
      sortFunc: sort
    },
    {
      id: 'updatedAt',
      name: 'Updated at',
      active: true,
      sortFunc: sort
    },
    { id: 'provider', name: 'Provider name', active: false },
    { id: 'transaction', name: 'Transaction type', active: false },
    { id: 'transactionStatus', name: 'Transaction status', active: false },
    { id: 'udKey', name: 'Unstoppable domain key', active: false }
  ];
  const [columns, ColumnsFilter] = useColumns('currencies', defaultColumns);

  useEffect(() => {
    load({});
  }, []);

  function setDetails(cur?: string) {
    history.push(`/currencies${cur ? `/${cur}` : ''}`);
  }

  useEffect(() => {
    if (currency && currency !== 'global-settings' && items.length) {
      const cur = currency === 'new' ? emptyCurrency : items.find((i: any) => i.currency === currency);
      setCurrency(cur);
    }
  }, [currency, items]);

  function save(data: any) {
    updateItem(data, 'currency');
    if (currency === 'new') {
      setDetails(data.currency);
    } else {
      setCurrency(data);
    }
  }

  useEffect(() => {
    accordionRef.current?.collapse();
  }, [filter]);

  let currencies = items as ICurrency[];
  if (filter.type) {
    currencies = currencies.filter((c) => c.type === filter.type);
  }
  if (typeof (filter.enabled) === 'boolean') {
    currencies = currencies.filter((c) => c.enabled === filter.enabled);
  }

  if(filter.input) {
    const lowerInput = toLower(filter.input)
    currencies = currencies.filter((c) => {
      const isCurrencyInFilter = toLower(c.currency).includes(lowerInput)
      const isSymbolInFilter = toLower(c.symbol).includes(lowerInput)
      const isChainInFilter = toLower(c.chain).includes(lowerInput)
      return isCurrencyInFilter || isSymbolInFilter || isChainInFilter
    })
  }
  if (sortBy) {
    const compare = (a: ICurrency, b: ICurrency) => {
      if (sortDirection === 'asc') {
        // @ts-ignore
        return a[sortBy] < b[sortBy] ? -1 : a[sortBy] > b[sortBy] ? 1 : 0;
      } else {
        // @ts-ignore
        return b[sortBy] < a[sortBy] ? -1 : b[sortBy] > a[sortBy] ? 1 : 0;
      }
    }
    // @ts-ignore
    currencies = currencies.sort(compare);
  }

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <CurrenciesFilter filter={filter} onChange={setFilter} />
      </FilterWrapper>
      <Grid item className={globalClasses.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={globalClasses.paper}>
          <CardActions>
            <Grid container justify="space-between" className={mobileClasses.gap}>
              <Grid item>
                <Button variant="contained" size="large" onClick={() => setDetails('new')}>Create Currency</Button>
              </Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0
            ? <CurrenciesList items={currencies} onRowClick={setDetails} columns={columns} sortBy={sortBy} sortDirection={sortDirection} />
            : <EmptyList loading={loading} error={error} />
          }
          <Loader loading={loading} />
        </Paper>
      </Grid>
      {currency && currency !== 'global-settings' && <CurrencyDetails currency={currencyDetails} id={currency} onClose={() => setDetails()} loading={loading} onCreate={save} />}
    </Grid>
  );
}
