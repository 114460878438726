import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Document, Page } from 'react-pdf';
import { DocumentType } from '../../../api/kyc';
import Typography from '@material-ui/core/Typography';

interface IProps {
  document: string;
  type?: DocumentType;
  imageClass?: any;
  width: number;
  height?: number;
}

const useStyles = makeStyles(() => ({
  navigationBtnWrapper: {
    display: 'flex',
    minWidth: 190,
    height: 21,
    alignItems: 'center',
    '& > *': {
      marginLeft: 10
    }
  },
  downloadBtnWrapper: {
    display: 'flex',
    width: 'calc(100% - 280px)',
    justifyContent: 'center'
  }
}))

export class DocumentViewControlsPortal extends React.Component {
  el: HTMLDivElement;
  ref: any;
  constructor(props: any) {
    super(props);
    this.el = document.createElement('div');
    this.el.style.cssText = 'display: flex; width: 100%; alignItems: center;';

    this.ref = React.createRef();
  }

  componentDidMount() {
    this.ref.current = document.querySelector('.head');
    this.ref.current && this.ref.current.prepend(this.el);
  }
  componentWillUnmount() {
    this.ref.current && this.ref.current.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el
    );
  }
}

const downloadDocument = (file: string) => {
  const element = document.createElement('a');
  element.setAttribute('href', file);
  element.setAttribute('download', 'document_prouf_of_found.pdf');

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export default function DocumentView({ document, imageClass, type, width, height }: IProps) {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(-1);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }:any) => {
    setNumPages(numPages);
  }

  if (!document) {
    return <Typography variant="body1" align="center">Document data is empty</Typography>
  }
  if (document.startsWith('JVBERi0')) {
    const file = `data:application/pdf;base64,${document}`
    return (
      <Document file={file} className={imageClass} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} width={!height ? width : undefined} height={height} />
        {numPages>1 && 
          <DocumentViewControlsPortal>
            <div className={classes.navigationBtnWrapper}>
              <button disabled={pageNumber === 1} onClick={()=>setPageNumber(pageNumber - 1)}>Prev</button>
              <button disabled={numPages === pageNumber} onClick={()=>setPageNumber(pageNumber + 1)}>Next</button>
              <p>Page {pageNumber} of {numPages}</p>
            </div>
            <div className={classes.downloadBtnWrapper}>
              <button onClick={() => downloadDocument(file)}>Download</button>
            </div>
          </DocumentViewControlsPortal>
        }
      </Document>
    );
  }
  if (document.startsWith('AAAA')) {
    return (
      <video controls className={imageClass}>
        <source type="video/mp4" src={`data:video/mp4;base64,${document}`} />
      </video>
    )
  }

  return (
    <img
      src={`data:image/jpeg;base64,${document}`}
      alt={type && type.split('_').join(' ')}
      className={imageClass}
    />
  );
}
