import React from 'react';

import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import StoreIcon from '@material-ui/icons/Store';
import DateView from '../../../components/DateView';
import Label from '../../../components/Label';
import BoolLabel from '../../../components/Label/BoolLabel';
import { ICard } from '../../../api/cardController';
import { IColumn } from '../../../hooks/useColumns';
import PopupLink from '../../../components/UI/PopupLink';
import TableCellId from '../../../components/TableCellId/TableCellId';
import Tablecell from '../../../components/TableCell/TableCell';
import useMobileStyles from '../../../hooks/useMobileStyles';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    cursor: 'pointer',
    height: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  id: {
    display: 'flex',
    alignItems: 'center',
  },
  store: {
    width: '15px',
    height: '24px',
    marginRight: theme.spacing(1),
    '& svg': {
      width: '15px'
    }
  }
}));

interface ICardListProps {
  items: ICard[];
  columns: IColumn[];
}

export default function CustomersList({ items, columns }: ICardListProps) {
  const classes = useStyles();
  const mobileClasses = useMobileStyles();

  const content: { [key: string]: (item: ICard, key: number) => void } = {
    id: (i, k) => <TableCellId key={k}>{i.id}</TableCellId>,
    customerId: (i, k) => <Tablecell title="Customer Id" key={k}>
      <PopupLink popup="customer" id={i.customerId}>{i.customerId}</PopupLink>
      </Tablecell>,
    number: (i, k) => <Tablecell title="Card number" key={k}>{i.number}</Tablecell>,
    createdAt: (i, k) => <Tablecell title="Created at" key={k}><DateView value={i.createdAt} /></Tablecell>,
    updatedAt: (i, k) => <Tablecell title="Updated at" key={k} isLast><DateView value={i.updatedAt} /></Tablecell>
  }

  return (
    <Table size="small">
      <TableHead className={mobileClasses.tableHead}>
        <TableRow>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow key={i.id} className={clsx(classes.row, mobileClasses.row)} hover>
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}