import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { Form } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useFormStyles from '../../../../hooks/useFormStyles';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import ApmSelect from '../../../../components/Form/ApmSelect';
import { CurrencySelect } from '../../../../components/Form';
import { CurrencyType } from '../../../../api/currency';
import InputField from '../../../../components/Form/InputField';
import { OnChange } from 'react-final-form-listeners';
import { composeValidators, maxValue, minValue, mustBeNumber, required } from '../../../../validators';
import { useBreakpoints } from '../../../../hooks/useBreakpoints ';

type ApmFee = {
  key: string;
  deposit: number;
  depositFixed: number;
  withdrawal: number;
  withdrawalFixed: number;
  fixedCurrency: string;
  depositFeeMin: number;
  index?: number;
};

const useStyles = makeStyles((theme) => ({
  header: {
    margin: '10px 0 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0',
    },
  },
  apmTable: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  apmRow: {
    display: 'flex',
    marginBottom: '0',
    width: '100%',
    justifyContent: 'space-between',
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '2px !important',
      paddingBottom: '2px !important',
    }
  },
  addApm: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
    },
  },
  rowMobile: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }
}));

export default function ApmFeeForm({ fee, update, isValid }: any) {
  const formClasses = useFormStyles();
  const classes = useStyles();
  const [apmFee, setApmFee] = useState<{ apms: ApmFee[] }>({ apms: [] });
  const [excluded, setExcluded] = useState<string[]>([]);
  const isSm = useBreakpoints('sm');

  useEffect(() => {
    let initial: any = fee.apmFee || {};
    let entries: ApmFee[] = Object.entries(initial).map(([key, value]) => {
      return { key, ...(value as ApmFee) };
    });
    entries.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
    setApmFee({ apms: entries });
    setExcluded(entries.map((entry) => entry.key));
  }, [fee.apmFee]);

  const initApmFee: ApmFee = {
    key: '',
    deposit: 0,
    depositFixed: 0,
    withdrawal: 0,
    withdrawalFixed: 0,
    fixedCurrency: 'EUR',
    depositFeeMin: 1.5
  };

  function updateForm(data: ApmFee[], valid: boolean) {
    isValid(valid);
    let convert = data.reduce((acc: any, value: ApmFee, index: number) => {
      acc[value.key] = {
        deposit: value.deposit,
        depositFixed: value.depositFixed,
        withdrawal: value.withdrawal,
        withdrawalFixed: value.withdrawalFixed,
        fixedCurrency: value.fixedCurrency || 'EUR',
        depositFeeMin: value.depositFeeMin,
        index: index,
      };
      return acc;
    }, {});
    update(convert);
  }

  function removeRow(index: number, values: ApmFee[], fields: any) {
    const keyToRemove = values[index].key;
    const newExcluded = excluded.filter((apm) => apm !== keyToRemove);
    setExcluded(newExcluded);
    fields.remove(index);
    updateForm(values.filter((_, i) => i !== index), true);
  }

  function getExcluded(key: any) {
    return excluded.filter((apm) => key !== apm);
  }

  function updateExcluded(values: ApmFee[]) {
    setExcluded(values.map((apm) => apm.key));
  }

  return (
    <>
      <div className={clsx(formClasses.row, classes.header)}>
        <Typography variant="body1" color="primary">
          Apm fee
        </Typography>
      </div>
      <Form
        onSubmit={() => {}}
        initialValues={apmFee}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, values, valid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldArray name="apms">
                {({ fields }) => (
                  <div className={classes.apmTable}>
                    {fields.map((name, index) => (
                      <div
                        key={name}
                        className={clsx(
                          formClasses.row,
                          classes.rowMobile,
                          classes.apmRow
                        )}
                      >
                        <ApmSelect
                          name={`${name}.key`}
                          label="Apm"
                          margin={'normal'}
                          exclude={getExcluded(values.apms[index].key)}
                          validate={composeValidators(
                            required,
                            (value: any) =>
                              value !== 'undefined' ? undefined : 'Required field'
                          )}
                        />
                        <OnChange name={`${name}.key`}>
                          {() => {
                            updateForm(values.apms, valid);
                            updateExcluded(values.apms);
                          }}
                        </OnChange>
                        <InputField
                          name={`${name}.deposit`}
                          placeholder="Deposit"
                          label="Deposit"
                          margin={'normal'}
                          validate={composeValidators(
                            mustBeNumber,
                            (v: any) => maxValue(v, 0.2),
                            (v: any) => minValue(v, 0)
                          )}
                        />
                        <OnChange name={`${name}.deposit`}>
                          {() => updateForm(values.apms, valid)}
                        </OnChange>
                        <InputField
                          name={`${name}.depositFixed`}
                          placeholder="Deposit Fixed"
                          label="Deposit Fixed"
                          margin={'normal'}
                          validate={composeValidators(
                            mustBeNumber,
                            (v: any) => maxValue(v, 1000),
                            (v: any) => minValue(v, 0)
                          )}
                        />
                        <OnChange name={`${name}.depositFixed`}>
                          {() => updateForm(values.apms, valid)}
                        </OnChange>
                        <InputField
                          name={`${name}.withdrawal`}
                          placeholder="Withdrawal"
                          label="Withdrawal"
                          margin={'normal'}
                          validate={composeValidators(
                            mustBeNumber,
                            (v: any) => maxValue(v, 0.2),
                            (v: any) => minValue(v, 0)
                          )}
                        />
                        <OnChange name={`${name}.withdrawal`}>
                          {() => updateForm(values.apms, valid)}
                        </OnChange>
                        <InputField
                          name={`${name}.withdrawalFixed`}
                          placeholder="Withdrawal Fixed"
                          label="Withdrawal Fixed"
                          margin={'normal'}
                          validate={composeValidators(
                            mustBeNumber,
                            (v: any) => maxValue(v, 1000),
                            (v: any) => minValue(v, 0)
                          )}
                        />
                        <OnChange name={`${name}.withdrawalFixed`}>
                          {() => updateForm(values.apms, valid)}
                        </OnChange>
                        <CurrencySelect
                          name={`${name}.fixedCurrency`}
                          label="Fixed fee currency"
                          type={CurrencyType.FIAT}
                          fullWidth={true}
                          margin={'normal'}
                        />
                        <OnChange name={`${name}.fixedCurrency`}>
                          {() => updateForm(values.apms, valid)}
                        </OnChange>
                        <InputField
                          name={`${name}.depositFeeMin`}
                          placeholder="Deposit Fee Min"
                          label="Deposit Fee Min"
                          margin={'normal'}
                          validate={composeValidators(
                            mustBeNumber,
                            (v: any) => maxValue(v, 1000),
                            (v: any) => minValue(v, 0)
                          )}
                        />
                        <OnChange name={`${name}.depositFeeMin`}>
                          {() => updateForm(values.apms, valid)}
                        </OnChange>
                        <div
                          className={!isSm ? 'v-center' : 'v-left'}
                          style={{ flex: '0 0 44px', marginTop: 4 }}
                        >
                          <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              fields.remove(index);
                              removeRow(index, values.apms, fields);
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    ))}
                    <Button
                      className={classes.addApm}
                      type="button"
                      variant="contained"
                      onClick={() => {
                        fields.push({ ...initApmFee });
                        isValid(false);
                      }}
                    >
                      Add apm fee
                    </Button>
                  </div>
                )}
              </FieldArray>
            </form>
          );
        }}
      />
    </>
  );
}
