import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { isJSON } from '../../../helpers/stringHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface IDialogTitleProps {
  children?: any;
  onClose: any;
}

const DialogTitle = (props: IDialogTitleProps) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    '& pre': {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all'
    }
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

interface IScorechainDialog {
  open: boolean;
  onClose: Function;
  data: any;
  typeName?: string;
}

const prepareData = (rsvdData: any) => {
  let renderData;
  const normalizedData = isJSON(rsvdData) ? JSON.parse(rsvdData) : rsvdData;
  switch(normalizedData.provider) {
    case 'SEON': {
      const {success, data, error} = normalizedData.response;
      if (success) {
        renderData = {
          name: 'Seon',
          data: {
            bean_details: {
              bin_type: data.bin_details?.bin_type,
              bin_level: data.bin_details?.bin_level,
              bin_country: data.bin_details?.bin_country
            },
            applied_rules: (data.applied_rules && data.applied_rules.length) 
              ? data.applied_rules.map((item: any) => ({name: item.name, score: item.score, operation: item.operation})) 
              : data.applied_rules
          }
        }
      } else {
        renderData = {
          error
        }
      }
     
    }
    case 'SCORECHAIN': {
      renderData = {name: 'Scorechain ' + normalizedData.request.type || '', data: normalizedData.response};
      // const {details, error} = normalizedData.response;
      // if(details){
      //   return {
      //     name: 'Scorechain',
      //     data: details.relationships.map((item: any) => {
      //       return {
      //         scx: item.scx,
      //         type: item.type,
      //         label: item.label,
      //         percent: item.percent
      //       }
      //     })
      //   }
      // } else {
      //   return {
      //     name: 'Scorechain',
      //     data: {
      //       error
      //     }
      //   }
      // }
    }
    default: {
      renderData = {data: normalizedData}
    }
  }

  //@ts-ignore
  const str = JSON.stringify(renderData, null, 2);
  return {
    name: rsvdData.name,
    data: rsvdData.data?.seon_id 
          ? str.replace(`"seon_id": ${rsvdData.data.seon_id}`, `"seon_id": <a style="color:#90caf9" href="https://admin.seon.io/transactions/${rsvdData.data.seon_id }/" target="_blank">${rsvdData.data.seon_id}</a>` )
          : ['SCORECHAIN'].includes(normalizedData.provider)
            ? str
            : str.split('&').join('&<br />')
  }
}

export default function AntiFraudDialog(props: IScorechainDialog) {
  const {data, typeName, onClose} = props;
  const handleClose = () => onClose();
  const renderData = data && prepareData(data); 

  return (data &&
    <div>
      <Dialog onClose={handleClose} open={props.open}>
        <DialogTitle onClose={handleClose}>
          {renderData.name} {typeName} status
        </DialogTitle>
        <DialogContent dividers>
          <div><pre dangerouslySetInnerHTML={{__html:renderData.data}}></pre></div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}