import Api, { useFetchApi } from './index';

export function useTracingList() {
  return useFetchApi(
    async () => Api.get('/metrics'),
    { data: {} }
  );
}

export function useTracingOne() {
   return useFetchApi(
     async (url: string) => Api.get(`/metrics/${url}`),
     { data: {} }
   );
 }
 
