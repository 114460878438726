import Api, { useFetchApi, useUpdateApi } from './index';

export enum ACLType {
  CARD_PAN = 'CARD_PAN',
  EMAIL = 'EMAIL',
  MAIL_DOMAIN = 'MAIL_DOMAIN',
  CRYPTO_ADDRESS = 'CRYPTO_ADDRESS',
  IP_ADDRESS = 'IP_ADDRESS'
}

export enum ListType {
  BLACK = 'BLACK',
  WHITE = 'WHITE'
}

export interface IACLItem {
  id: number;
  createdAt: number;
  updatedAt: number;
  value: string;
  type: ACLType;
  black: boolean;
}

export function useAclList() {
  return useFetchApi(
    async (query: any) => Api.post('/acl/list', { limit: 50, ...query }),
    { items: [] }
  )
}

export function useCreateBWItem(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/acl/add', data),
    cb,
    'Item successfully created'
  )
}

export function useDeleteBWItem(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/acl/delete', data),
    cb,
    'Item successfully removed'
  )
}

export function useBWImport(cb: any) {
  return useUpdateApi(
    async (file: any) => {
      const fd = new FormData();
      fd.append('file', file);
      return Api.post('/acl/import', fd, { headers: { 'content-type': 'multipart/form-data' } })
    },
    cb,
    'Items successfully imported'
  )
}
