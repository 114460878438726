import { TextField } from 'final-form-material-ui';
import { Field } from 'react-final-form';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField'
import React from 'react';

interface IInputProps extends Partial<OutlinedTextFieldProps> {
  name: string;
  type?: string;
  label?: any;
  required?: boolean;
  fullWidth?: boolean;
  validate?: any;
  helperText?: string;
  disabled?: boolean;
  inputRef?: any;
  onFocus?: any;
  multiline?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  className?: any;
  autoComplete?: string;
  autoFocus?: boolean;
  InputProps?: any;
  inputProps?: any;
}

export default ({ name, type, multiline, margin, ...rest }: IInputProps) => (
  <Field
    name={name}
    type={type || 'text'}
    component={type === 'hidden' ? 'input' : TextField}
    variant="outlined"
    margin={margin || 'normal'}
    fullWidth
    multiline={multiline}
    rows={multiline && 3}
    {...rest}
  />
);
