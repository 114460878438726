import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import CardActions from '@material-ui/core/CardActions';
import useColumns, { IColumn } from '../../../hooks/useColumns';
import { useTracingList, useTracingOne } from '../../../api/tracing';
import TracingList, { ITracing } from './TracingList';
import EmptyList from '../../../components/EmptyList';
import Loader from '../../../components/Loader';

interface ITracingItem {
  name: string,
  description: string | null,
  baseUnit: string | null,
  measurements: { statistic: string, value: number }[]
}

const useStyles = makeStyles(() => ({
  root: {},
  table:{ 
   width: '100%',
   minWidth: 'fit-content' 
  },
}));

export type TSortField = 'name' | 'description' | 'count' | 'total' | 'max' | 'avarage';
export type TSortDirection = 'asc' | 'desc';

export default function TracingPage(){

  const classes = useGlobalStyles();
  const classesPage = useStyles();

  const [{ data: list, error: errorList }, loadingMetrics, loadMetrics] = useTracingList();
  const [{ data: item, error: errorItem }, loadingItem, loadItem] = useTracingOne();
  const [traces, setTraces] = useState<ITracing[]>([]);
  const [sortBy, setSortBy] = useState<TSortField | undefined>();
  const [sortDirection, setSortDirection] = useState<TSortDirection>('asc');

  const sort = (field: TSortField, currentDirection: TSortDirection, currentSort?: TSortField) => {
    if (currentSort === field) {
      if (currentDirection === 'asc') {
        setSortDirection('desc');
      } else {
        setSortDirection('asc');
      }
    } else {
      setSortBy(field);
      setSortDirection('asc');
    }
  };

  const defaultColumns: IColumn[] = [
    { id: 'name', name: 'Name', active: true, sortFunc: sort },
    { id: 'description', name: 'Description', active: true, sortFunc: sort },
    { id: 'base', name: 'Base unit', active: true },
    { id: 'count', name: 'Count', active: true, sortFunc: sort },
    { id: 'total', name: 'Total Time', active: true, sortFunc: sort },
    { id: 'max', name: 'Max', active: true, sortFunc: sort },
    { id: 'avarage', name: 'Avarage time', active: true, sortFunc: sort },
  ];

  const [columns, ColumnsFilter] = useColumns('tracing', defaultColumns);

  function transformationTracing(item: ITracingItem){
    const data = {
      name: item.name,
      description: item.description || ' ',
      baseUnit: item.baseUnit || ' ',
      count: item?.measurements[0]?.value || ' ',
      total: item?.measurements[1]?.value || ' ',
      max: item?.measurements[2]?.value || 0,
      avarage: (item?.measurements[0]?.value && item?.measurements[1]?.value) 
      ? Number(item.measurements[1].value)/Number(item.measurements[0].value)
      : 0
    };
    setTraces([...traces, data]);
  }

useEffect(()=>{
  if (item && item?.measurements) {
    transformationTracing(item);
  }
},[item]);

  useEffect(() => {
    loadMetrics();
 }, []);

 useEffect(()=>{
  if (list && list?.names) {
    list.names.forEach((item: string) => {
      loadItem(item);
    });
  }
 },[list]);

 let loading = loadingMetrics || loadingItem;

 let listTraces = traces as ITracing[];

 if (sortBy) {
  const compare = (a: ITracing, b: ITracing) => {
    if (sortDirection === 'asc') {
      return a[sortBy] < b[sortBy] ? -1 : a[sortBy] > b[sortBy] ? 1 : 0;
    } else {
      return b[sortBy] < a[sortBy] ? -1 : b[sortBy] > a[sortBy] ? 1 : 0;
    }
  };
  listTraces = listTraces.sort(compare);
}

  return(
    <Grid container spacing={3}>
      <Grid item className={clsx(classes.table,classesPage.table)}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justify="flex-end">
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {listTraces.length > 0
            ? <TracingList items={listTraces} columns={columns} sortBy={sortBy} sortDirection={sortDirection} />
            : <EmptyList error={errorList || errorItem} loading={loading} />
          }
          <Loader loading={loading} />
        </Paper>
      </Grid>
    </Grid>
  );
}