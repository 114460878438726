import Api, { useFetchApi, useUpdateApi } from './index';

export interface ICard {
  "id": number;
  "createdAt": number;
  "updatedAt": number;
  "number": string;
  "customerId": number;
  "whitelisted": boolean;
}

export function useCardList() {
  return useFetchApi(
    async (data: any) => Api.post('/card/list', data),
    { items: [] }
  );
}