import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Form } from 'react-final-form';
import { Input, Select, CurrencySelect, ProtectionInput, DateTimeField } from '../../../../components/Form';
import DetailsPopup from '../../../../components/Layout/DetailsPopup';
import Label from '../../../../components/Label';
import useFormStyles from '../../../../hooks/useFormStyles';
import { required } from '../../../../validators';
import { IBookkeeperBalanceItem, AccountType, AccountName, useBookkeeperBalanceAdd, useBookkeeperBalanceUpdate } from '../../../../api/bookkeeper';

interface IProps {
  item: IBookkeeperBalanceItem;
  onClose: any;
  loading: boolean;
  onSave: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

export default function BookkeepingBalanceDetails({ item, onClose, loading, onSave }: IProps) {
  const formClasses = useFormStyles();
  const classes = useStyles();
  const isView = !!item && !!item.id;
  const saveFunc = isView ? useBookkeeperBalanceUpdate : useBookkeeperBalanceAdd;
  const [save, saving] = saveFunc((res: any) => {
    if (res.success) {
      onSave(res.data);
    }
  });

  return (
    <DetailsPopup onClose={onClose} loading={loading}>
      {item
        ? (
          <>
            <Typography variant="h6" color="primary" component="div">
              {!isView
                ? 'Add balance entry'
                : (
                  <div className={classes.title}>
                    Balance entry {item.id}
                    {item.manual && <Label label="Manual" type="warning" size="small" />}
                  </div>
                )
              }
            </Typography>
            <Form
              onSubmit={save}
              initialValues={{ ...item, '2fa': '' }}
              render={({ handleSubmit, form }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    // @ts-ignore
                    handleSubmit(e).then(() => form.restart());
                  }}
                  className={clsx(formClasses.root, classes.root)}
                >
                  <div className={formClasses.row}>
                    {isView
                      ? (
                        <Input
                          name="type"
                          label="type"
                          disabled
                          margin="none"
                        />
                      ) : (
                        <Select
                          name="type"
                          label="Type"
                          options={Object.values(AccountType).map((e: string) => ({ value: e, label: e }))}
                          validate={required}
                        />
                      )
                    }
                  </div>
                  <div className={formClasses.row}>
                    <DateTimeField name="timestamp" label="Timestamp" disabled={isView} />
                  </div>
                  <div className={formClasses.row}>
                    {isView
                      ? (
                        <Input
                          name="account"
                          label="Account"
                          disabled
                          margin="none"
                        />
                      ) : (
                        <Select
                          name="account"
                          label="Account"
                          options={Object.values(AccountName).map((n: string) => ({ value: n, label: n }))}
                          validate={required}
                        />
                      )}
                  </div>
                  <div className={formClasses.row}>
                    <Input
                      name="subAccount"
                      label="Sub account"
                      type="text"
                      margin="none"
                      disabled={isView}
                    />
                  </div>
                  <div className={formClasses.row}>
                    {isView
                      ? (
                        <Input
                          name="currency"
                          label="Currency"
                          disabled
                          margin="none"
                        />
                      ) : (
                        <CurrencySelect
                          name="currency"
                          label="Currency"
                          validate={required}
                        />
                      )}
                  </div>
                  <div className={formClasses.row}>
                    <Input
                      name="balance"
                      label="Balance"
                      type="number"
                      validate={required}
                    />
                  </div>
                  <div className={formClasses.row}>
                    <ProtectionInput />
                  </div>
                  <div className={formClasses.actions}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      disabled={saving}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              )}
            />
          </>
        ) : <Typography align="center">No data to display</Typography>
      }
    </DetailsPopup>
  );
}
