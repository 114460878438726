import React, {useEffect, useMemo} from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import AutocompleteField, {IAutocompleteOption} from './AutocompleteField';
import {IApm, useApmList} from "../../api/apm";

interface IProps {
  name: string;
  label: string;
  fullWidth?: boolean;
  multi?: boolean
  margin?: 'none' | 'dense' | 'normal';
  exclude?: string[];
  validate?: any;
  disabled?: boolean;
}

const mapApm = (apm: IApm): IAutocompleteOption => {
  return {
    value: apm.externalId,
    label: apm.name,
    disabled: !apm.enabled
  }
}

export default function ApmSelect({
  name,
  label,
  multi,
  fullWidth,
  exclude,
  validate,
  disabled,
  margin,
}: IProps) {
  const classes = useCurrencySelectorStyles()
  const [{ items }, loading, load] = useApmList();

  useEffect(() => {
    load();
  }, [])

  const options = useMemo(() => {
    const options = items.map(mapApm);
    return options.filter((i: any) => !exclude?.includes(i.value))
  }, [items, exclude])



  return (
    <div className={classes.wrapper}>
      <AutocompleteField
        name={name}
        label={label}
        loading={loading}
        options={options}
        margin={margin}
        multiple={multi}
        fullWidth={fullWidth}
        validate={validate}
        disabled={disabled}
        disableCloseOnSelect={multi}
      />
    </div>
  );
}

const useCurrencySelectorStyles = makeStyles(() => {
  return {
    wrapper: {
      display: 'flex',
      position: 'relative',
      width: '100%',
    }
  }
})
