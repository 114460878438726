import React from 'react';

import clsx from 'clsx';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import DateView from '../../components/DateView';
import BlockchainTxStatusLabel from '../../components/Label/BlockchainTxStatusLabel';
import BlockchainLink from '../../components/BlockchainLink';
import { IBlockchainTx } from '../../api/blockchain';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { IColumn } from '../../hooks/useColumns';
import { splitAddress } from '../../helpers/addressHelper';
import Tablecell from '../../components/TableCell/TableCell';
import useMobileStyles from '../../hooks/useMobileStyles';

interface IProps {
  item: IBlockchainTx;
  lastChild: IBlockchainTx;
  columns: IColumn[];
  rowCheck: boolean;
  isSelected: boolean;
  select: any;
  multiSelect: boolean;
  setDetails: (id: string) => void
}

const content: { [key: string]: (i: IBlockchainTx, key: number) => void } = {
  id: (item, key) => <Tablecell title="ID" key={key}>{item.id}</Tablecell>,
  orderId: (item, key) => <Tablecell key={key} title="Order ID">{item.orderId}</Tablecell>,
  txId: (item, key) => <Tablecell key={key} title="TX ID"><BlockchainLink charsLimit={15} value={item.txId} type="tx" provider={item.provider} currency={item.currency} /></Tablecell>,
  amount: (item, key) => <Tablecell key={key} title="Amount">{item.amount}</Tablecell>,
  fee: (item, key) => <Tablecell key={key} title="Fee">{item.fee}</Tablecell>,
  currency: (item, key) => <Tablecell key={key} title="Currency">{item.currency}</Tablecell>,
  customer: (item, key) => <Tablecell key={key} title="Customer">{item.customerEmail}</Tablecell>,
  address: (item, key) => {
    const [address] = splitAddress(item?.address)
    return <Tablecell key={key} title="Address"><BlockchainLink charsLimit={15} value={address} type="address" currency={item.currency} /></Tablecell>
  },
  provider: (item, key) => <Tablecell key={key} title="Provider">{item.provider}</Tablecell>,
  status: (item, key) => <Tablecell key={key} title="Status"><BlockchainTxStatusLabel status={item.status} /></Tablecell>,
  created: (item, key) => <Tablecell key={key} title="Created at"><DateView value={item.createdAt} /></Tablecell>,
  updated: (item, key) => <Tablecell key={key} title="Updated at" isLast><DateView value={item.updatedAt} /></Tablecell>
}

export default function BlockchainTxRow({ setDetails, item, rowCheck, isSelected, select, columns, multiSelect, lastChild }: IProps) {

  const classes = useGlobalStyles();
  const mobileClasses = useMobileStyles();

  return (
    <>
      <TableRow hover onClick={() => {
        setDetails(String(item.id))
      }}
        className={clsx(classes.pointer, mobileClasses.row, lastChild && mobileClasses.lastChild)} >
        {rowCheck && (
          <TableCell>
            <Checkbox
              checked={isSelected}
              color="primary"
              onChange={(event) => {
                setDetails('')
                event.stopPropagation();
                select(event, item.id);
              }}
              value="true"
            />
          </TableCell>
        )}
        {columns.map((c, index) => content[c.id](item, index))}
      </TableRow >
    </>
  );
}