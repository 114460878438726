export const chainLinks= [
   {
       chain: "ARBITRUM",
       href: 'https://arbiscan.io/address/'
   },
   {
       chain: "BSC",
       href:'https://bscscan.com/address/'
   },
   {
       chain: "POLYGON",
       href: 'https://polygonscan.com/address/'
   },
   {
       chain: "AVALANCHE",
       href: 'https://avascan.info/blockchain/all/address/'
   },
   {
       chain: "ZKSYNC",
       href: 'https://explorer.zksync.io/address/'
   },
   {
       chain: "VELAS_EVM",
       href: 'https://evmexplorer.velas.com/address/'
   },
   {
       chain: "AURORA",
       href: 'https://browser.aurorachain.io/block.html#/searchResult?fromAddress='
   },
   {
       chain: "NEAR",
       href: 'https://nearblocks.io/en/address/'
   },
   {
       chain: "GNOSIS",
       href:'https://gnosisscan.io/address/'
   },
   {
       chain: "SOLANA",
       href: 'https://explorer.solana.com/address/'
   },
   {
       chain: "ETHEREUM",
       href: 'https://etherscan.io/address/'
   },
   {
       chain: "BICOCCA",
       href: ''
   },
   {
       chain: "POLYGON_CCW",
       href: 'https://polygonscan.com/address/'
   }
 ]