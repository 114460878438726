import Link from '@material-ui/core/Link';
import React from 'react';
import { BlockchainTxProvider } from '../../api/blockchain';
import ShortString from '../UI/ShortString';
import { useSelector } from "react-redux";
import { ICurrency } from "../../api/currency";
import makeStyles from '@material-ui/core/styles/makeStyles';
const useStyles = makeStyles((theme: any) => ({
  BlockchainLink: {
    marginRight: '5px',
    [theme.breakpoints.down('xs')]: {
      overflowWrap: 'anywhere',
     },
  },
}));

interface IProps {
  type: 'tx' | 'address';
  value?: string;
  charsLimit?: number;
  provider?: BlockchainTxProvider;
  currency?: string;
}

export default function BlockchainLink({ type, value, charsLimit, currency }: IProps) {
  const classes = useStyles();
  const currenciesMap = useSelector((store: any) => store.appData.currencies.map)

  if (!value) return null;

  const currencyObject = (currency ? currenciesMap[currency] : {}) as ICurrency
  const explorerAddr = currencyObject?.explorerAddr || currencyObject?.defExplorerAddr
  const explorerTx = currencyObject?.explorerTx || currencyObject?.defExplorerTx
  let baseUrl = type === 'tx' ? explorerTx : explorerAddr

  if (value.includes('CP:')) {
    baseUrl = 'https://explorer.coinspaid.com/query/';
  }

  return (
    <Link className={classes.BlockchainLink} href={baseUrl && `${baseUrl}${value}`} target="_blank">
      {charsLimit
        ? <ShortString chars={charsLimit} text={value} />
        : value}
    </Link>
  );
}
