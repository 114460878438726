import React, { useEffect, useRef } from 'react';

import { Waypoint } from 'react-waypoint';

import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import CardActions from '@material-ui/core/CardActions';

import KYCList from './KYCList';
import EmptyList from '../../components/EmptyList';
import Loader from '../../components/Loader';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import useColumns, { IColumn } from '../../hooks/useColumns';
import {useKycList} from "../../api/kyc";
import KYCFilter from "./KYCFilter";
import useFilter from "../../hooks/useFilter";
import FilterWrapper, { ICollapseHandle } from '../../components/FilterWrapper/FilterWrapper';

export const SumSubUrl = process.env.REACT_APP_SUMSUB_URL || 'https://cockpit.sumsub.com';
export const getSumSubUrl = (id: any) => `${SumSubUrl}/checkus#/applicant/${id}/basicInfo?clientId=utorg`;

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'customer', name: 'Customer ID', active: true },
  { id: 'email', name: 'Email', active: true },
  { id: 'country', name: 'Country', active: true },
  { id: 'trdParty', name: 'Third party', active: true },
  { id: 'extReview', name: 'Ext. review answer', active: true },
  { id: 'status', name: 'Status', active: true, component: <TableCell align="center" key="status">Status</TableCell> },
  { id: 'created', name: 'Created at', active: true },
  { id: 'updated', name: 'Updated at', active: true },
];

export default function KYCPage() {
  const classes = useGlobalStyles();
  const [{ items, cursor, full, error }, loading, load ] = useKycList();
  const emptyFilter = () => ({
    id: undefined,
    customerId: undefined,
    customerEmail: undefined,
    country: undefined,
    status: undefined,
    externalReviewAnswer: undefined,
  });

  const accordionRef = useRef<ICollapseHandle>(null);

  const [setFilter, filter] = useFilter('kyc', emptyFilter(), true, true);

  const [columns, ColumnsFilter] = useColumns('kyc', defaultColumns);
  const globalCountry = useSelector((store:any) => store.appData.countries.globalCountry);

  useEffect(() => {
    setFilter({
      ...filter,
      country: globalCountry || filter.country,
    })
  }, [globalCountry])

  useEffect(() => {
    accordionRef.current?.collapse();
    load({
      cursor: 0,
      filter: {
        ...filter,
        customerEmail: filter.customerEmail?.trim(),
        country: globalCountry || filter.country,
      },
    });
  }, [filter]);

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <KYCFilter filter={filter} onChange={setFilter} />
      </FilterWrapper>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justify="flex-end">
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0 ? <KYCList items={items} columns={columns} /> : <EmptyList error={error} loading={loading} />}
          <Loader loading={loading} />
          {!loading && !error && !full && <Waypoint onEnter={() => load({ cursor, filter })} />}
        </Paper>
      </Grid>
    </Grid>
  );
}
