import React, { FC } from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { Form } from "react-final-form";

import clsx from 'clsx';

import { Input, Select, ProtectionInput } from "../../../components/Form";
import { useKycStatesUpdate, IKycState } from "../../../api/kyc";
import { lengthRequired, required, composeValidators } from '../../../validators';

import useFormStyles from "../../../hooks/useFormStyles";
import { useBreakpoints } from "../../../hooks/useBreakpoints ";
import useMobileStyles from "../../../hooks/useMobileStyles";

const options = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

interface IStateDetailsProps {
  state?: IKycState;
  onSave: any;
  onCancel: any;
}

const StateDetails: FC<IStateDetailsProps> = ({ state, onSave, onCancel }) => {
  const formClasses = useFormStyles();
  const mobileClasses = useMobileStyles();
  const isResolutionSevenHundred = useBreakpoints(700);
  const [update, updating] = useKycStatesUpdate((res: any) => {
    if (res.success) {
      onSave();
    }
  });

  return (
    <>
      <Typography variant="h6" color="primary">
        State details
      </Typography>
      <Form
        onSubmit={(values) => update({ ...values, id: state?.id })}
        initialValues={{ ...state, "2fa": "" }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={formClasses.row}>
              <Input disabled name="code" label="Code" />
              <Input disabled name="name" label="Name" />
            </div>
            <div className={clsx(formClasses.row, isResolutionSevenHundred && mobileClasses.gap)}>
              <Select name="supported" label="Supported" options={options} noEmptyOption={true} fullWidth={isResolutionSevenHundred}/>
              <Select name="inHRTC" label="In EU HRTC list" options={options}  noEmptyOption={true} fullWidth={isResolutionSevenHundred}/>
            </div>
            <div className={formClasses.row}>
              <Input
                name="riskScore"
                label="Risk score"
                type="number"
                inputProps={{ lang: "en" }}
                validate={composeValidators(required)}
              />
              <Input
                name="kycScore"
                label="Kyc score"
                type="number"
                inputProps={{ lang: "en" }}
                validate={composeValidators(required)}
              />
            </div>
            <div className={formClasses.row}>
              <ProtectionInput />
            </div>
            <div className={formClasses.actions}>
              <Button type="submit" variant="contained" disabled={updating}>
                Save
              </Button>
              <Button
                variant="contained"
                disabled={updating}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default StateDetails;
