import Api, { useFetchApi, useUpdateApi } from "./index";

export interface IAuthority extends IAuthorityEmpty {
  id: string;
}

interface IAuthorityEmpty {
  active: boolean;
  name: string;
  description: string;
}

export const emptyAuthority: IAuthorityEmpty = {
  name: "",
  active: false,
  description: "",
};

export function useAuthorityList() {
  return useFetchApi(
    async (type?: string) => Api.post("/authority/list", type && { type }),
    {
      items: [],
    }
  );
}

export function useAuthorityCreate(cb: any) {
  return useUpdateApi(
    async (data: IAuthorityEmpty) => Api.post("/authority/create", data),
    cb,
    `Authority successfully created`
  );
}

export function useAuthorityUpdate(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post("/authority/update", data),
    cb,
    `Authority successfully updated`
  );
}
export function useAuthorityAdminList() {
  return useFetchApi(
    async (id: number) => Api.post("/authority/admin/list", { id }),
    {
      items: [],
    }
  );
}
