import React from 'react';
import merge from 'lodash/merge';
import { Form } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import useFormStyles from '../../../hooks/useFormStyles';
import { ICustomer } from '../../../api/customers';
import { getInitialUI } from '../../../api/merchant';
import { Input, Checkbox, Select } from '../../../components/Form';
import { isHEX, isURL } from '../../../validators';
import PaletteItem from './PaletteItem';
import { useBreakpoints } from '../../../hooks/useBreakpoints ';

interface IUIFormProps {
  customer: ICustomer;
  onSave: any;
}

const langs = [
  { value: 'en-US', label: 'English' },
  { value: 'ru-RU', label: 'Russian' },
];

export default function UISettings({ customer, onSave }: IUIFormProps) {
  const formClasses = useFormStyles();
  const { ui } = customer.settings;
  const initial = merge(getInitialUI(), ui);
  const isResolutionSevenHundred = useBreakpoints(700);

  return (
    <Form
      onSubmit={(values) => onSave({
        ...customer.settings,
        ui: values,
        fee: customer.fees,
        excludedCurrencies: customer.excludedCurrencies,
        smsesPerDay: customer.dailySmsLimit,
      })}
      initialValues={initial}
      render={({ handleSubmit, values, invalid }) => (
        <div className={formClasses.root}>
          <div className={formClasses.row}>
            <Typography variant="body1" color="primary" align="center">Global</Typography>
          </div>
          <div className={formClasses.row}>
            <Input name="global.logo" label="Logo URL" />
          </div>
          <div className={formClasses.row}>
            <Input name="global.favicon" label="Favicon URL" />
          </div>
          <div className={formClasses.row}>
            <Select
              name="global.locale.defaultLang"
              label="Default locale"
              options={langs}
              fullWidth={isResolutionSevenHundred}
            />
            <Checkbox name="global.locale.langChangeDisable" label="Disable language change" />
          </div>
          <Typography variant="body2" color="primary">Form title</Typography>
          <div className={formClasses.row}>
            <Input name="form.title.en" label="EN" />
            <Input name="form.title.ru" label="RU" />
          </div>
          <Typography variant="body2" color="primary">Form button text</Typography>
          <div className={formClasses.row}>
            <Input name="form.buttonName.en" label="EN" />
            <Input name="form.buttonName.ru" label="RU" />
          </div>
          <Typography variant="body2" color="primary">Form button colors</Typography>
          <div className={formClasses.row}>
            <PaletteItem color={values.form.buttonColor && values.form.buttonColor.base}>
              <Input name="form.buttonColor.base" label="Main" validate={isHEX} />
            </PaletteItem>
            <PaletteItem color={values.form.buttonColor && values.form.buttonColor.hover}>
              <Input name="form.buttonColor.hover" label="Hover" validate={isHEX} />
            </PaletteItem>
          </div>
          <div className={formClasses.row}>
            <Input name="global.chatID" label="Freshchat token" />
          </div>
          <Typography variant="body2" color="primary">Form border radius</Typography>
          <div className={formClasses.row}>
            <Input name="form.borderRadius.input" label="Input" />
            <Input name="form.borderRadius.button" label="Button" />
          </div>
          <Divider className={formClasses.endBlock} />
          <div className={formClasses.row}>
            <Typography variant="body1" color="primary" align="center">Footer</Typography>
          </div>
          <div className={formClasses.row}>
            <Input name="global.footer.copyright" label="Copyright" />
            <Checkbox name="global.footer.show" label="Show footer content" className="v-center" />
          </div>
          <Typography variant="body2" color="primary">Footer text</Typography>
          <div className={formClasses.row}>
            <Input name="global.footer.text.en" label="EN" multiline />
            <Input name="global.footer.text.ru" label="RU" multiline />
          </div>
          <Typography variant="body2" color="primary">Terms menu item</Typography>
          <div className={formClasses.row}>
            <Input name="global.footer.menu.terms.url" label="URL" validate={isURL} />
            <Input name="global.footer.menu.terms.text.en" label="EN title" />
            <Input name="global.footer.menu.terms.text.ru" label="RU title" />
          </div>
          <Typography variant="body2" color="primary">Privacy menu item</Typography>
          <div className={formClasses.row}>
            <Input name="global.footer.menu.privacy.url" label="URL" validate={isURL} />
            <Input name="global.footer.menu.privacy.text.en" label="EN title" />
            <Input name="global.footer.menu.privacy.text.ru" label="RU title" />
          </div>
          <Divider className={formClasses.endBlock} />
          <div className={formClasses.row}>
            <Typography variant="body1" color="primary" align="center">Authorization form</Typography>
          </div>
          <Typography variant="body2" color="primary">Title</Typography>
          <div className={formClasses.row}>
            <Input name="form.authorization.title.en" label="EN" />
            <Input name="form.authorization.title.ru" label="RU" />
          </div>
          <Typography variant="body2" color="primary">Currency step title</Typography>
          <div className={formClasses.row}>
            <Input name="form.authorization.stepCurrencyTitle.en" label="EN" />
            <Input name="form.authorization.stepCurrencyTitle.ru" label="RU" />
          </div>
          <Typography variant="body2" color="primary">Email step title</Typography>
          <div className={formClasses.row}>
            <Input name="form.authorization.stepEmailTitle.en" label="EN" />
            <Input name="form.authorization.stepEmailTitle.ru" label="RU" />
          </div>
          <Typography variant="body2" color="primary">Currency field</Typography>
          <div className={formClasses.row}>
            <Input name="form.authorization.fields.currency.title.en" label="EN" />
            <Input name="form.authorization.fields.currency.title.ru" label="RU" />
            <div className={!isResolutionSevenHundred ? 'v-center' : 'v-left'}>
              <Checkbox name="form.authorization.fields.currency.show" label="Show field" />
            </div>
          </div>
          <Typography variant="body2" color="primary">Payment currency field</Typography>
          <div className={formClasses.row}>
            <Input name="form.authorization.fields.paymentCurrency.title.en" label="EN" />
            <Input name="form.authorization.fields.paymentCurrency.title.ru" label="RU" />
            <div className={!isResolutionSevenHundred ? 'v-center' : 'v-left'}>
              <Checkbox name="form.authorization.fields.paymentCurrency.show" label="Show field" />
            </div>
          </div>
          <Typography variant="body2" color="primary">Wallet address field</Typography>
          <div className={formClasses.row}>
            <Input name="form.authorization.fields.walletAddress.title.en" label="EN" />
            <Input name="form.authorization.fields.walletAddress.title.ru" label="RU" />
            <div className={!isResolutionSevenHundred ? 'v-center' : 'v-left'}>
              <Checkbox name="form.authorization.fields.walletAddress.show" label="Show field" />
            </div>
          </div>
          <Typography variant="body2" color="primary">Email field</Typography>
          <div className={formClasses.row}>
            <div className={!isResolutionSevenHundred ? 'v-center' : 'v-left'}>
              <Checkbox name="form.authorization.fields.email.show" label="Show field" />
            </div>
            <div className={!isResolutionSevenHundred ? 'v-center' : 'v-left'}>
              <Checkbox name="form.authorization.fields.email.disable" label="Disable field" />
            </div>
          </div>
          <Divider className={formClasses.endBlock} />
          <div className={formClasses.row}>
            <Typography variant="body1" color="primary" align="center">Personal info form</Typography>
          </div>
          <Typography variant="body2" color="primary">Title</Typography>
          <div className={formClasses.row}>
            <Input name="form.personalInfo.title.en" label="EN" />
            <Input name="form.personalInfo.title.ru" label="RU" />
          </div>
          <Divider className={formClasses.endBlock} />
          <div className={formClasses.row}>
            <Typography variant="body1" color="primary" align="center">Verification form</Typography>
          </div>
          <Typography variant="body2" color="primary">Title</Typography>
          <div className={formClasses.row}>
            <Input name="form.verification.title.en" label="EN" />
            <Input name="form.verification.title.ru" label="RU" />
          </div>
          <Typography variant="body2" color="primary">Info text</Typography>
          <div className={formClasses.row}>
            <Input name="form.verification.infoText.en" label="EN" multiline />
            <Input name="form.verification.infoText.ru" label="RU" multiline />
          </div>
          <Divider className={formClasses.endBlock} />
          <div className={formClasses.row}>
            <Typography variant="body1" color="primary" align="center">Palette</Typography>
          </div>
          <Typography variant="body2" color="primary">Primary</Typography>
          <div className={formClasses.row}>
            <PaletteItem color={values.global.palette.primary.main}>
              <Input name="global.palette.primary.main" label="Main" validate={isHEX} />
            </PaletteItem>
            <PaletteItem color={values.global.palette.primary.dark}>
              <Input name="global.palette.primary.dark" label="Dark" validate={isHEX} />
            </PaletteItem>
            <PaletteItem color={values.global.palette.primary.light}>
              <Input name="global.palette.primary.light" label="Light" validate={isHEX} />
            </PaletteItem>
          </div>
          <Typography variant="body2" color="primary">Secondary</Typography>
          <div className={formClasses.row}>
            <PaletteItem color={values.global.palette.secondary.main}>
              <Input name="global.palette.secondary.main" label="Main" validate={isHEX} />
            </PaletteItem>
            <PaletteItem color={values.global.palette.secondary.dark}>
              <Input name="global.palette.secondary.dark" label="Dark" validate={isHEX} />
            </PaletteItem>
            <PaletteItem color={values.global.palette.secondary.light}>
              <Input name="global.palette.secondary.light" label="Light" validate={isHEX} />
            </PaletteItem>
          </div>
          <Typography variant="body2" color="primary">Text</Typography>
          <div className={formClasses.row}>
            <PaletteItem color={values.global.palette.text.primary}>
              <Input name="global.palette.text.primary" label="Primary" validate={isHEX} />
            </PaletteItem>
            <PaletteItem color={values.global.palette.text.secondary}>
              <Input name="global.palette.text.secondary" label="Secondary" validate={isHEX} />
            </PaletteItem>
            {/*<PaletteItem color={values.global.palette.text.link}>*/}
            {/*  <Input name="global.palette.text.link" label="Link" validate={isHEX} />*/}
            {/*</PaletteItem>*/}
          </div>
          <Typography variant="body2" color="primary">Border</Typography>
          <div className={formClasses.row}>
            <PaletteItem color={values.global.palette.border}>
              <Input name="global.palette.border" label="Input border" validate={isHEX} />
            </PaletteItem>
          </div>
          <Divider className={formClasses.endBlock} />
          <div className={formClasses.row}>
            <Typography variant="body1" color="primary" align="center">Promo</Typography>
          </div>
          <div className={formClasses.row}>
            <Input name="promo.twitterLink" label="Twitter Link" />
          </div>
          <div className={formClasses.actions}>
            <Button
              onClick={handleSubmit}
              disabled={invalid}
              size="large"
              variant="contained"
            >
              Save
            </Button>
          </div>
        </div>
      )}
    />
  );
}
