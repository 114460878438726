import React from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Form } from 'react-final-form';
import {
  IPaymentGroup,
  useCreatePaymentGroup,
  useDeletePaymentGroup,
  useUpdatePaymentGroup
} from "../../../../api/paymentGroup";
import useFormStyles from "../../../../hooks/useFormStyles";
import DetailsPopup from "../../../../components/Layout/DetailsPopup";
import {Input, ProtectionInput} from "../../../../components/Form";

interface IProps {
  group: IPaymentGroup | { id: null };
  onClose: any;
  onSave: any;
}

export default function PaymentGroupDetails({ group, onClose, onSave }: IProps) {
  const formClasses = useFormStyles();
  const [create, creating] = useCreatePaymentGroup((res: any) => {
    if (res.success) {
      onSave();
    }
  });

  const [update, updating] = useUpdatePaymentGroup((res: any) => {
    if (res.success) {
      onSave();
    }
  });

  const [deleteGroup, deleting] = useDeletePaymentGroup((res: any) => {
    if (res.success) {
      onSave();
    }
  });

  const formData = {...group, id: group.id === 'new' ? undefined : group.id};
  return (
    <DetailsPopup onClose={onClose} loading={false} >
      {formData
        ? (
          <>
            <Typography variant="h6" color="primary">{
              formData.id
                ? `Update Group ${formData?.id}`
                : 'Create new group'
            }</Typography>
            <Form
              onSubmit={(values) => formData.id
                ? update({...values, id: formData.id})
                : create({ ...values})}
              initialValues={{ ...formData, '2fa': '' }}
              render={({ handleSubmit, values }) => (
                <form
                  autoComplete='nope'
                  onSubmit={handleSubmit}
                >
                  <div className={formClasses.row}>
                    <Input name="id" label="Id" disabled={formData.id !== undefined}/>
                  </div>
                  <div className={formClasses.row}>
                    <Input name="name" label="Name" />
                  </div>
                  <div className={formClasses.row}>
                    <Input type="text" name="logo" label="Logo" />
                  </div>
                  <div className={formClasses.row}>
                    <ProtectionInput />
                  </div>
                  <div className={formClasses.actions}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={updating || creating}
                    >
                      Save
                    </Button>
                    {formData?.id &&
                        <Button
                            variant="contained"
                            disabled={updating || creating || deleting || values['2fa']?.length < 6}
                            color="secondary"
                            onClick={() => {
                              deleteGroup({id: values.id, '2fa': values['2fa'] })
                            }}
                        >
                            Delete
                        </Button>
                    }
                  </div>
                </form>
              )}
            />
          </>
        ) : <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  )
}