import React from 'react';
import { Form } from 'react-final-form';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Input, BoolSelect } from '../../../components/Form';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';

export interface IKycStatesFilter {
  code: string
  supported: boolean
  highRisk : boolean
  riskScore : number
  scoreMin : number
  scoreMax : number
}

interface IProps {
  filter: Partial<IKycStatesFilter>;
  onChange: any;
}

const useStyles = makeStyles(() => ({
  row: {
    '& > div': {
      maxWidth: '100% !important'
    }
  }
}))

export default function KYCStatesFilter({ filter, onChange }: IProps) {
  const classes = useGlobalStyles();
  const styles = useStyles()

  return (
    <Form
      onSubmit={onChange}
      initialValues={filter}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={clsx(classes.filters, styles.row)}>
            <Input
              name="code"
              label="Code"
              margin="none"
            />
            <Input
              name="riskScore"
              label="Risk score"
              margin="none"
            />
            <Input
              name="scoreMin"
              label="Min KYC score"
              margin="none"
            />
            <Input
              name="scoreMax"
              label="Max KYC score"
              margin="none"
            />
          </div>
          <div className={clsx(classes.filters, styles.row)}>
            <BoolSelect
              name="supported"
              label="Supported"
            />
            <BoolSelect
              name="highRisk"
              label="High risk"
            />
            <Button type="submit" variant="contained" size="large" className="apply">Apply</Button>
          </div>
        </form>
      )}
    />
  );
}
