import React from 'react';

import SearchIcon from "@material-ui/icons/Search";
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';

interface ISearchSubstrProps {
   search: string
   setSearch: (search: string)=> void
   label: string
   id: string
   labelWidth?: number
}

function SearchSubstr({ search, setSearch, label, id, labelWidth }:ISearchSubstrProps) {
return(
   <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor={`search-${id}`}>{label}</InputLabel>
      <OutlinedInput
         id={`search-${id}`}
         value={search}
         onChange={(e) => setSearch(e.target.value)}
         autoComplete="new-password"
         startAdornment={
         <InputAdornment position="start">
            <SearchIcon />
         </InputAdornment>}
         labelWidth={labelWidth || 40}
      />
   </FormControl>
)}
export default SearchSubstr;