import React, { useEffect, useState, useMemo } from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import isEqual from 'lodash/isEqual';
import { Form } from 'react-final-form';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import ColHead from '../../../../components/UI/ColHead';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ConfirmationDialog from './ConfirmationDialog';
import useFormStyles from '../../../../hooks/useFormStyles';
import MUInput from '@material-ui/core/OutlinedInput';
import { Input, ProtectionInput } from '../../../../components/Form';
import { ICustomer } from '../../../../api/customers';
import { useDeleteFee } from '../../../../api/currency';
import { composeValidators, mustBeNumber, maxValue, minValue } from '../../../../validators';
import Label from '../../../../components/Label';
import { useBreakpoints } from '../../../../hooks/useBreakpoints ';
import useMobileStyles from '../../../../hooks/useMobileStyles';
import Tablecell from '../../../../components/TableCell/TableCell';

interface IProps {
  customer: ICustomer;
  fees: any;
  restHandlers: any;
  currencies: any;
}

const feeFields = ['rolling', 'deposit', 'depositFixed', 'depositMerchant', 'depositMerchantFixed', 'withdrawal', 'withdrawalFixed'];

function normalizeFeesValues(raw: any) {
  const feesData: any = {};
  feeFields.forEach((f) => {
    const fee = raw[f];
    const decimals = String(fee).split('-')[1];
    if (decimals) {
      feesData[f] = fee.toFixed(decimals);
    } else {
      feesData[f] = fee;
    }
  })
  return feesData;
}

const deletePopupRenderData = (customerId: string, deleting: any) => ({
  title: `Clear customer #${customerId} fee set for ${deleting.currency}?`,
  btnLabel: 'Clear',
  data: deleting
})

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      gap: '15px',
      alignItems: 'flex-start',
    },
  },
  filterBtn: {
    marginRight: theme.spacing(1)
  },
  form: {
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0px 0px',
    },
  },
  table: {
    '& th': {
      position: 'sticky',
      zIndex: 3,
      top: theme.spacing(-2),
      backgroundColor: theme.palette.background.paper
    },
    '& tr:last-child td': {
      borderBottom: 0,
    },
  },
  tableRow: {
    '& td': {
      padding: 0,
      paddingRight: theme.spacing(5),
      borderBottom: 0,
    },
    '& td>div': {
      marginTop: theme.spacing(1),
    },
    '& td label': {
      fontSize: theme.spacing(1.5)
    }
  },
  input: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: '1.24em',
  },
  actions: {
    border: 'none',
    marginTop: 0,
    paddingTop: 0
  },
  clear: {
    flex: '0 0 44px',
     marginTop: 4,
     [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  }
}))

//@ts-ignore
const sortCurrencies = (currencies: any, filterByType: any, filterByName: any, sortDesc: boolean) => {
  if(!currencies || !currencies.length) return [];

  const filterFn = (currType: string, filterName: any) => {
    return (item: any) => item.type === currType && (filterByName ? item.currency.includes(filterByName.toUpperCase()) : true);
  }

  const sortFn = (sortDesc: boolean) => {
    return (a: any, b: any) => {
      if (sortDesc) {
        //@ts-ignore
        return b.currency < a.currency ? -1 : b.currency > a .currency? 1 : 0;
      } else {
        // @ts-ignore
        return a.currency < b.currency ? -1 : a.currency > b .currency? 1 : 0;
      }
    }
  }

  const fiat = currencies.filter(filterFn('FIAT', filterByName)).sort(sortFn(sortDesc));
  const crypto = currencies.filter(filterFn('CRYPTO', filterByName)).sort(sortFn(sortDesc));

  switch(filterByType) {
    case 'FIAT': return [...fiat];
    case 'CRYPTO': return [...crypto];
    default: return [...fiat, ...crypto];
  }
};

export default function CustomerFeesForm({ customer, fees, currencies, restHandlers }: IProps) {
  const formClasses = useFormStyles();
  const classes = useStyles();
  const [feesForms, setFeesForms] = useState<any>({});
  const [filterByType, setFilterByType] = useState<'FIAT' | 'CRYPTO' | null>(null);
  const [filterByName, setFilterByName] = useState('');
  const [sortDesc, setSortDesc] = useState(false);
  const isSm = useBreakpoints('sm');
  const mobileClasses = useMobileStyles();

  const [showConfirmationDialogWithData, setShowConfirmationDialogWithData] = useState<any | undefined>();
  const { create, creating, update, updating, load } = restHandlers;

  const [deleteFee, feeDeleting] = useDeleteFee((res: any) => {
    if (res.success) {
      // setFeesForms({ ...feesForms, [res.data.currency]: res.data });
      // setDeleting(undefined);
      setShowConfirmationDialogWithData(null);
      load(customer.id);
    }
  });

  useEffect(() => {
    let initial: any = {};
    const sortedCurrencies = sortCurrencies(currencies, filterByType, filterByName, sortDesc);
    if (fees.length && sortedCurrencies.length) {
      sortedCurrencies.forEach((c: any) => {
        const defaultSetings = {
          customerId: customer.id,
          currency: c.currency,
          id: undefined
        };

        const customerCurrenciesSettings = fees.find((f: any) => f.customerId === customer.id && f.currency === c.currency);
        const customerSettings = fees.find((f: any) => f.customerId === customer.id && !f.currency);
        const globalCurrenciesSettings = fees.find((f: any) => !f.customerId && f.currency === c.currency);

        if (customerCurrenciesSettings) {
          initial[c.currency] = { ...customerCurrenciesSettings, fees: normalizeFeesValues(customerCurrenciesSettings.fees), appliedFrom: 'customer currency' };
        } else if (customerSettings) {
          initial[c.currency] = {
            ...customerSettings,
            ...defaultSetings,
            fees: normalizeFeesValues(customerSettings.fees),
            appliedFrom: 'customer'
          };
        } else if (globalCurrenciesSettings) {
          initial[c.currency] = {
            ...globalCurrenciesSettings,
            ...defaultSetings,
            fees: normalizeFeesValues(globalCurrenciesSettings.fees),
            appliedFrom: 'currency'
          };
        } else {
          const globalSettings = fees.find((f: any) => !f.customerId && !f.currency);
          initial[c.currency] = {
            ...globalSettings,
            ...defaultSetings,
            fees: normalizeFeesValues(globalSettings.fees),
            appliedFrom: 'global'
          };
        }
      })
    }
    setFeesForms(initial);
  }, [fees, currencies, filterByType, filterByName, sortDesc])

  return <>
    <ConfirmationDialog
      showConfirmationDialogWithData={showConfirmationDialogWithData}
      setShowConfirmationDialogWithData={setShowConfirmationDialogWithData}
      onSubmit={(data: any) => deleteFee({ id: data.id, '2fa': data['2fa'] })}
    />
    <div className={formClasses.row}>
      <Typography variant="body1" color="primary" >Fees exceptions for customer currencies</Typography>
    </div>
    <div className={classes.filters}>
      <Button className={classes.filterBtn} variant={!filterByType ? 'contained' : 'outlined'} onClick={() => setFilterByType(null)}>All</Button>
      <Button className={classes.filterBtn} variant={filterByType === 'FIAT' ? 'contained' : 'outlined'} onClick={() => setFilterByType('FIAT')}>Fiat</Button>
      <Button className={classes.filterBtn} variant={filterByType === 'CRYPTO' ? 'contained' : 'outlined'} onClick={() => setFilterByType('CRYPTO')}>Crypto</Button>

      <MUInput
        classes={classes}
        name='currencyCode'
        onChange={({target}) => setFilterByName(target.value)}
      />
    </div>
      <Form
        onSubmit={(values) => {
          Object.keys(values).forEach((c) => {
            if (c !== '2fa' && !isEqual(values[c], feesForms[c])) {
              const data = { fees: {}, ...values[c], '2fa': values['2fa'] };
              if (!!data.id) {
                return update(data);
              }
              create(data);
            }
          })
        }}
        initialValues={{ ...feesForms, '2fa': '' }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={clsx(formClasses.root, classes.form)}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <ColHead
                    title='Currency'
                    onSort={() => setSortDesc(!sortDesc)}
                    isSorted={true}
                    sortDirection={sortDesc ? 'desc' : 'asc'}
                  />
                  {!isSm && 
                    <>
                      <ColHead title='Deposit' />
                      <ColHead title='Dep. Fixed' />
                      <ColHead title='Withdrawal' />
                      <ColHead title='Withdrawal Fixed' />
                      <ColHead title='Merch. Deposit' />
                      <ColHead title='Merch. Dep. Fixed' />
                      <ColHead title='Applied from' />
                    </>}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(feesForms).map((f) => (
                  <TableRow className={clsx(formClasses.row, mobileClasses.row, classes.tableRow,'closest')} key={f}>
                    <Tablecell title="Currency">
                      <Typography variant="body1" color="primary" component="div" className="v-center" style={{ flex: '0 0 35px', marginTop: 6 }}>
                        {f}
                      </Typography>
                    </Tablecell>
                    <Tablecell title="Deposit">
                      <Input
                        name={`${f}.fees.deposit`}
                        label=""
                        validate={composeValidators(mustBeNumber, (v: any) => maxValue(v, 1), (v: any) => minValue(v, 0))}
                        fullWidth={isSm}
                      />
                    </Tablecell>
                    <Tablecell title="Dep. Fixed">
                      <Input
                        name={`${f}.fees.depositFixed`}
                        label=""
                        validate={composeValidators(mustBeNumber, (v: any) => minValue(v, 0))}
                      />
                    </Tablecell>
                    <Tablecell title="Withdrawal">
                      <Input
                        name={`${f}.fees.withdrawal`}
                        label=""
                        validate={composeValidators(mustBeNumber, (v: any) => maxValue(v, 1), (v: any) => minValue(v, 0))}
                      />
                    </Tablecell>
                    <Tablecell title="Withdrawal Fixed">
                      <Input
                        name={`${f}.fees.withdrawalFixed`}
                        label=""
                        validate={composeValidators(mustBeNumber, (v: any) => minValue(v, 0))}
                      />
                    </Tablecell>
                    <Tablecell title="Merch. Deposit">
                      <Input
                        name={`${f}.fees.depositMerchant`}
                        label=""
                        validate={composeValidators(mustBeNumber, (v: any) => maxValue(v, 1), (v: any) => minValue(v, 0))}
                      />
                    </Tablecell>
                    <Tablecell title="Merch. Dep. Fixed">
                      <Input
                        name={`${f}.fees.depositMerchantFixed`}
                        label=""
                        validate={composeValidators(mustBeNumber, (v: any) => minValue(v, 0))}
                      />
                    </Tablecell>
                    <Tablecell title="Applied from">
                      <Input
                        name={`${f}.fees.rolling`}
                        label=""
                        validate={composeValidators(mustBeNumber, (v: any) => maxValue(v, 1), (v: any) => minValue(v, 0))}
                      />
                    </Tablecell>
                    <TableCell>
                      <div className={clsx(classes.clear,'v-center')}>
                        {!!feesForms[f].id
                          ? (
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => setShowConfirmationDialogWithData(deletePopupRenderData(customer.id, feesForms[f]))}
                            >Clear</Button>
                          ) : <Label label={feesForms[f].appliedFrom}/>}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <ColHead
                    title='Currency'
                    onSort={() => setSortDesc(!sortDesc)}
                    isSorted={true}
                    sortDirection={sortDesc ? 'desc' : 'asc'}
                  />
                  {!isSm && 
                    <>
                      <ColHead title='Deposit' />
                      <ColHead title='Dep. Fixed' />
                      <ColHead title='Withdrawal' />
                      <ColHead title='Withdrawal Fixed' />
                      <ColHead title='Merch. Deposit' />
                      <ColHead title='Merch. Dep. Fixed' />
                      <ColHead title='Applied from' />
                    </>}
                  <TableCell></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
            <div className={formClasses.row}>
              <ProtectionInput />
            </div>
            <div className={clsx(formClasses.actions, classes.actions)}>
              <Button
                variant="contained"
                type="submit"
                disabled={creating || updating}
              >Save fees</Button>
            </div>
          </form>
        )}
      />
  </>
}
