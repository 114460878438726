import React from 'react';

import TableFilter from '../../components/Filter';
import { Input, Select } from '../../components/Form';
import { SettlementStatus } from '../../api/settlements';
import { Checkbox, FormControlLabel } from '@material-ui/core';

export interface ISettlementsFilter {
  dateFrom: Date | null;
  dateTo: Date | null;
  customerId: string | null;
  id: string | null;
  status: string | null;
}

interface IProps{
  filter: ISettlementsFilter,
  onChange: any,
  setIsIgnoreDateFilter?: (dateIgnore: boolean)=> void,
  isIgnoreDateFilter?: boolean
}

export default function SettlementsFilter({ filter, onChange, setIsIgnoreDateFilter, isIgnoreDateFilter }: IProps){
  return (
    <TableFilter onApply={onChange} filter={filter}>
        <Input
          name="id"
          label="Settlement ID"
          margin="none"
        />
        <Input
          name="customerId"
          label="Merchant ID"
          margin="none"
        />
        <Select
          name="status"
          label="Status"
          multi
          fullWidth
          options={Object.keys(SettlementStatus).map((t) => ({ value: t, label: t }))}
        />
        {setIsIgnoreDateFilter && 
          <FormControlLabel
            control={
              <Checkbox 
                checked={isIgnoreDateFilter} 
                onChange={() => setIsIgnoreDateFilter(!isIgnoreDateFilter)} 
              />}
            label="Ignore filter by Date"
        />}
    </TableFilter>
  );
}