import React, { useState } from 'react';
import { Form } from 'react-final-form';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateView from '../../../components/DateView';
import BoolLabel from '../../../components/Label/BoolLabel';
import Loader from '../../../components/Loader';
import { ProtectionInput } from '../../../components/Form';
import { IColumn } from '../../../hooks/useColumns';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import { IACLItem, useDeleteBWItem } from '../../../api/acl';
import Tablecell from '../../../components/TableCell/TableCell';
import TablecellId from '../../../components/TableCellId/TableCellId';
import useMobileStyles from '../../../hooks/useMobileStyles';

interface IProps {
  items: IACLItem[];
  columns: IColumn[];
  onDelete: any;
}

export default function BlackWhiteList({ items, columns, onDelete }: IProps) {
  const [del, setDel] = useState<IACLItem | undefined>();
  const globalClasses = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  const [remove, removing] = useDeleteBWItem((res: any) => {
    if (res.success) {
      onDelete(del, 'id', 'delete');
      setDel(undefined);
    }
  })

  const activeCols = columns.filter((c) => c.active);

  const content: { [key: string]: (i: IACLItem) => void } = {
    id: ({ id }) => <TablecellId key="1">{id}</TablecellId>,
    type: ({ type }) => <Tablecell title="Type" key="2">{type}</Tablecell>,
    value: ({ value }) => <Tablecell title="Value" key="3">{value}</Tablecell>,
    black: ({ black }) => <Tablecell title="Is blacklist" key="4"><BoolLabel value={black} /></Tablecell>,
    createdAt: ({ createdAt }) => <Tablecell title="Created" key="created"><DateView value={createdAt} /></Tablecell>,
    updatedAt: ({ updatedAt }) => <Tablecell title="Updated" key="updated"><DateView value={updatedAt} /></Tablecell>,
    action: (i) => (
      <TableCell key="action">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setDel(i)}
          size="small"
        >
          Remove
        </Button>
      </TableCell>
    )
  };

  return (
    <>
      {del && (
        <Dialog open onClose={() => setDel(undefined)}>
          <div className={globalClasses.dialog}>
            <Loader loading={removing} width={450}>
              <Form
                onSubmit={remove}
                initialValues={{ id: del.id, '2fa': '' }}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <DialogTitle>Remove item #{del.id}?</DialogTitle>
                    <DialogContent>
                      <DialogContentText>{del.type}: {del.value}</DialogContentText>
                      <ProtectionInput />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="contained"
                        onClick={() => setDel(undefined)}
                        size="small"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        size="small"
                      >
                        Remove
                      </Button>
                    </DialogActions>
                  </form>
                )}
              />
            </Loader>
          </div>
        </Dialog>
      )}
      <Table size="small">
        <TableHead className={mobileClasses.tableHead}>
          <TableRow>
            {activeCols.map((c) => <TableCell key={c.id}>{c.name}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((i) => (
            <TableRow hover key={i.id} className={mobileClasses.row}>
              {activeCols.map((c) => content[c.id](i))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}