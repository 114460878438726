import React from 'react';

import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

import { ICustomer } from '../../../api/customers';
import { Input, ProtectionInput } from '../../../components/Form';
import { composeValidators, mustBeNumber, required } from '../../../validators';
import useFormStyles from '../../../hooks/useFormStyles';
import { CurrencyType, ICurrency } from '../../../api/currency';
import SelectField from '../../../components/Form/SelectField';

const useStyles = makeStyles((theme) => ({
  form: {
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px'
    },
  },
  button: {
    width: '284px',
    [theme.breakpoints.down(700)]: {
      width: '100%',
    },
  },
  row: {
    flex: '0 0 300px',
    width: '300px',
    paddingTop: '10px',
    [theme.breakpoints.down(700)]: {
      flex: '0 0 100%',
      width: '100%',
    },
  },
  formActions: {
    display: 'block',
  }
}));

const INITIAL_VALUES= {
  formData:[{ customerId:'', currencies: [{ currency: 'EUR', amount: '' }] }],
  '2fa': '',
};

interface IFormCurrency {
  currency: string,
  amount: string,
}

interface IFormData {
  customerId: string,
  currencies: IFormCurrency[]
}

interface ISettlementCreateForm {
  create: (data: any)=> void
  customer: ICustomer
  currencies: ICurrency[]
}

function SettlementCreateForm({ create, customer, currencies }:ISettlementCreateForm) {

  const classes = useStyles();
  const formClasses = useFormStyles();

  return(
    <Form
      onSubmit={(values)=> {
      
        const mappedFormData = Object.fromEntries(values.formData.map((item: IFormData)=>
        [item.customerId, item?.currencies?.length > 0 ? Object.fromEntries(item.currencies.map(currency=> [currency.currency, currency.amount])): '']));

        create({
          settlementsData: mappedFormData,
          customerId: customer.id,
          '2fa': values['2fa']
        });
      }}    
      mutators={{
        ...arrayMutators
      }}
      initialValues={INITIAL_VALUES}
      render={({ handleSubmit, form: {
        mutators: { push } }, invalid }) => (
        <form onSubmit={handleSubmit}>
          <FieldArray name="formData">
            {({ fields }) => (
              <div>
                {fields.map((name, index) => (
                  <div key={name}>
                    <Input
                      name={`${name}.customerId`}
                      label="Customer ID"
                      type="search"
                      autoComplete="xyz123"
                      validate={composeValidators(
                        required,
                        mustBeNumber
                      )}
                    />

                    <div className={clsx(formClasses.row, classes.row)}>
                      <Button type="button"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={() => fields.remove(index)}
                      >
                        Remove Customer ID
                      </Button>
                    </div>

                    <FieldArray name={`${name}.currencies`}>
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={name}>
                              <div className={formClasses.row}>
                                <SelectField
                                  name={`${name}.currency`}
                                  label="Currency"
                                  noEmptyOption
                                  fullWidth
                                  options={currencies.filter((currency: ICurrency)=> currency.type === CurrencyType.FIAT)
                                    .map((item: ICurrency)=>({ label: item.currency, value: item.currency }))}
                                />
                              </div>
                              <div className={formClasses.row}>
                                <Input
                                  name={`${name}.amount`}
                                  label="Amount"
                                  validate={composeValidators(required, mustBeNumber)}
                                  fullWidth
                                />
                              </div>
                              <div className={clsx(formClasses.row, classes.row)}>
                                <Button type="button"
                                  variant="contained"
                                  color="secondary"
                                  className={classes.button}
                                  onClick={() => fields.remove(index)}
                                >
                                  Remove currency
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </FieldArray>

                    <div className={clsx(formClasses.row, classes.row)}>
                      <Button 
                        type="button" 
                        variant="contained"
                        className={classes.button}
                        onClick={() => push(`${name}.currencies`)} 
                        >
                          Add currency
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>

          <div className={clsx(formClasses.row, classes.row)}>
            <Button 
              type="button" 
              variant="contained" 
              onClick={() => push('formData')}
              className={classes.button}
            >
              Add Customer ID
            </Button>
          </div>

          <div className={clsx(formClasses.actions, classes.formActions)}>
            <div className={formClasses.row}>
              <ProtectionInput />
            </div>
            <div className={clsx(formClasses.row, classes.row)}>
              <Button type="submit" className={classes.button} variant="contained" disabled={invalid}>
                Save
              </Button>
          </div>
          </div>
        </form>
      )}
    />
  );
}

export default SettlementCreateForm;