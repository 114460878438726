import React, { FC } from 'react';

import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';

import { IMerchantSettlementFees } from '../../../../types';
import PopupLink from '../../../../components/UI/PopupLink';

interface IMerchantsFees {
  fees: Record<string, IMerchantSettlementFees>
}

export const MerchantsFees: FC<IMerchantsFees> = ({ fees }) => {
  return(
    <>
      <Typography variant="body1" component="div" color="primary">Merchant's Fees</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Customer ID</TableCell>
            <TableCell align="center">Fiat Settlement Fee</TableCell>
            <TableCell align="center">Crypto Settlement Fee</TableCell>
            <TableCell align="center">FX Settlement Fee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(fees).map((customerId, index) =>
            (
              <TableRow key={index}>
                <TableCell>
                  <PopupLink popup="customer" id={customerId} baseUrl={'/customers'}>
                    {customerId}
                  </PopupLink>
                </TableCell>
                <TableCell align="center">{fees[customerId].fiatSettlementFee}</TableCell>
                <TableCell align="center">{fees[customerId].cryptoSettlementFee}</TableCell>
                <TableCell align="center">{fees[customerId].fxSettlementFee}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </>
  );
}
export default MerchantsFees;