import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateView from '../../components/DateView';
import RollingStatusLabel from '../../components/Label/RollingStatusLabel';
import ColHead from '../../components/UI/ColHead';
import PopupLink from '../../components/UI/PopupLink';
import { IRollingItem } from '../../api/rolling';
import { IColumn } from '../../hooks/useColumns';
import TableCellId from '../../components/TableCellId/TableCellId';
import Tablecell from '../../components/TableCell/TableCell';
import useMobileStyles from '../../hooks/useMobileStyles';

interface IProps {
  items: IRollingItem[];
  columns: IColumn[];
}

const content: { [key: string]: (item: IRollingItem, key: number) => void } = {
  id: (i, k) => <TableCellId key={k}>{i.id}</TableCellId>,
  created: (i, k) => <Tablecell key={k} title="Created"><DateView value={i.createdAt} /></Tablecell>,
  amount: (i, k) => <Tablecell key={k} title="Amount">{i.amount}</Tablecell>,
  currency: (i, k) => <Tablecell key={k} title="Currency">{i.currency}</Tablecell>,
  orderId: (i, k) => <Tablecell key={k} title="Order ID"><PopupLink popup="order" id={i.orderId}>{i.orderId}</PopupLink></Tablecell>,
  customer: (i, k) => (
    <Tablecell key={k} title="Customer">
      <PopupLink popup="customer" id={i.customerId}>{i.customerEmail || i.customerId}</PopupLink>
    </Tablecell>
  ),
  status: (i, k) => <Tablecell key={k} title="Status"><RollingStatusLabel status={i.status} /></Tablecell>,
  updated: (i, k) => <Tablecell key={k} title="Updated" isLast><DateView value={i.updatedAt} /></Tablecell>,
};

export default function RollingReserveList({ items, columns }: IProps) {
  const mobileClasses = useMobileStyles();

  return (
    <Table size="small">
      <TableHead className={mobileClasses.tableHead}>
        <TableRow>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow key={i.id} hover className={mobileClasses.row}>
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}