import Api, { useFetchApi } from './index';

export enum WalletOperationType {
  DEPOSIT = 'DEPOSIT', // Add to balance
  WITHDRAWAL = 'WITHDRAWAL',  // Subtract from balance after success withdrawal
  LOCK = 'LOCK',        // Lock balance before withdrawal
  UNLOCK = 'UNLOCK'
}

export enum WalletOperationIssuerType {
  PAYMENT = 'PAYMENT',
  ORDER = 'ORDER',
  ADMIN = 'ADMIN'
}

export interface IWalletOperation {
  id: string;
  createdAt: number;
  updatedAt: number;
  customerId: number;
  walletId: number;
  type: WalletOperationType;
  balance: number;
  locked: number;
  issuedBy: number;
  issuerType: string;
  currency: string;
}

export interface IMasterAccountBalance {
  id: string;
  createdAt: number;
  updatedAt: number;
  customerId: number;
  balance: number;
  locked: number;
  sid: string;
  email: string;
  currency: string;
  isMaster: boolean;
}

export interface ITotalBalanceByCurrency {
  currency: string,
  amount: number
}

export function useWalletOperations() {
  return useFetchApi(
    async (query: any) => Api.post('/customer/wallet/operations', { limit: 50, ...query }),
    { items: [] }
  )
}

export function useCurrencyBalances() {
  return useFetchApi(
    async (customerId: number) => Api.post('/customer/wallet/master_account/balances', { customerId }),
    { items: [] }
  );
}

export function useMasterAccountBalances() {
  return useFetchApi(
    async (customerId: number) => Api.post('customer/wallet/master_account/wallets', { customerId }),
    { items: [] }
  );
}