import React from 'react';
import { Route, useRouteMatch, useHistory } from 'react-router';
import CustomerDetails from '../../views/Customers/Details';
import OrderDetails from '../../views/Orders/Details';
import PaymentDetails from '../../views/Payments/Details';
import KYCDetails from '../../views/KYC/Details';
import ClearingDetails from '../../views/Clearing/Details';
import GlobalSettings from '../../views/GlobalSettings';
import PaymentProviderDetails from '../../views/GlobalSettings/PaymentProviders/Details';
import BinCodeDetails from '../../views/ACL/BinCodes/Details';

export default function PopupsProvider() {
  const match = useRouteMatch();
  const pageMatch: any = useRouteMatch(`${match.url}/:popup/:itemId?`);
  const history = useHistory();

  function closePopup() {
    history.push(match.url.replace('/global-settings', ''));
  }

  return (
    <>
      <Route path={`${match.url}/customer/:itemId`}>
        <CustomerDetails id={pageMatch?.params?.itemId} onClose={closePopup} />
      </Route>
      <Route path={`${match.url}/order/:itemId`}>
        <OrderDetails id={pageMatch?.params?.itemId} onClose={closePopup} />
      </Route>
      <Route path={`${match.url}/payment/:itemId`}>
        <PaymentDetails id={pageMatch?.params?.itemId} onClose={closePopup} />
      </Route>
      <Route path={`${match.url}/kyc-details/:itemId`}>
        <KYCDetails id={pageMatch?.params?.itemId} onClose={closePopup} />
      </Route>
      <Route path={`${match.url}/clearing-details/:itemId`}>
        <ClearingDetails id={pageMatch?.params?.itemId} onClose={closePopup} />
      </Route>
      <Route path={`${match.url}/provider/:itemId`}>
        <PaymentProviderDetails id={pageMatch?.params?.itemId} onClose={closePopup} />
      </Route>
      <Route path={`${match.url}/code/:itemId`}>
        <BinCodeDetails id={pageMatch?.params?.itemId} onClose={closePopup} />
      </Route>
      {(match.url.includes('global-settings') || pageMatch?.params?.popup === 'global-settings')
      && <GlobalSettings onClose={closePopup} />}
    </>
  );
}
