import React, { useState } from 'react';

import { Form } from 'react-final-form';

import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Input, ProtectionInput, Checkbox } from '../../../../components/Form';
import Chip from '@material-ui/core/Chip';
import useFormStyles from '../../../../hooks/useFormStyles';
import { mustBeNumber, required } from '../../../../validators';
import { GatewayConfig, GatewayConfigExtendFields, useGatewayConfigSave } from '../../../../api/gatewayConfig';

interface IProps {
  data: GatewayConfig;
  view: boolean;
  onUpdate: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    marginTop: theme.spacing(2)
  },
  asc: {
    alignSelf: 'center',
    [theme.breakpoints.down(700)]: {
      alignSelf: 'flex-start',
    },
  },
  button: {
    flex: '0 0 34%',
    [theme.breakpoints.down(700)]: {
      flex: '0 0 100%',
      width: '100%',
    },
  },
}));

export function GatewayConfigForm({ data, view, onUpdate }: IProps) {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const [other, setOther] = useState<GatewayConfigExtendFields>(data.other || {});
  const [otherName, setOtherName] = useState('');
  const [otherValue, setOtherValue] = useState('');
  const [save, saving] = useGatewayConfigSave(view ? 'update' : 'create', (res: any) => {
    if (res.success) {
      onUpdate();
    }
  })


  return (
    data && Object.keys(data).length
      ? (
        <Form
          onSubmit={(values) => {
            if (values.connection !== undefined) {
              save({ ...values, other, mapping: undefined, pair: undefined })
            } else {
              save({ ...values, connection: { password: data.connection.password }, other, mapping: undefined, pair: undefined })
            }
          }
          }
          initialValues={{
            ...data,
            pairs: data.pairs || [],
            mappings: data.mappings || [],
            '2fa': '',
            mapping: '',
            pair: '',
          }}
          mutators={{
            addPair: ([add], state, { changeValue }) => {
              // @ts-ignore
              changeValue(state, 'pairs', () => [...state.formState.values.pairs, add]);
              changeValue(state, 'pair', () => '');
            },
            removePair: ([remove], state, { changeValue }) => {
              // @ts-ignore
              changeValue(state, 'pairs', () => state.formState.values.pairs.filter((p) => p !== remove));
            },
            addMapping: ([add], state, { changeValue }) => {
              // @ts-ignore
              changeValue(state, 'mappings', () => [...state.formState.values.mappings, add]);
              changeValue(state, 'mapping', () => '');
            },
            removeMapping: ([remove], state, { changeValue }) => {
              // @ts-ignore
              changeValue(state, 'mappings', () => state.formState.values.mappings.filter((p) => p !== remove));
            },
          }}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit} autoComplete='nope' className={clsx(formClasses.root, classes.form)}>
              {!view && (
                <div className={formClasses.row}>
                  <Input
                    name="id"
                    label="ID"
                    validate={required}
                  />
                </div>
              )}
              <div className={formClasses.row}>
                <Checkbox name="enabled" label="Gateway Enabled" wrapperClassName={classes.asc} />
                <Input
                  name="fee"
                  label="Gateway Fee"
                  validate={mustBeNumber}
                />
              </div>
              <Typography variant="body1">Connection</Typography>
              <div className={formClasses.row}>
                <Input name="connection.host" label="Host" />
              </div>
              <div className={formClasses.row}>
                <Input name="connection.key" label="Key" />
                <Input name="connection.secret" label="Secret" />
              </div>
              <div className={formClasses.row}>
                <Input type='search' name="connection.username" label="Username" autoComplete='xcv487' id='xcv487'/>
                <Input name="connection.password" label="Password" />
              </div>
              <Typography variant="body1">Pairs</Typography>
              <div className={formClasses.row}>
                <Input label="Pair" name="pair" />
                <Button
                  size="medium"
                  variant="contained"
                  disabled={values.pair === undefined || values.pair.length < 7 || values.pairs.includes(values.pair)}
                  onClick={() => form.mutators.addPair(values.pair)}
                  className={clsx(classes.asc, classes.button)}
                >
                  Add
                </Button>
              </div>
              <div className={clsx(formClasses.row, 'wrap')}>
                {values.pairs.map((p: string) => (
                  <Chip label={p} onDelete={() => form.mutators.removePair(p)} key={p} style={{ flex: '0 0 30%' }} />
                ))}
              </div>
              <Typography variant="body1" style={{ marginBottom: 8 }}>Mappings</Typography>
              <div className={formClasses.row}>
                <Input label="Mapping" name="mapping" />
                <Button
                  size="medium"
                  variant="contained"
                  disabled={values.mapping === undefined || values.mapping.length < 7 || values.mappings.includes(values.mapping)}
                  onClick={() => form.mutators.addMapping(values.mapping)}
                  className={clsx(classes.asc, classes.button)}
                >
                  Add
                </Button>
              </div>
              <div className={clsx(formClasses.row, 'wrap')}>
                {values.mappings.map((p: string) => (
                  <Chip label={p} onDelete={() => form.mutators.removeMapping(p)} key={p} style={{ flex: '0 0 30%' }} />
                ))}
              </div>
              <Typography variant="body1">Other Fields</Typography>
              <div className={formClasses.row}>
                <TextField
                  label="Field Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  defaultValue={otherName}
                  value={otherName}
                  onChange={({ target: { value } }) => setOtherName(value)}
                />
                <TextField
                  label="Field Value"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  defaultValue={otherValue}
                  value={otherValue}
                  onChange={({ target: { value } }) => setOtherValue(value)}
                />
                <Button
                  size="medium"
                  variant="contained"
                  onClick={() => {
                    setOther({ ...other, [otherName]: otherValue });
                    setOtherName('');
                    setOtherValue('');
                  }}
                  disabled={!otherName.length || !otherValue.length || !!other[otherName]}
                  className={clsx(classes.asc, classes.button)}
                >
                  Add
                </Button>
              </div>
              {Object.entries(other).map(([fName, fValue], i) => (
                <div className={formClasses.row} key={i}>
                  <TextField
                    variant="outlined"
                    label={fName}
                    defaultValue={fValue}
                    onBlur={({ target: { value } }) => value.length ? setOther({ ...other, [fName]: value }) : null}
                  />
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => {
                      const o = { ...other };
                      delete o[fName];
                      setOther(o);
                    }}
                    className={clsx(classes.asc, classes.button)}
                  >
                    Remove
                  </Button>
                </div>
              ))}
              <div className={formClasses.row}>
                <ProtectionInput />
                <div />
              </div>
              <div className={formClasses.actions}>
                <Button
                  type="submit"
                  disabled={saving}
                  variant="contained"
                >Save</Button>
              </div>
            </form>
          )}
        />
      )
      : <Typography align="center">No data to display</Typography>
  );
}
