import React, { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';

import { useIvyBanksList } from '../../../../../api/apm';
import useGlobalStyles from '../../../../../components/Layout/useGlobalStyles';
import EmptyList from '../../../../../components/EmptyList';
import PaymentProviderIvyBanksList from './PaymentProviderIvyBanksList';
import Loader from '../../../../../components/Loader';
import useColumns, { IColumn } from '../../../../../hooks/useColumns';

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'Bank ID', active: true },
  { id: 'name', name: 'Bank Name', active: true },
  { id: 'test', name: 'Test', active: true },
  { id: 'defaultBank', name: 'Default Bank', active: true },
  { id: 'currencies', name: 'Currencies', active: true },
  { id: 'market', name: 'Market', active: true },
  { id: 'capabilities', name: 'Capabilities', active: true },
  { id: 'logo', name: 'Bank Logo', active: true },
];

export default function PaymentProviderIvyBanks() {
  const [{ items, error }, loading, load] = useIvyBanksList();
  const classes = useGlobalStyles();

  const [columns, ColumnsFilter] = useColumns('ivyBanks', defaultColumns);

  useEffect(()=>{
    load();
  },[]);

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        {items.length > 0 && (
          <CardActions>
            <Grid container justify="flex-end">
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
        )}
        {items.length > 0
          ? <PaymentProviderIvyBanksList items={items} columns={columns} />
          : <EmptyList error={error} loading={loading} />
        }
        <Loader width={900} loading={loading} />
      </Grid>
    </Grid>
  );
}
