import React from 'react';
import TableFilter from '../../components/Filter';
import { DateTimeField, Input, Select } from '../../components/Form';
import { WalletOperationType, WalletOperationIssuerType } from '../../api/walletOperations';
import Descriptor from '../../components/Descriptor';

export interface IWalletOperationsFilter {
  customerId: string | null;
  walletId: string | null;
  dateFrom: number | null;
  dateTo: number | null;
  issuedBy: string | null;
  operationType: WalletOperationType | null;
  issuerType : WalletOperationIssuerType | null;
}

interface IProps {
  filter: IWalletOperationsFilter;
  onChange: any;
}

export default function WalletOperationsFilter({ filter, onChange }: IProps) {
  return (
    <TableFilter onApply={onChange} filter={filter}>
      <Descriptor group="filters" title="clearsDate" >
        <Input
          name="customerId"
          label="Customer ID"
          margin="none"
        />
      </Descriptor>
      <Descriptor group="filters" title="clearsDate" >
        <Input
          name="walletId"
          label="Wallet ID"
          margin="none"
        />
      </Descriptor>
      <Input
        name="issuedBy"
        label="Issued by"
        margin="none"
      />
      <Select
        name="operationType"
        label="Type"
        options={Object.keys(WalletOperationType).map((s) => ({ value: s, label: s }))}
      />
      <Select
        name="issuerType"
        label="Issuer type"
        options={Object.keys(WalletOperationIssuerType).map((s) => ({ value: s, label: s }))}
      />
    </TableFilter>
  );
}
