import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {Form} from 'react-final-form';
import {DateTimeField, Input, ProtectionInput, Select} from '../../../../components/Form';
import useFormStyles from '../../../../hooks/useFormStyles';
import {composeValidators, minValue, mustBeNumber, required} from '../../../../validators';
import {InvoiceStatus, IOlyInvoice, useOlyResend, useOlySend} from "../../../../api/oly";
import {DataRow} from "../../../../components/DataView";
import ShortString from "../../../../components/UI/ShortString";
import DateView from "../../../../components/DateView";
import BoolLabel from "../../../../components/Label/BoolLabel";
import Divider from "@material-ui/core/Divider";
import { useBreakpoints } from '../../../../hooks/useBreakpoints ';
import useMobileStyles from '../../../../hooks/useMobileStyles';

interface IProps {
  data: IOlyInvoice;
  onSend: (id?: number) => void;
}

export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP'

}

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    marginTop: theme.spacing(2)
  },
  asc: {
    alignSelf: 'center',
  },
  request: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  postbackRow: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
   },
}));

export function OlyInvoiceSendForm({ data, onSend }: IProps) {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const mobileClasses = useMobileStyles();
  const isResolutionSevenHundred = useBreakpoints(700);
  const [send, sending] = useOlySend((res: any) => {
    if(res.success){
      onSend(res.data.id);
    }
  });
  const [initRequest, setInitRequest] = useState();
  const [resend, resending] = useOlyResend((res: any) => {
    if(res.success)
      onSend();
  });

  useEffect(() => {
    setInitRequest(data?.request);
  }, [data]);

  if(data?.id) {
    return (
      <div className={classes.root}>
        <DataRow label="Status">
          <BoolLabel value={data.status === InvoiceStatus.SENT} labels={[InvoiceStatus.SENT, InvoiceStatus.ERROR]}/>
        </DataRow>
        <DataRow label="Postback URL"><div className={classes.postbackRow}>{data.postbackUrl}</div></DataRow>
        <DataRow label="Created">
          <DateView value={data.createdAt}/>
        </DataRow>
        <DataRow label="Updated">
          <DateView value={data.updatedAt}/>
        </DataRow>
        <DataRow label="Error">
          <ShortString chars={40} text={data.error}/>
        </DataRow>
        <DataRow label="Admin email">{data.adminEmail}</DataRow>
        <DataRow label="Admin ID">{data.adminId}</DataRow>
        <Divider/>
        <Typography variant="subtitle1" className={classes.request}>Request</Typography>
        {data?.request?.transactionId && (
            <>
              <DataRow label="Transaction ID">{data?.request.transactionId}</DataRow>
              <DataRow label="Transaction date">
                <DateView value={data?.request.transactionDate}/>
              </DataRow>
              <DataRow label="Amount">{data?.request.amount}</DataRow>
              <DataRow label="Currency">{data?.request.currency}</DataRow>
            </>
          )
        }
        {data?.request?.transactionId && (
          <DataRow label="Raw json">{JSON.stringify(data?.request)}</DataRow>
        )}
        {data.status === 'ERROR' && (
          <Form
            onSubmit={resend}
            initialValues={{
              id: data.id,
              '2fa': ''
            }}
            render={({handleSubmit}) => (
              <form
                onSubmit={handleSubmit}
                className={formClasses.row}
                style={{flex: '1 1 auto', alignItems: 'center', marginBottom: 0}}
              >
                <Input name="id" type="hidden"/>
                <ProtectionInput margin={!isResolutionSevenHundred ? 'none' : 'normal'}/>
                <Button
                  className={mobileClasses.button}
                  type="submit"
                  variant="contained"
                  disabled={resending}
                >Resend</Button>
              </form>
            )}
          />)
        }
      </div>
    )
  }

  return (
    <Form
      onSubmit={send}
      initialValues={{
        jsonRequest: initRequest,
        '2fa': ''
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}
              className={clsx(formClasses.root, classes.form)}
        >
          <div className={formClasses.row}>
            <Input
              name="jsonRequest.transactionId"
              label="Transaction ID"
              type="search"
              autoComplete="xyz123"
              validate={required}
              disabled={sending}
            />
            <DateTimeField
              name="jsonRequest.transactionDate"
              label="Transaction date"
              margin={true}
              validate={required}
              disabled={sending}
            />
          </div>
          <div className={formClasses.row}>
            <Input
              name="jsonRequest.amount"
              label="Amount"
              validate={composeValidators(required, mustBeNumber, (v:any) => minValue(v, 0))}
              disabled={sending}
            />
            <Select
              name="jsonRequest.currency"
              label="Currency"
              options={Object.keys(Currency).map((c) => ({ value: c, label: c }))}
              validate={required}
              margin="normal"
              disabled={sending}
              fullWidth={isResolutionSevenHundred}
            />
          </div>

          <div className={formClasses.row}>
            <ProtectionInput disabled={sending}/>
            <div />
          </div>
          <div className={formClasses.actions}>
            <Button
              type="submit"
              disabled={sending}
              variant="contained"
            >Send</Button>
          </div>
        </form>
      )}
    />
  );
}
