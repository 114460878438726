import makeStyles from "@material-ui/core/styles/makeStyles";

const useGlobalStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(1),
  },
  table: {
    maxWidth: "inherit",
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  filters: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingBottom: "0 !important",
    flexFlow: "row wrap",
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
    "& > *": {
      marginRight: `${theme.spacing(2)}px !important`,
      marginBottom: `${theme.spacing(2.5)}px !important`,
      flex: "1 1 0px",
      minWidth: "18% !important",
      maxWidth: "18% !important",
      [theme.breakpoints.down('sm')]: {
        minWidth: '100% !important',
        maxWidth: '100% !important'
      },
      "&.apply": {
        minWidth: "90px !important",
        flex: "0 0",
        [theme.breakpoints.down('sm')]: {
          minWidth: '100% !important',
        },
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: "2px !important",
      paddingBottom: "2px !important",
    },
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '10px'
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      gap: '0px'
    },
  },
  tooltiped: {
    textDecoration: "underline",
    textDecorationStyle: "dotted",
    fontStyle: "italic",
  },
  nowrap: {
    whiteSpace: "nowrap",
  },
  pointer: {
    cursor: "pointer",
  },
  successText: {
    color: theme.palette.success.main,
  },
  errorText: {
    color: theme.palette.error.main,
  },
  breakWord: {
    wordBreak: "break-all",
  },
  dialog: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    width: 450,
    "& .MuiDialogContent-root": {
      padding: `0 ${theme.spacing(1)}px`,
    },
    "& .MuiDialogTitle-root": {
      padding: `0 ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
  },
  cardActions: {
    display: "flex",
    "& .import": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default useGlobalStyles;
