import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IPayment } from '../../api/payment';
import { IColumn } from '../../hooks/useColumns';
import PaymentRow from './PaymentRow';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface IProps {
  items: IPayment[];
  columns: IColumn[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  tableHead: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default function PaymentsList({ items, columns }: IProps) {

  const classes = useStyles();

  return (
    <Table size="small">
      <TableHead className={classes.tableHead}>
        <TableRow>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => <PaymentRow item={i} key={i.id} columns={columns} />)}
      </TableBody>
    </Table>
  );
}
