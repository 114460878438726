import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import format from 'date-fns/format';
import { logOutAction } from '../store/actions/common';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4530/api';

const Api = axios.create({
  withCredentials: !!process.env.REACT_APP_API_URL,
  baseURL: `${apiUrl}/admin/v1`,
});

export default Api;

const getErrFromResponse = (e: any): string => {
  if (e.response.data.error.details) {
    return e.response.data.error.details.map((error: any) => {
      for (let key in error) {
        return error[key]
      }
    }).join(' ')
  } else {
    return e.response.data.error.message || e.response.data.error.type
  }
}

interface IData {
  items?: any;
  full: boolean;
  data?: any;
  error?: any;
  left?: number;
  cursor?: number;
}

export function useFetchApi(remote: any, initialData: any, isDownload = false, filename?: string): [IData, boolean, (...args: any) => void, (...args: any) => void] {
  const [data, setData] = useState({ ...initialData, error: null, full: false });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const load = async (...args: any[]) => {
    setLoading(true);
    const isPagination = args && args.length && args[0] && typeof (args[0]) === 'object' && !!args[0].cursor;
    if (!isPagination && !isDownload && data.items && data.items.length) {
      setData({ ...data, items: [] });
    }
    let res = initialData;
    try {
      res = await remote(...args);
    } catch (e) {
      let err = e.toString();
      if (e.response && e.response.data && e.response.data.error) {
        err = getErrFromResponse(e);
        setData({ ...data, error: e.response.data.error });
        if (['UNAUTHORIZED'].includes(e.response.data.error.type) || e.response.data.error.message === 'Auth credentials not found') {
          dispatch(logOutAction());
        }
      } else {
        setData({ ...data, error: err });
      }
      enqueueSnackbar(err, { variant: 'error' });
    }

    if (isDownload) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
      link.download = `${filename}-${format(Date.now(), 'dd.MM.yyyy-HH.mm.ss')}.csv`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else if (res.data) {
      if (res.data.error) {
        enqueueSnackbar((res.data.error.message || res.data.error.type), { variant: 'error' });
        setData({ ...data, error: res.data.error });
      } else {
        const respData = res.data.data;
        const isDataArr = Array.isArray(respData);
        const full = (respData && isDataArr && respData.length < (args[0]?.limit || 50)) || res.data.hasNext === false;
        setData({
          ...data,
          ...res.data,
          error: null,
          items: respData && isDataArr && data.items
            ? (isPagination ? data.items.concat(respData) : [].concat(isDataArr ? respData : [respData]))
            : [],
          data: !isDataArr ? respData : {},
          full,
          cursor: respData && res.data.cursor
        });
      }
    }
    setLoading(false);
  };

  const updateItem = (item: any, compareKey = 'id', type = 'update') => {
    if (item === undefined) return;
    const items = [...data.items];
    if (compareKey !== null) {
      const i = items.findIndex((it: any) => it[compareKey].toString() === item[compareKey].toString());
      if (i !== -1) {
        if (type === 'delete') {
          items.splice(i, 1);
        } else {
          items[i] = item;
        }
      } else {
        items.unshift(item);
      }
    }
    const upd = { ...data, items };
    if (type !== 'delete') {
      if (Array.isArray(upd.data)) {
        upd.data[0] = item;
      } else {
        upd.data = item;
      }
    } else {
      upd.data = {};
    }
    setData(upd);
  }

  return [data, loading, load, updateItem];
}

export function useUpdateApi(remote: any, callback: any, successMessage?: string, logErrors = false): [(...args: any) => void, boolean] {
  const [updating, setUpdating] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const req = async (...args: any) => {
    setUpdating(true);

    try {
      const res = await remote(...args);
      if (res.data.error) {
        enqueueSnackbar(res.data.error, { variant: 'error' });
      }
      if (successMessage) {
        enqueueSnackbar(successMessage, { variant: 'success' });
      }
      callback(res.data);
    } catch (e) {
      let err = e.toString();
      if (logErrors) {
        console.error('REQUEST ERROR: ' + e.toString());
      }
      if (e.response && e.response.data && e.response.data.error) {
        err = getErrFromResponse(e);
        callback(e.response.data);
        if (['UNAUTHORIZED'].includes(e.response.data.error.type) || e.response.data.error.message === 'Auth credentials not found') {
          dispatch(logOutAction());
        }
      }
      enqueueSnackbar(err, { variant: 'error' });
    }

    setUpdating(false);
  };

  return [req, updating];
}
