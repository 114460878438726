import React, { useEffect } from 'react';
import TableFilter from '../../components/Filter';
import { Input, Autocomplete } from '../../components/Form';
import { useActivitiesList } from '../../api/admin';

export interface IAdminActivityFilter {
  dateFrom: Date | null;
  dateTo: Date | null;
  adminId: string | null;
  types: string[] | null;
}

interface IOrdersFilterProps {
  filter: IAdminActivityFilter;
  onChange: any;
  type?: 'page' | 'popup';
}

export default function AdminActivityFilter({ filter, onChange, type }: IOrdersFilterProps) {
  const [{ items: activities }, loading, load] = useActivitiesList();

  useEffect(() => {
    load();
  }, [])

  return (
    <TableFilter onApply={onChange} filter={filter}>
      {type !== 'popup' && (
        <Input
          name="adminId"
          label="Admin ID"
          margin="none"
        />
      )}
      <Autocomplete
        multiple
        name="types"
        label="Type"
        options={activities.map((a: string) => ({ value: a, label: a }))}
        // onChange={(ev, v: any) => onChange({ ...filter, types: v ? [v.value] : undefined })}
        // value={filter.types ? filter.types[0] : undefined}
        loading={loading}
      />
    </TableFilter>
  );
}
