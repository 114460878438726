import Api, { useFetchApi, useUpdateApi } from './index';
import { IMerchantSettlementFees } from '../types';

export enum SettlementStatus {
  SETTLED = 'SETTLED',
  REQUESTED = 'REQUESTED',
  REJECTED = 'REJECTED'
}

export interface Operations{
  [key: number]: { [key: string]: number }
}

export interface ISettlement{
  id: number;
  createdAt: number;
  updatedAt: number;
  status: SettlementStatus
  customerId: number;
  settlementAmount: number;
  settlementCurrency?: string
  requestedEurAmount: string
  operations: Operations
  settlementFees: Record<string, IMerchantSettlementFees & { additionalSettlementFee?: number }>;
}
export function useSettlementsList() {
  return useFetchApi(
    async (query: any) => Api.post('/settlement/find', { ...query, limit: 50 }),
      { items: [] }
  );
}

export function useSettlementOne() {
  return useFetchApi(
    async (id: string) => Api.post('/settlement/find', { filter: { id }, limit: 1 }),
      { items: [] }
  );
}

export function useSettlementUpdate(cb: any) {
  return useUpdateApi(
     async (data: any) => Api.post('/settlement/update', data),
     cb,
     'Settlement successfully updated'
  );
}

export function useSettlementCreate(cb: any) {
  return useUpdateApi(
     async (data: any) => Api.post('/settlement/init', data),
     cb,
     'Settlement successfully created'
  );
}

interface IAdditionalSettlementFeeRequest {
  settlementId: number;
  additionalSettlementFee: number;
  '2fa': string;
}

export function useSettlementAdditionalFeeUpdate(cb: any) {
  return useUpdateApi(
     async (data: IAdditionalSettlementFeeRequest) => Api.post('/settlement/additional-fee', data),
     cb,
     'Additional fee saved'
  );
}


