import React from 'react';

import { format, utcToZonedTime } from 'date-fns-tz';

interface IDateViewProps {
  value?: Date | number;
  timeless?: boolean;
  onlyTime?: boolean;
}

export default function DateView({ value, timeless, onlyTime }: IDateViewProps) {
  if (!value) return null;
  const timeZone = 'Africa/Abidjan';
  let pattern = 'dd.MM.yyyy HH:mm:ss';
  if (timeless) {
    pattern = 'dd.MM.yyyy';
  }
  if (onlyTime) {
    pattern = 'HH:mm:ss';
  }
  return <>{format(utcToZonedTime(value, timeZone), pattern, { timeZone })}</>
}
