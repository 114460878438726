import React, { FC } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import BoolLabel from "../../../components/Label/BoolLabel";
import Loader from "../../../components/Loader";
import { IKycState } from "../../../api/kyc";
import { IColumn } from "../../../hooks/useColumns";
import Tablecell from '../../../components/TableCell/TableCell';
import useMobileStyles from "../../../hooks/useMobileStyles";

interface IKycStatesListProps {
  items: IKycState[];
  columns: IColumn[];
  isLoading: boolean;
  onStateClick: (state: IKycState) => void;
}

const content: { [key: string]: (item: IKycState, key: number) => void } = {
  name: (i, k) => (
    <Tablecell title="Name" position="center" key={k}>
      {i.name}
    </Tablecell>
  ),
  code: (i, k) => (
    <Tablecell title="Code" position="center" key={k}>
      {i.code}
    </Tablecell>
  ),
  country: (i, k) => <Tablecell title="Country" key={k}>{i.country}</Tablecell>,
  supported: (i, k) => (
    <Tablecell title="Supported" key={k}>
      <BoolLabel value={i.supported} />
    </Tablecell>
  ),
  inHRTC: (i, k) => (
    <Tablecell title="In EU HRTC list" key={k}>
      <BoolLabel value={i.inHRTC} />
    </Tablecell>
  ),
  risk: (i, k) => (
    <Tablecell title="Risk score" key={k} position="center">
      {i.riskScore}
    </Tablecell>
  ),
  kyc: (i, k) => (
    <Tablecell title="KYC score" key={k} position="center" isLast>
      {i.kycScore}
    </Tablecell>
  ),
};

const KYCCountriesList: FC<IKycStatesListProps> = ({
  isLoading,
  items,
  columns,
  onStateClick,
}) => {

  const mobileClasses = useMobileStyles();
  
  return (
    <Loader loading={isLoading}>
      <Table size="small">
        <TableHead className={mobileClasses.tableHead}>
          <TableRow>
            {columns.map(
              (c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((i) => (
            <TableRow key={i.code} hover onClick={() => onStateClick(i)} className={mobileClasses.row}>
              {columns.map((c, index) => content[c.id](i, index))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Loader>
  );
};

export default KYCCountriesList;
