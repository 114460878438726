import { IFormApmSettings, IFormBank, IApmMetadata } from '../api/apm';

export const mappedFormApmMetadata = (metadata: IFormBank[] | undefined)=> {
   if (!metadata) {
     return {};
   }
 
   if (metadata.length > 0) {
     return Object.fromEntries(metadata.map(bank=> {
       return [bank.id, { name: bank.name, logo: bank.logo }];
     }));
   }
 
   return {};
};

export const prepareFormApmSettings = (provider: string, settings: IFormApmSettings)=> {
   if (provider === 'SAFECHARGE') {
      return null;
   }

   return { ...settings, metadata: mappedFormApmMetadata(settings?.metadata) };
};

export const prepareInitialFormApmMetadata = (metadata: IApmMetadata)=> {
   return Object.entries(metadata).map(([bankId, bankInfo])=> {
     return { id: bankId, name: bankInfo.name, logo: bankInfo.logo };
   });
 };