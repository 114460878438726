import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import subDays from 'date-fns/subDays';
import subWeeks from 'date-fns/subWeeks';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import subMonths from 'date-fns/subMonths';
import { utcToZonedTime } from 'date-fns-tz';

export const timeZone = 'Africa/Abidjan';
export const offset = new Date().getTimezoneOffset()*60000;

export const getUTCTime = (value: Date)=>{
  return utcToZonedTime(value, timeZone);
};

export const startDate = getUTCTime(startOfDay(subMonths(new Date(), 1)));
export const todayStart = () => getUTCTime(startOfDay(new Date()));
export const todayEnd = () => getUTCTime(endOfDay(new Date()));
export const yesterdayStart = () => getUTCTime(startOfDay(subDays(new Date(), 1)));
export const yesterdayEnd = () => getUTCTime(endOfDay(subDays(new Date(), 1)));
export const thisWeekStart = () => getUTCTime(startOfWeek(new Date()));
export const lastWeekStart = () => getUTCTime(startOfDay(subWeeks(new Date(), 1)));
export const thisMonthStart = () => getUTCTime(startOfMonth(new Date()));
export const lastMonthStart = () =>getUTCTime(startOfDay(subMonths(new Date(), 1)));

export const predefinedDates = () => ({
  default: { title: '', dateFrom: startDate, dateTo: todayEnd().valueOf() },
  today: { title: 'Today', dateFrom: todayStart().valueOf(), dateTo: todayEnd().valueOf() },
  yesterday: { title: 'Yesterday', dateFrom: yesterdayStart().valueOf(), dateTo: yesterdayEnd().valueOf() },
  tWeek: { title: 'This week', dateFrom: thisWeekStart().valueOf(), dateTo: todayEnd().valueOf() },
  lWeek: { title: 'Last week', dateFrom: lastWeekStart().valueOf(), dateTo: todayEnd().valueOf() },
  tMonth: { title: 'This month', dateFrom: thisMonthStart().valueOf(), dateTo: todayEnd().valueOf() },
  lMonth: { title: 'Last month', dateFrom: lastMonthStart().valueOf(), dateTo: todayEnd().valueOf() },
});
