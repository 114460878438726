import Api, { useFetchApi, useUpdateApi } from './index';

export interface ILegalInfo {
  id: string;
  name: string;
  address: string;
  regNum: string;
}

export interface IApiKey {
  id: string;
  customerId: string;
  key: string;
  sid: string;
  domain: string;
  checkSignature: boolean;
  publicApi: boolean;
  createdAt: number;
  updatedAt: number;
  legal?: ILegalInfo;
  customerEmail?: string;
  dmrProxy?: boolean;
  sendKycMeta?: boolean;
  enabledInvoices?: boolean;
  excludedCountries: string[];
}

export const emptyApiKey = {
  customerId: '',
  key: '',
  sid: '',
  domain: 'https://',
  checkSignature: false,
  publicApi: false,
  excludedCountries: []
};

export function useApiKeys() {
  return useFetchApi(
    async (query: any) => {
      const qd = query?.filter?.queryData;
      const filter: any = {};
      if (qd) {
        if (!(/^\d+$/.test(qd) && qd.toString().length <= 19)) {
          filter.name = qd;
        } else {
          filter.customerId = qd;
        }
      }
      return Api.post('/customer/apikey/list', {limit: 50, ...query, filter})
    },
    { items: [] }
  );
}

export function useCreateApiKey(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/customer/apikey/new', data),
    cb,
    'API key successfully created'
  )
}

export function useUpdateApiKey(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/customer/apikey/update', data),
    cb,
    'API key successfully updated'
  )
}

export function useDeleteApiKey(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/customer/apikey/delete', { id: data.id, '2fa': data['2fa'] }),
    cb,
    'API key successfully deleted'
  )
}

export function useCreateLegalInfo(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/customer/apikey/legal/bind', data),
    cb,
    'Legal info successfully added'
  )
}

export function useBindLegalInfo(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/customer/apikey/bind/legal', data),
    cb,
    'Legal info successfully binded'
  )
}

export function useSettingsReset(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/customer/apikey/reset/override', data),
    cb,
    'Settings override successfully'
  )
}

export function useConvertApiKey(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/customer/apikey/convert', data),
    cb,
    'Merchant account successfully created'
  );
}
