import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { getMaskedRequestCardData } from '../../../../helpers/maskHelper';
import { DataRow } from '../../../../components/DataView';
import DateView from '../../../../components/DateView';
import TextCopy from '../../../../components/TextCopy';
import ShortString from '../../../../components/UI/ShortString';
import { IApiRequests } from '../../../../api/apiRequests';
import EmptyList from '../../../../components/EmptyList';


const useStyles = makeStyles((theme) => ({
  root: {},
  list: {
    paddingLeft: theme.spacing(2),
    wordBreak: 'break-word'
  }
}));

interface IProps {
  data : IApiRequests | undefined;
}

export default function IApiRequestsOverview({ data }: IProps) {
   
  const classes = useStyles();

  let maskedRequest: any;

  if(data) {
    maskedRequest = getMaskedRequestCardData(data.request);
  }

  return (
      <>
        {(data && Object.keys(data).length) &&
        <>
         <DataRow label="Requested by ID">{data.requestedById}</DataRow>
         <DataRow label="URL">{data.url}</DataRow>
         <DataRow label="Created at">
            <DateView value={data.createdAt} />
         </DataRow>
         <DataRow label="Updeated at">
            <DateView value={data.updatedAt} />
         </DataRow>
         <Typography variant="body1" component="div" color="primary">
            Headers
            {data.headers && <TextCopy text={JSON.stringify(data.headers).toString()} />}
         </Typography>
         {Object.keys(data.headers).map((k) => (
            <Typography variant="body2" key={k} className={classes.list}>
               <strong>{k}:</strong> <ShortString chars={80} text={data.headers[k]} />
            </Typography>
         ))}
         <Typography variant="body1" component="div" color="primary">
            Request
            <TextCopy text={data.request} />
         </Typography>
            {Object.keys(maskedRequest).map((k) => {
            return (
               <Typography variant="body2" key={k} className={classes.list}>
               <strong>{k}:</strong> { maskedRequest[k] }
               </Typography>
            );
         })}
         <Typography variant="body1" component="div" color="primary">
            Response
            {data.response.data && <TextCopy text={JSON.stringify(data.response.data).toString()} />}
            {data.response.error && <TextCopy text={JSON.stringify(data.response.error).toString()} />}
         </Typography>
         {data.response.data && Object.keys(data.response.data).map((k) => (
            <Typography variant="body2" key={k} className={classes.list}>
               <strong>{k}:</strong> {'' + JSON.stringify(data.response.data[k])}
            </Typography>
         ))}
         {data.response.error && Object.keys(data.response.error).map((k) => (
            <Typography variant="body2" key={k} className={classes.list}>
               <strong>{k}:</strong> {'' + JSON.stringify(data.response.error[k])}
            </Typography>
         ))}
       </>}
       {(!data || !Object.keys(data).length) && 
      <EmptyList text="No data to display" width={500} loading={false} />}
      </>
  );
}