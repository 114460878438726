import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Loader from '../../../components/Loader';
import EmptyList from '../../../components/EmptyList';
import ActivityRow from './Activity/ActivityRow';
import ACLFastAdd, { ACLData } from '../../ACL/BlakWhite/ACLFastAdd';
import { useCustomerActivity, ICustomerActivity } from '../../../api/customers';
import useMobileStyles from '../../../hooks/useMobileStyles';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  table: {
    marginBottom: theme.spacing(2)
  },
  checkbox: {
    fontSize: 10
  }
}));

interface IProps {
  id: any;
  active?: boolean;
}

export default function CustomerActivity({ id, active }: IProps) {
  const [{ items, error, full }, loading, load] = useCustomerActivity();
  const [acl, setAcl] = useState<ACLData | undefined>();
  const classes = useStyles();
  const mobileClasses = useMobileStyles();

  return (
    <>
      {acl && <ACLFastAdd onClose={() => setAcl(undefined)} value={acl.value} type={acl.type} white={acl.white} />}
      {error
        ? <EmptyList loading={loading} error={error} width={500} />
        : (
          <>
            <Table size="small" className={classes.table}>
              <TableHead className={mobileClasses.tableHead}>
                <TableRow>
                  <TableCell align="center">Type</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>IP</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((i: ICustomerActivity) => <ActivityRow item={i} key={i.id} setAcl={setAcl} lastChild={items[items.length-1]}/>)}
              </TableBody>
            </Table>
            <Loader loading={loading} width={500} />
            {active && !loading && !error && !full
            && <Waypoint onEnter={() => load({ customerId: id, cursor: items.length })} />}
          </>
        )}
    </>
  );
}
