import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useGlobalStyles from '../../../../components/Layout/useGlobalStyles';
import { ProtectionInput } from '../../../../components/Form';

export default function ConfirmationDialog(props: any) {
  const { showConfirmationDialogWithData, setShowConfirmationDialogWithData } = props;
  const globalClasses = useGlobalStyles();

  const onSubmit = (value: any) => {
    props.onSubmit({...showConfirmationDialogWithData.data, ...value})
  }

  return <>
    { showConfirmationDialogWithData && 
      <Dialog open onClose={() => setShowConfirmationDialogWithData(undefined)}>
        <div className={globalClasses.dialog}>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <DialogContentText component="div">
                    <strong>{showConfirmationDialogWithData.title}</strong>
                  </DialogContentText>
                  <ProtectionInput />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setShowConfirmationDialogWithData(undefined)} size="small">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="secondary"
                  >
                    {showConfirmationDialogWithData.btnLabel}
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </div>
      </Dialog>
    }
  </>
}