import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ActivityRow from './ApplicationActivityRow';
import { ISumSubCallback } from '../../../../api/kyc';
import useMobileStyles from '../../../../hooks/useMobileStyles';

interface IProps {
  data: ISumSubCallback[];
}

export default function ApplicationActivityTab({ data }: IProps) {

const mobileClasses = useMobileStyles();

  return (
    <>
      {(data && data.length)
        ? (
          <Table size="small">
            <TableHead className={mobileClasses.tableHead}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Review answer</TableCell>
                <TableCell>Review status</TableCell>
                <TableCell>Created at</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((d) => (
                <ActivityRow item={d} key={d.id} />
              ))}
            </TableBody>
          </Table>
        ) : <Typography variant="body1" align="center">No data to display</Typography>}
    </>
  )
}
