import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {Form} from 'react-final-form';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import useFormStyles from '../../../../hooks/useFormStyles';
import {Input, ProtectionInput} from '../../../../components/Form';
import {ICustomer, useBillingAddress} from '../../../../api/customers';

interface IProps {
  customer: ICustomer;
  onSave: any;
}

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2)
  }
}));

export default function BillingAddressForm({ customer, onSave }: IProps) {
  const formClasses = useFormStyles();
  const classes = useStyles();

  const [save, saving] = useBillingAddress((res: any) => {
    if (res.success) {
      onSave(customer.id);
    }
  });

  return (
    <Form
      onSubmit={save}
      initialValues={{
        id: customer.id,
        country: customer.billingCountry,
        city: customer.city,
        address: customer.address,
        zipCode: customer.zipCode,
        '2fa': ''
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}  className={clsx(formClasses.root, classes.form)}>
          <Typography variant="body1" color="primary" align="left">Billing address</Typography>
          <div className={formClasses.row}>
            <Input
              type="search"
              autoComplete="xyz123"
              name="country"
              label="Country"/>
            <Input
              type="search"
              autoComplete="xyz123"
              name="city"
              label="City"/>
            <Input
              type="search"
              autoComplete="xyz123"
              name="zipCode"
              label="Zip Code"/>
          </div>
          <div className={formClasses.row}>
            <Input
              type="search"
              autoComplete="xyz123"
              name="address"
              label="Address"/>
          </div>
          <div className={formClasses.row}>
            <ProtectionInput />
            <div/>
          </div>
          <div className={formClasses.actions}>
            <Button
              type="submit"
              disabled={saving}
              variant="contained"
            >Save</Button>
          </div>
        </form>
      )}
    />
  );
}
