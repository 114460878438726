import React from 'react';
import { useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

interface IProps {
  popup: string;
  id?: string | number;
  children: any;
  baseUrl?: string;
}

export default function PopupLink({ popup, id, children, baseUrl }: IProps) {
  const match = useRouteMatch();
  const url = match.url.includes(`/${popup}/${id}`) ? '' : `/${popup}/${id}`

  if (!id) return <>{children}</>;

  return <Link component={NavLink} to={`${baseUrl || match.url}${url}`} onClick={(e: any) => {e.stopPropagation()}}>{children}</Link>;
}
