import { useState } from "react";

import axios from "axios";

export function useUpdateApi(remote: any, callback: any): [(...args: any) => void, boolean] {
   const [updating, setUpdating] = useState(false);

   const req = async (data?: any, options?: any) => {
      setUpdating(true);
      try {
         const res = await remote(data || {}, options);

         callback(res.data);
      } catch (e) {
         if (e.response && e.response.data && e.response.data.error) {
            callback(e.response.data);
         }
      }

      setUpdating(false);
   };

   return [req, updating];
}


export function useHowToUpdate(cb: any) {
   return useUpdateApi(
      () => axios.post('http://localhost:3000/api/howto/update'),
      cb
   );
}

export function useBlogsUpdate(cb: any) {
   return useUpdateApi(
      () => axios.post('http://localhost:3000/api/blog/update'),
      cb
   );
}


export function useRecommendsUpdate(cb: any) {
   return useUpdateApi(
      () => axios.post('http://localhost:3000/api/recommends/update'),
      cb
   );
}


export function useSBSUpdate(cb: any) {
   return useUpdateApi(
      () => axios.post('http://localhost:3000/api/sbs/update'),
      cb
   );
}



export function useFaqUpdate(cb: any) {
   return useUpdateApi(
      () => axios.post('http://localhost:3000/api/faqs/update'),
      cb
   );
}

export function useGlobalFaqUpdate(cb: any) {
   return useUpdateApi(
      () => axios.post('http://localhost:3000/api/global-faqs/update'),
      cb
   );
}


export function useMetaUpdate(cb: any) {
   return useUpdateApi(
      () => axios.post('http://localhost:3000/api/meta/update'),
      cb
   );
}

export function usePagesUpdate(cb: any) {
   return useUpdateApi(
      () => axios.post('http://localhost:3000/api/pages/update'),
      cb
   );
}
export function useCurrenciesUpdate(cb: any) {
   return useUpdateApi(
      () => axios.post('http://localhost:3000/api/currencies/update'),
      cb
   );
}

export function useMerchantCurrenciesUpdate(cb: any) {
   return useUpdateApi(
      () => axios.post('http://localhost:3000/api/merchant-currencies/update'),
      cb
   );
}

