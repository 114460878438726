import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateView from '../../components/DateView';
import Label from '../../components/Label';
import PopupLink from '../../components/UI/PopupLink';
import { IKYCItem } from '../../api/kyc';
import { getKYCStatusLabelType } from '../../helpers/KYCHelper';
import { getSumSubUrl } from './index';
import { IColumn } from '../../hooks/useColumns';
import TableCellId from '../../components/TableCellId/TableCellId';
import Tablecell from '../../components/TableCell/TableCell';
import useMobileStyles from '../../hooks/useMobileStyles';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    cursor: 'pointer'
  },
  sumSubResult: {
    '&.red': {
      color: theme.palette.error.main
    },
    '&.green': {
      color: theme.palette.success.main
    }
  }
}));

interface IKYCListProps {
  items: IKYCItem[];
  columns: IColumn[];
}

export default function KYCList({ items, columns }: IKYCListProps) {
  const classes = useStyles();
  const history = useHistory();
  const mobileClasses = useMobileStyles();

  const content: { [key: string]: (item: IKYCItem, key: number) => void } = {
    id: (i, k) => <TableCellId key={k}>{i.id}</TableCellId>,
    customer: (i, k) => <Tablecell title="Customer ID" key={k}><PopupLink popup="customer" id={i.customerId}>{i.customerId}</PopupLink></Tablecell>,
    email: (i, k) => <Tablecell title="Email" key={k}><span style={{wordBreak:'break-word'}}>{i.customerEmail}</span></Tablecell>,
    country: (i, k) => <Tablecell title="Country" key={k}>{i.country}</Tablecell>,
    trdParty: (i, k) => (
      <Tablecell title="Third party" key={k}>
        {i.flow === 'SUMSUB' && i.externalApplicationId && (
          <Link href={getSumSubUrl(i.externalApplicationId)} target="_blank">
            {i.externalApplicationId}
          </Link>
        )}
      </Tablecell>
    ),
    extReview: (i, k) => (
      <Tablecell key={k} title="Ext. review answer">
        {i.externalReviewAnswer && (
          <span className={clsx(classes.sumSubResult, i.externalReviewAnswer.toLowerCase())}>
            {i.externalReviewAnswer}
          </span>
        )}
      </Tablecell>
    ),
    status: (i, k) => (
      <Tablecell key={k} position="center" title="Status">
        <Label
          label={i.status}
          type={getKYCStatusLabelType(i.status)}
          size="small"
        />
      </Tablecell>
    ),
    created: (i, k) => <Tablecell key={k} title="Created at"><DateView value={i.createdAt} /></Tablecell>,
    updated: (i, k) => <Tablecell key={k} title="Updated at"><DateView value={i.updatedAt} /></Tablecell>
  };

  return (
    <Table size="small">
      <TableHead className={mobileClasses.tableHead}>
        <TableRow>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow key={i.id} className={clsx(classes.row, mobileClasses.row, mobileClasses.lastChild)} onClick={() => history.push(`/kyc/kyc-details/${i.id}`)} hover>
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}