import React from 'react';
import Label from './index';
import Descriptor from '../Descriptor';
import { getOrderStatusType } from '../../helpers/orderHelper';
import { OrderStatus } from '../../api/order';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

interface IProps {
  status: OrderStatus;
  kycData?: any;
}

export default function OrderStatusLabel({ status, kycData }: IProps) {
  return (
    <Descriptor group="orderStatus" title={status}>
      <Label label={status} type={getOrderStatusType(status)} size="small" />
      {kycData?.items[0] ? 
        <Typography variant="body2" align="right" style={{ alignSelf: 'center', marginLeft: 8 }}>
          <Link component={NavLink} to={`/kyc/kyc-details/${kycData?.items[0].id}`} onClick={(e: any) => {e.stopPropagation()}}>
            {kycData?.items[0].status}
          </Link>
        </Typography>
      : ''}
    </Descriptor>
  );
}
