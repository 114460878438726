import React, { useEffect } from "react";

import {
  IAuthority,
  useAuthorityAdminList,
  useAuthorityList,
} from "../../../api/authority";

import { useAuthorityUpdate } from '../../../api/admin';

import { Button, Typography } from "@material-ui/core";
import Loader from "../../../components/Loader";
import { Form } from "react-final-form";
import { ProtectionInput, Select } from "../../../components/Form";
import {
  useCustomersList,
} from "../../../api/customers";
import useFormStyles from "../../../hooks/useFormStyles";
import useAuthorityStyles from "../../../hooks/useAuthorityStyles";
import BoolLabel from "../../../components/Label/BoolLabel";
import { IAdmin } from "../../../api/admin";

interface IAuthorityAdminTabProps {
  id: number;
  admin: IAdmin;
}

function AuthorityAdminTab({ id, admin }: IAuthorityAdminTabProps) {
  const formClasses = useFormStyles();
  const classesAuthority = useAuthorityStyles();
  const [{ items: authoritiesCustomer }, loadingAuthorities, loadAuthorities] =
    useAuthorityAdminList();
  const [{ items: customerList }, loadingCustomer, loadCustomer] =
    useCustomersList();
  const [{ items }, loading, load] = useAuthorityList();
  const [update, updating] = useAuthorityUpdate((res: any) => {
    if (res.success) {
      loadAuthorities(id);
    }
  });

  useEffect(() => {
    load('ADMIN');
    loadCustomer({ email: admin.email });
  }, []);
  const customer = customerList[0];

  useEffect(() => {
    if (customer) {
      loadAuthorities(id);
    }
  }, [customer]);

  const authorities: IAuthority[] = [];
  authoritiesCustomer?.forEach((i: { id: string }) => {
    const authority = items.find((item: IAuthority) => item.id === i.id);
    if (authority) {
      authorities.push(authority.id);
    }
  });
  return (
    <>
      <Loader
        loading={updating || loadingCustomer || loadingAuthorities}
        width={500}
      >
        {authorities.length > 0 && (
          <div className={classesAuthority.list}>
            <Typography
              variant="body1"
              color="primary"
              className={classesAuthority.title}
            >
              Authorities
            </Typography>
            {authoritiesCustomer.map((authority: IAuthority) => (
              <div className={classesAuthority.item} key={authority.id}>
                <div className={classesAuthority.label}>
                  <BoolLabel value={authority.active} size="small" />
                </div>
                <div>{authority.name}</div>
              </div>
            ))}
          </div>
        )}
        <Typography variant="body1" color="primary">
          Change authorities
        </Typography>
        <Form
          onSubmit={(values) => update({ ...values, id: id })}
          initialValues={{ id, authorities, "2fa": "" }}
          render={({ handleSubmit, values, invalid }) => (
            <form onSubmit={handleSubmit} className={formClasses.root}>
              <div className={classesAuthority.row}>
                <Select
                  name="authorities"
                  label="Authorities"
                  options={items.map((i: any) => ({
                    value: i.id,
                    label: i.name,
                  }))}
                  multi
                  fullWidth
                  loading={loading}
                  margin="normal"
                />
              </div>
              <div className={classesAuthority.row}>
                <ProtectionInput />
              </div>
              <div className={formClasses.actions}>
                <Button
                  type="submit"
                  disabled={invalid}
                  size="large"
                  variant="contained"
                >
                  Update
                </Button>
              </div>
            </form>
          )}
        />
      </Loader>
    </>
  );
}
export default AuthorityAdminTab;
