import React from 'react';
import TableFilter from '../../components/Filter';
import { Input } from '../../components/Form';
import { composeValidators, maxLength, mustBeNumber } from '../../validators';
import Descriptor from "../../components/Descriptor";

export interface IRollingFilter {
  dateFrom: number | null;
  dateTo: number | null;
  orderId: string | null;
  customer?: string;
}

interface IProps {
  filter: IRollingFilter;
  onChange: any;
}

export default function RollingFilter({ filter, onChange }: IProps) {
  return (
    <TableFilter onApply={onChange} filter={filter}>
      <Descriptor group="filters" title="clearsDate" >
        <Input
          name="orderId"
          label="Order ID"
          margin="none"
          validate={composeValidators(mustBeNumber, (v: any) => maxLength(v, 19))}
        />
      </Descriptor>
      <Input name="customer" label="Customer" margin="none" />
    </TableFilter>
  );
}
