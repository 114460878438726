import React, {useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Form } from 'react-final-form';
import DetailsPopup from '../../../components/Layout/DetailsPopup';
import {Input, ProtectionInput, Select} from '../../../components/Form';
import useFormStyles from '../../../hooks/useFormStyles';
import {useKycLimitsUpdate, useKycLimitsDelete, IKYCLimitsItem, useKycLimitsCreate} from '../../../api/kyc';
import {LegalEntityOne, useLegalEntityList} from "../../../api/legalEntity";
import { useBreakpoints } from '../../../hooks/useBreakpoints ';


interface IProps {
  limit?: IKYCLimitsItem;
  limitLoading: boolean;
  isCreate: boolean;
  onClose: any;
  onSave: any;
  onDelete: any;
}

const list = [
  {label: 'por', value: 'por'},
  {label: 'utp+por', value: 'utp+por'},
  {label: 'utp-EU+por', value: 'utp-EU+por'},
  {label: 'utp-EU-and-por', value: 'utp-EU-and-por'},
  {label: 'utp-EU', value: 'utp-EU'},
  {label: 'utp', value: 'utp'},
]

export default function KycLimitsDetails({ limit, limitLoading, onDelete, isCreate = false, onClose, onSave }: IProps) {
  const formClasses = useFormStyles();
  const [{ items: entities }, loadingEntity, loadEntity] = useLegalEntityList();
  const isResolutionSevenHundred = useBreakpoints(700);
  const [create, creating] = useKycLimitsCreate((res: any) => {
    if (res.success) {
      onSave();
    }
  })
  const [update, updating] = useKycLimitsUpdate((res: any) => {
    if (res.success) {
      onSave();
    }
  })
  const [deleteLimit, deleting] = useKycLimitsDelete((res: any) => {
    if (res.success) {
      onDelete();
    }
  })
  const formData: IKYCLimitsItem | {id: null} = (!isCreate && limit) ? limit : {id: null};

  useEffect(() => {
    loadEntity();
  }, []);

  function save(data: any){
    isCreate ? create(data) : update(data);
  }

  return (
    <DetailsPopup onClose={onClose} loading={limitLoading} >
      {formData 
        ? (
          <>
            <Typography variant="h6" color="primary">{
              formData?.id 
              ? `Update KYC limit id ${formData?.id}`
              : 'Create new KYC limit'
            }</Typography>
            <Form
              onSubmit={save}
              initialValues={{ ...formData, '2fa': '' }}
              render={({ handleSubmit, values }) => (
                <form
                  onSubmit={handleSubmit}
                >
                  {formData.id &&
                    <div className={formClasses.row}>
                      <Input disabled name="id" label="Id" />
                    </div>
                  }
                  <div className={formClasses.row}>
                    <Input type="number" name="newLevel" label="New Level" />
                  </div>
                  <div className={formClasses.row}>
                    <Input type="number" name="amountFrom" label="Amount From" />
                  </div>
                  <div className={formClasses.row}>
                    <Input type="number" name="amountTo" label="Amount To" />
                  </div>
                  <div className={formClasses.row}>
                    <Input type="number" name="currentLevel" label="Current Level" />
                  </div>
                  <div className={formClasses.row}>
                    <Input name="flow" label="Flow" />
                  </div>
                  <div className={formClasses.row}>
                    <Select
                      name="legalEntity"
                      label={`Legal Entity`}
                      options={entities.map((c: LegalEntityOne) => ({ value: c.id, label: `${c.name}` }))}
                      loading={loadingEntity}
                      margin="normal"
                      fullWidth={isResolutionSevenHundred}
                    />
                  </div>
                  <div className={formClasses.row}>
                    <ProtectionInput />
                  </div>
                  <div className={formClasses.actions}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={updating || creating}
                    >
                      Save
                    </Button>
                  {formData?.id && 
                    <Button
                      variant="contained"
                      disabled={deleting || updating || creating || values['2fa']?.length < 6}
                      color="secondary"
                      onClick={() => {
                        deleteLimit({id: values.id, '2fa': values['2fa'] })
                      }}
                    >
                      Delete
                    </Button>
                  }
                  </div>
                </form>
              )}
            />
          </>
        ) : <Typography align="center">No data to display</Typography>} 
    </DetailsPopup>
  )
}
