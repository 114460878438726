import React, {useEffect, useRef, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import EmptyList from "../../../components/EmptyList";
import useGlobalStyles from "../../../components/Layout/useGlobalStyles";
import {defaultInvoice, useOlyList} from "../../../api/oly";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {useHistory, useParams} from "react-router";
import ColumnsFilter from "../../../components/Columns";
import OlyInvoiceList from "./OlyInvoiceList";
import useColumns, {IColumn} from "../../../hooks/useColumns";
import OlyInvoiceDetails from "./Details";
import Loader from "../../../components/Loader";
import {Waypoint} from "react-waypoint";
import OlyInvoiceFilter, {IOlyInvoiceFilter} from "./OlyInvoiceFilter";
import useFilter from "../../../hooks/useFilter";
import useMobileStyles from '../../../hooks/useMobileStyles';
import FilterWrapper, { ICollapseHandle } from "../../../components/FilterWrapper/FilterWrapper";

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'created', name: 'Created at', active: true },
  { id: 'updated', name: 'Updated at', active: true },
  { id: 'transactionId', name: 'Transaction ID', active: true },
  { id: 'status', name: 'Status', active: true },
  { id: 'postbackUrl', name: 'Postback URL', active: true },
  { id: 'adminEmail', name: 'Admin email', active: true },
  { id: 'adminId', name: 'Admin ID', active: true }
];

export default function OlyInvoicePage(){
  const classes = useGlobalStyles();
  const [{ items, cursor, full, error }, loading, load] = useOlyList();
  const accordionRef = useRef<ICollapseHandle>(null);
  const history = useHistory();
  const [columns, ColumnsFilter] = useColumns('admins', defaultColumns);
  const { olyInvoiceId } = useParams();
  const mobileClasses = useMobileStyles();
  const [olyInvoice, setOlyInvoice] = useState();
  const emptyFilter = () => ({
    status: undefined
  });
  const [setFilter, filter] = useFilter('oly', emptyFilter());

  useEffect(() => {
    accordionRef.current?.collapse();
    load({
      filter,
      cursor: 0,
    });
  }, [filter])

  useEffect(() => {
    if (olyInvoiceId && items.length) {
      const invoice = olyInvoiceId === 'new' ? defaultInvoice : items.find((i: any) => i.id.toString() === olyInvoiceId);
      setOlyInvoice(invoice);
    }
  }, [olyInvoiceId, items]);

  function setDetails(id?: string) {
    history.push(`/other/oly${id ? `/${id}` : ''}`);
  }

  function sendData(id?: number){
    load({cursor: 0, filter});
    if(id){
      setOlyInvoice(id);
      setDetails(id.toString());
    }

  }

  return(
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <OlyInvoiceFilter filter={filter as IOlyInvoiceFilter} onChange={setFilter} />
      </FilterWrapper>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justify="space-between" className={mobileClasses.gap}>
              <Grid item>
                <Button variant="contained" size="large" onClick={() => setDetails('new')}>Send new invoice</Button>
              </Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0
            ? <OlyInvoiceList items={items} onRowClick={setDetails} columns={columns} />
            : <EmptyList error={error} loading={loading} />
          }
          <Loader loading={loading} />
          {!loading && !error && !full && <Waypoint onEnter={() => load({ cursor, filter })} />}
        </Paper>
      </Grid>
      {olyInvoiceId && <OlyInvoiceDetails
          id={olyInvoiceId}
          data={olyInvoice}
          onClose={() => setDetails()}
          loading={loading}
          onSend={sendData}
      />}
    </Grid>
  );
}