import Api, { useFetchApi } from './index';

export interface IApiRequests{
  id: number;
  url: string;
  requestedById: number;
  headers: any;
  request: any;
  response: any;
  createdAt: any;
  updatedAt: any;
}

export function useApiRequestsList() {
  return useFetchApi(
    async (query: any) => Api.post('/log/request/list', { ...query, limit: 50 }),
    { items: [] }
  );
}
