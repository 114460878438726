import Api, { useFetchApi } from './index';

export enum RollingStatus {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
  PAYOUT = 'PAYOUT',
}

export interface IRollingItem {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  orderId: number;
  customerId: number;
  customerEmail?: string;
  amount: number;
  currency: string;
  status: RollingStatus;
}

export function useRollingReserveList() {
  return useFetchApi(
    async (query: any) => {
      const q: any = { filter: {} };
      Object.keys(query).forEach((k) => {
        if (k === 'filter') {
          Object.keys(query.filter).forEach((f) => {
            if (f === 'customer' && query.filter.customer) {
              if (/^\d+$/.test(query.filter.customer) && query.filter.customer.length < 19) {
                q.filter.customerId = query.filter.customer;
              } else {
                q.filter.customerEmail = query.filter.customer;
              }
            } else {
              q.filter[f] = query.filter[f];
            }
          })
        } else {
          q[k] = query[k];
        }
      })
      return Api.post('/rolling/list', { filter: {}, limit: 50, ...q })
    },
    { items: [] }
  )
}

export function useRollingByOrder() {
  return useFetchApi(
    async (orderId: any) => Api.post('/rolling/list', { filter: { orderId }, limit: 1 }),
    { items: [] }
  );
}
