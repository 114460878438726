import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  }
}));

interface IUploadFieldProps {
  onLoad: any;
  input: any;
}

export default function UploadField({ onLoad, input }: IUploadFieldProps) {
  const [fileName, setFileName] = useState();
  const onDrop = useCallback((files: File[]) => {
    files.forEach((f) => {
      setFileName(f.name);
      // const reader = new FileReader();
      // reader.onload = () => onLoad(reader.result);
      // reader.readAsArrayBuffer(f);
      onLoad(f);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const classes = useStyles();

  return (
    <div className={classes.root} {...getRootProps()}>
      <input {...getInputProps()} {...input} />
      <Button variant="text" color="primary">{fileName ? fileName : 'Select file to import'}</Button>
    </div>
  );
}
