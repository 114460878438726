import React from 'react';

import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateView from '../../../components/DateView';
import BoolLabel from '../../../components/Label/BoolLabel';
import Ellipsis from '../../../components/UI/Ellipsis';
import { IColumn } from '../../../hooks/useColumns';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import { IPaymentProvider } from '../../../api/paymentProviders';
import TableCellId from '../../../components/TableCellId/TableCellId';
import Tablecell from '../../../components/TableCell/TableCell';
import useMobileStyles from '../../../hooks/useMobileStyles';

interface IProps {
  items: IPaymentProvider[];
  columns: IColumn[];
  openDetails: any;
}

const content: {[key: string]: (i: IPaymentProvider) => void} = {
  id: ({ id }) => <TableCellId key="id">{id}</TableCellId>,
  name: ({ name }) => <Tablecell title="Name" key="name">{name}</Tablecell>,
  enabled: ({ enabled }) => <Tablecell title="Enabled" key="enabled">
    <BoolLabel value={enabled} />
    </Tablecell>,
  depositSupported: ({ depositSupported }) => <Tablecell title="Deposit supported" key="dep">
    <BoolLabel value={depositSupported} /></Tablecell>,
  withdrawalSupported: ({ withdrawalSupported }) => 
    <Tablecell title="Withdrawal supported" key="withdrwl">
      <BoolLabel value={withdrawalSupported} />
    </Tablecell>,
  priority: ({ priority }) => <Tablecell title="Priority" key="prioroty">{priority}</Tablecell>,
  processingExpirationTime: ({ processingExpirationTime }) => 
  <Tablecell title="Processing exp. time" key="pet">{processingExpirationTime}</Tablecell>,
  currencies: ({ currencies }) => (
    <Tablecell title="Currencies" key="currencies">
      <Ellipsis
        text={currencies.sort(sortStrings).join(', ')}
        height={16}
      />
    </Tablecell>
  ),
  countries: ({ countries }) => (
    <Tablecell title="Countries" key="countries">
      <Ellipsis
        text={countries.sort(sortStrings).join(', ')}
        height={16}
      />
    </Tablecell>
  ),
  createdAt: ({ createdAt }) => <Tablecell title="Created" key="created">
    <DateView value={createdAt} /></Tablecell>,
  updatedAt: ({ updatedAt }) => <Tablecell title="Updated" key="updated">
    <DateView value={updatedAt} /></Tablecell>,
};

export const sortStrings = (a: string, b: string) => {
  if(a < b) { return -1; }
  if(a > b) { return 1; }
  return 0;
}

export default function PaymentProvidersList({ items, columns, openDetails }: IProps) {
  const globalClasses = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  const activeCols = columns.filter((c) => c.active);

  return (
    <Table size="small">
      <TableHead className={mobileClasses.tableHead}>
        <TableRow>
          {activeCols.map((c) => <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow hover 
            className={clsx(globalClasses.pointer, mobileClasses.row, mobileClasses.lastChild)}
            key={i.id}
            onClick={() => openDetails(i.id)}>
            {activeCols.map((c) => content[c.id](i))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}