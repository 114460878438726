import React, {FC, useEffect} from 'react'

import TabsPanel from '../../../components/Tabs';
import {IKycCountry, useKycStates} from "../../../api/kyc";
import DetailsPopup from "../../../components/Layout/DetailsPopup";

import CountryOverviewTab from './CountryOverviewTab'
import StatesTab from './StatesTab'

interface IDetailsProps {
  country?: IKycCountry;
  countriesLoading: boolean;
  onClose: any;
  onSave: any;
}

const Details: FC<IDetailsProps> = ({countriesLoading, onClose  , country, onSave}) => {
  const withStates = country?.useState

  const [{ items: states }, statesLoading, loadStates] = useKycStates()
  const loadStatesForCountry = (filters: any = {}) => {
    const countryFilters = {...filters, country: country?.code }
    loadStates(countryFilters)
  }

  useEffect(() => {
    if(withStates){
      loadStatesForCountry()
    }
  }, [country?.name])


  const tabs = [
    {
      title: "Overview",
      content: <CountryOverviewTab country={country} onSave={onSave} />
    },
  ]

  if(withStates) {
    tabs.push({
      title: "States",
      content: <StatesTab isLoading={statesLoading} states={states} onLoad={loadStatesForCountry} />
    })
  }


  return (
    <DetailsPopup onClose={onClose} loading={countriesLoading}>
      <TabsPanel tabs={tabs} />
    </DetailsPopup>
  )
}

export default Details
