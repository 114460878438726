import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { Waypoint } from 'react-waypoint';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import ClearingList from './ClearingList';
import ClearingFilter from './ClearingFilter';
import EmptyList from '../../components/EmptyList';
import Loader from '../../components/Loader';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { useClearingList, useClearingCSV } from '../../api/clearing';
import useFilter from '../../hooks/useFilter';
import { IClearingFilter } from './ClearingFilter';
import useColumns, { IColumn } from '../../hooks/useColumns';
import {clearDatesIfIdsInFilter} from "../../helpers/filtersHelper";
import FilterWrapper, { ICollapseHandle } from '../../components/FilterWrapper/FilterWrapper';

export const emptyFilter = () => ({
  status: null,
  pair: null,
  orderId: null,
  externalId: null,
  dates: undefined,
  provider: null,
});

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'orderId', name: 'Order ID', active: true },
  { id: 'extId', name: 'Ext. ID', active: true },
  { id: 'exchange', name: 'Exchange', active: true },
  { id: 'pair', name: 'Pair', active: true },
  { id: 'side', name: 'Side', active: true },
  { id: 'amount', name: 'Amount', active: true },
  { id: 'price', name: 'Price', active: true },
  { id: 'status', name: 'Status', active: true },
  { id: 'created', name: 'Created at', active: true },
  { id: 'updated', name: 'Updated at', active: true },
];

export default function ClearingPage() {
  const classes = useGlobalStyles();
  const [{ items, full, error }, loading, load ] = useClearingList();
  const [setFilter, filter] = useFilter('clearing', emptyFilter());
  const [{ data }, exporting, loadCsv] = useClearingCSV();
  const history = useHistory();
  const [columns, ColumnsFilter] = useColumns('clearing', defaultColumns);
  const accordionRef = useRef<ICollapseHandle>(null);

  function setDetailsUrl(id?: string) {
    history.push(`/clearing${id ? `/clearing-details/${id}` : ''}`);
  }

  useEffect(() => {
    const reqFilters = clearDatesIfIdsInFilter(filter,['orderId', 'externalId'])

    accordionRef.current?.collapse();

    load({ filter: reqFilters });
  }, [filter])

  function exportCsv(e: any) {
    e.preventDefault();
    loadCsv({ filter, csv: true });
  }

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <ClearingFilter filter={filter as IClearingFilter} onChange={setFilter} onExport={exportCsv} />
      </FilterWrapper>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justify="flex-end">
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0
            ? <ClearingList items={items} onRowClick={setDetailsUrl} columns={columns} />
            : <EmptyList error={error} loading={loading} />}
          <Loader loading={loading} />
          {!loading && !error && !full && <Waypoint onEnter={() => load({ cursor: items.length, filter })} />}
        </Paper>
      </Grid>
    </Grid>
  );
}
