import React from 'react';
import { Form } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ACLType, ListType, useCreateBWItem } from '../../../api/acl';
import DetailsPopup from '../../../components/Layout/DetailsPopup';
import { Input, Select, ProtectionInput } from '../../../components/Form';
import useFormStyles from '../../../hooks/useFormStyles';
import { required } from '../../../validators';

interface IProps {
  type: ListType;
  onSave: any;
  onClose: any;
}

export default function CreateBWItemPopup({ type, onSave, onClose }: IProps) {
  const formClasses = useFormStyles();
  const [save, saving] = useCreateBWItem((res: any) => {
    if (res.success) {
      onSave(res.data);
      onClose();
    }
  });

  return (
    <DetailsPopup onClose={onClose}>
      <Typography variant="h6" color="primary">Create {type.toLowerCase()}list item</Typography>
      <Form
        onSubmit={save}
        initialValues={{
          type: '',
          value: '',
          white: type === ListType.WHITE,
          '2fa': ''
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={formClasses.row}>
              <Select
                name="type"
                label="Type"
                options={Object.keys(ACLType).map((k) => ({ value: k, label: k }))}
                validate={required}
                margin="normal"
                fullWidth
              />
              <div />
            </div>
            <div className={formClasses.row}>
              <Input
                name="value"
                label="Value"
                validate={required}
                fullWidth
              />
              <div />
            </div>
            <div className={formClasses.row}>
              <ProtectionInput />
              <div />
            </div>
            <div className={formClasses.actions}>
              <Button
                type="submit"
                variant="contained"
                disabled={saving}
              >
                Save
              </Button>
            </div>
          </form>
        )}
      />
    </DetailsPopup>
  )
}
