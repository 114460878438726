import React, {useEffect, useMemo, useState} from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {Form} from 'react-final-form';
import useFormStyles from "../../../../hooks/useFormStyles";
import DetailsPopup from "../../../../components/Layout/DetailsPopup";
import {CurrencySelect, Input, ProtectionInput, Select} from "../../../../components/Form";
import {
  useCreatePaymentMethod,
  useDeletePaymentMethod,
  useOnePaymentMethod,
  useUpdatePaymentMethod
} from "../../../../api/paymentMethod";
import {composeValidators, maxValue, minValue, mustBeNumber, required} from "../../../../validators";
import {IKycCountry, useKycCountries} from "../../../../api/kyc";
import {CurrencyType, useCurrenciesList} from "../../../../api/currency";
import {usePaymentProvidersList} from "../../../../api/paymentProviders";
import {usePaymentGroupList} from "../../../../api/paymentGroup";
import { useBreakpoints } from '../../../../hooks/useBreakpoints ';

interface IProps {
  id: number | 'new';
  group?: string;
  onClose: any;
  onSave: any;
}

export default function PaymentMethodDetails({ id, group, onClose, onSave }: IProps) {
  const formClasses = useFormStyles();
  const [method, loading, load] = useOnePaymentMethod();
  const [create, creating] = useCreatePaymentMethod((res: any) => {
    if (res.success) {
      onSave(res.data.id);
    }
  });

  const [update, updating] = useUpdatePaymentMethod((res: any) => {
    if (res.success) {
      onSave(res.data.id);
    }
  });

  const [deleteMethod, deleting] = useDeletePaymentMethod((res: any) => {
    if (res.success) {
      onSave();
    }
  });

  const [currenciesList, loadingCurrencies, loadCurrencies] = useCurrenciesList();
  const [providersList, loadingProviders, loadProviders] = usePaymentProvidersList();
  const [groupList, loadingGroups, loadGroups] = usePaymentGroupList();
  const [{ items: countries }, loadingCountries, loadCountries] = useKycCountries();
  const isResolutionSevenHundred = useBreakpoints(700);

  useEffect(() => {
    if(id != 'new')
      load(id);
  }, [id]);

  useEffect(() => {
    loadCurrencies();
    loadProviders({});
    loadCountries();
    loadGroups();
  }, [])

  useState()

  const currencies = useMemo(() => {
    return currenciesList.items.map((i: any) => i.type === 'FIAT' && { label: i.currency, value: i.currency }).filter((v: any) => !!v);
  }, [currenciesList]);

  const providers = useMemo(() => {
    return providersList.items.map((i: any) => ({ label: i.name, value: i.id })).filter((v: any) => !!v);
  }, [providersList]);

  const groups = useMemo(() => {
    return groupList.items.map((i: any) => ({ label: i.name, value: i.id })).filter((v: any) => !!v);
  }, [groupList]);

  const formData = {...method.data, group: id != 'new' ? method.data?.group : group};
  return (
    <DetailsPopup onClose={onClose} loading={loading} >
      {formData
        ? (
          <>
            <Typography variant="h6" color="primary">{
              formData?.id
                ? `Update Payment method id ${formData?.id}`
                : 'Create new Payment method'
            }</Typography>
            <Form
              onSubmit={(values) => formData.id
                ? update({...values})
                : create({ ...values })}
              initialValues={{ ...formData, '2fa': '' }}
              render={({ handleSubmit, values }) => (
                <form
                  autoComplete='nope'
                  onSubmit={handleSubmit}
                >
                  <div className={formClasses.row}>
                    <Input name="name" label="Name" />
                  </div>
                  <div className={formClasses.row}>
                    <Select
                      name="enabled"
                      label="Enabled"
                      options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                      margin="normal"
                      validate={required}
                      fullWidth={isResolutionSevenHundred}
                    />
                    <Input name="priority" label="Priority" />
                    <Select
                      name="group"
                      label="Group"
                      options={groups}
                      margin="normal"
                      validate={required}
                      fullWidth={isResolutionSevenHundred}
                    />
                  </div>
                  <Typography variant="body2" color="primary">Provider settings</Typography>
                  <div className={formClasses.row}>
                    <Select
                      name="provider"
                      label="Provider"
                      options={providers}
                      margin="normal"
                      validate={required}
                      fullWidth={isResolutionSevenHundred}
                    />
                    <Select
                      name="flow"
                      label="Url open flow"
                      options={[{ label: 'Iframe', value: 'IFRAME' }, { label: 'Redirect', value: 'REDIRECT' }]}
                      margin="normal"
                      validate={required}
                      fullWidth={isResolutionSevenHundred}
                    />
                    <Input type='search' name="externalId" autoComplete='xyz123' label="ExternalId" id='external_id'/>
                  </div>
                  <Typography variant="body2" color="primary">Fees</Typography>
                  <div className={formClasses.row}>
                    <Input type="number"
                           name="fee.deposit"
                           label="Deposit"
                           validate={composeValidators(required, mustBeNumber, (v: any) => maxValue(v, 0.2), (v: any) => minValue(v, 0))}
                    />
                    <Input type="number"
                           name="fee.depositFixed"
                           label="Deposit Fixed"
                           validate={composeValidators(required, mustBeNumber, (v: any) => maxValue(v, 1000), (v: any) => minValue(v, 0))}
                    />
                    <Select
                      name="fee.fixedCurrency"
                      label="Fixed Currency"
                      options={currencies}
                      margin="normal"
                      validate={required}
                      fullWidth={isResolutionSevenHundred}
                    />
                  </div>
                  <Typography variant="body2" color="primary">Currencies</Typography>
                  <div className={formClasses.row}>
                    <CurrencySelect
                      name="includedCurrencies"
                      label={`Included Currencies  (${values?.includedCurrencies?.length || 0})`}
                      multi
                      type={CurrencyType.FIAT}
                      margin="normal"
                      supported={values.supportedCurrencies}
                    />
                  </div>
                  <div className={formClasses.row}>
                    <CurrencySelect
                      name="excludedCurrencies"
                      label={`Excluded Currencies  (${values?.excludedCurrencies?.length || 0})`}
                      multi
                      type={CurrencyType.FIAT}
                      margin="normal"
                      supported={values.supportedCurrencies}
                    />
                  </div>
                  <Typography variant="body2" color="primary">Countries</Typography>
                  <div className={formClasses.row}>
                      <Select
                        name="includedCountries"
                        label={`Included Countries (${values?.includedCountries?.length || 0})`}
                        options={countries.map((c: IKycCountry) => ({ value: c.code, label: `${c.name} (${c.code})` }))}
                        multi
                        loading={loadingCountries}
                        margin="normal"
                        fullWidth={isResolutionSevenHundred}
                      />
                  </div>
                  <div className={formClasses.row}>
                    <Select
                      name="excludedCountries"
                      label={`ExcludedCountries (${values?.excludedCountries?.length || 0})`}
                      options={countries.map((c: IKycCountry) => ({ value: c.code, label: `${c.name} (${c.code})` }))}
                      multi
                      loading={loadingCountries}
                      margin="normal"
                      fullWidth={isResolutionSevenHundred}
                    />
                  </div>
                  <div className={formClasses.row}>
                    <ProtectionInput />
                  </div>
                  <div className={formClasses.actions}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={updating || creating || deleting || values['2fa']?.length < 6}
                    >
                      Save
                    </Button>
                    {formData?.id &&
                        <Button
                            variant="contained"
                            disabled={updating || creating || deleting || values['2fa']?.length < 6}
                            color="secondary"
                            onClick={() => {
                              deleteMethod({id: values.id, '2fa': values['2fa'] })
                            }}
                        >
                            Delete
                        </Button>
                    }
                  </div>
                </form>
              )}
            />
          </>
        ) : <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  )
}