import React, { useState } from 'react';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { DataRow } from '../../../components/DataView';
import { Input, ProtectionInput } from '../../../components/Form';
import BoolLabel from '../../../components/Label/BoolLabel';
import Loader from '../../../components/Loader';
import useFormStyles from '../../../hooks/useFormStyles';
import { required, isEmail, composeValidators } from '../../../validators';
import { IAdmin, useSaveAdmin, use2FADisable, usePasswordReset, useDisableAdmin } from '../../../api/admin';
import useMobileStyles from '../../../hooks/useMobileStyles';

interface IProps {
  admin: IAdmin;
  onCreate: any;
  view: boolean;
}

export default function AdminOverviewTab({ admin, onCreate, view }: IProps) {
  const formClasses = useFormStyles();
  const mobileClasses = useMobileStyles();
  const [disabling2fa, setDisabling2fa] = useState(false);
  const [changePasswordForm, setChangePasswordForm] = useState(false);
  const [deactivateForm, setDeactivateForm] = useState(false);
  const [save, saving] = useSaveAdmin((res: any) => {
    if (res.success) {
      onCreate(res.data);
    }
  });
  const [disable2fa, disabling2faProcess] = use2FADisable((res: any) => {
    if (res.success) {
      onCreate({ ...admin, googleAuthEnabled: false });
    }
  });
  const [changePassword, passwordChanging] = usePasswordReset(() => {});
  const [disableAdmin, disablingAdmin] = useDisableAdmin((res: any) => {
    if (res.success) {
      onCreate({ ...admin, active: false });
    }
  });

  return (
    <Loader loading={saving || passwordChanging || disablingAdmin}>
      {admin
        ? (
          <Form
            onSubmit={(values) => {
              if (changePasswordForm) {
                return changePassword({ id: admin.id, password: values.password, '2fa': values['2fa']});
              }
              if (deactivateForm) {
                return disableAdmin({ id: admin.id, '2fa': values['2fa']});
              }
              save({ ...values, email: values.email.trim() });
            }}
            initialValues={{ ...admin, '2fa': '' }}
            render={({ handleSubmit, invalid, values }) => (
              <form onSubmit={handleSubmit} className={formClasses.root}>
                <div className={formClasses.row}>
                  <Input
                    name="email"
                    label="Email"
                    type="search"
                    autoComplete="xyz123"
                    validate={composeValidators(required, isEmail)}
                    disabled={view}
                  />
                  <Input
                    name="username"
                    label="User name"
                    type="search"
                    autoComplete="xyz123"
                    disabled={view}
                  />
                </div>
                <div className={formClasses.row}>
                  <Input
                    name="firstName"
                    label="First name"
                    type="text"
                    disabled={view}
                  />
                  <Input
                    name="lastName"
                    label="Last name"
                    type="text"
                    disabled={view}
                  />
                </div>
                <div className={formClasses.row}>
                  <Input
                    name="position"
                    label="Position"
                    type="search"
                    autoComplete="xyz123"
                    disabled={view}
                  />
                </div>
                {admin.googleAuthEnabled && (
                  <DataRow label="Google Auth">
                    {disabling2fa
                      ? (
                        <Form
                          onSubmit={(values) => disable2fa({ id: admin.id, '2fa': values['2fa'] })}
                          initialValues={{ '2fa': '' }}
                          render={({ handleSubmit, invalid }) => (
                            <form onSubmit={handleSubmit} className={formClasses.row}>
                              <ProtectionInput margin="none" />
                              <Button
                                type="submit"
                                disabled={invalid || disabling2faProcess}
                                variant="contained"
                                size="large"
                              >Confirm</Button>
                            </form>
                          )}
                        />
                      ) : (
                        <div className={formClasses.row}>
                          <div className={mobileClasses.blockMargin}>
                            <BoolLabel value size="medium" labels={['Enabled', 'Disabled']} />
                          </div>
                          <Button
                            variant="contained"
                            size="medium"
                            onClick={() => setDisabling2fa(true)}
                          >
                            Disable
                          </Button>
                        </div>
                      )
                    }
                  </DataRow>
                )}
                {changePasswordForm && <Typography variant="body1" color="primary">Set new password</Typography>}
                {(!view || changePasswordForm) && (
                  <div className={formClasses.row}>
                    <Input
                      name="password"
                      label={changePasswordForm ? 'New password' : 'Password'}
                      type="password"
                      autoComplete="new-password"
                      validate={required}
                    />
                  </div>
                )}
                {(!view || changePasswordForm || deactivateForm) && (
                  <>
                    {deactivateForm && <Typography variant="body1" color="secondary">Deactivate admin?</Typography>}
                    <div className={formClasses.row}>
                      <ProtectionInput />
                    </div>
                  </>
                )}
                <div className={formClasses.actions}>
                  {view && !changePasswordForm && !deactivateForm && admin.active && (
                    <>
                      <Button
                        type="button"
                        variant="contained"
                        size="large"
                        onClick={() => setChangePasswordForm(true)}
                      >
                        Change password
                      </Button>
                      {!admin.googleAuthEnabled && (
                        <Button
                          type="button"
                          variant="contained"
                          size="large"
                          color="secondary"
                          onClick={() => setDeactivateForm(true)}
                        >
                          Deactivate admin
                        </Button>
                      )}
                    </>
                  )}
                  {(!view || changePasswordForm || deactivateForm) && (
                    <>
                      <Button
                        type="submit"
                        disabled={invalid}
                        variant="contained"
                        size="large"
                        color={deactivateForm ? 'secondary' : 'default'}
                      >
                        {deactivateForm ? 'Deactivate' : 'Save'}
                      </Button>
                      {(changePasswordForm || deactivateForm) && (
                        <Button
                          type="button"
                          variant="contained"
                          size="large"
                          onClick={() => {
                            setChangePasswordForm(false);
                            setDeactivateForm(false);
                          }}
                        >
                          Cancel
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </form>
            )}
          />
        ) : <Typography align="center">No data to display</Typography>}
    </Loader>
  );
}
