import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useGatewayConfigOne } from '../../../../api/gatewayConfig';
import DetailsPopup from '../../../../components/Layout/DetailsPopup';
import TabsPanel from '../../../../components/Tabs';
import { GatewayConfigForm } from './GatewayConfig';

interface IProps {
  id: string;
  onClose: any;
  onSave: () => void;
}

export function GatewayDetails({ id, onClose, onSave }: IProps) {
  const [{ data }, loading, load] = useGatewayConfigOne();

  useEffect(() => {
    id !== 'new' && load(id);
  }, [id])

  const tabs = [
    {
      title: 'Config',
      content: <GatewayConfigForm data={data} view={id !== 'new'} onUpdate={onSave} />
    }
  ];

  return (
    <DetailsPopup loading={loading} onClose={onClose}>
      {id === 'new' || data
        ? (
          <>
            <Typography variant="h6">{id === 'new' ? 'New Gateway' : data.id}</Typography>
            <TabsPanel tabs={tabs} />
          </>
        ) : <Typography align="center">No data to display</Typography>}
    </DetailsPopup>
  )
}
