import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Waypoint } from 'react-waypoint';
import { Form } from 'react-final-form'

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import CardList from './CardList';
import EmptyList from '../../../components/EmptyList';
import Loader from '../../../components/Loader';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import { useCardList } from '../../../api/cardController';
import SearchField from '../../../components/Form/SearchField';
import useColumns, { IColumn } from '../../../hooks/useColumns';
import FilterWrapper, { ICollapseHandle } from '../../../components/FilterWrapper/FilterWrapper';

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'customerId', name: 'Customer Id', active: true},
  { id: 'number', name: 'Card number', active: true},
  { id: 'createdAt', name: 'Created at', active: true},
  { id: 'updatedAt', name: 'Updated at', active: true},
];

const useStyles = makeStyles((theme) => ({
  filter: {
    '& .MuiPaper-root': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5)
    }
  }
}))

const initialValues = {
  customerId: '',
  number: '',
}

export default function CardControllerPage() {
  const classes = useStyles();
  const gClasses = useGlobalStyles();
  const [{ items, full, error }, loading, load ] = useCardList();
  const [columns] = useColumns('customers', defaultColumns);
  const accordionRef = useRef<ICollapseHandle>(null);

  useEffect(() => {
    load({});
  }, []);

  function filter(filter: any) {
    accordionRef.current?.collapse();
    load({ filter });
  }

  const filteredCards = items.sort((a: any, b: any) => b.createdAt - a.createdAt)

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <Form
          initialValues={initialValues}
          onSubmit={filter}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} className={clsx(gClasses.filters, classes.filter)}>
                <SearchField
                  name="customerId"
                  placeholder="Search by id..."
                />
                <SearchField
                  name="number"       
                  placeholder="Search by card"
                />
                <Button type="submit" variant="contained" size="large" className="apply">Apply</Button>
              </form>
            )
          }}
        />
      </FilterWrapper>

      <Grid item className={gClasses.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={gClasses.paper}>
          {items.length > 0
            ? <CardList items={filteredCards} columns={columns} />
            : <EmptyList error={error} loading={loading} />
          }
          <Loader loading={loading} />
          {/* {!loading && !error && !full && !cardNumber.length && !customerId.length && items.length > 1
          && <Waypoint onEnter={() => load({ cursor: items.length })} />} */}
          {!loading && !error && !full && <Waypoint onEnter={() => load({ cursor: items.length, filter })} />}
          {<Waypoint onEnter={() => load({ cursor: items.length, filter, limit : 100 })} />}
        </Paper>
      </Grid>
    </Grid>
  );
}
