import React, { useEffect, useRef, useState } from 'react';

import { useHistory, useParams } from 'react-router';

import { useSelector } from 'react-redux';

import { Form } from 'react-final-form';

import { Waypoint } from 'react-waypoint';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import BlockchainTxList from './BlockchainTxList';
import BlockchainTxFilter, { IBlockchainTxFilter } from './BlockchainTxFilter';
import EmptyList from '../../components/EmptyList';
import Loader from '../../components/Loader';
import ColHead from '../../components/UI/ColHead';
import { ProtectionInput } from '../../components/Form';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import {
  BlockchainTxStatus,
  IBlockchainTx,
  useBlockchainReinitTx,
  useBlockchainTxList,
  useSendBTC,
} from '../../api/blockchain';
import useFilter from '../../hooks/useFilter';
import useColumns, { IColumn } from '../../hooks/useColumns';
import { clearDatesIfIdsInFilter } from '../../helpers/filtersHelper';
import { BlockchainTxDetails } from './Details';
import useMobileStyles from '../../hooks/useMobileStyles';
import FilterWrapper, { ICollapseHandle } from '../../components/FilterWrapper/FilterWrapper';

const useStyles = makeStyles((theme) => ({
  root: {},
  total: {
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(2),
  },
  sendConfirmation: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
  table: {
    width: '100%',
  },
}));

const defaultColumns: IColumn[] = [
  {
    id: 'id',
    name: 'ID',
    active: true,
    component: <ColHead title="ID" descGroup="blockchainColumns" key="id" />,
  },
  { id: 'orderId', name: 'Order ID', active: true },
  {
    id: 'txId',
    name: 'TX ID',
    active: true,
    component: <ColHead title="TX ID" key="txId" />,
  },
  { id: 'amount', name: 'Amount', active: true },
  { id: 'fee', name: 'Fee', active: true },
  { id: 'currency', name: 'Currency', active: true },
  { id: 'customer', name: 'Customer', active: true },
  { id: 'address', name: 'Address', active: true },
  { id: 'provider', name: 'Provider', active: true },
  { id: 'status', name: 'Status', active: true },
  { id: 'created', name: 'Created at', active: true },
  { id: 'updated', name: 'Updated at', active: true },
];

export default function BlockchainTxPage() {
  const classes = useGlobalStyles();
  const localClasses = useStyles();
  const mobileClasses = useMobileStyles();
  const history = useHistory();
  const [{ items, cursor, full, error }, loading, load] = useBlockchainTxList();
  const globalCountry = useSelector(
    (store: any) => store.appData.countries.globalCountry
  );
  const emptyFilter = () => ({
    status: null,
    currency: null,
    orderId: null,
    customer: undefined,
    address: null,
    txId: null,
    providers: [],
    country: globalCountry || null,
  });
  const [setFilter, filter] = useFilter('blockchain', emptyFilter());
  const [columns, ColumnsFilter] = useColumns('blockchainTXs', defaultColumns);
  const [selected, setSelected] = useState([] as number[]);
  const [multiSelect, setMultiSelect] = useState(false);
  const [canSelect, setCanSelect] = useState(false);
  const accordionRef = useRef<ICollapseHandle>(null);
  const [totalSelectedAmount, setTotalSelectedAmount] = useState(0);
  const { blockchainId } = useParams<any>();
  const [sendBtcDialog, setSendBtcDialog] = useState(false);
  const [sendBtc, sending] = useSendBTC((res: any) => {
    if (res.success) {
      setSendBtcDialog(false);
      setSelected([]);
      load({ filter });
      setCanSelect(filter.status === BlockchainTxStatus.MANUAL);
    }
  });

  useEffect(() => {
    setFilter({
      ...filter,
      country: globalCountry,
    });
  }, [globalCountry]);

  const [reinitTx, sendingReinitTx] = useBlockchainReinitTx((res: any) => {
    setSendBtcDialog(false);
    setSelected([]);
    history.push('/blockchain');
    const reqFilters = clearDatesIfIdsInFilter(filter, ['orderId', 'txId']);
    load({ filter: reqFilters });
    setCanSelect(filter.status === BlockchainTxStatus.ERROR);
  });

  function updateList() {
    setSelected([]);
    const reqFilters = clearDatesIfIdsInFilter(filter, ['orderId', 'txId']);
    load({ filter: reqFilters });
    setCanSelect(filter.status === BlockchainTxStatus.ERROR);
  }

  useEffect(() => {
    if (selected.length) {
      let total = 0;
      items
        .filter((i: any) => selected.includes(i.id))
        .forEach((i: any) => {
          total += i.amount;
        });
      setTotalSelectedAmount(parseFloat(total.toFixed(8)));
    }
  }, [selected]);

  const handleSelectAll = (event: any) => {
    let selectedProducts: number[];

    if (event.target.checked) {
      selectedProducts = items.map((i: any) => i.id);
    } else {
      selectedProducts = [];
    }

    setSelected(selectedProducts);
  };

  const handleSelectOne = (event: any, id: number) => {
    const sI = selected.indexOf(id);
    let newSelected: number[] = [];

    if (!multiSelect) {
      newSelected = [id];
    } else if (sI === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (sI === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (sI === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (sI > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, sI),
        selected.slice(sI + 1)
      );
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    const reqFilters = clearDatesIfIdsInFilter(filter, ['orderId', 'txId']);
    accordionRef.current?.collapse();
    load({ filter: reqFilters });
    setSelected([]);
    setMultiSelect(filter.status === BlockchainTxStatus.MANUAL || filter.status === BlockchainTxStatus.ERROR);
    setCanSelect([BlockchainTxStatus.MANUAL, BlockchainTxStatus.ERROR].includes(filter.status));
  }, [filter]);

  function setDetails(id?: string) {
    history.push(`/blockchain${id ? `/${id}` : ''}`);
  }

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <BlockchainTxFilter
        filter={filter as IBlockchainTxFilter}
        onChange={setFilter}
      />
      </FilterWrapper>
      <Grid item className={localClasses.table}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justify="space-between" className={mobileClasses.gap}>
              <Grid item>
                {selected.length > 0 &&
                  filter.status === BlockchainTxStatus.MANUAL && (
                    <>
                      <Typography
                        variant="body2"
                        component="div"
                        className={localClasses.total}
                      >
                        Total amount{' '}
                        <Typography color="primary" component="span">
                          {totalSelectedAmount} BTC
                        </Typography>
                      </Typography>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => setSendBtcDialog(true)}
                      >
                        Send BTC
                      </Button>
                    </>
                  )}
                {selected.length > 0 && filter.status === BlockchainTxStatus.ERROR &&
                  <>
                    <Button variant="contained" size="small" onClick={() => setSendBtcDialog(true)}>Resubmit</Button>
                  </>
                }
                {selected.length > 0 &&
                  [BlockchainTxStatus.MANUAL, BlockchainTxStatus.ERROR].includes(filter.status) && (
                    <>
                      <Dialog
                        open={sendBtcDialog}
                        onClose={() => setSendBtcDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <div className={localClasses.sendConfirmation}>
                          <Form
                            onSubmit={(values) => {
                              filter.status === BlockchainTxStatus.MANUAL &&
                              sendBtc({ ...values, transactions: selected });

                              filter.status === BlockchainTxStatus.ERROR &&
                              reinitTx({ ...values, transactionIds: selected });
                            }}
                            initialValues={{ '2fa': '' }}
                            render={({ handleSubmit, values, invalid }) => (
                              <form onSubmit={handleSubmit}>
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    {filter.status === BlockchainTxStatus.ERROR &&
                                      'Confirm reinitiate of blockchain transactions'}
                                    {filter.status ===
                                      BlockchainTxStatus.MANUAL &&
                                      `${totalSelectedAmount} BTC will be sent to customer`}
                                    <ProtectionInput />
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={() => setSendBtcDialog(false)}
                                    size="small"
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    disabled={sending || invalid}
                                  >
                                    Confirm
                                  </Button>
                                </DialogActions>
                              </form>
                            )}
                          />
                        </div>
                      </Dialog>
                    </>
                  )}
              </Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0 ?
            <BlockchainTxList
              setDetails={setDetails}
              items={items}
              columns={columns}
              rowCheck={canSelect}
              selected={selected}
              multiSelect={multiSelect}
              selectAll={handleSelectAll}
              selectOne={handleSelectOne}
            /> : <EmptyList error={error} loading={loading} />
          }
          <Loader loading={loading} />
          {!loading && !error && !full && <Waypoint onEnter={() => load({ cursor, filter })} />}
        </Paper>
        {blockchainId && blockchainId !== 'global-settings' && (
          <BlockchainTxDetails
            id={blockchainId}
            onClose={() => history.push('/blockchain')}
            updateList={updateList}
          />
        )}
      </Grid>
    </Grid>
  );
}
