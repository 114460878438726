import React from 'react';

import {Form} from 'react-final-form';

import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import {CurrencySelect, Input, ProtectionInput} from '../../../components/Form';
import useFormStyles from '../../../hooks/useFormStyles';
import {LegalEntityOne } from '../../../api/legalEntity';
import {composeValidators, maxLength, minLength, minValue, mustBeNumber, required} from '../../../validators';
import {CurrencyType} from "../../../api/currency";

interface IProps {
  data: LegalEntityOne;
  onSave: (data: any) => void;
  saving: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    marginTop: theme.spacing(2)
  },
  asc: {
    alignSelf: 'center',
  },
  row: {
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '2px !important',
      paddingBottom: '2px !important',
    }
  },
}));

export function LegalEntitySettingsForm({ data, onSave, saving }: IProps) {
  const classes = useStyles();
  const formClasses = useFormStyles();

  if (!data || !Object.keys(data).length) {
    return <Typography align="center">No data to display</Typography>
  }

  return (
    <Form
      onSubmit={onSave}
      initialValues={{
        ...data,
        '2fa': '',
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={clsx(formClasses.root, classes.form)}>
          <div className={formClasses.row}>
            <Input
              name="settings.occasionalAmount"
              label="Occasional amount"
              validate={composeValidators(required, mustBeNumber, (v: string) => minValue(v, 0))}
            />
          </div>
          <div className={clsx(formClasses.row, classes.row)}>
            <CurrencySelect
              type={CurrencyType.FIAT}
              name="settings.occasionalCurrency"
              label="Occasional currency"
              fullWidth={true}
              margin={"normal"}
            />
          </div>
          <div className={formClasses.row}>
            <Input
              name="settings.occasionalCount"
              label="Occasional count"
              type="search"
              autoComplete="xyz123"
              validate={composeValidators(required, mustBeNumber, (v: string) => minValue(v, 0))}
            />
          </div>
          <div className={formClasses.row}>
            <Input
              type="search"
              autoComplete="xyz123"
              name="settings.occasionalPeriod"
              label="Occasional period (in months)"
              validate={composeValidators(required, mustBeNumber, (v: any) => minValue(v, 0))}
            />
          </div>
          <div className={formClasses.row}>
            <Input
              type="search"
              autoComplete="xyz123"
              name="settings.domain"
              label="Legal Entity Domain"
              validate={composeValidators(
                  required,
                  (v: any) => maxLength(v, 100),
                  (v: any) => minLength(v, 3)
              )}
            />
          </div>
          <Typography variant="body2" color="primary">Email template params</Typography>
          <div className={formClasses.row}>
                <Input
                    type="search"
                    autoComplete="xyz123"
                    name="settings.emailTemplateParams.apiKey"
                    label="Mailchimp API KEY (Envvar or value)"
                    validate={composeValidators(
                        required,
                        (v: any) => maxLength(v, 100),
                        (v: any) => minLength(v, 3)
                    )}
                />
            </div>
          <div className={formClasses.row}>
            <Input
                type="search"
                autoComplete="xyz123"
                name="settings.emailTemplateParams.sender"
                label="Email Sender"
                validate={composeValidators(
                    required,
                    (v: any) => maxLength(v, 100),
                    (v: any) => minLength(v, 3)
                )}
            />
          </div>
          <div className={formClasses.row}>
            <Input
                type="search"
                autoComplete="xyz123"
                name="settings.emailTemplateParams.appStoreAppUrl"
                label="AppStore Url"
                validate={composeValidators(
                    (v: any) => maxLength(v, 100),
                    (v: any) => minLength(v, 3)
                )}
            />
          </div>
          <div className={formClasses.row}>
            <Input
                type="search"
                autoComplete="xyz123"
                name="settings.emailTemplateParams.googlePlayAppUrl"
                label="GooglePlay Url"
                validate={composeValidators(
                    (v: any) => maxLength(v, 100),
                    (v: any) => minLength(v, 3)
                )}
            />
          </div>
          <div className={formClasses.row}>
            <Input
                type="search"
                autoComplete="xyz123"
                name="settings.emailTemplateParams.logoUrl"
                label="Logo Url"
                validate={composeValidators(
                    required,
                    (v: any) => maxLength(v, 100),
                    (v: any) => minLength(v, 3)
                )}
            />
          </div>
          <div className={formClasses.row}>
            <Input
                type="search"
                autoComplete="xyz123"
                name="settings.emailTemplateParams.senderName"
                label="Organisation Name"
                validate={composeValidators(
                    required,
                    (v: any) => maxLength(v, 100),
                    (v: any) => minLength(v, 3)
                )}
            />
          </div>
          <div className={formClasses.row}>
            <Input
                type="search"
                autoComplete="xyz123"
                name="settings.emailTemplateParams.supportEmail"
                label="Support email"
                validate={composeValidators(
                    required,
                    (v: any) => maxLength(v, 100),
                    (v: any) => minLength(v, 3)
                )}
            />
          </div>
          <div className={formClasses.row}>
            <ProtectionInput />
            <div />
          </div>
          <div className={formClasses.actions}>
            <Button
              type="submit"
              disabled={saving}
              variant="contained"
            >Save</Button>
          </div>
        </form>
      )}
    />
  )
}
