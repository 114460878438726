import React from 'react';

import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core';
import { ProtectionInput } from '../../../../components/Form';
import Button from '@material-ui/core/Button';

import { useAdminScoringReinitParamsData } from '../../../../api/kyc';

import useFormStyles from '../../../../hooks/useFormStyles';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    width: '100%',
    height: theme.spacing(5),
    '& div': {
      margin: 0
    }
  },
  button: { 
    marginLeft: theme.spacing(1)
  }

}))

export default function TempReinitCustomerScore({customerId, load}: any) {
  const formClasses = useFormStyles();
  const classes = useStyles();

  const [reinit, reining] = useAdminScoringReinitParamsData((res: any) => {
    if (res.success) {
      load({id: customerId});
    }
  });

  return (
    <Form
      onSubmit={(data: any) => reinit({...data, id: customerId})}
      initialValues={{'2fa': ''}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={formClasses.root}>
          <div className={classes.row}>
            <ProtectionInput />
            <Button
              variant="contained"
              type="submit"
              disabled={reining}
              fullWidth
              classes={{root: classes.button}}
            >
              Reinit score
            </Button>
          </div>
        </form>
      )}
    />
  )
}