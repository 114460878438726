import Api, { useFetchApi, useUpdateApi } from './index';
import {PaymentProvider} from "./payment";

export interface IPaymentMethod {
  id: number;
  name: string;
  enabled: boolean;
  priority: number;
  provider: PaymentProvider;
  flow: string;
  group: string;
  externalId: string;
  includedCountries: string[];
  excludedCountries: string[];
  includedCurrencies: string[];
  excludedCurrencies: string[];
  fee: IPaymentMethodFee
}

export interface IPaymentMethodFee{
  deposit: number;
  depositFixed: number;
  fixedCurrency: string;
}

export function useListPaymentMethod() {
  return useFetchApi(
    async (query: any) => Api.post('/payment-method/list', { limit: 50, ...query }),
    { items: [] }
  )
}

export function useCreatePaymentMethod(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/payment-method/create', data),
    cb,
    'Method successfully created'
  )
}

export function useUpdatePaymentMethod(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/payment-method/update', data),
    cb,
    'Method successfully updated'
  )
}

export function useDeletePaymentMethod(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/payment-method/delete', data),
    cb,
    'Method successfully deleted'
  )
}

export function useOnePaymentMethod() {
  return useFetchApi(
    async (id: any) => Api.post('/payment-method/one', { id }),
    {}
  )
}

