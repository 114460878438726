import React from 'react';

import clsx from 'clsx';

import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import { IColumn } from '../../../hooks/useColumns';
import { InvoiceStatus, IOlyInvoice } from '../../../api/oly';
import DateView from '../../../components/DateView';
import BoolLabel from '../../../components/Label/BoolLabel';
import ShortString from '../../../components/UI/ShortString';
import TableCellId from '../../../components/TableCellId/TableCellId';
import useMobileStyles from '../../../hooks/useMobileStyles';
import Tablecell from '../../../components/TableCell/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';

interface IProps {
  items: IOlyInvoice[];
  columns: IColumn[];
  onRowClick: any;
}

const content: { [key: string]: (i: IOlyInvoice, key: number) => void } = {
  id: (i, key) => <TableCellId key={key}>{i.id}</TableCellId>,
  created: (i, key) => <Tablecell key={key} title="Created at"><DateView value={i.createdAt} /></Tablecell>,
  updated: (i, key) => <Tablecell key={key} title="Updated at"><DateView value={i.updatedAt} /></Tablecell>,
  transactionId: (i, key) =>
    <Tablecell key={key} title="Transaction ID">
      <ShortString chars={30} text={i.request?.transactionId?.toString() || ''} />
    </Tablecell>,
  status: (i, key) =>
    <Tablecell key={key} title="Status">
      <BoolLabel value={i.status === InvoiceStatus.SENT} labels={[InvoiceStatus.SENT, InvoiceStatus.ERROR]}/>
    </Tablecell>,
  postbackUrl: (i, key) =>
    <Tablecell key={key} title="Postback URL">
      <ShortString chars={40} text={i.postbackUrl} />
    </Tablecell>,
  adminEmail: (i, key) => <Tablecell key={key} title="Admin email">{i.adminEmail}</Tablecell>,
  adminId: (i, key) => <Tablecell key={key} title="Admin ID" isLast>{i.adminId}</Tablecell>,
}

export default function OlyInvoiceList({ items, onRowClick, columns }: IProps){
  const classes = useGlobalStyles();
  const mobileClasses = useMobileStyles();

  return(
    <Table size="small">
      <TableHead className={mobileClasses.tableHead}>
        <TableRow>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow key={i.id} className={clsx(classes.pointer, mobileClasses.row)} onClick={() => onRowClick(i.id)} hover>
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}