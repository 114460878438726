import Api, {useFetchApi, useUpdateApi} from "./index";
import {Currency} from "../views/Other/OlyInvoice/Details/OlyInvoiceForm";


export enum InvoiceStatus {
  SENT = 'SENT',
  ERROR = 'ERROR'
}
export interface IOlyInvoice{
  id?: number;
  adminEmail: string;
  adminId: number;
  request: any;
  status: InvoiceStatus;
  error?: string;
  postbackUrl: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export const defaultInvoice: IOlyInvoice = {
  adminEmail: '',
  adminId: 0,
  request: {
    currency: Currency.EUR,
    transactionDate: new Date
  },
  status: InvoiceStatus.SENT,
  postbackUrl: ''
};

export function useOlyList() {
  return useFetchApi(
    async (query: any) => Api.post('/postback-dispatcher/list', { ...query, limit: 50 }),
    { items: []}
  )
}

export function useOlySend(cb: any) {
  return useUpdateApi(
    async (data: any) => {
      if(data?.jsonRequest?.transactionId)
        data.jsonRequest.transactionId = data.jsonRequest.transactionId.trim();
      return Api.post('/postback-dispatcher/send', data);
    },
    cb
  );
}

export function useOlyResend(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/postback-dispatcher/resend', data),
    cb
  );
}