import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { Form } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { Input } from '../../../components/Form';
import { required, lengthRequired } from '../../../validators';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import useFormStyles from '../../../hooks/useFormStyles';
import { usePasswordChange, use2FAInit, use2FABind, use2FAUnbind } from '../../../api/admin';
import { setUserAction } from '../../../store/actions/common';

const useStyles = makeStyles((theme) => ({
  root: {},
  divide: {
    margin: `${theme.spacing(2)}px 0`
  },
  centerItems: {
    display: 'flex',
    justifyContent: 'center'
  },
  qr: {
    marginTop: theme.spacing(2),
    marginRight: 0,
    maxWidth: 200,
    border: '30px solid #fff'
  },
  codeInput: {
    maxWidth: 220
  }
}));

export default function AdminSettings() {
  const { user } = useSelector((state: any) => state.common);
  const [changePassword, setChangePassword] = useState(false);
  const [qrUrl, setQrUrl] = useState();
  const [secret, setSecret] = useState();
  const [disable2fa, setDisable2fa] = useState(false);
  const globalClasses = useGlobalStyles();
  const formClasses = useFormStyles();
  const classes = useStyles();
  const [save, saving] = usePasswordChange((res: any) => {
    if (res.success) {
      setChangePassword(false);
    }
  });
  const [getQr, qrGetting] = use2FAInit((res: any) => {
    if (res.success) {
      setQrUrl(res.data.qrUrl);
      setSecret(res.data.secret);
    }
  });
  const dispatch = useDispatch();
  const [bind2fa, binding2fa] = use2FABind((res: any) => {
    if (res.success) {
      setQrUrl(undefined);
      setSecret(undefined);
      dispatch(setUserAction({ ...user, googleAuthEnabled: true }));
    }
  });
  const [unbind2fa, unbinding2fa] = use2FAUnbind((res: any) => {
    if (res.success) {
      dispatch(setUserAction({ ...user, googleAuthEnabled: false }));
    }
  });
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={globalClasses.paper}>
          <Form
            initialValues={{ ...user, oldPassword: '', newPassword: '' }}
            onSubmit={(values) => {
              save({ email: user.email.trim(), oldPassword: values.oldPassword, newPassword: values.newPassword });
            }}
            render={({ invalid, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className={formClasses.row}>
                  <Input name="email" label="Email" disabled />
                  <Input name="username" label="Username" disabled />
                </div>
                <div className={formClasses.row}>
                  <Input name="firstName" label="First name" disabled />
                  <Input name="lastName" label="Last name" disabled />
                </div>
                <Divider className={formClasses.endBlock} />
                {changePassword
                  ? (
                    <>
                      <Typography variant="body1" color="primary" align="center">Change password</Typography>
                      <div className={formClasses.row}>
                        <Input name="oldPassword" label="Current password" type="password" validate={required} />
                        <Input name="newPassword" label="New password" type="password" validate={required} />
                      </div>
                      <div className={formClasses.actions}>
                        <Button
                          disabled={invalid || saving}
                          type="submit"
                          variant="contained"
                        >
                          Save
                        </Button>
                      </div>
                    </>
                  ) : <Button variant="contained" onClick={() => setChangePassword(true)}>Change password</Button>}
              </form>
            )}
          />
          <Divider className={classes.divide} />
          {!user.googleAuthEnabled
            ? (
              <Form
                onSubmit={(values) => bind2fa(values)}
                initialValues={{ code: '' }}
                render={({ invalid, handleSubmit, form: { reset } }) => (
                  <form onSubmit={(e) => {
                    // @ts-ignore
                    handleSubmit(e).then(() => {
                      reset();
                    })
                  }}>
                    {qrUrl
                      ? (
                        <>
                          <Typography variant="body1" color="primary" align="center">Enable 2FA</Typography>
                          <div className={clsx(formClasses.row, classes.centerItems)}>
                            <img
                              className={classes.qr}
                              src={decodeURIComponent(qrUrl)}
                              alt={decodeURIComponent(qrUrl)}
                            />
                          </div>
                          <Typography variant="body1" align="center">{secret}</Typography>
                          <div className={clsx(formClasses.row, classes.centerItems)}>
                            <Input
                              name="code"
                              label="2FA code"
                              validate={(v: any) => lengthRequired(v, 6)}
                              className={classes.codeInput}
                            />
                          </div>
                          <div className={clsx(formClasses.actions, classes.centerItems)}>
                            <Button
                              disabled={invalid || binding2fa}
                              type="submit"
                              variant="contained"
                            >
                              Save
                            </Button>
                          </div>
                        </>
                      ) : <Button variant="contained" onClick={getQr} disabled={qrGetting}>Enable 2FA</Button>}
                  </form>
                )}
              />
            ) : (
              <Form
                onSubmit={(values) => unbind2fa(values)}
                initialValues={{ code: '' }}
                render={({ invalid, handleSubmit, form: { reset } }) => (
                  <form onSubmit={(e) => {
                    // @ts-ignore
                    handleSubmit(e).then(() => {
                      reset();
                    })
                  }}>
                    {disable2fa
                      ? (
                        <>
                          <Typography variant="body1" color="primary" align="center">Disable 2FA</Typography>
                          <div className={clsx(formClasses.row, classes.centerItems)}>
                            <Input
                              name="code"
                              label="2FA code"
                              validate={(v: any) => lengthRequired(v, 6)}
                              className={classes.codeInput}
                            />
                          </div>
                          <div className={clsx(formClasses.actions, classes.centerItems)}>
                            <Button
                              disabled={invalid || unbinding2fa}
                              type="submit"
                              variant="contained"
                            >
                              Send
                            </Button>
                          </div>
                        </>
                      ) : <Button variant="contained" onClick={() => setDisable2fa(true)}>Disable 2FA</Button>}
                  </form>
                )}
              />
            )}
        </Paper>
      </Grid>
    </Grid>
  );
}
