import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateView from '../../../components/DateView';
import BoolLabel from '../../../components/Label/BoolLabel';
import { IBookkeeperBalanceItem } from '../../../api/bookkeeper';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import { IColumn } from '../../../hooks/useColumns';

interface IProps {
  items: IBookkeeperBalanceItem[];
  columns: IColumn[];
  onRowClick: any;
}

const content: { [key: string]: (item: IBookkeeperBalanceItem, key: number) => void} = {
  id: (i, key) => <TableCell key={key}>{i.id}</TableCell>,
  type: (i, key) => <TableCell key={key}>{i.type}</TableCell>,
  account: (i, key) => <TableCell key={key}>{i.account}</TableCell>,
  subAccount: (i, key) => <TableCell key={key}>{i.subAccount}</TableCell>,
  balance: (i, key) => <TableCell key={key}>{i.balance}</TableCell>,
  currency: (i, key) => <TableCell key={key}>{i.currency}</TableCell>,
  manual: (i, key) => <TableCell key={key}><BoolLabel value={i.manual} /></TableCell>,
  created: (i, key) => <TableCell key={key}><DateView value={i.createdAt} /></TableCell>
}

export default function BalanceList({ items, onRowClick, columns }: IProps) {
  const classes = useGlobalStyles();
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {columns.map((c) => <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow key={i.id} className={classes.pointer} onClick={() => onRowClick(i.id)} hover>
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}