import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Loader from '../../../components/Loader';
import DateView from '../../../components/DateView';
import ACLFastAdd from '../../ACL/BlakWhite/ACLFastAdd';
import { ICustomerAddress, useCustomerAddresses } from '../../../api/customers';
import { ACLType } from '../../../api/acl';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  table: {
    marginBottom: theme.spacing(2)
  },
  checkbox: {
    fontSize: 10
  }
}));

interface IProps {
  id: any;
}

export default function CustomerAddresses({ id }: IProps) {
  const [{ items }, loading, load] = useCustomerAddresses();
  const classes = useStyles();
  const [dialog, setDialog] = useState<ICustomerAddress | undefined>();
  const [whiteList, setWhiteList] = useState(false);

  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id])

  let aclType: ACLType | undefined = undefined;
  if (dialog) {
    switch (dialog.type) {
      case 'CARD':
        aclType = ACLType.CARD_PAN;
        break;
      case 'CRYPTO':
        aclType = `${dialog.currency}_ADDRESS` as ACLType;
        break;
      default:
    }
  }

  return (
    <Loader loading={loading} width={450}>
      {dialog && aclType
      && <ACLFastAdd onClose={() => setDialog(undefined)} value={dialog?.address} type={aclType} white={whiteList} />}
      {!loading && !items.length
        ? <Typography variant="body1" align="center">No data to display</Typography>
        : (
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell>Requested by</TableCell>
                <TableCell>Processed by</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Direction</TableCell>
                <TableCell>Address</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((o: ICustomerAddress) => (
                <TableRow key={o.id}>
                  <TableCell>
                    <DateView value={o.createdAt} />
                  </TableCell>
                  <TableCell>
                    <DateView value={o.createdAt} />
                  </TableCell>
                  <TableCell>{o.requestedById}</TableCell>
                  <TableCell>{o.processedById}</TableCell>
                  <TableCell>{o.currency}</TableCell>
                  <TableCell>{o.type}</TableCell>
                  <TableCell>{o.direction}</TableCell>
                  <TableCell>{o.address}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        setWhiteList(false);
                        setDialog(o);
                      }}
                    >
                      Blacklist
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setWhiteList(true);
                        setDialog(o);
                      }}
                    >
                      Whitelist
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
    </Loader>
  );
}
