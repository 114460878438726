import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link'
import { Input } from '../../../components/Form';
import DateView from '../../../components/DateView';
import { useKycComment, IKYCItem } from '../../../api/kyc';
import useFormStyles from '../../../hooks/useFormStyles';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  comment: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  date: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  link: {
    cursor: 'pointer'
  }
}));

interface IProps {
  type: 'last' | 'all';
  data: IKYCItem;
  updateApplication: any;
  changeTab?: any;
}

export default function KycInternalComments({ type, data, updateApplication, changeTab }: IProps) {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const [save, saving] = useKycComment((res: any) => {
    if (res.success) {
      updateApplication({ ...data, ...res.data });
    }
  });

  const comments = data.comments.sort((a, b) => (b.timestamp - a.timestamp));

  return (
    <>
      {type === 'last' && (
          <>
            <Typography variant="body1" color="primary" className={classes.title}>
              <span>{comments.length > 0 ? 'Last internal comment' : 'Internal comment'}</span>
              <Link onClick={() => changeTab(2)} className={classes.link}>All comments</Link>
            </Typography>
            {comments.length > 0 && (
              <div className={classes.comment}>
                <Typography variant="body2" component="div" className={classes.date} color="textSecondary">
                  <DateView value={comments[0].timestamp} />
                  <span>Added by {comments[0].adminId}</span>
                </Typography>
                <Typography variant="body1">{comments[0].comment}</Typography>
              </div>
            )}
          </>
        )}
      <Form
        onSubmit={save}
        initialValues={{ id: data.id, comment: '' }}
        render={({ handleSubmit, invalid, form }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // @ts-ignore
              handleSubmit(e).then(() => form.reset());
            }}
          >
            <div className={formClasses.row}>
              <Input
                name="comment"
                label="Comment"
                multiline
              />
            </div>
            <div className={formClasses.row}>
              <Button
                type="submit"
                disabled={saving}
                size="large"
                variant="contained"
                className={formClasses.button}
              >
                Add comment
              </Button>
            </div>
          </form>
        )}
      />
      {type === 'all' && (
        <>
          {comments.map((c) => (
            <div className={classes.comment} key={c.timestamp}>
              <Typography variant="body2" component="div" className={classes.date} color="textSecondary">
                <DateView value={c.timestamp} />
                <span>Added by {c.adminId}</span>
              </Typography>
              <Typography variant="body1">{c.comment}</Typography>
            </div>
          ))}
        </>
      )}
    </>
  );
}
