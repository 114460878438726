import React from 'react';

import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import useMobileStyles from '../../../../../hooks/useMobileStyles';
import TableCellId from '../../../../../components/TableCellId/TableCellId';
import Tablecell from '../../../../../components/TableCell/TableCell';
import { IColumn } from '../../../../../hooks/useColumns';
import { IIvyBank } from '../../../../../api/apm';
import BoolLabel from '../../../../../components/Label/BoolLabel';
import Ellipsis from '../../../../../components/UI/Ellipsis';
import { sortStrings } from '../../PaymentProvidersList';

const content: { [key: string]: (item: IIvyBank, key: number) => void } = {
  id: (i, k) => <TableCellId key={k}>{i.id}</TableCellId>,
  name: (i, k) => <Tablecell key={k} title="Bank Name">{i.name}</Tablecell>,
  test: (i, k) => <Tablecell title="Test" key={k}><BoolLabel value={i.test} size="small" /></Tablecell>,
  defaultBank: (i, k) => <Tablecell title="Default Bank" key={k}><BoolLabel value={i.defaultBank} size="small" /></Tablecell>,
  currencies: ({ currencies }) => (
   <Tablecell title="Currencies" key="currencies">
     <Ellipsis
       text={currencies.sort(sortStrings).join(', ')}
       height={16}
     />
   </Tablecell>
  ),
  market: ({ market }) => (
   <Tablecell title="Market" key="market">
     <Ellipsis
       text={market.sort(sortStrings).join(', ')}
       height={16}
     />
   </Tablecell>
  ),
  capabilities: ({ capabilities }) => (
   <Tablecell title="Capabilities" key="capabilities">{capabilities.sort(sortStrings).join(', ')}</Tablecell>
 ),
  logo: (i, k) => <Tablecell title="Bank Logo" key={k}><img height={36} width={36} src={i.logo} alt="Bank icon" /></Tablecell>,
};

interface IPaymentProviderIvyBanksList {
   items: IIvyBank[];
   columns: IColumn[];
}

export default function PaymentProviderIvyBanksList({ items, columns }: IPaymentProviderIvyBanksList) {
   const mobileClasses = useMobileStyles();
 
   return (
     <Table size="small">
       <TableHead>
         <TableRow className={mobileClasses.tableHead}>
           {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
         </TableRow>
       </TableHead>
       <TableBody>
         {items.map((i) => (
           <TableRow key={i.id} 
             className={clsx(mobileClasses.row, mobileClasses.lastChild)} 
             hover
           >
             {columns.map((c, index) => content[c.id](i, index))}
           </TableRow>
         ))}
       </TableBody>
     </Table>
   );
 }