import React from 'react';

import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateView from '../../components/DateView';
import BoolLabel from '../../components/Label/BoolLabel';
import PopupLink from '../../components/UI/PopupLink';
import ShortString from '../../components/UI/ShortString';
import { IApiKey } from '../../api/apiKeys';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { IColumn } from '../../hooks/useColumns';
import TableCellId from '../../components/TableCellId/TableCellId';
import Tablecell from '../../components/TableCell/TableCell';
import useMobileStyles from '../../hooks/useMobileStyles';
import Ellipsis from "../../components/UI/Ellipsis";
import {sortStrings} from "../GlobalSettings/PaymentProviders/PaymentProvidersList";

interface IProps {
  items: IApiKey[];
  columns?: IColumn[];
  onRowClick?: any;
  type?: 'page' | 'popup';
}

const content: { [key: string]: (item: IApiKey, key: number) => void } = {
  id: (i, k) => <TableCellId key={k}>{i.id}</TableCellId>,
  customer: (i, k) => (
    <Tablecell key={k} title="Customer">
      <PopupLink popup="customer" id={i.customerId}>{i.customerEmail || i.customerId}</PopupLink>
    </Tablecell>
  ),
  merchant: (i, k) => <Tablecell title="Merchant name" key={k}>{i.legal ? i.legal.name : 'N/A'}</Tablecell>,
  domain: (i, k) => <Tablecell title="Domain" key={k}>{i.domain}</Tablecell>,
  key: (i, k) => <Tablecell title="Key" key={k}><ShortString chars={30} text={i.key} /></Tablecell>,
  sid: (i, k) => <Tablecell title="SID" key={k}>{i.sid}</Tablecell>,
  checkSign: (i, k) => <Tablecell title="Check signature" key={k}><BoolLabel value={i.checkSignature} /></Tablecell>,
  public: (i, k) => <Tablecell title="Public API" key={k}><BoolLabel value={i.publicApi} /></Tablecell>,
  enabledInvoices: (i, k) => <Tablecell title="Invoices" key={k}><BoolLabel value={i.enabledInvoices} /></Tablecell>,
  created: (i, k) => <Tablecell title="Created at" key={k}><DateView value={i.createdAt} /></Tablecell>,
  updated: (i, k) => <Tablecell title="Updated at" key={k}><DateView value={i.updatedAt} /></Tablecell>,
  excludedCountries: (i, k) =>
    <Tablecell title="Excluded countries" key={k}>
      <Ellipsis
        text={i.excludedCountries?.sort(sortStrings).join(', ')}
        height={16}
      />
    </Tablecell>,
};

export default function ApiKeysList({ items, onRowClick, columns }: IProps) {
  const classes = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  return (
    <Table size="small">
      <TableHead className={mobileClasses.tableHead}>
        {columns
          ? (
            <TableRow>
              {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
            </TableRow>
          ) : (
            <TableRow>
              <TableCell>Merchant name</TableCell>
              <TableCell>Domain</TableCell>
              <TableCell>Key</TableCell>
              <TableCell>SID</TableCell>
              <TableCell>Check signature</TableCell>
              <TableCell>Public API</TableCell>
              <TableCell>Enabled invoices</TableCell>
            </TableRow>
            )}
      </TableHead>
      <TableBody>
        {items.map((i) => columns
          ? (
            <TableRow key={i.id} className={clsx(onRowClick && classes.pointer, mobileClasses.row, mobileClasses.lastChild)} onClick={() => typeof (onRowClick) === 'function' && onRowClick(i.id)} hover>
              {columns.map((c, index) => content[c.id](i, index))}
            </TableRow>
          ) : (
            <TableRow key={i.id} className={clsx(onRowClick && classes.pointer, mobileClasses.row, mobileClasses.lastChild)} onClick={() => typeof (onRowClick) === 'function' && onRowClick(i.id)} hover>
              {content.merchant(i, 1)}
              {content.domain(i, 2)}
              {content.key(i, 3)}
              {content.sid(i, 4)}
              {content.checkSign(i, 5)}
              {content.public(i, 6)}
              {content.enabledInvoices(i, 7)}
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
}