import React, { useEffect, useRef } from 'react';
import { Waypoint } from 'react-waypoint';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import CardActions from '@material-ui/core/CardActions';
import EmptyList from '../../components/EmptyList';
import Loader from '../../components/Loader';
import WalletOperationsList from './WalletOperationsList';
import WalletOperationsFilter from './WalletOperationsFilter';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import useFilter from '../../hooks/useFilter';
import { useWalletOperations } from '../../api/walletOperations';
import useColumns, { IColumn } from '../../hooks/useColumns';
import {clearDatesIfIdsInFilter} from "../../helpers/filtersHelper";
import FilterWrapper, { ICollapseHandle } from '../../components/FilterWrapper/FilterWrapper';

const emptyFilter = () => ({
  customerId: null,
  walletId: null,
  issuedBy: null,
  operationType: null,
  issuerType: null
});

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true, component: <TableCell align="center" key="id">ID</TableCell> },
  { id: 'walletId', name: 'Wallet ID', active: true },
  { id: 'currency', name: 'Currency', active: true },
  { id: 'customer', name: 'Customer ID', active: true },
  { id: 'type', name: 'Type', active: true },
  { id: 'balance', name: 'Balance', active: true },
  { id: 'locked', name: 'Locked', active: true },
  { id: 'issuedBy', name: 'Issued by', active: true },
  { id: 'issuerType', name: 'Issuer type', active: true },
  { id: 'created', name: 'Created', active: true },
  { id: 'updated', name: 'Updated', active: true },
];

export default function WalletOperationsPage() {
  const [{ items, full, error }, loading, load] = useWalletOperations();
  const [setFilter, filter] = useFilter('walletOperations', emptyFilter());
  const [columns, ColumnsFilter] = useColumns('walletOperations', defaultColumns);
  const globalClasses = useGlobalStyles();
  const accordionRef = useRef<ICollapseHandle>(null);

  useEffect(() => {
    const reqFilters = clearDatesIfIdsInFilter(filter,['customerId', 'walletId'])

    accordionRef.current?.collapse();

    load({ filter: reqFilters });
  }, [filter])

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <WalletOperationsFilter filter={filter} onChange={setFilter} />
      </FilterWrapper>
      <Grid item className={globalClasses.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={globalClasses.paper}>
          <CardActions>
            <Grid container justify="flex-end">
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0 ? <WalletOperationsList operations={items} columns={columns} /> : <EmptyList loading={loading} error={error} />}
          <Loader loading={loading} />
          {!loading && !error && !full && <Waypoint onEnter={() => load({ cursor: items.length, filter })} />}
        </Paper>
      </Grid>
    </Grid>
  );
}
