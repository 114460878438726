export const getMaskedCard = (card: string) => {
  if(!card) return '';
  const l = card.length;
  return `${card.substring(0, 4)}********${card.substring(l - 4, l)}`;
};

export const getMaskedRequestCardData = (rawRequest: string) => {
  let requestData: Record<string, any>;
  try {
    requestData = JSON.parse(rawRequest);
  } catch (e){
    requestData =  {unparsedRequest: rawRequest};
  }

  const mappedRequest = { ...requestData };
  if(mappedRequest.cardData) {
    mappedRequest.cardData.number = getMaskedCard(mappedRequest.cardData.number);
  }
  return mappedRequest;
};



