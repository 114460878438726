import React from 'react'

import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Select } from '../../../../components/Form';
import CreateIcon from '@material-ui/icons/Create';
import Paper from '@material-ui/core/Paper';
import useMobileStyles from '../../../../hooks/useMobileStyles';
import Tablecell from '../../../../components/TableCell/TableCell';

interface IProps {
  data: any; 
  changedFields: string[];
  onChangeField: any;
  classes: any;
  historyMarkers: any;
}

const plannedMonthlyTranasactionsList = [
  {label: 'From 5000', value: 'FROM_5000_EUR'},
  {label: 'From 1000 to 5000', value: 'FROM_1000_TO_5000_EUR'},
  {label: 'Below 1000', value: 'BELOW_1000_EUR'},
]

export default function ProductRisks({data, changedFields, onChangeField, classes, historyMarkers}: IProps) {

  const mobileClasses = useMobileStyles();

  return (
    <>
      <Typography>Product risks</Typography>
      <Paper classes={{root: classes.paper}} variant="outlined">
        <Table>
          <TableHead className={mobileClasses.tableHead}>
            <TableRow>
              <TableCell classes={{root: classes.firstColumn}}>Product risks</TableCell>
              <TableCell classes={{root: classes.secondColumn}}>Value</TableCell>
              <TableCell>Risk score</TableCell>
              <TableCell>Updated By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={mobileClasses.row}>
              <Tablecell title="Product risks">Planned monthly transaction</Tablecell>
              <Tablecell title="Value">
                <div className={classes.cellInnerWrapper}>
                  <Select 
                    name="plannedMonthlyTransactionAmount"
                    label=""
                    options={plannedMonthlyTranasactionsList}
                    classes={{outlined: classes.outlined}}
                    inputProps={{classes: {input: classes.input}}}
                    changeSelectHandler={(value: any) => onChangeField('plannedMonthlyTransactionAmount', value)}
                    fullWidth
                  />
                  <div className={classes.iconWrapper}>
                    {changedFields.includes('plannedMonthlyTransactionAmount') ? <CreateIcon /> : null}
                  </div>
                </div>
              </Tablecell>
              <Tablecell title="Risk score">{data.plannedMonthlyTransactionAmountValue}</Tablecell>
              <Tablecell title="Updated By" isLast>{historyMarkers.plannedMonthlyTransactionAmount?.wasChanged ? 'Admin' : 'System'}</Tablecell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}