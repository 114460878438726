import update from 'immutability-helper';

const storedUser = window.localStorage.getItem('user');

const initialState = {
  user: storedUser ? JSON.parse(storedUser) : {},
  isAuth: !!window.localStorage.getItem('auth')
};

export default function common(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_USER':
      window.localStorage.setItem('auth', 'true');
      window.localStorage.setItem('user', JSON.stringify(action.user));
      return update(state, { user: { $set: action.user }, isAuth: { $set: true } });
    case 'USER_LOGOUT':
      window.localStorage.removeItem('auth');
      window.localStorage.removeItem('user');
      return update(state, { user: { $set: {} }, isAuth: { $set: false } });
    default:
      return state;
  }
}
