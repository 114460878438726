import React from 'react';

import TableFilter from '../../../components/Filter';
import { Input } from '../../../components/Form';

export interface IApiRequestsFilter {
  dateFrom: Date | null;
  dateTo: Date | null;
  requestedById: string;
}

interface IProps{
  filter: IApiRequestsFilter,
  onChange: any,
}

export default function ApiRequestsFilter({ filter, onChange }: IProps){
  return (
    <TableFilter onApply={onChange} filter={filter}>
       <Input
         name="requestedById"
         label="Requested by ID"
         margin="none"
       />
    </TableFilter>
  );
}