interface IBaseFilter {
  dateTo: number
  dateFrom: number
  [key: string]: any
}

/**
 * function returns updated filters object without dateTo/dateFrom fields if values
 * in excludingFields are not empty
 * @param filters
 * @param excludingFields
 */
export const clearDatesIfIdsInFilter = (filters: IBaseFilter, excludingFields: string[]) => {

  const reqFilters: Record<string, any> = { ...filters }

  const notEmptyExceptions = excludingFields.reduce((result, key) => {
    return result || !!reqFilters[key]
  }, false)

  if(notEmptyExceptions) {
    reqFilters.dateFrom = null
    reqFilters.dateTo = null
  }

  return reqFilters

}
