import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import DateView from '../DateView';

export default function Timer() {
  const [time, setTime] = useState(Date.now());
  function makeClock() {
    setTime(Date.now());
    setTimeout(makeClock, 10000);
  }

  useEffect(() => {
    makeClock();
  }, []);
  return (
    <Typography variant="body2">
      <DateView value={time} onlyTime />
    </Typography>
  );
}
