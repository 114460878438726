import React, { useEffect } from 'react';

import { Form } from 'react-final-form';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useFormStyles from '../../../hooks/useFormStyles';
import Loader from '../../../components/Loader';
import CustomerApiKeys from './ApiKeys';
import { Select, ProtectionInput } from '../../../components/Form';
import { useAuthorityList } from '../../../api/authority';
import { 
  ICustomer,
  useCustomerAuthoritiesUpdate,
  useCustomerFeesList, 
} from '../../../api/customers';
import SubaccountList from './SubaccountList';
import CopyForm from './ApiKeys/CopyForm';
interface IProps {
  customer: ICustomer;
  onUpdate: any;
  loadCustomer?: (id: string)=> void
}

export default function AuthorityTab({ customer, onUpdate, loadCustomer }: IProps) {
  const [{ items: fees }, loadingFees, loadFees] = useCustomerFeesList();
 
  const [{ items }, loading, load] = useAuthorityList();
  const [update, updating] = useCustomerAuthoritiesUpdate((res: any) => {
    if (res.success) {
      const authorities = res.data.map((a: any) => a.name);
      onUpdate({ ...customer, authorities });
    }
  });
  const formClasses = useFormStyles();

  useEffect(() => {
    load('CUSTOMER');
  }, [])

  useEffect(() => {
    if (customer?.id) {
      loadFees(customer.id);
    }
  }, [customer?.id]);

  const authorities: any[] = [];
  customer.authorities?.forEach((i) => {
    const a = items.find((item: any) => item.name === i);
    if (a) {
      authorities.push(a.id);
    }
  });

  const isMerchant = customer.authorities?.includes('ROLE_MERCHANT');

  return (
    <>
      {isMerchant && <CopyForm customer={customer} loadCustomer={loadCustomer} customerFees={fees} />}
        
      <Typography variant="body1" color="primary">Authorities</Typography>
      <Loader loading={updating || loadingFees} width={500}>
        <Form
          onSubmit={(values) => update(values)}
          initialValues={{ id: customer.id, authorities, '2fa': '' }}
          render={({ handleSubmit, values, invalid }) => (
            <form onSubmit={handleSubmit} className={formClasses.root}>
              <div className={formClasses.row}>
                <Select
                  name="authorities"
                  label="Authorities"
                  options={items.map((i: any) => ({ value: i.id, label: i.description }))}
                  multi
                  fullWidth
                  loading={loading}
                  margin="normal"
                />
              </div>
              <div className={formClasses.row}>
                <ProtectionInput />
              </div>
              <div className={formClasses.actions}>
                <Button
                  type="submit"
                  disabled={invalid}
                  size="large"
                  variant="contained"
                >
                  Save
                </Button>
              </div>
            </form>
          )}
        />
      </Loader>
      {isMerchant && customer?.isMaster && <SubaccountList masterAccountId={customer.id} />}
      {isMerchant &&
        <CustomerApiKeys 
          customerId={customer.id} 
          subAccount={customer?.masterAccountId} 
          loadCustomer={loadCustomer}
        />}
    </>
  );
}
