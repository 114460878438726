import React, { FC } from 'react';
import { Form } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Input } from '../../../../components/Form';
import { IFeesFormProps } from './FeesForm';
import useFormStyles from '../../../../hooks/useFormStyles';
import { mustBeNumber } from '../../../../validators';

export const SettlementFees: FC<IFeesFormProps> = ({ customer, updateSettings }) => {
  const classes = useFormStyles()
  return (
    <Form
      initialValues={{ ...customer.settings.settlementFees, '2fa': '' }}
      onSubmit={(values) => updateSettings({
        ...customer.settings,
        settlementFees: {
          fxSettlementFee: Number(values.fxSettlementFee),
          fiatSettlementFee: Number(values.fiatSettlementFee),
          cryptoSettlementFee: Number(values.cryptoSettlementFee),
        },
      })}
      render={({ handleSubmit, valid }) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="body1" color="primary">Settlement Fees</Typography>
          <div className={classes.row}>
            <Input name="fxSettlementFee" label="FX Settlement Fee" validate={mustBeNumber} />
            <Input name="fiatSettlementFee" label="Fiat Settlement Fee" validate={mustBeNumber} />
            <Input name="cryptoSettlementFee" label="Crypto Settlement Fee" validate={mustBeNumber} />
          </div>
          <div className={classes.actions}>
            <Button type="submit" disabled={!valid} variant="contained">Save</Button>
          </div>
        </form>
      )}
    />
  )
}
