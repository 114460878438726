import React from 'react';

import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core';
import { ProtectionInput } from '../../../components/Form';
import Button from '@material-ui/core/Button';

import { useResendOrderPostback } from '../../../api/order';

import useFormStyles from '../../../hooks/useFormStyles';

// api/admin/v1/order/postback/retry

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    width: '100%',
    height: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
    '&>div': {
      margin: 0,
      marginRight: theme.spacing(0.5)
    },
    '& input': {
      padding: `${theme.spacing(0.49)}px ${theme.spacing(1)}px`
    },
    '& label.MuiInputLabel-outlined': {
      transform: 'translate(15px, 7px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -5px) scale(0.75) !important'
    },
    '& .MuiFormHelperText-contained': {
      top: theme.spacing(3)
    },
  },
  button: { 
    marginLeft: theme.spacing(0.5)
  }
}));

export default function ResendPostbackForm({orderId, reloadOrderDetails}: any) {
  const formClasses = useFormStyles();
  const classes = useStyles();

  const [reinit, reining] = useResendOrderPostback((res: any) => {
    reloadOrderDetails();
  });

  return (
    <Form
      onSubmit={(data: any) => reinit({...data, id: orderId})}
      initialValues={{'2fa': ''}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={formClasses.root}>
          <div className={classes.row}>
            <ProtectionInput />
            <Button
              variant="contained"
              type="submit"
              disabled={reining}
              fullWidth
              classes={{root: classes.button}}
            >
              Resend
            </Button>
          </div>
        </form>
      )}
    />
  )
}