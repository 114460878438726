import React, {useEffect, useMemo, useState} from 'react';

import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';

import AutocompleteField, {IAutocompleteOption} from './AutocompleteField';
import {CurrencyType, ICurrency, useCurrenciesList} from '../../api/currency';

interface IProps {
  name: string;
  label: string;
  type?: CurrencyType;
  multi?: boolean;
  fullWidth?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  exclude?: string[];
  validate?: any;
  disabled?: boolean;
  supported?: string[];
  include?: string[];
  optionDisabled?: boolean;
}

interface ICurrencySelectOption extends IAutocompleteOption {
  type: CurrencyType
}

const sortCurrencies = (currencyA: ICurrency, currencyB: ICurrency) => {
  if(currencyA.type !== currencyB.type) {
    return currencyA.type.localeCompare(currencyB.type)
  }

  return currencyA.currency.localeCompare(currencyB.currency)
}

const mapCurrency = (currency: ICurrency): ICurrencySelectOption => {
  const option: ICurrencySelectOption = {
    value: currency.currency,
    label: currency.currency,
    disabled: !currency.enabled,
    type: currency.type,
  }

  if(currency.type === CurrencyType.CRYPTO) {
    option.caption = `${currency.symbol} (${currency.chain})`
  }

  return option
}

export default function CurrencySelect({
  type,
  name,
  label,
  multi,
  fullWidth,
  exclude,
  validate,
  disabled,
  supported,
  include,
  margin,
  optionDisabled
}: IProps) {
  const classes = useCurrencySelectorStyles()
  const [{ items }, loading, load] = useCurrenciesList();
  const [currencyType, setCurrencyType] = useState<CurrencyType | undefined>(type)

  useEffect(() => {
    setCurrencyType(type)
  }, [type])

  const toggleCurrencyType = () => {
    setCurrencyType((value) => {
      if(!value) {
        return CurrencyType.CRYPTO
      }

      if(value === CurrencyType.CRYPTO) {
        return CurrencyType.FIAT
      }

      if(value === CurrencyType.FIAT) {
        return undefined
      }
    })
  }

  useEffect(() => {
    load();
  }, [])

  const options = useMemo(() => {
    const options = currencyType
      ? items.filter((i: ICurrency) => i.type === currencyType).sort(sortCurrencies).map(mapCurrency)
      : items.sort(sortCurrencies).map(mapCurrency);

    if (include) {
      include.forEach((c) => {
        if (!options.find((o: any) => o.value === c)) {
          options.push({ value: c, label: c });
        }
      })
    }
    return options.filter((i: any) => !exclude?.includes(i.value) && (supported ? supported.includes(i.value) : true))
  }, [items, currencyType, exclude, supported])



  return (
    <div className={classes.wrapper}>
      <AutocompleteField
        name={name}
        label={label}
        loading={loading}
        options={options}
        groupBy={type ? undefined : (option: any) => option.type}
        multiple={multi}
        margin={margin}
        fullWidth={fullWidth}
        validate={validate}
        disabled={disabled}
        optionDisabled={optionDisabled}
        disableCloseOnSelect={multi}
      />
      {!type && (
        <IconButton className={classes.typeButton} size="small" onClick={toggleCurrencyType}>
          {!currencyType && <DoneAllIcon />}
          {currencyType === CurrencyType.FIAT && <AttachMoneySharpIcon />}
          {currencyType === CurrencyType.CRYPTO && <MoneyOffIcon />}
        </IconButton>
      )}
    </div>
  );
}

const useCurrencySelectorStyles = makeStyles(() => {
  return {
    wrapper: {
      display: 'flex',
      position: 'relative',
      width: '100%',
    },
    typeButton: {
      position: 'relative',
      borderRadius: 0,
    },
    currencyTypeLetter: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      display: 'grid',
      placeContent: 'center',
    }
  }
})
