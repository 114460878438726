import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router";

import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import EmptyList from "../../../components/EmptyList";
import Loader from "../../../components/Loader";
import { GatewaysConfigsList } from "./GatewaysList";
import { GatewayDetails } from "./Details";
import useGlobalStyles from "../../../components/Layout/useGlobalStyles";
import {
  GatewayConfig,
  useGatewaysConfigsList,
} from "../../../api/gatewayConfig";
import useColumns, { IColumn } from "../../../hooks/useColumns";
import SearchIcon from "@material-ui/icons/Search";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";

const defaultColumns: IColumn[] = [
  { id: "id", active: true, name: "ID" },
  { id: "enabled", active: true, name: "Enabled" },
  { id: "fee", active: true, name: "Fee" },
  { id: "pairs", active: true, name: "Pairs" },
  { id: "mappings", active: true, name: "Mappings" },
  { id: "createdAt", active: true, name: "Created" },
  { id: "updatedAt", active: true, name: "Updated" },
];

export function GatewaysPage() {
  const classes = useGlobalStyles();
  const [search, setSearch] = useState("");
  const [{ items, error }, loading, load] = useGatewaysConfigsList();
  const [columns] = useColumns("gateways-configs", defaultColumns);
  const history = useHistory();
  const { gatewayId }: any = useParams();

  function setDetails(id: string) {
    history.push(`/settings/gateway-configs/${id}`);
  }

  useEffect(() => {
    load();
  }, []);

  const searchFilter = items.filter((item: GatewayConfig) =>
  item.id.toLowerCase().includes(search.toLowerCase()) || item.pairs.join(",").toLowerCase().includes(search.toLowerCase())); 

  return (
    <Grid container spacing={3}>
      <Grid
        item
        className={classes.table}
        style={{ width: "100%", minWidth: "fit-content" }}
      >
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justify="space-between" style={{ gap: '20px' }}>
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => setDetails("new")}
                >
                  Add Gateway
                </Button>
              </Grid>
              <Grid item>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="search-gateways">Search</InputLabel>
                  <OutlinedInput
                    id="search-gateways"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    autoComplete="new-password"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    labelWidth={47}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardActions>
          <Loader loading={loading}>
            {searchFilter.length > 0 ? (
              <GatewaysConfigsList
                items={searchFilter}
                columns={columns}
                openDetails={setDetails}
              />
            ) : (
              <EmptyList error={error} loading={loading} />
            )}
          </Loader>
        </Paper>
      </Grid>
      {gatewayId && gatewayId !== "global-settings" && (
        <GatewayDetails
          id={gatewayId}
          onClose={() => history.push("/settings/gateway-configs")}
          onSave={load}
        />
      )}
    </Grid>
  );
}
