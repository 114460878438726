import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import EmptyList from '../../../components/EmptyList';
import Loader from '../../../components/Loader';
import PaymentProvidersFilter from './PaymentProvidersFilter';
import PaymentProvidersList from './PaymentProvidersList';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import { usePaymentProvidersList, usePaymentProvidersCacheClear } from '../../../api/paymentProviders';
import useFilter from '../../../hooks/useFilter';
import useColumns, { IColumn } from '../../../hooks/useColumns';
import FilterWrapper, { ICollapseHandle } from '../../../components/FilterWrapper/FilterWrapper';

const defaultColumns: IColumn[] = [
  { id: 'id', active: true, name: 'ID' },
  { id: 'name', active: true, name: 'Name' },
  { id: 'enabled', active: true, name: 'Enabled' },
  { id: 'depositSupported', active: true, name: 'Deposit supported' },
  { id: 'withdrawalSupported', active: true, name: 'Withdrawal supported' },
  { id: 'priority', active: true, name: 'Priority' },
  { id: 'processingExpirationTime', active: true, name: 'Processing exp. time' },
  { id: 'currencies', active: true, name: 'Currencies' },
  { id: 'countries', active: true, name: 'Countries' },
  { id: 'createdAt', active: true, name: 'Created' },
  { id: 'updatedAt', active: true, name: 'Updated' },
];

export default function PaymentProvidersPage() {
  const classes = useGlobalStyles();
  const [{ items, error }, loading, load ] = usePaymentProvidersList();
  const accordionRef = useRef<ICollapseHandle>(null);
  const [reload, reloading] = usePaymentProvidersCacheClear((res: any) => {
    if (res.success) {
      load();
    }
  })
  const [setFilter, filter] = useFilter('payment-providers', {});
  const [columns, setColumns] = useColumns('payment-providers', defaultColumns);
  const history = useHistory();

  function setDetails(id: string) {
    history.push(`/payment-providers/provider/${id}`);
  }

  function applyFilter() {
    accordionRef.current?.collapse();
    load({ filter });
  }

  useEffect(() => {
    applyFilter();
  }, []);

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <PaymentProvidersFilter filter={filter} onChange={setFilter} onSubmit={applyFilter} reload={reload} />
      </FilterWrapper>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <Loader loading={loading || reloading}>
            {items.length > 0
              ? <PaymentProvidersList items={items} columns={columns} openDetails={setDetails} />
              : <EmptyList error={error} loading={loading} />
            }
          </Loader>
        </Paper>
      </Grid>
    </Grid>
  );
}
