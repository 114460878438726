import Api, { useFetchApi } from './index';

export enum PaymentStatus {
  NEW = 'NEW', // New payment
  SUCCESS = 'SUCCESS', // Money charged or refunded
  ACCEPTED = 'ACCEPTED', // Money blocked on card
  BLOCKED = 'BLOCKED', // Blocked by anti-fraud filter or AML officer
  ERROR = 'ERROR',
  ERROR_UNBLOCK = 'ERROR_UNBLOCK'
}

export enum PaymentProvider {
  SKRILL = 'SKRILL',
  IVY = 'IVY',
  PAYSAFE = 'PAYSAFE',
  FINARO = 'FINARO',
  VIMONI = 'VIMONI'
}

export enum PaymentSource {
  CARD = 'CARD',
  CARD_INTERNAL = 'CARD_INTERNAL',
  APM = 'APM'
}

export enum PaymentType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL'
}

export interface IPaymentApm {
  id: string;
  name: string;
}

export interface IPaymentOperation {
  success: boolean;
  externalId: string;
  type: string;
  request: string;
  error: string;
  response: string;
  createdAt: number;
  provider: string;
  transactionInfo?: {
    cardNumber?: string;
  }
}

export interface IPayment {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  orderId: string;
  type: PaymentType;
  amount: number;
  currency: string;
  source: PaymentSource;
  provider: PaymentProvider;
  status: PaymentStatus;
  apm?: IPaymentApm;
  finished: boolean;
  error?: string;
  operations: IPaymentOperation[];
  requestedById: string;
  method?: number;
  methodName?: string;
}

export function usePaymentsList() {
  return useFetchApi(
    async (query: any) => {
      const q: any = { filter: {} };
      Object.keys(query).forEach((k) => {
        if (k === 'filter') {
          Object.keys(query.filter).forEach((f) => {
            if (f === 'requestedBy' && query.filter.requestedBy) {
              if (/^\d+$/.test(query.filter.requestedBy) && query.filter.requestedBy.length <= 19) {
                q.filter.requestedById = query.filter.requestedBy;
              } else {
                q.filter.requestedBy = query.filter.requestedBy;
              }
            } else {
              q.filter[f] = query.filter[f];
            }
          })
        } else {
          q[k] = query[k];
        }
      })
      return Api.post('/payment/list', { filter: {}, limit: 50, ...q })
    },
    { items: [] }
  );
}
