import React, { useEffect, useRef, useState } from 'react';

import { Waypoint } from 'react-waypoint';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ActivitiesList from './ActivitiesList';
import EmptyList from '../../components/EmptyList';
import Loader from '../../components/Loader';
import AdminActivityFilter, { IAdminActivityFilter } from './ActivitiesFilter';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import { useAdminsActivitiesList } from '../../api/admin';
import useFilter from '../../hooks/useFilter';
import SearchSubstr from '../../components/SearchSubstr/SearchSubstr';
import { useBreakpoints } from '../../hooks/useBreakpoints ';
import FilterWrapper, { ICollapseHandle } from '../../components/FilterWrapper/FilterWrapper';

export const emptyFilter = () => ({
  adminId: undefined,
  types: undefined
});

export default function AdminsActivitiesPage() {
  const classes = useGlobalStyles();
  const isSm = useBreakpoints('sm');
  const [search, setSearch] = useState("");
  const [{ items, full, error }, loading, load] = useAdminsActivitiesList();
  const [setFilter, filter] = useFilter('adminActivity', emptyFilter());
  const accordionRef = useRef<ICollapseHandle>(null);

  useEffect(() => {
    accordionRef.current?.collapse();
    load({ filter });
  }, [filter])

  const searchFilter = items.filter((item: any) =>
    item?.extra && JSON.stringify(item.extra).toLowerCase().includes(search.toLowerCase())
  ); 

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <Grid container>
          <Grid item xs={!isSm ? 10 : 12}><AdminActivityFilter filter={filter as IAdminActivityFilter} onChange={setFilter} /></Grid>
          {!isSm && <Grid item style={{padding:'8px 12px 0px'}} xs={2}>
            <SearchSubstr search={search} setSearch={setSearch} label='Search' id='admins_activity_search'/>
          </Grid>}
          {isSm && <Grid item style={{padding:'8px 12px 0px'}}>
            <SearchSubstr search={search} setSearch={setSearch} label="Search" id="admins_activity_search"/>
          </Grid>}
        </Grid>
      </FilterWrapper>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          {searchFilter.length > 0 ? <ActivitiesList items={searchFilter} /> : <EmptyList error={error} loading={loading} />}
          <Loader loading={loading} />
          {!loading && !error && !full && searchFilter.length > 0
          && <Waypoint onEnter={() => load({ filter, cursor: items.length })} />}
        </Paper>
      </Grid>
    </Grid>
  );
}
