import React, { useEffect, useState } from 'react';

import { Form } from 'react-final-form';

import { DataRow } from '../../../components/DataView';
import DateView from '../../../components/DateView';
import Loader from '../../../components/Loader';
import EmptyList from '../../../components/EmptyList';
import ClearingStatusLabel from '../../../components/Label/ClearingStatusLabel';
import { ClearingStatus, IClearingItem, useClearingByOrder, useClearingReinit } from '../../../api/clearing';
import ShortString from '../../../components/UI/ShortString';
import { ProtectionInput } from '../../../components/Form';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& .input': {
      flex: '1 1 0px',
      marginRight: theme.spacing(2)
    },
    '& .save': {
      flex: '0 0 100px',
      width: '100px',
      marginTop: theme.spacing(1)
    },
  },
}));

interface IProps {
  orderId: number;
}

export default function ClearingTab({ orderId }: IProps) {
  const classes = useStyles();
  const [{ items, error }, loading, load ] = useClearingByOrder();
  const [reinit, setReinit] = useState(false);

  const [reinitOrder, reiniting] = useClearingReinit((res: any) => {
    if (res.success) {
      setReinit(false);
      load(orderId);
    }
  });

  useEffect(() => {
    if (orderId) {
      load(orderId);
    }
  }, [orderId]);

  return (
    <Loader loading={loading} width={560}>
      {items.length
        ? items.map((item: IClearingItem, key: number) => {
            return (
              <div key={key}>
                <DataRow label="Clearing order ID">{item.id}</DataRow>
                <DataRow label="Status">
                  <ClearingStatusLabel status={item.status} />
                </DataRow>
                {item.status === ClearingStatus.ERROR && (
                  <DataRow label="Error">
                    <ShortString chars={60} text={item.error} />
                  </DataRow>
                )}
                {item.status === ClearingStatus.ERROR && (
                  <DataRow label="Reinit">
                    {reinit
                      ? (
                        <Form
                          onSubmit={(values) => reinitOrder({ ...values, id: item.id })}
                          initialValues={{ '2fa': '' }}
                          render={({ handleSubmit, invalid, values }) => (
                            <form onSubmit={handleSubmit}>
                              <div className={classes.row}>
                                <ProtectionInput margin="none" />
                                <Button
                                  type="submit"
                                  disabled={invalid || reiniting}
                                  variant="contained"
                                  className="save"
                                  size="large"
                                  style={{ margin: '0 0 0 8px' }}
                                >
                                  Reinit
                                </Button>
                              </div>
                            </form>
                          )}
                        />
                      )
                      : <Button onClick={() => setReinit(true)} variant="contained" size="large">Reinit</Button>
                    }
                  </DataRow>
                )}
                <DataRow label="Pair">{item.pair}</DataRow>
                <DataRow label="Amount">{item.amount}</DataRow>
                <DataRow label="Price">{item.price}</DataRow>
                <DataRow label="Side">{item.side}</DataRow>
                <DataRow label="Exchange">{item.provider}</DataRow>
                <DataRow label="External ID">{item.externalId}</DataRow>
                <DataRow label="Created">
                  <DateView value={item.createdAt} />
                </DataRow>
                <DataRow label="Updated">
                  <DateView value={item.updatedAt} />
                </DataRow>
              </div>
            )
          })
        : <EmptyList loading={loading} error={error} text="No data to display" width={560} />
      }
    </Loader>
  );
}
