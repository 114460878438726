import Api, { useFetchApi, useUpdateApi } from './index';

export interface IPaymentGroup {
  id: string;
  name: string;
  logo: string;
}

export function usePaymentGroupList() {
  return useFetchApi(
    async (query: any) => Api.post('/payment-group/list', { limit: 50, ...query }),
    { items: [] }
  )
}

export function useCreatePaymentGroup(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/payment-group/create', data),
    cb,
    'Group successfully created'
  )
}

export function useUpdatePaymentGroup(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/payment-group/update', data),
    cb,
    'Group successfully updated'
  )
}

export function useDeletePaymentGroup(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/payment-group/delete', data),
    cb,
    'Group successfully deleted'
  )
}

