import React from 'react';
import { useSelector } from 'react-redux';
import TableFilter from '../../components/Filter';
import { CurrencySelect, Input, Select } from '../../components/Form';
import { CurrencyType } from '../../api/currency';
import { BlockchainTxStatus, BlockchainTxProvider } from '../../api/blockchain';
import { mustBeNumber, maxLength, composeValidators } from '../../validators';
import { IKycCountry } from '../../api/kyc';
import Descriptor from "../../components/Descriptor";

export interface IBlockchainTxFilter {
  dateFrom?: Date | null;
  dateTo: Date | null;
  status: BlockchainTxStatus | null;
  currency: string | null;
  orderId: string | null;
  customer?: string;
  address: string | null;
  txId: string | null;
  providers?: BlockchainTxProvider[];
}

interface IProps {
  filter: IBlockchainTxFilter,
  onChange: any
}

export default function BlockchainTxFilter({ filter, onChange }: IProps) {
  const countries = useSelector((store: any) => store.appData.countries.data);

  return (
    <TableFilter onApply={onChange} filter={filter}>
      <CurrencySelect
        name="currency"
        label="Currency"
        type={CurrencyType.CRYPTO}
      />
      <Select
        name="status"
        label="Status"
        options={Object.keys(BlockchainTxStatus).map((s) => ({ value: s, label: s }))}
      />
      <Descriptor group="filters" title="clearsDate" >
        <Input
          name="orderId"
          label="Order ID"
          margin="none"
          autoComplete="xyz123"
          type="search"
          validate={composeValidators(mustBeNumber, (v: any) => maxLength(v, 19))}
        />
      </Descriptor>
      <Input
        name="customer"
        label="Customer"
        margin="none"
        autoComplete="xyz123"
        type="search"
      />
      <Input
        name="address"
        label="Address"
        margin="none"
        autoComplete="xyz123"
        type="search"
      />
      <Descriptor group="filters" title="clearsDate" >
        <Input
          name="txId"
          label="Transaction ID"
          margin="none"
          autoComplete="xyz123"
          type="search"
        />
      </Descriptor>
      <Select
        name="providers"
        label="Providers"
        options={Object.keys(BlockchainTxProvider).map((p) => ({ value: p, label: p }))}
        multi
      />
      <Select
        name="country"
        label="Country"
        options={countries?.map((country: IKycCountry) => ({ value: country.code, label: country.name }))}
      />
    </TableFilter>
  );
}
