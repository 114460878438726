import React from 'react';

import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import {IPaymentMethod} from "../../../api/paymentMethod";
import {IColumn} from "../../../hooks/useColumns";
import BoolLabel from "../../../components/Label/BoolLabel";
import useMobileStyles from '../../../hooks/useMobileStyles';
import Tablecell from '../../../components/TableCell/TableCell';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    cursor: 'pointer'
  },
  id: {
    display: 'flex',
    width: 150,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      width: '100%',
      justifyContent: 'center'
    },
    // alignItems: 'center',
  },
  store: {
    width: '15px',
    height: '24px',
    marginRight: theme.spacing(1),
    '& svg': {
      width: '15px'
    }
  }
}));

interface IPaymentMethodListProps {
  items: IPaymentMethod[];
  columns: IColumn[];
  onRowClick: any;
}

export default function PaymentMethodList({ items, onRowClick, columns }: IPaymentMethodListProps) {
  const classes = useStyles();
  const mobileClasses = useMobileStyles();

  const content: { [key: string]: (item: IPaymentMethod, key: number) => void } = {
    id: (i, k) => (<TableCell className={classes.id} key={k}>{i.id}</TableCell>),
    name: (i, k) => (<Tablecell key={k} title="Name">{i.name}</Tablecell>),
    externalId: (i, k) => (<Tablecell key={k} title="External ID">{i.externalId}</Tablecell>),
    provider: (i, k) => <Tablecell key={k} title="Provider">{
      <Link component={NavLink} onClick={(e:any) => e.stopPropagation()} to={`/payment-providers/provider/${i.provider}`}>{i.provider}</Link>
    }
    </Tablecell>,
    group: (i, k) => <Tablecell key={k} title="Group">{
      <Link component={NavLink} onClick={(e:any) => e.stopPropagation()} to={`/settings/payment-groups/${i.group}`}>{i.group}</Link>
    }
    </Tablecell>,
    priority: (i, k) => <Tablecell key={k} title="Priority">{i.priority}</Tablecell>,
    flow: (i, k) => <Tablecell key={k} title="Flow">{i.flow}</Tablecell>,
    enabled: (i, k) => <Tablecell key={k} title="Enabled"><BoolLabel value={i.enabled} size='small' /></Tablecell>,
    deposit: (i, k) => <Tablecell key={k} title="Deposit">{i.fee.deposit}</Tablecell>,
    depositFixed: (i, k) => <Tablecell key={k} title="Deposit Fixed">{i.fee.depositFixed}</Tablecell>,
    fixedCurrency: (i, k) => <Tablecell key={k} title="Fee currency">{i.fee.fixedCurrency}</Tablecell>
  }

  return (
    <Table size="small">
      <TableHead className={mobileClasses.tableHead}>
        <TableRow>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow key={i.id} className={clsx(classes.row, mobileClasses.row)} onClick={() => onRowClick(i.id)} hover>
            {columns.map((c, index) => content[c.id] && content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}