import Button from '@material-ui/core/Button';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch, Route } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import EmptyList from '../../components/EmptyList';
import Loader from '../../components/Loader';
import useGlobalStyles from '../../components/Layout/useGlobalStyles';
import ApiKeysFilter, { IApiKeysFilter } from './ApiKeysFilter';
import { useApiKeys, emptyApiKey } from '../../api/apiKeys';
import useFilter from '../../hooks/useFilter';
import ApiKeyDetails from './Details';
import ApiKeysList from './ApiKeysList';
import useColumns, { IColumn } from '../../hooks/useColumns';
import {Waypoint} from "react-waypoint";
import useMobileStyles from '../../hooks/useMobileStyles';
import FilterWrapper, { ICollapseHandle } from '../../components/FilterWrapper/FilterWrapper';
import TableCell from "@material-ui/core/TableCell";

const emptyFilter: IApiKeysFilter = {
  queryData: undefined
};

const defaultColumns: IColumn[] = [
  { id: 'id', name: 'ID', active: true },
  { id: 'customer', name: 'Customer', active: true },
  { id: 'merchant', name: 'Merchant name', active: true },
  { id: 'domain', name: 'Domain', active: true },
  { id: 'key', name: 'Key', active: true },
  { id: 'sid', name: 'SID', active: true },
  { id: 'checkSign', name: 'Check signature', active: true },
  { id: 'public', name: 'Public API', active: true },
  { id: 'enabledInvoices', name: 'Invoices', active: true },
  { id: 'created', name: 'Created at', active: true },
  { id: 'updated', name: 'Updated at', active: true },
  { id: 'excludedCountries', name: 'Countries excluded', active: true, component: <TableCell align="right" key="exc">Countries excluded</TableCell> },
];

export default function ApiKeysPage() {
  const classes = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  const [{ items, full, error, cursor  }, loading, load, updateList] = useApiKeys();
  const [setFilter, filter] = useFilter('apiKeys', emptyFilter, false);
  const [columns, ColumnsFilter] = useColumns('apiKeys', defaultColumns);
  const history = useHistory();
  const [key, setKey] = useState();
  const match = useRouteMatch();
  const pageMatch: any = useRouteMatch(`${match.url}/:popup/:itemId`);
  const accordionRef = useRef<ICollapseHandle>(null);

  function setDetails(id?: string) {
    history.push(`/widget-keys${id ? `/key/${id}` : ''}`);
  }

  useEffect(() => {
    accordionRef.current?.collapse();
    load({filter: {queryData: filter.queryData}});
  }, [filter])

  useEffect(() => {
    let keyData = undefined;
    if (pageMatch && pageMatch.params && pageMatch.params.popup === 'key' && pageMatch.params.itemId) {
      keyData = pageMatch.params.itemId === 'new' ? emptyApiKey : items.find((i: any) => i.id.toString() === pageMatch.params.itemId);
    }
    setKey(keyData)
  }, [pageMatch, items])

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <ApiKeysFilter filter={filter as IApiKeysFilter} onChange={setFilter} />
      </FilterWrapper>
      <Grid item className={classes.table}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justify="space-between" className={mobileClasses.gap}>
              <Grid item>
                <Button type="button" variant="contained" size="large" onClick={() => setDetails('new')}>Create API key</Button>
              </Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0
              ? <ApiKeysList items={items} onRowClick={setDetails} columns={columns} />
              : <EmptyList loading={loading} error={error} />
          }
          <Loader loading={loading} />
          {!loading && !error && !full && items.length > 1 && (
              <Waypoint onEnter={() => load({ cursor, filter: {queryData: filter.queryData}})} />
          )}
        </Paper>
      </Grid>
      <Route path={`${match.url}/key/:itemId`}>
        <ApiKeyDetails keyData={key} id={pageMatch?.params?.itemId} onClose={() => setDetails()} loading={loading} onActionComplete={updateList} />
      </Route>
    </Grid>
  );
}
