import makeStyles from '@material-ui/core/styles/makeStyles';

const useMobileStyles = makeStyles((theme) => ({
   root: {},
   row: {
     [theme.breakpoints.down('sm')]: {
       display: 'flex',
       flexDirection: 'column',
       width: '100%'
     },
   },
   tableHead: {
     [theme.breakpoints.down('sm')]: {
       display: 'none',
     },
   },
   nowrap: {
    whiteSpace: 'nowrap'
  },
   colHead: {
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    },
  },
   mobile: {
     display: 'none',
     color: theme.palette.primary.main,
     [theme.breakpoints.down('sm')]: {
       display: 'block',
     },
   },
   id: {
     [theme.breakpoints.down('sm')]: {
       fontSize: '18px',
       textAlign: 'center',
     },
   },
   cell: {
     [theme.breakpoints.down('sm')]: {
       display: 'flex',
       gap: '10px',
       alignItems: 'center',
       flexDirection: 'row',
     },
   },
   blockMargin: {
    marginBottom: '10px',
   },
   lastChild: {
    marginBottom: '20px',
   },
   gap: {
    gap: '20px',
   },
   button: {
    [theme.breakpoints.down(700)]: {
      alignSelf: 'flex-start',
      width: '100%',
      marginLeft: theme.spacing(-1),
    },
   },
   checkbox: {
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      alignItems: 'flex-start',
    },
  },
}));

export default useMobileStyles;
