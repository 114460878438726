import React, {FC, useMemo} from 'react';
import { useField, useForm } from 'react-final-form'
import toLower from 'lodash/toLower'

import TextField from '@material-ui/core/TextField';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiAutocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@material-ui/lab/Autocomplete';

import Loader from '../Loader';

export interface IAutocompleteOption {
  value: string;
  label: string;
  caption?: string;
  name?: string;
  disabled?: boolean;
}

interface IMuiAutocompleteProps extends Omit<MuiAutocompleteProps<IAutocompleteOption, boolean, boolean, boolean>, 'renderInput'> {
  name: string;
  options: IAutocompleteOption[];
  label: string;
  loading?: boolean;
  validate?: any
  helperText?: string;
  margin?: 'none' | 'dense' | 'normal';
  optionDisabled?: boolean;
}

const getOptionLabel = (option: IAutocompleteOption | string) => {
  if(typeof option === 'string') {
    return option
  }
  return option?.label || ''
}

const SelectAllSymbol = Symbol('SelectAll')

const Autocomplete: FC<IMuiAutocompleteProps> = ({
  multiple,
  name,
  options,
  label,
  loading,
  fullWidth= true,
  helperText,
  validate,
  margin,
  optionDisabled,
  ...rest
}) => {
  const classes = useStyles()
  const { change } = useForm()
  const { input, meta } = useField(name, { multiple, validate })
  const { value } = input
  const { touched, error, valid } = meta

  const selected = useMemo(() => {
    if(multiple && Array.isArray(value)) {
      return options.filter(option => value.includes(option.value))
    }

    if(multiple && !Array.isArray(value)) {
      return options.filter((option) => value === option.value)
    }

    if(Array.isArray(value)) {
      return options.find((option) => value[0] === option.value)
    }

    return options.find((option) => value === option.value)

  }, [options, value])

  const componentOptions: IAutocompleteOption[] = !multiple ? options : [{
      value: SelectAllSymbol as any,
      label: "SELECT ALL",
    },
    ...options
  ]

  return (
    <FormControl fullWidth={fullWidth} error={touched && !valid} margin={margin}>
      <Loader loading={!!loading} height={40}>
        <MuiAutocomplete<IAutocompleteOption, boolean, boolean, boolean>
          openOnFocus
          options={componentOptions}
          onChange={(event, value: any, reason ) => {

            if(!value) {
              change(name, undefined)
              return
            }

            if(typeof value === "string") {
              change(name, value)
              return;
            }

            if(multiple && reason === 'select-option' && Array.isArray(value)) {
              const hasSelectAll = value.find((option) => {
                return option.value === SelectAllSymbol
              })
              if(hasSelectAll) {
                change(name, options.map((it: IAutocompleteOption | string) => {
                  if(typeof it === "string") {
                    return it
                  }
                  return it.value
                }))
                return;
              }
            }

            if(multiple) {
              change(name, value.map((it: IAutocompleteOption | string) => {
                if(typeof it === "string") {
                  return it
                }
                return it.value
              }))
              return
            }

            change(name, value.value)
          }}
          value={selected ?? null as any}
          multiple={multiple}
          getOptionLabel={getOptionLabel}
          getOptionDisabled={option=>!!option.disabled && !!optionDisabled}
          getOptionSelected={(option, value: IAutocompleteOption | string) => {
            if(typeof value === "string") {
              return option.value === value
            }
            return option.value === value.value
          }}
          renderOption={(option)=> {
            let optionColor: TypographyProps['color'] = 'textPrimary'

            if(option.disabled) {
              optionColor = 'secondary'
            }

            if((option.value as any) === SelectAllSymbol){
              optionColor = 'primary'
            }

            return (
              <div>
                <Typography color={optionColor} component="span">{option.label}</Typography>
                {option.caption && <Typography color="textSecondary" variant="overline" component="span">&nbsp;{option.caption}</Typography>}
              </div>
            )
          }}
          filterOptions={(options, state) => {
            const { inputValue } = state
            return options.filter(it => {
              const labelSearch = toLower(it.label).includes(toLower(inputValue))
              const captionSearch = !it.caption ? false : toLower(it.caption).includes(toLower(inputValue))
              return labelSearch || captionSearch
            })
          }}
          renderTags={(value) => {
            const tags = value.map(it => it.label)
            return <span className={classes.tag}>({tags.length}) {tags.join(', ')}</span>
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                className={classes.input}
                label={label}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off'
                }}
                fullWidth
                variant="outlined"
                name={name}
                InputLabelProps={{
                  shrink: true
                }}
              />
            )
          }}
          {...rest}
        />
        {(helperText || (touched && !valid)) && <FormHelperText>{error || helperText}</FormHelperText>}
      </Loader>
    </FormControl>
  );
}


const useStyles = makeStyles(() => {
  return {
    tag: {
      maxWidth: 'calc(100% - 40px)',
      display: 'inline-block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal'
    },
    input: {
      minWidth: '180px'
    }
  }
})

export default Autocomplete

