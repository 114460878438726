import React, {useEffect, useRef } from 'react';
import {useHistory, useParams} from 'react-router';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import EmptyList from '../../../components/EmptyList';
import Loader from '../../../components/Loader';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import useColumns, { IColumn } from '../../../hooks/useColumns';
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import {useListPaymentMethod} from "../../../api/paymentMethod";
import PaymentMethodList from "./PaymentMethodList";
import PaymentMethodDetails from "./Details";
import PaymentMethodFilter, {IPaymentMethodFilter} from "./PaymentMethodFilter";
import useFilter from "../../../hooks/useFilter";
import {Waypoint} from "react-waypoint";
import FilterWrapper, { ICollapseHandle } from '../../../components/FilterWrapper/FilterWrapper';

const emptyFilter: IPaymentMethodFilter = {
  provider: undefined,
  group: undefined,
  enabled: undefined
};


const defaultColumns: IColumn[] = [
  { id: 'id', active: true, name: 'Method ID' },
  { id: 'name', active: true, name: 'Name' },
  { id: 'enabled', active: true, name: 'Enabled' },
  { id: 'group', active: true, name: 'Group' },
  { id: 'provider', active: true, name: 'Provider' },
  { id: 'priority', active: true, name: 'Priority' },
  { id: 'flow', active: true, name: 'Flow' },
  { id: 'externalId', active: true, name: 'External ID' },
  { id: 'depositFixed', active: true, name: 'Deposit Fixed' },
  { id: 'deposit', active: true, name: 'Deposit' },
  { id: 'fixedCurrency', active: true, name: 'Fee currency' },
];

export default function PaymentMethodPage() {
  const classes = useGlobalStyles();
  const [{ items, error, full }, loading, load ] = useListPaymentMethod();
  const [columns, ColumnsFilter] = useColumns('payment-methods', defaultColumns);
  const history = useHistory();
  const [setFilter, filter] = useFilter('payment-methods', emptyFilter, false);
  const { methodId }: any = useParams();
  const accordionRef = useRef<ICollapseHandle>(null);

  function setDetails(id?: string) {
    if(id) {
      history.push(`/settings/payment-methods/${id}`);
    } else{
      history.push(`/settings/payment-methods`);
    }
  }

  useEffect(() => {
    accordionRef.current?.collapse();
    load({filter, cursor: 0});
  }, [filter])

  function onUpdate() {
    load();
  }

  return (
    <Grid container spacing={3}>
      <FilterWrapper ref={accordionRef}>
        <PaymentMethodFilter filter={filter as IPaymentMethodFilter} onChange={setFilter} />
      </FilterWrapper>
      <Grid item className={classes.table} style={{ width: '100%', minWidth: 'fit-content' }}>
        <Paper className={classes.paper}>
          <CardActions>
            <Grid container justify="space-between">
              <Grid item>
                <Button type="button" variant="contained" size="large" onClick={() => setDetails("new")}>Create</Button>
              </Grid>
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          <Loader loading={loading}>
            {items.length > 0
              ? <PaymentMethodList items={items} columns={columns} onRowClick={setDetails}/>
              : <EmptyList error={error} loading={loading} />
            }
          </Loader>
          {!loading && !error && !full && <Waypoint onEnter={() => load({ cursor: items.length, filter })} />}
        </Paper>
      </Grid>
      {(methodId) &&
        <PaymentMethodDetails
          id={methodId}
          onClose={() => setDetails()}
          onSave={() => onUpdate()}
        />
      }
    </Grid>
  )
}
