import React, { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router';

import { isSameDay } from 'date-fns';

import Typography from '@material-ui/core/Typography';
import DetailsPopup from '../../../components/Layout/DetailsPopup';
import TabsPanel from '../../../components/Tabs';
import UISettings from './UISettings';
import AuthorityTab from './AuthorityTab';
import CustomerLimitsTab from './LimitsTab';
import EmptyList from '../../../components/EmptyList';
import CustomerOverviewTab from './OverviewTab';
import OrdersTab from './OrdersTab';
import FeesTab from './FeesTab';
import CustomerActivity from './ActivityTab';
import TextCopy from '../../../components/TextCopy';
import { useCustomerSettingsUpdate, useCustomerSmsReset, ICustomerSettings, defaultSettings, useCustomerDetails } from '../../../api/customers';
import CustomerAddresses from './Addresses';
import CustomerWallets from './Wallets';
import { useBreakpoints } from '../../../hooks/useBreakpoints ';
import StarIcon from '@material-ui/icons/Star';
import { amber } from '@material-ui/core/colors';
import LinkIcon from '@material-ui/icons/Link';
import { Button, makeStyles } from '@material-ui/core';
import Descriptor from '../../../components/Descriptor';
import { getUTCTime } from '../../../helpers/datesHelper';
import WalletsMasterAccountTab from './WalletsMasterAccountTab';

const useStyles = makeStyles(theme => ({
  icon: {
    color: amber[600],
  },
  customerInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      gap: '15px',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  },
  customerId: {
    display: 'flex',
    alignItems: 'center',
  },
  subAccount: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  descriptor: {
    width: '20px',
  }
}));

interface IProps {
  id: string;
  onClose: any;
}

export default function CustomerDetails({ id, onClose }: IProps) {
  const {hash} = useLocation();
  const history = useHistory();
  const [{ data: customer, error }, loading, load, updateCustomer] = useCustomerDetails();
  const [activeTab, setActiveTab] = useState(hash.replace('#', ''));
  const classes = useStyles();
  const [update, updating] = useCustomerSettingsUpdate((res: any) => {
    if (res.success) {
      load(id);
    }
  }, 'Settings updated');
  const [resetSMS, smsResetting] = useCustomerSmsReset((res: any) => {
    if (res.success) {
      load(id);
    }
  });

  useEffect(() => {
    load(id);
  }, [id]);

  function saveSettings(data: ICustomerSettings) {
    const settings = {
      customerId: id,
      ...data,
      postback: data.postback,
      ui: JSON.stringify(data.ui)
    };
    update(settings);
  }

  if (!customer.settings && customer.authorities?.includes('ROLE_MERCHANT')) {
    customer.settings = { ...defaultSettings };
  }

  let tabs: any[] = [];
  if (Object.keys(customer).length) {
    tabs = [
      {
        title: 'Overview',
        value: 'overview',
        content: <CustomerOverviewTab
          customer={customer}
          onSave={saveSettings}
          resetSMS={resetSMS}
          smsResetting={smsResetting}
          loadCustomer={load}
        />
      },
      {
        title: 'Fees & APMs',
        value: 'fees',
        content: <FeesTab customer={customer} updateSettings={saveSettings} />
      },
      {
        title: 'Authority & API',
        value: 'authorityAndAPI',
        content: <AuthorityTab customer={customer} onUpdate={updateCustomer} loadCustomer={load} />
      },
      {
        title: 'TX Limits',
        value: 'txLimits',
        content: <CustomerLimitsTab id={customer.id} isMerchant={customer.authorities?.includes('ROLE_MERCHANT')} />
      },
      {
        title: 'Orders',
        value: 'orders',
        content: <OrdersTab id={customer.id} />
      },
      {
        title: 'Activity',
        value: 'activity',
        content: <CustomerActivity id={customer.id} />
      },
      {
        title: 'Addresses',
        value: 'addresses',
        content: <CustomerAddresses id={customer.id} />
      }
    ];
    if (customer.authorities?.includes('ROLE_MERCHANT')) {
      tabs.push({
        title: 'UI',
        value: 'ui',
        content: <UISettings customer={customer} onSave={saveSettings} />
      }, {
        title: 'Wallets & Operations',
        value: 'wallets',
        content: customer.isMaster
            ? <WalletsMasterAccountTab id={customer.id} />
            : <CustomerWallets id={customer.id} />
      });
    }
  }
  const activeTabIndex = tabs.findIndex(item=>item.value === activeTab);
  const isSmallMonitor = useBreakpoints(1150);

  const FIFTEEN_MINUTES = 900000;

  return (
    <DetailsPopup loading={loading || updating} onClose={onClose} width={!isSmallMonitor ? 1100 : 'calc(100% - 40px)'}>
      {error
        ? <EmptyList error={error} loading={loading} />
        : (
          <>
            <div className={classes.customerInfo}>
              <Typography variant="h6" component="div" className={classes.customerId}>
                Customer {customer.id}<TextCopy text={customer.id} />
                {customer?.isMaster && <Descriptor className={classes.descriptor} group="masterAccount" title="master"><StarIcon className={classes.icon} /></Descriptor>}
                {customer?.masterAccountId && <Descriptor className={classes.descriptor} group="masterAccount" title="subAccount"><LinkIcon className={classes.icon} /></Descriptor>}
              </Typography>
              {customer?.masterAccountId &&
                <div className={classes.subAccount}>
                  {((customer.createdAt + FIFTEEN_MINUTES) > Date.now()) && <Typography>DISABLED</Typography>}
                  <Button
                    variant="contained"
                    onClick={() => history.push(`/customers/customer/${customer?.masterAccountId}`)}
                  >
                    Go to Master Account
                  </Button>
                </div>}
            </div>
            <TabsPanel tabs={tabs} onTabChange={setActiveTab} activeTab={activeTabIndex >= 0 ? activeTabIndex : 0} />
          </>
        )}
    </DetailsPopup>
  );
}
