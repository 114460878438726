import React from 'react';
import { Select, BoolSelect, Input } from '../../components/Form';
import { CurrencyType } from '../../api/currency';
import TableFilter from "../../components/Filter";

export interface ICurrenciesFilter {
  type?: CurrencyType;
  enabled?: boolean;
}

interface IProps {
  filter: ICurrenciesFilter;
  onChange: any;
}

export default function CurrenciesFilter({ filter, onChange }: IProps) {
  return (
    <TableFilter
      onApply={onChange}
      filter={filter}
    >
      <Select
        name="type"
        label="Type"
        options={Object.keys(CurrencyType).map((s) => ({ value: s, label: s }))}
      />
      <BoolSelect
        name="enabled"
        label="Enabled"
      />
      <Input
        name="input"
        label="Currency/Symbol/Chain"
        margin="none"
      />
    </TableFilter>
  );
}
