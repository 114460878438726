import React, { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { ACLType } from '../../../../api/acl';
import DateView from '../../../../components/DateView';
import { DataRow } from '../../../../components/DataView';
import { ICustomerActivity } from '../../../../api/customers';
import useGlobalStyles from '../../../../components/Layout/useGlobalStyles';
import Tablecell from '../../../../components/TableCell/TableCell';
import useMobileStyles from '../../../../hooks/useMobileStyles';

interface IProps {
  item: ICustomerActivity;
  setAcl: any;
  lastChild?: ICustomerActivity
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  highlight: {
    background: 'rgba(256,256,256,0.1)',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  headers: {
    wordBreak: 'break-all'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  buttons: {
    display: 'flex',
    gap: '5px',
  },
}));

export default function ActivityRow({ item, setAcl, lastChild }: IProps) {
  const [expand, setExpand] = useState(false);
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const mobileClasses = useMobileStyles();
  return (
    <>
      <TableRow hover onClick={() => setExpand(!expand)} 
        className={clsx(globalClasses.pointer,mobileClasses.row, lastChild && mobileClasses.lastChild, expand && classes.highlight)}>
        <Tablecell title="Type" position="center">{item.type}</Tablecell>
        <Tablecell title="Source">{item.source}</Tablecell>
        <Tablecell title="IP">
          <div className={classes.row}>
            {item.ip}
            {item.ip && (
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setAcl({ type: ACLType.IP_ADDRESS, value: item.ip })}
                  size="small"
                >
                  Blacklist
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setAcl({ type: ACLType.IP_ADDRESS, value: item.ip, white: true })}
                  size="small"
                >
                  Whitelist
                </Button>
              </div>
              
            )}
          </div>
        </Tablecell>
        <Tablecell title="Country">{item.country}</Tablecell>
        <Tablecell title="Created">
          <DateView value={item.createdAt} />
        </Tablecell>
      </TableRow>
      {expand && (
        <TableRow>
          <TableCell colSpan={5} className={classes.highlight}>
            <DataRow label="City" labelWidth={80}>{item.city}</DataRow>
            <DataRow label="Endpoint" labelWidth={80}>{item.endpoint}</DataRow>
            <DataRow label="Method" labelWidth={80}>{item.method}</DataRow>
            <DataRow label="User agent" labelWidth={80}>{item.userAgent}</DataRow>
            <DataRow label="Headers" labelWidth={80}>
              <div className={classes.headers}>{item.headers}</div>
            </DataRow>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
